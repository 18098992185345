<template>
  <!-- MODAL Edit Incidence -->
  <div id="editHistoricIncidenceModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 shadow">
      <!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="warning-outline" class="tx-18 me-2 align-bottom"></ion-icon>
            {{ dictionary['Actualizar observaciones'][session.user.language] + ": " + editHistoricIncidenceData.id }}
          </h6>
        </div>
        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleEditCommentsModal')">
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <!-- CONTENT Edit Device -->
      <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
        <div id="editDeviceScroll" style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="col-12 p-2 pe-0 pb-0">
            <form class="p-0 overflow-hidden d-flex flex-wrap pe-2">
              <div class="col-12 m-0 p-2 border rounded overflow-hidden">
                <div class="bg-menu text-white p-1 m-0 rounded">
                  {{ dictionary['Incidencia'][session.user.language]+" "+editHistoricIncidenceData.typeOfIncidence }}
                </div>              
               

                <div class="col-12 p-0 ps-1 pe-1">
                  <hr class="tx-clomos-dark ms-2 me-2">
                </div>
                
                <div class="row m-0 ps-1">
                  <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-2">
                    {{ dictionary['Comentarios'][session.user.language] }}
                  </label>
                </div>
                <div class="d-flex flex-row col-12 p-1 form-group">
                  
                  <div class="col-10">
                  <textarea id="inputNewObservation" v-model="newObservation" class="form-control col-12 p-0" style="color:black; min-height:100px;"></textarea>
                </div>               
                </div>               
                <div class="col-12 mt-2 mb-2">
                  <!-- SUBMIT SETTINGS -->
                  <button type="button" @click="setIncidenceConfiguration" id="btnSubmitDeviceConfiguration" class="button-ok gradient-tablas rounded-3" style="height: 2rem !important;">
                    <span class="button-text text-white">
                      <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-14 hydrated"></ion-icon>
                      <span class="text-white mt-0 tx-12">{{ dictionary['Actualizar observaciones'][session.user.language] }}</span>
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import $ from 'jquery';

import api from '@/services/api';

export default {
  name: "EditCommentsModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    incidenceId: {
      type: String,
      required: true,
    },
    incidenceInfo:{
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      editHistoricIncidenceData: {
        id:this.incidenceInfo.id,
        label: "",
        observaciones: "",
        status:"",
        level: "",
        typeOfIncidence:"",
        dateInvestigation:"",
        dateClosed:"",
        dateOpen:"",
        dateProgress:"",
        dateSolved:"",
        newObservations:[],
      },
      newObservation: '',
    };
  },
  computed: {
    
  },
  emits: ["toggleEditCommentsModal"],
  methods: {
 
    
  async setIncidenceConfiguration() {
      
    if (this.newObservation.trim().length === 0) {
      $("#inputNewObservation").addClass("missing");
      setTimeout(() => {
        $("#inputNewObservation").removeClass("missing");
      }, 3000);
      return;      
      }     
      this.editHistoricIncidenceData.observaciones=this.newObservation;   
    
    
     try {
        let response = await api.setHistoricComment(this.editHistoricIncidenceData);        
        if (response["status-code"] === "200") {
          window.alert("Actualizado con exito");
          this.$emit('toggleEditCommentsModal');
        } else {
          window.alert("Error al actualizar la incidencia");
        }
      } catch (error) {
        window.alert("Error al actualizar la incidencia");
        console.error("Error:", error);
      }
     
      
    },
  },
  
}
</script>

<style>

#editHistoricIncidenceModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>