<template>
  <!-- SECTION Lockers-->
  <div id="lockersSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
        <div class="row col-lg-12 col-md-12 col-12 table-responsive m-0 p-0 pb-3">
          <div class="position-sticky col-12 m-0 pt-2 pb-2 bg-head text-white border-1">
            <div class="row p-0 m-0">
              <!--Primera sección-->
              <div class="row col-xl-5 col-lg-6 col-md-12 col-12 m-0 pt-0 ps-3 pe-4 text-white rounded-5 rounded-end">
                <div class="col m-0 pt-0 ps-0 text-white d-flex flex-row">
                  <h6 class="tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 d-flex flex-row">
                    <ion-icon name="location" class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ dictionary["Empresas"][session.user.language] }}
                  </h6>               
                </div>

              </div>
              <!-- Fin Primera sección-->
              <!--Segunda sección-->
              <div class="row col-xl-7 col-lg-6 col-md-9 col-12 justify-content-end p-0 m-0 pe-3">
                <div class="row col-lg-8 col-md-8 col-8 justify-content-end">
                  <span class="text-end text-white mt-1 mb-2 pe-4">
                    <ion-icon name="file-tray-stacked" class="tx-16 mb-1 me-1 align-bottom"></ion-icon>
                    {{ dictionary["Lockers"][session.user.language] }}
                  </span>
                </div>
                <div class="row col-xl-2 col-lg-3 col-md-3 col-4 justify-content-end p-0 m-0 pt-1 pe-3">
                  <button v-if="this.session.user.rol === '255'" id="btnDisplayNewLockerModal"
                    :title="dictionary['Añadir locker'][session.user.language]" style="width: 24px; max-height: 24px;"
                    class="button-ok bg-white mt-0 me-2 rounded shadow" @click="toggleNewLockerModal">
                    <span>
                      <span class="button-text text-dark">
                        <ion-icon name="add-circle-outline" class="text-dark pt-0 tx-18 hydrated"
                          data-bs-toggle="tooltip" data-bs-placement="bottom"
                          :title="dictionary['Añadir locker'][session.user.language]"></ion-icon>
                      </span>
                    </span>
                  </button>

                  <button style="width: 24px; max-height: 24px;" class="button-ok bg-white mt-0 me-2 rounded shadow"
                  @click="this.$emit('toggleConfigurationView')">
                  <span>
                    <span class="button-text text-dark">
                      <ion-icon name="return-down-back-outline" class="text-dark pt-0 me-0 tx-18 hydrated"></ion-icon>
                    </span>
                  </span>
                </button>
                </div>
              </div>
            </div>
          </div>
          <!-- COMPONENT Lockers -->
          <div class=" col-md-12 col-12 m-0 ps-4 p-3 pt-0 pb-0 mt-2 h-auto ">
            <!-- TABLE Lockers -->
            <div v-show="displayType === 'table'" class="col-12 bg-caja rounded-5 shadow pt-2 pb-4"
              id="lockersTableContent"
              style="z-index: 0; height: auto;border-top: solid 5px #005793;border-bottom: solid 2px #008db4;">
              <table id="lockersConfigurationDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
                <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                  <tr>
                    <th v-for="column in lockersColumns" :key="column.data" :width="column.width"
                      class="fw-medium text-nowrap p-2">
                      {{ dictionary[column.text][session.user.language] }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>        
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- SECTION Lockers -->
  <!-- <StatusComponent v-if="this.$route.params.currentDisplay==='lockers'"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :visibility="visibility"
    :currentCompany="currentCompany"
    :requestedLockerId="targetLockerId"
    :dataTableOptions="dataTableOptions">
    </StatusComponent> -->

  <!-- MODAL New Locker -->
  <NewLockerModal v-if="display.NewLockerModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" v-on:toggleNewLockerModal="toggleNewLockerModal">
  </NewLockerModal>

  <!-- MODAL Edit Locker -->
  <EditLockerModal v-if="display.editLockerModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :lockerId="targetLockerId"
    v-on:toggleEditLockerModal="toggleEditLockerModal">
  </EditLockerModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal v-if="display.deleteLockerModal" :session="session" :dictionary="dictionary" :countries="countries"
    :alertHeader="dictionary['Locker a eliminar'][session.user.language] + ': '" :alertTarget="targetLockerId"
    v-on:acceptAlert="onDeleteLocker" v-on:dismissAlert="toggleDeleteLockerModal">
  </TextAlertModal>

  <!-- SECTION Slots -->
  <SlotsConfigurationComponent v-if="this.$route.params.currentDisplay === 'lockers'" :session="session" :dictionary="dictionary"
    :countries="countries" :timeZones="timeZones" :visibility="visibility" :currentCompany="currentCompany"
    :availableCompanies="availableCompanies" :targetLockerId="targetLockerId" :dataTableOptions="dataTableOptions"
    v-on:updateTargetLockerId="updateTargetLockerId">
  </SlotsConfigurationComponent>

</template>

<script>
import $ from 'jquery';
import SlotsConfigurationComponent from '@/components/lockers/SlotsConfigurationComponent';
import NewLockerModal from '@/components/lockers/NewLockerModal';
import EditLockerModal from '@/components/lockers/EditLockerModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "LockersConfigurationComponent",
  components: { SlotsConfigurationComponent, NewLockerModal, EditLockerModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    targetEmplacementId: {
      type: String,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    },
  },
  watch: {
    currentCompany: async function () {
      if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
        await this.getLockersDataByCompany();
      }
      else {
        await this.getEmplacementData();
        await this.getLockersDataByEmplacement();
      }
      this.onChangeDisplayType(this.displayType);
      if (this.lockerList[0] != undefined && this.targetLockerId === "")
        this.targetLockerId = this.lockerList[0].id
    },
    targetEmplacementId: async function () {
      if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
        await this.getLockersDataByCompany();
      }
      else {
        await this.getEmplacementData();
        await this.getLockersDataByEmplacement();
      }
      this.onChangeDisplayType(this.displayType);
      if (this.lockerList[0] != undefined && this.targetLockerId === "")
        this.targetLockerId = this.lockerList[0].id;
    }
  },
  data() {
    return {
      lockersColumns: [
        {
          text: "Identificador", data: "lockerId", width: "25%", className: "tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id=" + data + " class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
              + "<span data-id=" + data + " class='btnEditLocker c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>" + data + "</span>";
          }
        },
        { text: "Nombre", data: "lockerLabel", width: "15%", className: "tx-left align-middle" },
        { text: "Tipo", data: "lockerType", width: "10%", className: "tx-left align-middle" },
        {
          text: "Emplazamiento", data: "lockerEmplacement", width: "10%", className: "tx-left align-middle",
          render: (data) => {
            let emplacementLabel = data;
            if (this.emplacementList.length > 0) {
              for (let emplacement in this.emplacementList)
                if (this.emplacementList[emplacement].id === data)
                  emplacementLabel = this.emplacementList[emplacement].label;
            }
            return emplacementLabel;
          }
        },
        {
          text: "Fecha de comunicación", data: "lockerCommunicationDateTime", width: "10%", className: "align-middle",
          render: (data) => {
            return (data != "")
              ? "<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
              : "<span style='display: none;'></span> <spam>-</spam>";
          }
        },
        {
          text: "Configuración", data: "lockerId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id=" + data + " class='btnEditLocker btn tx-18 tx-primary'></ion-icon>"
          }
        },
        {
          text: "Eliminar", data: "lockerId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id=" + data + " class='btnDeleteLocker btn tx-18 tx-danger'></ion-icon>"
          }
        },
      ],
      targetEmplacementData: {},
      targetLockerId: this.$route.params.targetEntity,
      lockerList: [],
      emplacementList: [],
      displayType: 'table',
      lockerCardsPage: 0,
      display: {
        NewLockerModal: false,
        editLockerModal: false,
        linkLockerModal: false,
        deleteLockerModal: false,
      },
      linkAction: "link",
      currentIndex: 0,
    }
  },
  computed: {
    lockerCardsByPages() {
      let lockerCardsByPages = []
      let i = 0;
      while (i * 12 < this.lockerList.length) {
        lockerCardsByPages.push(this.lockerList.slice(i * 12, i * 12 + 12));
        i++;
      }
      return lockerCardsByPages;
    },
    currentLocker() {
      return this.lockerList[this.currentIndex] || {};
    }
  },
  emits: ['toggleConfigurationView'],
  methods: {
    nextLocker() {
      if (this.currentIndex < this.lockerList.length - 1) {
        this.currentIndex += 1;
      }
    },
    prevLocker() {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1;
      }
    },
    // DISPLAY
    
    onChangeDisplayType(type) {
      this.displayType = type;
      setTimeout(() => {
        if (type === "blueprint") this.displayLockersBlueprint();
        if (type === "table") this.displaylockersConfigurationDataTable();
        this.setButtonFunctions();
      }, 100);
    },
    onSetCardPage(index) {
      this.lockerCardsPage = index;
      this.setButtonFunctions();
    },
    displayLockersBlueprint() {
      setTimeout(() => {
        $(".lockerBlueprintMarker").get().forEach(marker => {
          let markerId = marker.getAttribute("data-id");
          for (let i in this.lockerList) {
            if (this.lockerList[i].id === markerId)
              marker.style = this.bluePrintMarkerPosition(this.lockerList[i]);
          }
        })
      }, 1000);
      let src = "";
      if (this.targetEmplacementData.variables != undefined
        && this.targetEmplacementData.variables.dynamic != undefined
        && this.targetEmplacementData.variables.dynamic.urlPlano != undefined)
        src = this.targetEmplacementData.variables.dynamic.urlPlano.value;
      if (src != undefined && src != '') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero=' + src + '&identificadorusuario=' + this.session.user.id + '&Token=' + localStorage.getItem("ClomosAuthToken");
        }
        catch (e) {
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    bluePrintMarkerPosition(locker) {
      let markerX = 0;
      let markerY = 0;
      let blueprintCanvas = document.getElementById('emplacementBlueprintForLockers');
      if (blueprintCanvas != null) {
        let ratioX = blueprintCanvas.naturalWidth / blueprintCanvas.offsetWidth;
        let ratioY = blueprintCanvas.naturalHeight / blueprintCanvas.offsetHeight;

        let domX = locker.geoJSON.geometry.coordinates[0] - blueprintCanvas.offsetLeft + 7;
        let domY = locker.geoJSON.geometry.coordinates[1] - blueprintCanvas.offsetTop + 7;

        markerX = Math.floor(domX / ratioX);
        markerY = Math.floor(domY / ratioY);
      }
      return "position: absolute; top: " + markerY + "px; left: " + markerX + "px;"
    },
    displaylockersConfigurationDataTable() {
      $("#lockersConfigurationDataTable").DataTable().clear()
      for (let i in this.lockerList) {
        $("#lockersConfigurationDataTable").DataTable().rows.add([{
          lockerId: this.lockerList[i].id,
          lockerEmplacement: this.lockerList[i].parent,
          lockerLabel: this.lockerList[i].label,
          lockerLocation: this.lockerList[i].parent,
          lockerType: this.lockerList[i].type,
          lockerStatus: this.lockerList[i].status,
          lockerCommunicationDateTime: (this.lockerList[i].communicationDateTime != undefined && this.lockerList[i].communicationDateTime != "") ? new Date(this.lockerList[i].communicationDateTime) : "",
          lockerVersion: this.lockerList[i].version,
        }]);
      }
      $("#lockersConfigurationDataTable").DataTable().draw();
      this.setButtonFunctions();
      if ($("#lockersConfigurationDataTable_paginate").get()[0] != undefined) $("#lockersConfigurationDataTable_paginate").get()[0].addEventListener("click", this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },
    async toggleNewLockerModal() {
      if (this.display.NewLockerModal) {
        if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
          await this.getLockersDataByCompany();
          this.displaylockersConfigurationDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getLockersDataByEmplacement();
          this.displaylockersConfigurationDataTable();
        }
        this.onChangeDisplayType(this.displayType);
      }
      this.display.NewLockerModal = !this.display.NewLockerModal;
    },
    async toggleEditLockerModal() {
      if (this.display.editLockerModal) {
        if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
          await this.getLockersDataByCompany();
          this.displaylockersConfigurationDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getLockersDataByEmplacement();
          this.displaylockersConfigurationDataTable();
        }
        this.onChangeDisplayType(this.displayType);
      }
      this.display.editLockerModal = !this.display.editLockerModal;
    },
    async toggleLinkLockerModal() {
      if (this.display.linkLockerModal) {
        if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
          await this.getLockersDataByCompany();
          this.displaylockersConfigurationDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getLockersDataByEmplacement();
          this.displaylockersConfigurationDataTable();
        }
        this.onChangeDisplayType(this.displayType);
      }
      this.display.linkLockerModal = !this.display.linkLockerModal;
    },
    async toggleDeleteLockerModal() {
      if (this.display.deleteLockerModal) {
        if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
          await this.getLockersDataByCompany();
          this.displaylockersConfigurationDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getLockersDataByEmplacement();
          this.displaylockersConfigurationDataTable();
        }
        if (this.lockerList[0] != undefined) this.targetLockerId = this.lockerList[0].id;
        this.onChangeDisplayType(this.displayType);
      }
      this.display.deleteLockerModal = !this.display.deleteLockerModal;
    },
    async refreshView() {
      await this.getEmplacementList();
      if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
        await this.getLockersDataByCompany();
        this.displaylockersConfigurationDataTable();
        
      }
      else if (this.targetEmplacementId != '') {
        await this.getEmplacementData();
        await this.getLockersDataByEmplacement();
        this.onChangeDisplayType(this.displayType);
       
      }
      if (this.lockerList[0] != undefined && this.targetLockerId === "")
        this.targetLockerId = this.lockerList[0].id;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetLocker").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetLocker);
        });
        $(".btnEditLocker").get().forEach(btn => {
          btn.addEventListener('click', this.onEditLocker);
        });
        $(".btnLinkLocker").get().forEach(btn => {
          btn.addEventListener('click', this.onLinkLocker);
        });
        $(".btnUnlinkLocker").get().forEach(btn => {
          btn.addEventListener('click', this.onUnlinkLocker);
        });
        $(".btnDeleteLocker").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteLocker);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getLockersDataByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getLockerListByCompany(request);
      this.lockerList = api.parseLockersFromResponse(response);
      if (callback && typeof callback === "function") callback(this.lockerList);
    },
    async getLockersDataByEmplacement(callback) {
      let request = {
        emplacementId: this.targetEmplacementId,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getMachineListByEmplacement(request);
      this.lockerList = api.parseMachinesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.lockerList);
    },
    async getEmplacementData(callback) {
      let response = await api.getEmplacementData(this.targetEmplacementId);
      this.targetEmplacementData = api.parseEmplacementFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetEmplacementData);
    },
    async getEmplacementList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    //UPDATE
    updateTargetLockerId(newId) {
      this.targetLockerId = newId;
    },
    // TARGET 
    onTargetLocker(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetLockerId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetLockerId } })
    },
    // SET 
    onEditLocker(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetLockerId = event.target.getAttribute('data-id');
      this.$router.replace({ params: { targetEntity: this.targetLockerId } })
      this.toggleEditLockerModal();
    },
    // LINK 
    onLinkLocker(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetLockerId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetLockerId } })
      this.linkAction = "link"
      this.toggleLinkLockerModal();
    },
    // UNLINK 
    onUnlinkLocker(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetLockerId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetLockerId } })
      this.linkAction = "unlink"
      this.toggleLinkLockerModal();
    },
    // DELETE
    async onDeleteLocker(event) {
      if (!this.display.deleteLockerModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetLockerId);
        this.targetLockerId = event.target.getAttribute('data-id');
        this.$router.replace({ params: { targetEntity: this.targetLockerId } })
      }
      else {
        let response = await api.deleteLocker(this.targetLockerId);
        if (response["status-code"] === "200") {
          window.alert("El locker ha sido eliminada satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el locker.");
        }
      }
      await this.toggleDeleteLockerModal();
    }
  },
  async created() {
    await this.getEmplacementList();
    if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
      await this.getLockersDataByCompany();
      this.displaylockersConfigurationDataTable();      
    }
    else if (this.targetEmplacementId != '') {
      await this.getEmplacementData();
      await this.getLockersDataByEmplacement();
      this.onChangeDisplayType(this.displayType)
     
    }
    if (this.lockerList[0] != undefined && this.targetLockerId === "")
      this.targetLockerId = this.lockerList[0].id;
  },
  mounted() {
    $("#lockersConfigurationDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.lockersColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btnCopyId:hover,
.btnTargetLocker:hover,
.btnLinkLocker:hover,
.btnUnlinkLocker:hover,
.btnEditLocker:hover,
.btnDeleteLocker:hover {
  color: var(--main-dark) !important;
}
</style>