<template>
  <!-- MODAL Edit Machine -->
  <div id="editMachineModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 shadow">
      <!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="cog" class="tx-18 me-2 align-bottom"></ion-icon>
            {{ dictionary['Editar máquina'][session.user.language] + ": " + editMachineData.label }}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleEditMachineModal')">
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <!-- CONTENT Edit Machine -->
      <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="col-12 p-3 pe-0 pb-0">

            <!-- FORM Machine -->
            <form class="row m-0 p-0 pe-3 overflow-hidden">
              <div class="col-lg-8 col-md-12 col-12">
                <div class="form-group border rounded mb-3 col-12 d-flex flex-wrap">
                  <!-- NOMBRE -->
                  <div class="col-lg-6 col-md-6 col-12">
                    <label class="col-12 p-0 pt-3 tx-bold text-clomos tx-14">
                      {{ dictionary['Nombre'][session.user.language] }}
                    </label>
                    <input v-model="editMachineData.label" id="inputEditMachineName" type="text"
                      class="form-control form-control-line" style="color:black;">
                  </div>
                  <!-- PADRE -->
                  <div class="col-lg-6 col-md-6 col-12">
                    <label class="col-12 p-0 pt-3 tx-bold text-clomos tx-14">
                      {{ dictionary['Emplazamiento'][session.user.language] }}
                    </label>
                    <select readonly disabled v-model="editMachineData.parent" id="selectNewMachineType"
                      class="form-control form-control-line" style="color:black; max-height: 10px;">
                      <option v-for="emplacement in emplacementList" :key="emplacement.id" :value="emplacement.id">
                        {{ emplacement.label }}</option>
                    </select>
                  </div>

                  <!-- CONTENT Map -->
                  <div class="col-12 pt-3">
                    <div v-if="this.$route.params.currentDisplay === 'commandCenters'" id="machineMapContent"
                      class="m-0 p-3 rounded-3" style="height: 250px; index: 0; border:solid 2px;">
                    </div>
                  </div>

                  <!-- LATITUD -->
                  <div class="col-lg-6 col-md-6 col-12">
                    <label class="col-12 p-0 pt-3 tx-bold text-clomos tx-14">
                      {{ dictionary['Latitud'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="editMachineData.geoJSON.geometry.coordinates[0]" id="inputEditBundleId"
                        type="number" min=0 class="form-control form-control-line" style="color:black;"
                        @change="onPositionChanged">
                    </div>
                  </div>

                  <!-- LONGITUD -->
                  <div class="col-lg-6 col-md-6 col-12">
                    <label class="col-12 p-0 pt-3 tx-bold text-clomos tx-14">
                      {{ dictionary['Longitud'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="editMachineData.geoJSON.geometry.coordinates[1]" id="inputEditBundleId"
                        type="number" min=0 class="form-control form-control-line" style="color:black;"
                        @change="onPositionChanged">
                    </div>
                  </div>

                  <div class="form-group col-12 p-0 pb-3 text-center">
                    <div class="col-12 mt-4">
                      <!-- SUBMIT MACHINE DATA-->
                      <button type="button" @click="setEditMachineData" id="btnSubmitMachineData"
                        class="button-ok gradient-tablas rounded-3">
                        <span class="button-text text-white">
                          <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                          <span class="text-white mt-0">{{ dictionary['Guardar cambios'][session.user.language] }}</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group border rounded mb-3 col-lg-4 col-md-12 col-12 bg-lat rounded-5 d-flex flex-wrap">

                <label class="col-12 p-0 pt-3 tx-14 tx-bold tx-clomos-dark" style="display: none;"
                  v-if="Object.keys(editMachineData.variables.analog)[0] != undefined">
                  {{ "ANA" }}
                </label>

                <div v-for="variable in editMachineData.variables.analog" :key="variable.analogVariableId"
                  class="col-12 mt-3">
                  <div class="m-0 p-0 w-100">
                    <label class="col-12 p-0 text-white tx-14">
                      {{ variable.label }}
                    </label>
                    <input v-model="editMachineData.variables.analog[variable.label].value"
                      :id="(variable.isConfiguration === 'false') ? ('editMachine' + variable.label) : 'inputEditMachine' + variable.label"
                      type="text" :disabled="variable.isConfiguration === 'false'"
                      :data-id="editMachineData.variables.analog[variable.label].variableId"
                      :data-type="editMachineData.variables.analog[variable.label].type"
                      class="inputEditMachine form-control form-control-line bg-white" style="color:black;">
                  </div>
                </div>
                <hr class="p-1 text-white w-100">
                <label class="col-12 p-0 tx-14 tx-bold tx-clomos-dark" style="display: none;"
                  v-if="Object.keys(editMachineData.variables.digital)[0] != undefined">
                  {{ "DIG" }}
                </label>

                <div v-for="variable in editMachineData.variables.digital" :key="variable.digitalVariableId"
                  class="col-12">
                  <div class="m-0 p-0 w-100">
                    <label class="col-12 p-0 text-white tx-14">
                      {{ variable.label }}
                    </label>
                    <div
                      :class="(variable.isConfiguration === 'false') ? ('pe-none ' + editMachineData.variables.digital[variable.label].value) : editMachineData.variables.digital[variable.label].value"
                      :id="(variable.isConfiguration === 'false') ? ('editMachine' + variable.label) : 'inputEditMachine' + variable.label"
                      :data-id="editMachineData.variables.digital[variable.label].variableId"
                      :data-type="editMachineData.variables.digital[variable.label].type"
                      :data-value="editMachineData.variables.digital[variable.label].value"
                      class="inputEditMachine form-control form-control-line border-0 tx-bold"
                      @click="(editMachineData.variables.digital[variable.label].value === 'true') ? editMachineData.variables.digital[variable.label].value = 'false' : editMachineData.variables.digital[variable.label].value = 'true'">
                      {{ dictionary[editMachineData.variables.digital[variable.label].value][session.user.language] }}
                    </div>
                  </div>
                </div>
                <hr class="p-1 text-white w-100">

                <label class="col-12 p-0 pt-3 tx-14 tx-bold tx-clomos-dark" style="display: none;"
                  v-if="Object.keys(editMachineData.variables.dynamic)[0] != undefined && Object.keys(editMachineData.variables.dynamic)[0] != 'configuracion'">
                  {{ "DIN" }}
                </label>

                <div v-for="variable in editMachineData.variables.dynamic" :key="variable.dynamicVariableId"
                  class="col-12">
                  <div class="m-0 p-0 w-100">
                    <label class="col-12 p-0 text-white tx-14">
                      {{ variable.label }}
                    </label>
                    <input v-model="editMachineData.variables.dynamic[variable.label].value"
                      :id="(variable.isConfiguration === 'false') ? ('editMachine' + variable.label) : 'inputEditMachine' + variable.label"
                      type="text" :disabled="variable.isConfiguration === 'false'"
                      :data-id="editMachineData.variables.dynamic[variable.label].variableId"
                      :data-type="editMachineData.variables.dynamic[variable.label].type"
                      class="inputEditMachine form-control form-control-line bg-white" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-12 p-0 pb-3">
                  <div class="col-12 mt-3">
                    <!-- SUBMIT SETTINGS-->
                    <button type="button" @click="setMachineVariables" id="btnSubmitMachineVariables"
                      class="button-ok gradient-tablas rounded-3">
                      <span class="button-text text-white">
                        <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                        <span class="text-white mt-0">{{ dictionary['Guardar variables'][session.user.language] }}</span>
                      </span>
                    </button>
                  </div>
                </div>

              </div>

             <!--  <div class="form-group border rounded mb-3 col-12 d-flex flex-wrap">

                <div class="col-12">
                  <div class="m-0 p-0 w-100">
                    <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                      {{ dictionary['Configuración'][session.user.language] }}
                      <span class="ps-1 small">(JSON)</span>
                    </label>
                    <textarea v-model="machineConfigurationForm.value" type="text" id="inputEditMachineConfiguration"
                      class="form-control form-control-line" style="color:black; text-align: start;"></textarea>
                  </div>
                </div>

                <div class="col-lg-6 col-12 m-0 p-0">
                </div>

                <div class="form-group col-lg-6 col-12 p-0 pb-3">
                  <div class="col-12 mt-4">
                   SUBMIT CONFIGURATION
                    <button type="button" @click="setMachineConfiguration" id="btnSubmitMachineConfiguration"
                      class="button-ok gradient-tablas rounded-3">
                      <span class="button-text text-white">
                        <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                        <span class="text-white mt-0">{{ dictionary['Guardar
                          configuración'][session.user.language]}}</span>
                      </span>
                    </button>
                  </div>
                </div>

                
              </div> -->

            </form>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
// Clomos
import api from '@/services/api';

export default {
  name: "EditMachineModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    machineId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editMachineData: {
        id: "",
        nifCif: "",
        dataConsumed: "",
        label: "",
        dclId: "",
        dclTypeId: "",
        dclClass: "",
        type: "",
        distributionGroup: "",
        parent: "",
        isBase: "",
        isPublic: 0,
        token: "",
        imgSrc: "",
        emplacement: "",
        fechaUltimaComunicacion: "",
        tieneAlarmaActivada: "",
        version: "",
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0, 0]
          }
        },
        variables: {
          analog: {},
          digital: {},
          dynamic: {},
        }
      },
      machineConfigurationForm: {
        id: "",
        variableId: "",
        value: ""
      },
      emplacementList: [],
      machineMap: undefined,
      machineMarker: undefined
    }
  },
  emits: ["toggleEditMachineModal"],
  methods: {
    onPositionChanged() {
      if (this.$route.params.currentDisplay === 'commandCenters') {
        this.machineMap.removeLayer(this.machineMarker);
        this.machineMarker = Leaflet.marker([this.editMachineData.geoJSON.geometry.coordinates[0], this.editMachineData.geoJSON.geometry.coordinates[1]]).addTo(this.machineMap);
        this.displayMachineMap()
      }
    },
    // SET
    displayMachineMap() {
      if (this.machineMap != undefined) {
        this.machineMap.invalidateSize();
        this.machineMap.remove();
      }
      var machineMap = Leaflet.map("machineMapContent");
      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang=' + this.session.user.language, { maxZoom: 19 }).addTo(machineMap);
      machineMap.on('click', this.onSetNewCoordinates)

      this.machineMap = machineMap;

      let machineMapListForGroup = [];
      if ($(".leaflet-control-attribution").get()[0] != undefined) $(".leaflet-control-attribution").get()[0].remove();
      var machineMarker = Leaflet.marker([this.editMachineData.geoJSON.geometry.coordinates[0], this.editMachineData.geoJSON.geometry.coordinates[1]]);
      machineMarker.addTo(machineMap);
      machineMapListForGroup.push(machineMarker);
      var machineMapGroup = Leaflet.featureGroup(machineMapListForGroup);
      this.machineMap.fitBounds(machineMapGroup.getBounds());

      this.machineMarker = machineMarker;
    },
    onSetNewCoordinates(event) {
      this.editMachineData.geoJSON = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [Number(event.latlng.lat), Number(event.latlng.lng)]
        }
      }
      this.machineMap.removeLayer(this.machineMarker);
      this.machineMarker = Leaflet.marker([this.editMachineData.geoJSON.geometry.coordinates[0], this.editMachineData.geoJSON.geometry.coordinates[1]]).addTo(this.machineMap);
    },
    async setEditMachineData() {
      let response = await api.setMachineData(this.editMachineData);
      if (response["status-code"] === "200") {
        window.alert("La máquina ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar la máquina.");
      }
      this.getEditMachineData();
    },
    async setMachineVariables() {
      let variablesData = {
        machineId: this.editMachineData.id
      };
      $(".inputEditMachine").get().forEach(input => {
        let variableLabel = input.id.replace("inputEditMachine", "");
        let ic = false;
        if (this.editMachineData.variables.analog[variableLabel] != undefined
          && this.editMachineData.variables.analog[variableLabel].isConfiguration === "true")
          ic = true;
        if (this.editMachineData.variables.digital[variableLabel] != undefined
          && this.editMachineData.variables.digital[variableLabel].isConfiguration === "true")
          ic = true;
        if (this.editMachineData.variables.dynamic[variableLabel] != undefined
          && this.editMachineData.variables.dynamic[variableLabel].isConfiguration === "true")
          ic = true;
        if (ic) {
          variablesData[variableLabel] = {
            value: (input.getAttribute("data-type") === "0")
              ? (input.getAttribute("data-value") === "true")
                ? 1
                : 0
              : input.value,
            id: input.getAttribute("data-id"),
            type: input.getAttribute("data-type")
          }
        }
      });
      let response = await api.setMachineVariables(variablesData);
      if (response["status-code"] === "200") {
        window.alert("La máquina ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar la máquina.");
      }
      this.getEditMachineData();
    },
    async setMachineConfiguration() {
      let response = await api.setMachineConfiguration(this.machineConfigurationForm);
      if (response["status-code"] === "200") {
        window.alert("La máquina ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar la máquina.");
      }
      this.getEditMachineData();
    },
    // GET 
    async getEditMachineData(callback) {
      let response = await api.getMachineData(this.machineId);
      this.editMachineData = api.parseMachineFromResponse(response);
      if (this.editMachineData.variables.dynamic['configuracion'] != undefined)
        this.machineConfigurationForm = {
          id: this.editMachineData.id,
          variableId: this.editMachineData.variables.dynamic['configuracion'].variableId,
          value: this.editMachineData.variables.dynamic['configuracion'].value
        }
      else
        this.machineConfigurationForm = {
          id: this.editMachineData.id,
          variableId: "",
          value: ""
        }
      if (callback && typeof callback === "function") callback(this.machineData);
    },
    async getEmplacementList() {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getEmplacementList(request);
      for (let i in response.plantas) [
        this.emplacementList[i] = {
          id: response.plantas[i].idDcl,
          label: response.plantas[i].etiqueta,
        }
      ]
    }
  },
  async created() {
    await this.getEditMachineData();
    await this.getEmplacementList();
    if (this.$route.params.currentDisplay === 'commandCenters')
      setTimeout(() => { this.displayMachineMap() }, 300);
  }
}
</script>

<style>
#editMachineModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>