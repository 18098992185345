<template>
<!-- MODAL New Emplacement -->
  <div id="newEmplacementModal" class="col-12 justify-content-center pe-6"  style="padding: 15vh 25%">
    <div class="col-12 m-0 p-0 section-container rounded-5 shadow">

<!-- HEAD New Company -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="location" class="tx-20 me-2 align-bottom"></ion-icon>
            {{dictionary['Nuevo '+'emplazamiento'][session.user.language]}}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleNewEmplacementModal')">	
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT New Emplacement -->
      <div class="col-12 bg-caja w-100 m-0 p-0 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="row m-0 p-2 card-body">

            <div class="col-lg-4 col-md-12 col-12 m-0 p-2">
              <form class="col-12 border rounded m-0 p-1 overflow-hidden">
                <div class="bg-menu text-white p-1 m-0 rounded"> 
                  {{dictionary['Emplazamiento'][session.user.language]}}
                </div>
                <div class="container-fluid col-12 m-0 p-0 pt-2">
                
                  <div class="row">
<!-- NOMBRE -->
                    <div class="form-group col-12 p-1">
                      <label class="p-0 pt-1 text-dark tx-bold tx-12">
                        {{dictionary['Nombre'][session.user.language]}}
                      </label>
                      <div class="col-12">
                        <input v-model="newEmplacementData.label" id="inputNewEmplacementLabel" type="text" 
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>

<!-- LOCALIZACION -->
                    <div class="form-group col-12 p-1">
                      <label class="p-0 pt-1 text-dark tx-bold tx-12">
                        {{dictionary['Localización'][session.user.language]}}
                      </label>
                      <div class="col-12">
                        <input v-model="newEmplacementData.location" id="inputNewEmplacementLocation" type="text" 
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-12 p-2 pt-3 pb-3">
                    <div class="col-12 m-0 p-0">
<!-- SUBMIT -->
                      <button type="button" @click="onCreateNewEmplacement"
                      id="btnSubmitCreateNewEmplaceonCreateNewEmplacement" class="button-ok gradient-tablas rounded-3" style="max-height: 2.5rem;">
                        <span class="text-white text-white mt-0 tx-14">
                          <ion-icon name="add-circle-outline" class="text-white pt-0 me-1 tx-14 hydrated"></ion-icon>
                          {{dictionary['Crear nuevo '+'emplazamiento'][session.user.language]}}
                        </span>
                      </button>
                    </div>
                  </div>
                  
                </div>
              </form>
            </div>

            <div class="col-lg-8 col-md-12 col-12 m-0 p-2">
<!-- Company Map-->
              <div class="col-12 m-0 p-0">
                <div class="form-group col-12 border bg-menu rounded-5 col-12 m-0 p-0">
                  <div class="form-group m-0 p-3 pt-0 pb-0">
                    <div class="text-white p-1 m-0 rounded-5"> 
                      {{dictionary['Geoposición'][session.user.language]}}
                    </div>
<!-- CONTENT Map -->
                    <div id="emplacementMapContent" class="m-0 p-3" style="width: 100%; height: 275px; index: 0; border-radius: 0.5rem;">
                    </div>

                  </div>
                  <div class="row col-lg-12 col-md-12 col-12 bg-menu text-white m-0 p-0 rounded-5 justify-content-around">
               
               <!-- LATITUD -->
                    <div class="form-group col-6 m-0 p-3 d-flex">
                      <label class="p-0 pe-2 tx-11">
                        {{dictionary['Latitud'][session.user.language]}}
                      </label>
                      <div class="m-0 p-0">
                        <input v-model="newEmplacementData.geoJSON.geometry.coordinates[0]" id="inputNewEmplacementLat" type="number" step=0.00001
                        class="form-control form-control-line"
                        @change="onPositionChanged">
                      </div>
                    </div>
               
               <!-- LONGITUD -->
                    <div class="form-group col-6 m-0 p-3 d-flex">
                       <label class="p-0 pe-2 tx-11">
                          {{dictionary['Longitud'][session.user.language]}}
                       </label>
                       <div class="m-0 p-0">
                          <input v-model="newEmplacementData.geoJSON.geometry.coordinates[1]" id="inputNewEmplacementLng" type="number" step=0.00001
                           class="form-control form-control-line"
                           @change="onPositionChanged">
                        </div>
                     </div>
                  </div>
                </div>
              </div>
            </div>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
// Clomos
import api from '@/services/api';

export default {
  name: "NewEmplacementModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newEmplacementData: {
        nifCif: this.currentCompany.nifCif,
        label: "",
        location: "",
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0,0]
          }
        }
      },
      emplacementMap: undefined,
      emplacementMarker: undefined
    }
  },
  emits: ["toggleNewEmplacementModal"],
  methods: {
    // DISPLAY
    displayEmplacementMap() {
      if (this.emplacementMap != undefined) {
        this.emplacementMap.invalidateSize();
        this.emplacementMap.remove();
      }
      var emplacementMap = Leaflet.map('emplacementMapContent');
      
      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang='+this.session.user.language).addTo(emplacementMap);
      emplacementMap.on('click', this.onSetNewCoordinates)
      
      this.emplacementMap = emplacementMap;
      
      let emplacementMapListForGroup = []; 
      if ($(".leaflet-control-attribution").get()[0] != undefined) $(".leaflet-control-attribution").get()[0].remove();
      var emplacementMarker = Leaflet.marker([this.newEmplacementData.geoJSON.geometry.coordinates[0],this.newEmplacementData.geoJSON.geometry.coordinates[1]]);
      emplacementMarker.addTo(emplacementMap);
      emplacementMapListForGroup.push(emplacementMarker);
      var emplacementMapGroup = Leaflet.featureGroup(emplacementMapListForGroup);
      this.emplacementMap.fitBounds(emplacementMapGroup.getBounds());

      emplacementMap.setView([40,-3],8);

      this.emplacementMarker = emplacementMarker;
    },
    onPositionChanged() {
      this.emplacementMap.removeLayer(this.emplacementMarker);
      this.emplacementMarker = Leaflet.marker([this.newEmplacementData.geoJSON.geometry.coordinates[0],this.newEmplacementData.geoJSON.geometry.coordinates[1]]).addTo(this.emplacementMap);
    },
    // SET
    async onCreateNewEmplacement() {
      let valid = true;
      if (this.newEmplacementData.label === "") {
        $("#inputNewEmplacementLabel").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputNewEmplacementLabel").get()[0].classList.remove("missing")
        }, 3000);
        return;
      }
      let response = await api.postNewEmplacement(this.newEmplacementData);
      console.log(response);
      if (response["status-code"] === "201") {
        this.$emit('toggleNewEmplacementModal')
        window.alert("El emplazamiento ha sido creado satisfactoriamente.");
      }
      else {
        window.alert("Error al crear el emplazamiento.");
      }
    },
    onSetNewCoordinates(event) {
      this.newEmplacementData.geoJSON = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [Number(event.latlng.lat), Number(event.latlng.lng)]
        }
      }
      this.emplacementMap.removeLayer(this.emplacementMarker);
      this.emplacementMarker = Leaflet.marker([this.newEmplacementData.geoJSON.geometry.coordinates[0],this.newEmplacementData.geoJSON.geometry.coordinates[1]]).addTo(this.emplacementMap);
    }
  },
  created() {
    setTimeout(()=>{this.displayEmplacementMap()}, 300);
  }
}
</script>

<style>

#newEmplacementModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>