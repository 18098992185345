<template>
  <!-- MODAL New Expedition -->
  <div id="orderLabelsModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 shadow">
<!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="arrow-redo-outline" class="tx-18 me-2 align-bottom"></ion-icon>
            {{dictionary["Etiquetas"][session.user.language]}}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleOrderLabelsModal')">	
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>
<!-- CONTENT Prepare Expedition -->
      <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5">
        <div style="overflow: hidden; max-height: 60vh;" class="p-3">
          <div class="col-12 p-3 border rounded form-material" style="overflow: auto;"
          v-show="orderData.params != undefined && orderData.params.bultos[0]!=undefined">
            <div id ="etiquetasContainer" class="etiquetas-container">

            <!-- Mostrar los datos de la etiqueta -->
              <div v-for="(bulto, index) in orderData.params.bultos.length" :key="index" class="pb-3">
                <div class="etiqueta ms-auto me-auto">
                  <div class="d-flex justify-content-between align-items-start">
                    <div>
                      <div class="col-12 d-flex flex-column">
                        <span class=" tx-20 tx-left pt-2">
                          <strong> Nº de Pedido: {{ orderSummary.idPedido }} </strong>
                        </span>
                        <h3 class=" tx-20 tx-left">
                          Bulto {{ index + 1 }} de {{ orderData.params.bultos.length }}
                        </h3>
                      </div>
                    </div>
                    <div class=" tx-20 tx-right">
                      <vue-qr :text="generarTextoQR(pedido, index + 1, orderData.params.bultos.length)" :size="120"
                        class="qr"></vue-qr>
                    </div>
                  </div>
                  <div class="tx-left">
                    <div class="col">
                      <p class="tx-20 mb-0">{{ orderSummary.nombre }} </p>
                      <p class="tx-20 mb-0">{{ orderSummary.direccion }}</p>
                      <p class="tx-20 mb-0">{{ orderSummary.codigoPostal }} {{ orderSummary.localidad }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

<!-- SUBMIT -->
            <div class="form-group col-lg-6 col-12 m-0 p-0 ms-auto me-auto">
              <div class="col-md-12">
                <button type="button" id="btnPrintLabels" class="button-ok gradient-tablas rounded-3"
                @click="onPrintLabels">
                  <span class="button-text text-white">
                    <ion-icon name="print-outline" class="tx-18 me-2 align-bottom"></ion-icon>
                    <span class="text-white text-nowrap mt-0">{{dictionary['Imprimir'][session.user.language]}}</span>
                  </span>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
// import $ from 'jquery';
import vueQr from "vue-qr/src/packages/vue-qr.vue";

export default {
  name: "GenerarEtiquetas",
  components: { vueQr },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    orderData: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      orderSummary: null,
    }
  },
  emits: [ "toggleOrderLabelsModal" ],
  methods: {
    generarTextoQR(bulto, index) {
      // Construir la cadena para el código QR con la estructura especificada
      let cadenaQR = `${this.orderSummary.idPedido};${index};${this.orderData.params.bultos.length};${this.orderSummary.nombre};${this.orderSummary.direccion};${this.orderSummary.codigoPostal};${this.orderSummary.localidad}`;
      return cadenaQR;
    },
    onPrintLabels() {
        var mywindow = window.open('', 'PRINT', 'height=auto, width=auto');
        var labels = document.getElementById("etiquetasContainer").innerHTML;
        mywindow.document.write('</head><body >');
        mywindow.document.write(labels);
        mywindow.document.write('</body></html>');
        mywindow.document.close();
        mywindow.focus();
        mywindow.print();
        mywindow.close();
        mywindow.print();
    }
  },
  created() {
    this.orderSummary = JSON.parse(this.orderData.summary);
  }
};
</script>
  
<style scoped>
#orderLabelsModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.etiquetas-container {
  max-width: 47rem;
  margin: 0 auto;
}
.etiqueta {
  background-color: #fff;
  padding: 10px;
  border: 2px solid #000;
  border-radius: 10px;
  min-width: 650px;
  max-width: 650px;
  min-height: 260px;
  max-height: 260px;
  }
</style> 