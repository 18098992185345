<template>
  <!-- SECTION Slots-->
  <div id="slotsSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
        <div class="row col-lg-12 col-md-12 col-12 table-responsive m-0 p-0 pb-3">
          <div class="position-sticky col-12 m-0 pt-2 pb-2 bg-head text-white border-1">
            <div class="row p-0 m-0">
              <!--Primera sección-->
              <div class="row col-xl-5 col-lg-5 col-md-12 col-12 m-0 pt-0 ps-3 pe-4 text-white rounded-5 rounded-end">

                <div class="col m-0 pt-0 ps-0 text-white d-flex flex-row">
                  <h6 class="tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 d-flex flex-row">
                    <ion-icon name="location" class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ dictionary["Lockers"][session.user.language] }}
                  </h6>
                  <select :value="targetLockerId" class="form-control m-0 p-0 text-nowrap bg-white border"
                    style="border: solid 2px white ; color: black; min-width:200px; height: 34px;"
                    @change="updateTargetLockerId">

                    <option v-for="locker in lockerList" :key="locker.id" :value="locker.id">
                      {{ locker.label }}
                    </option>
                  </select>


                </div>

              </div>
              <!-- Fin Primera sección-->
              <!--Segunda sección-->
              <div class="row col-xl-7 col-lg-7 col-md-9 col-12 justify-content-end p-0 m-0 pe-3">
                <div class="row col-lg-8 col-md-8 col-8 justify-content-end">
                  <span class="text-end text-white mt-1 mb-2 pe-4">
                    <ion-icon name="grid" class="tx-16 mb-1 me-1 align-bottom"></ion-icon>
                    {{ targetLockerData.label }}
                  </span>

                </div>
                <div class="row col-xl-4 col-lg-4 col-md-3 col-4 justify-content-end p-0 m-0 pt-1 pe-3">
                  <button v-if="this.session.user.rol === '255'" id="btnDisplayNewSlotModal"
                    :title="dictionary['Añadir emplazamiento'][session.user.language]"
                    style="width: 24px; max-height: 24px;" class="button-ok bg-white mt-0 me-2 rounded shadow"
                    @click="toggleNewSlotModal">
                    <span>
                      <span class="button-text text-dark">
                        <ion-icon name="add-circle-outline" class="text-dark pt-0 tx-18 hydrated"
                          data-bs-toggle="tooltip" data-bs-placement="bottom"
                          :title="dictionary['Añadir hueco'][session.user.language]"></ion-icon>
                      </span>
                    </span>
                  </button>

                  <button id="btnDisplayEditEraseModal" style="width: 24px; max-height: 24px;"
                    :title="dictionary['Actualizar'][session.user.language]"
                    class="button-ok bg-white mt-0 me-2 rounded shadow" @click="refreshView">
                    <span>
                      <span class="button-text text-dark">
                        <ion-icon name="refresh-outline" @click="onRefresh" class="text-dark pt-0 me-0 tx-16 hydrated"
                          :title="dictionary['Actualizar'][session.user.language]"></ion-icon>
                        <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                      </span>
                    </span>
                  </button>
                  <button id="btnDisplayEditEraseModal" :title="dictionary['Volver'][session.user.language]"
                    style="width: 24px; max-height: 24px;" class="button-ok bg-white mt-0 me-2 rounded shadow"
                    @click="this.$router.go(-1)">
                    <span>
                      <span class="button-text text-dark">
                        <ion-icon name="return-down-back-outline" class="text-dark pt-0 me-0 tx-16 hydrated"
                          :title="dictionary['Volver'][session.user.language]"></ion-icon>
                        <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- COMPONENT HUECOS -->
          <div>
            <div class=" col-md-12 col-12 row m-0 ps-2 p-3 pt-0 pb-0 mt-2 h-auto bg-caja rounded-5 shadow"
              style="z-index: 0; height: auto; border-top: 5px solid rgb(0, 87, 147); border-bottom: 2px solid rgb(0, 141, 180);">
              <!-- TABLE HUECOS -->
              <div v-show="displayType === 'table'" class="col-12  pt-2 pb-4" id="lockersTableContent"
                style="z-index: 0; height: auto;border-top: solid 5px #005793;border-bottom: solid 2px #008db4;">
                <table id="slotsDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
                  <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                    <tr>
                      <th v-for="column in slotsColumns" :key="column.data" :width="column.width"
                        class="fw-medium text-nowrap p-2">
                        {{ dictionary[column.text][session.user.language] }}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <!-- cards huecos-->
              <div class=" col-lg-5 col-12  table-responsive m-0 p-0 ">
                <!-- LOCKERS Slots -->
                <div v-show="displayType === 'locker'" :class="(displayType === 'locker') ? 'row' : ''"
                  class="p-0 m-0 rounded-3 bg-light" style="overflow: none;">
                  <div v-if="lockerSlotsGrid.length > 0"
                    class="col-lg-12 col-md-12 col-12 p-0 rounded-3 m-0 row justify-content-center p-1">
                    <table class="tx-black fw-medium justify-content-center h-100 ms-1"
                      style="border: solid white 3px; border-radius: 10px;">
                      <tr v-for="row in lockerSlotsGrid" :key="row" style="height: 100px;">
                        <td v-for="slot in row" :key="slot" @click="moreInfo(slot)"
                          class="tx-white fw-medium justify-content-center rounded-5"
                          style="border:solid white 5px; width: 120px !important; height: 100%; max-height: 150px; min-height: 150px; font-size: 1em;"
                          :style="{
                            backgroundColor:
                              (slot.variables.analog.estado.value === '') ? 'silver' :
                                Number(slot.variables.analog.estado.value) === 0 ? 'CornflowerBlue' :
                                  Number(slot.variables.analog.estado.value) === 1 ? 'orange' :
                                    Number(slot.variables.analog.estado.value) === 2 ? 'red' :
                                      Number(slot.variables.analog.estado.value) === 3 ? 'orange' :
                                        'transparent'
                          }">
                          <div v-if="slot.label != undefined">
                            {{ slot.label }} <img
                              v-if="slot.variables.digital['accesible'] != undefined && slot.variables.digital.accesible.value === 'true'"
                              src="@/assets/img/discapacidades.png" :data-id="slot.id"
                              class=" m-0 ms-1 p-0 icon-size tx-white"
                              style="width: 18px; height: 18px; filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(100%);">
                          </div>
                          <div class="tx-11">
                            {{ (slot.variables.analog.estado.value === '') ? '' :
                              (Number(slot.variables.analog.estado.value) === 0) ?
                                dictionary["Vacío"][session.user.language] :
                                (Number(slot.variables.analog.estado.value) === 1) ?
                                  dictionary["Reservado"][session.user.language] :
                                  (Number(slot.variables.analog.estado.value) === 2 && slot.variables.dynamic?.idPedido.value=="") ?
                                  dictionary["Bloqueado"][session.user.language] :
                                  (Number(slot.variables.analog.estado.value) === 2)?
                                    dictionary["Ocupado"][session.user.language] :
                                    (Number(slot.variables.analog.estado.value) === 3) ?
                                      dictionary["Espera"][session.user.language] :
                                      "" }}
                          </div>
                          <div v-if="slot.id != ''" class="col-12 d-flex justify-content-around m-0 p-0 pt-2 pb-1">
                            <ion-icon v-if="slot.variables.dynamic['tipo'] != undefined"
                              :name='slot.variables.dynamic.tipo.value === "frio" ? "snow-outline" : slot.variables.dynamic.tipo.value === "ambiente" ? "sunny-outline" : ""'
                              :data-id="slot.id" class='m-0 p-0 tx-18 tx-white'></ion-icon>
                            <ion-icon v-if="slot.variables.digital['cerrado'] !== undefined"
                              :name='slot.variables.digital["cerrado"].value === "false" ? "lock-open-outline" : "lock-closed-outline"'
                              class='m-0 p-0 tx-18 tx-white'></ion-icon>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div v-else class="col-lg-12 col-md-12 col-12 p-0 rounded-3 m-0 row justify-content-center p-1">
                    <table class="tx-black fw-medium justify-content-center h-100 ms-1"
                      style="border: solid white 3px; border-radius: 10px;">
                      <tr v-for="(row, rowIndex) in dummyGrid" :key="'dummy-row-' + rowIndex" style="height: 100px;">
                        <td v-for="(slot, slotIndex) in row" :key="'dummy-slot-' + slotIndex"
                          class="tx-white fw-medium justify-content-center rounded-5"
                          style="border: solid white 5px; width: 170px !important; height: 100%; max-height: 100px; min-height: 100px; font-size: 1em; background-color: lightgray;">
                          <div class="placeholder-label"
                            style="height: 18px; background: gray; width: 50%; margin: auto;"></div>
                          <div class="placeholder-status"
                            style="height: 14px; background: silver; width: 80%; margin: 5px auto;"></div>
                          <div class="placeholder-icons col-12 d-flex justify-content-around m-0 p-0 pt-2 pb-1">
                            <div style="width: 18px; height: 18px; background: gray; border-radius: 50%;"></div>
                            <div style="width: 18px; height: 18px; background: gray; border-radius: 50%;"></div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div v-show="lockerSlotsGrid.length > 0" class="col-lg-7 col-12 bg-light table-responsive  m-0 p-0 ">
                <!-- LOCKERS Slots -->
                <div v-show="displayType === 'locker'" :class="(displayType === 'locker') ? 'row' : ''"
                  class="p-0 m-0 rounded-3" style="overflow: none;">
                  <div
                    class="col-lg-12 col-md-12 col-12 rounded-3 text-center tx-20 p-0 pt-0 m-0 row justify-content-center">

                    <!--<ion-icon @click="onRefresh" name="reload-outline" class="border-3 rounded p-2 tx-30 text-primary"
                      style="cursor:pointer;"></ion-icon>-->

                    <div v-if="!slotFilter && lockerSlotsGrid.length > 0"
                      class="p-1 pb-0 pt-1 row justify-content-center">
                      <div class="row col-lg-6 col-12 justify-content-center bg-light ps-1 pe-1 pt-0 ">
                        <div class="col-lg-12 col-md-12 col-12 p-3 pb-0 m-0">
                          <!-- Primera tarjeta con los primeros 3 elementos -->
                          <div class="row mb-1 justify-content-around">
                            <div class="col-md-4 col-12 p-2">
                              <div class="bg-white border border-info rounded-3 pt-2" style="min-height: 80px;">
                                <p class="tx-16 mb-0 text-start w-100 text-center"><strong>{{ dictionary["Huecos totales"][session.user.language]}}: </strong></p>
                                <span>{{ targetLockerData.variables.analog.huecosTotales.value }}</span>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 p-2">
                              <div class="bg-white border border-info rounded-3 pt-2" style="min-height: 80px;">
                                <p class="tx-16 mb-0 text-start w-100 text-center"><strong>{{ dictionary["Huecos libres"][session.user.language]}}: </strong> </p>
                                <span>{{ targetLockerData.variables.analog.huecosLibres.value }}</span>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 p-2">
                              <div class="bg-white border border-info rounded-3 pt-2" style="min-height: 80px;">
                                <p class="tx-16 mb-0 text-start w-100 text-center"><strong>{{ dictionary["Huecos ocupados"][session.user.language]}}: </strong> </p>
                                <span>{{ targetLockerData.variables.analog.huecosOcupados.value }}</span>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 p-2">
                              <div class="bg-white border border-info rounded-3 pt-2" style="min-height: 80px;">
                                <p class="tx-15 mb-0 text-start w-100 text-center"><strong>{{ dictionary["Temperatura sistema"][session.user.language]}}: </strong></p>
                                <span>{{ targetLockerData.variables.analog.temperaturaSistema.value + " "+targetLockerData.variables.analog.temperaturaSistema.unit }}</span>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 p-2">
                              <div class="bg-white border border-info rounded-3 pt-2" style="min-height: 80px;">
                                <p class="tx-15 mb-0 text-start w-100 text-center"><strong>{{ dictionary["Temperatura frio"][session.user.language]}}: </strong></p>
                                <span>{{ targetLockerData.variables.analog.temperaturaFrio.value + " "+targetLockerData.variables.analog.temperaturaSistema.unit }}</span>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 p-2">
                              <div class="bg-white border border-info rounded-3 pt-2" style="min-height: 80px;">
                                <p class="tx-15 mb-0 text-start w-100 text-center"><strong>{{
                                  dictionary["Humedad"][session.user.language] }}: </strong></p>
                                <span>{{ targetLockerData.variables.analog.humedadFrio.value + " %" }}</span>
                              </div>
                            </div>
                          </div>

                          <!-- Segunda tarjeta con los últimos 3 elementos -->


                        </div>
                      </div>
                      <div class="m-0 p-0 col-lg-6 col-12 bg-light">
                        <VueEcharts :v-if="lastDayLineChartOptions[0] != undefined" :option="lastDayLineChartOptions"
                          style="height: 300px;" id="lastDayChartCanvas" class="col-12 ps-0 pe-0">
                        </VueEcharts>
                      </div>
                    </div>


                    <div v-if="slotFilter" class="p-3 pb-0 pt-1 row justify-content-center">
                      <div class=" col-12 justify-content-center bg-light p-3 pt-0 ">
                        <div>
                          <label class="tx-clomos-black tx-20 tx-bold text-nowrap">
                            {{ dictionary["Nombre"][session.user.language] }}:
                            <span class="ps-1 tx-clomos-black">
                              {{ slotFilter.label }} </span>
                          </label>
                          <br>
                        </div>
                        <div
                          v-if="slotFilter.variables.dynamic.idPedido.value && slotFilter.variables.dynamic.idPedido != undefined">
                          <label class="tx-clomos-black  tx-18 text-nowrap"> {{ dictionary["ID pedido"][session.user.language] }}:
                            <span v-if="slotFilter.variables.dynamic.idPedido.value.length < 8"
                              class="ps-1 tx-clomos-black">

                              {{ slotFilter.variables.dynamic.idPedido.value }} </span> </label>
                          <label class="ps-1 tx-clomos-black tx-18"
                            v-if="slotFilter.variables.dynamic.idPedido.value.length >= 8"> {{
                              slotFilter.variables.dynamic.idPedido.value }}</label>
                          <br>
                        </div>
                        <div>
                          <label class="tx-clomos-black tx-18 text-nowrap">{{ dictionary["Estado puerta"][session.user.language]}}: <span class="ps-1 tx-clomos-black">
                              {{ slotFilter.variables.digital.cerrado.value === "true" ?
                                dictionary["Cerrada"][session.user.language] :
                                dictionary["Abierta"][session.user.language]
                              }}
                            </span> </label>
                          <br>
                        </div>
                        <div v-if="slotFilter.token">
                          <label class="tx-clomos-black tx-18 text-nowrap"> Token: <span class="ps-1 tx-clomos-black">
                              {{ slotFilter.token }} </span> </label>
                          <br>
                        </div>
                        <div>
                          <button v-if="!showSpinner" type="button" @click="forceSlotOpening(slotFilter.id)"
                            id="btnForceSlotOpenning" class="button-open rounded-3 bs-gray col-md-6 col-12 ">
                            <span class="button-text text-white">
                              <ion-icon
                                :name="slotFilter.variables.digital.solicitaAperturaRemota && slotFilter.variables.digital.solicitaAperturaRemota.value === 'true' ? 'lock-closed-outline' : 'lock-open-outline'"
                                class="text-white pt-0 me-2 tx-18 hydrated"></ion-icon>
                              <span class="text-white mt-0">{{
                                (slotFilter.variables.digital.solicitaAperturaRemota &&
                                  slotFilter.variables.digital.solicitaAperturaRemota.value
                                  === "true") ? dictionary['Deshabilitar apertura remota'][session.user.language] :
                                  dictionary['Habilitar apertura remota'][session.user.language] }}</span>
                            </span>
                          </button>
                          <div v-if="showSpinner" class="spinner-border text-dark" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>

                        </div>
                        <div>
                          <button type="button" @click="disableSlot(slotFilter)" id="btnForceSlotOpenning"
                            class="button-open rounded-3 bs-gray col-md-6 col-12 ">
                            <span class="button-text text-white">
                              <ion-icon name="power-outline" class="text-white pt-0 me-2 tx-18 hydrated"></ion-icon>
                              <span class="text-white mt-0">{{
                                (slotFilter.variables.analog.estado && slotFilter.variables.analog.estado.value
                                  != "2") ? dictionary['Deshabilitar hueco'][session.user.language] :
                                  dictionary['Habilitar hueco'][session.user.language] }}</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="!slotFilter" class="col-12 row bg-light border-top">
                    <div id="tableContent"
                      class=" col-lg-6 col-12  table-responsive m-0 p-3 mt-3 mb-1   overflow-hidden">
                      <table id="ActiveAlarmsDataTable" class="col-12 m-0 p-0 dataTables_wrapper no-footer"
                        style="width: 100%;">
                        <thead class="gradient-tablas tx-14 text-white text-center"
                          style="position: -webkit-sticky; position: sticky; top: 0; background-color: #343a40; z-index: 1;">
                          <tr>
                            <th v-for="column in activeAlarmColumns" :key="column.data" :width="column.width"
                              class="fw-medium text-nowrap p-1">
                              {{ dictionary[column.text][session.user.language] }}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <div class="m-0 p-0 col-lg-6 col-12 bg-light">
                      <VueEcharts :option="activeAlarmLineChartOptions" id="Chart2Canvas" class="col-12 ps-0 pe-0"
                        style="height: 300px;">
                      </VueEcharts>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="lockerSlotsGrid.length === 0" class="col-lg-7 col-12 table-responsive m-0 p-0 ">

                <!-- LOCKERS Slots -->
                <div v-show="displayType === 'locker'" :class="(displayType === 'locker') ? 'row' : ''"
                  class="p-0 m-0 rounded-3" style="overflow: none;">
                  <div
                    class="col-lg-12 col-md-12 col-12 rounded-3 text-center tx-20 p-0 pt-0 m-0 row justify-content-center">

                    <!-- Placeholder de filtro -->
                    <div class="p-1 pb-0 pt-1 row justify-content-center">
                      <div class="row col-lg-6 col-12 justify-content-center bg-light ps-1 pe-1 pt-0">
                        <div class="col-lg-12 col-md-12 col-12 p-3 pb-0 m-0">

                          <!-- Primera tarjeta -->
                          <div class="row mb-1 justify-content-around">
                            <div class="col-md-4 col-12 p-2">
                              <div class="bg-light border border-secondary rounded-3 pt-2" style="min-height: 80px;">
                                <p class="tx-16 mb-0 text-start w-100 text-center"><strong>{{ dictionary["Huecos totales"][session.user.language]}}: </strong></p>
                                <span>--</span>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 p-2">
                              <div class="bg-light border border-secondary rounded-3 pt-2" style="min-height: 80px;">
                                <p class="tx-16 mb-0 text-start w-100 text-center"><strong>{{ dictionary["Huecos libres"][session.user.language]}}: </strong></p>
                                <span>--</span>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 p-2">
                              <div class="bg-light border border-secondary rounded-3 pt-2" style="min-height: 80px;">
                                <p class="tx-16 mb-0 text-start w-100 text-center"><strong>{{ dictionary["Huecos ocupados"][session.user.language]}}:</strong></p>
                                <span>--</span>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 p-2">
                              <div class="bg-light border border-secondary rounded-3 pt-2" style="min-height: 80px;">
                                <p class="tx-15 mb-0 text-start w-100 text-center"><strong>{{ dictionary["Temperatura sistema"][session.user.language]}}:</strong>
                                </p>
                                <span>-- °C</span>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 p-2">
                              <div class="bg-light border border-secondary rounded-3 pt-2" style="min-height: 80px;">
                                <p class="tx-15 mb-0 text-start w-100 text-center"><strong>{{ dictionary["Temperatura frio"][session.user.language]}}:</strong></p>
                                <span>-- °C</span>
                              </div>
                            </div>
                            <div class="col-md-4 col-12 p-2">
                              <div class="bg-light border border-secondary rounded-3 pt-2" style="min-height: 80px;">
                                <p class="tx-15 mb-0 text-start w-100 text-center"><strong>{{
                                  dictionary["Humedad"][session.user.language] }}:</strong></p>
                                <span>-- %</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="m-0 p-0 col-lg-6 col-12 bg-light">
                        <div class="d-flex align-items-center justify-content-center" style="height: 300px;">
                          <p class="text-muted">Gráfico cargando...</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Placeholder para tabla -->
                  <div class="p-1 pb-0 pt-1 justify-content-center col-12 row bg-light">
                    <div class="col-6 table-responsive m-0 p-3 mt-3 mb-1 ms-2 overflow-hidden">
                      <table id="DummyTable" class="col-12 m-0 p-0">
                        <thead class="gradient-tablas tx-14 text-white text-center"
                          style="position: sticky; top: 0; background-color: #343a40; z-index: 1;">
                          <tr>
                            <th class="fw-medium text-nowrap p-1">Cargando..</th>
                            <th class="fw-medium text-nowrap p-1"></th>
                            <th class="fw-medium text-nowrap p-1"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-5">
                      <div class="d-flex align-items-center justify-content-center" style="height: 300px;">
                        <p class="text-muted">Gráfico cargando...</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-12 row m-0 ps-2 p-3 pt-0 pb-0 mt-2 h-auto">
            <div class=" col-col-12 bg-caja rounded-5 shadow"
              style="z-index: 0; height: auto; border-top: 5px solid rgb(0, 87, 147); border-bottom: 2px solid rgb(0, 141, 180);">
              <h6 class="mt-3">Tabla de Incidencias del Locker: {{ targetLockerData.label }}</h6>
              <!-- <img src="@/assets/img/incidencias.png" class="img-fluid mt-4"> -->
              <div class="col-lg-12 col-md-12 p-2">
                <!-- Buttons above the table -->
                <div class="text-center">
                  <div class="row justify-content-center p-3 pt-0 pb-2">
                    <button @click="toggleFilter('0')" type="button"
                      :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('0') ? 'btn-primary-active btn-custom-primary' : 'btn-custom-primary']">
                      {{ dictionary["Pendiente de revisión"][session.user.language] }}
                    </button>
                    <button @click="toggleFilter('1')" type="button"
                      :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('1') ? 'btn-secondary-active btn-custom-secondary' : 'btn-custom-secondary']">
                      {{ dictionary["En investigación"][session.user.language] }}
                    </button>
                    <button @click="toggleFilter('2')" type="button"
                      :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('2') ? 'btn-success-active btn-custom-success' : 'btn-custom-success']">
                      {{ dictionary["En proceso de resolucion"][session.user.language] }}
                    </button>
                    <button @click="toggleFilter('3')" type="button"
                      :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('3') ? 'btn-danger-active btn-custom-danger' : 'btn-custom-danger']">
                      {{ dictionary["Solucionada"][session.user.language] }}
                    </button>
                    <button @click="toggleFilter('4')" type="button"
                      :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('4') ? 'btn-warning-active btn-custom-warning' : 'btn-custom-warning']">
                      {{ dictionary["Cerrada"][session.user.language] }}
                    </button>
                  </div>
                </div>
                <div class="table-responsive p-2">
                  <table v-show="incidenceList.length > 0" id="IncidencesDataTable"
                    class="table table-striped p-0 pb-3 bg-caja w-100">
                    <thead class=" tx-14 text-white text-center overflow-hidden gradient-tablas">
                      <tr>
                        <th v-for="column in incidenceColumns" :key="column.data" :width="column.width"
                          class="fw-medium text-nowrap p-2">
                          {{ dictionary[column.text][session.user.language] }}
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- SECTION Slots -->
  <!-- <StatusComponent v-if="this.$route.params.currentDisplay==='slots'"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :requestedSlotId="targetSlotId"
  :dataTableOptions="dataTableOptions">
  </StatusComponent> -->

  <!-- SECTION Slots -->
  <SlotsConfigurationComponent v-if="this.$route.params.currentDisplay === 'lockers'" :session="session"
    :dictionary="dictionary" :countries="countries" :timeZones="timeZones" :visibility="visibility"
    :currentCompany="currentCompany" :availableCompanies="availableCompanies" :targetLockerId="targetLockerId"
    :dataTableOptions="dataTableOptions" v-on:updateTargetLockerId="updateTargetLockerId">
  </SlotsConfigurationComponent>

  <!-- MODAL New Slot -->
  <NewSlotModal v-if="display.newSlotModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :targetLockerId="targetLockerId"
    v-on:toggleNewSlotModal="toggleNewSlotModal">
  </NewSlotModal>
  <!--MODAL Edit Incidence-->
  <EditHistoricModal v-if="display.editHistoricModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
    :incidenceId="targetIncidenceId" :incidenceInfo="targetIncidence"
    v-on:toggleEditHistoricIncidenceModal="toggleEditHistoricIncidenceModal">
  </EditHistoricModal>
  <!--MODAL Add Comments-->
  <EditCommentsModal v-if="display.editCommentsModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
    :incidenceId="targetIncidenceId" :incidenceInfo="targetIncidence"
    v-on:toggleEditCommentsModal="toggleEditCommentsModal">
  </EditCommentsModal>


</template>

<script>
import $ from 'jquery';
//import StatusComponent from '@/components/StatusComponent';
import SlotsConfigurationComponent from '@/components/lockers/SlotsConfigurationComponent';
import NewSlotModal from '@/components/lockers/NewSlotModal';
import EditHistoricModal from '@/components/incidences/EditHistoricModal';
import EditCommentsModal from '@/components/incidences/EditCommentsModal';
import echartsOptionsJson from '@/assets/json/echarts_options.json';
import { VueEcharts } from 'vue3-echarts';
// Clomos
import api from '@/services/api';

export default {
  name: "SlotComponent",
  components: { /* StatusComponent,*/ SlotsConfigurationComponent, NewSlotModal, VueEcharts, EditHistoricModal, EditCommentsModal },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    /*  targetLockerId: {
       type: String,
       required: true,
     }, */
    dataTableOptions: {
      type: Object,
      required: true,
    }
  },
  watch: {
    currentCompany: async function () {
      if (this.$route.params.currentDisplay === 'slots' || this.targetLockerId == '') {
        await this.getBundleListByCompany();
      }
      else {
        await this.getLockersDataByCompany();
        await this.getLockerData();
        await this.getSlotListByLocker();
        this.variableHistory = {};
        await this.getLockerVariableHistory();
      }
      this.onChangeDisplayType(this.displayType);
      if (this.slotList[0] != undefined && this.targetSlotId === "")
        this.targetSlotId = this.slotList[0].id;
    },
    targetLockerId: async function () {
      this.lockerSlotsGrid = []
      if (this.$route.params.currentDisplay === 'slots' || this.targetLockerId == '') {
        await this.getBundleListByCompany();
      }
      else {
        await this.getLockersDataByCompany();
        await this.getLockerData();
        await this.getSlotListByLocker();
        this.variableHistory = {};
        await this.getLockerVariableHistory()
        await this.getHistoricIncidencesByDcl();
        this.displayIncidencesDataTable();
        await this.getAlarmsbyDcl(this.targetLockerId);
        this.displayActiveAlarmsDataTable();

      }
      this.onChangeDisplayType(this.displayType);
      if (this.slotList[0] != undefined && this.targetSlotId === "")
        this.targetSlotId = this.slotList[0].id;
    },
    lockerSlotsGrid(newValue) {
      if (newValue.length > 0) {
        this.$nextTick(() => {
          this.displayActiveAlarmsDataTable();
        });
      }
    },
    activeAlarmsList(newValue) {
      if (newValue.length === 0) {
        this.activeAlarmLineChartOptions = JSON.parse(JSON.stringify(echartsOptionsJson))["lineChartOptions"];
      }
    }
  },
  data() {
    return {
      slotsColumns: [
        {
          text: "Identificador", data: "slotId", width: "25%", className: "tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id=" + data + " class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
              + "<span data-id=" + data + " class='btnTargetProduct c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>" + data + "</span>";
          }
        },
        { text: "Etiqueta", data: "slotLabel", width: "15%", className: "tx-left align-middle" },
        /*  { text: "Tipo", data: "slotType", width: "10%", className: "tx-left align-middle" }, */
        {
          text: "Emplazamiento", data: "slotEmplacement", width: "10%", className: "tx-left align-middle",
          render: (data) => {
            let emplacementLabel = data;
            if (this.lockerList.length > 0) {
              for (let emplacement in this.lockerList)
                if (this.lockerList[emplacement].id === data)
                  emplacementLabel = this.lockerList[emplacement].label;
            }
            return emplacementLabel;
          }
        },
        { text: "Token", data: "slotToken", width: "15%", className: "tx-left align-middle" },
        {
          text: "Fecha de comunicación", data: "slotCommunicationDateTime", width: "10%", className: "align-middle",
          render: (data) => {
            return (data != "")
              ? "<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
              : "<span style='display: none;'></span> <spam>-</spam>";
          }
        },
        {
          text: "Coordenadas", data: "slotGeoJSON", width: "15%", className: "tx-left align-middle",
          render: (data) => {
            if (data.geometry != undefined && data.geometry.type === "Point") return "<div class='d-flex pt-1'><span class='me-2 ms-2 tx-black'>Lat:</span> <span>" + Number(data.geometry.coordinates[0]) + "</span> <span class='me-2 ms-2 tx-black'>Lon:</span> <span>" + Number(data.geometry.coordinates[1])
            else return "<span class='me-2 ms-2 tx-primary'>Lat:</span> <span>" + 0 + "</span>  <span class='me-2 ms-2 tx-primary'> Lon:</span></div> " + 0
          }
        },
        {
          text: "Apertura remota", data: "slotId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='exit-outline' data-id=" + data + " class='btnOpen btn tx-18 tx-primary'></ion-icon>";
          }
        },

      ],
      incidenceColumns: [
        {
          text: "", data: "IncidenceHistoricId", width: "5%", className: " align-middle",
          render: (data) => {
            return "<ion-icon name='chevron-down-circle-outline' data-id=" + data + " class='btnDetailIncidence btn tx-24 text-black tx-primary  p-0 m-0'></ion-icon>";
          }
        },
        {
          text: "Incidencia", data: "IncidenceLabel", width: "10%", className: " align-middle tx-left",
          render: (data) => {
            return data;
          }
        },
        {
          text: "Estado",
          data: "IncidenceHistoricStatus",
          width: "10%",
          className: "tx-left align-middle",
          render: (data) => {
            let text;
            let color;

            switch (data) {
              case "0":
                text = this.dictionary["Pendiente de revisión"][this.session.user.language];
                color = "#3498db";
                break;
              case "1":
                text = this.dictionary["En investigación"][this.session.user.language];
                color = "#2ecc71";
                break;
              case "2":
                text = this.dictionary["En proceso de resolucion"][this.session.user.language];
                color = "#e67e22";
                break;
              case "3":
                text = this.dictionary["Solucionada"][this.session.user.language];
                color = "#e74c3c";
                break;
              case "4":
                text = this.dictionary["Cerrada"][this.session.user.language];
                color = "#f39c12";
                break;
              default:
                text = data.status;
                color = "black";
            }

            return `<span style="color: ${color};">${text}</span>`;
          }
        },

        {
          text: "Nivel de gravedad", data: "IncidenceLevel", width: "7%", className: "tx-left align-middle",
          render: (data) => {
            switch (data) {
              case "0":
                return "<div value='0' class='row'>" +
                  "<span style='opacity: 0;'>" + 0 + "</span>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: greenyellow; '></div>" +
                  "</div>";
              case "1":
                return "<div value='1' class='row'>" +
                  "<span style='opacity: 0;'>" + 1 + "</span>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: green; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: green; '></div>" +
                  "</div>";
              case "2":
                return "<div value='2' class='row'>" +
                  "<span style='opacity: 0;'>" + 2 + "</span>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: orange; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: orange; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: orange; '></div>" +
                  "</div>";
              case "3":
                return "<div value='3' class='row'>" +
                  "<span style='opacity: 0;'>" + 3 + "</span>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                  "</div>";
              case "4":
                return "<div value='4' class='row'>" +
                  "<span style='opacity: 0;'>" + 4 + "</span>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                  "</div>";
              default:
                return data.status;
            }


          }
        }, {
          text: "Fecha apertura", data: "openingDate", width: "10%", className: "tx-left align-middle",
          render: (data) => {

            return (api.parseDateUserTimezone(data, this.session.user, this.$dayjs))
          }
        },
        {
          text: "Actualizar", data: "IncidenceHistoricId", width: "5%", className: " align-middle",
          render: (data) => {
            return ("<ion-icon name='add-circle-outline' data-id=" + data + " class='btnModifyIncidence btn tx-24 text-black m-0 p-0'></ion-icon>"

            )
          }
        },

      ],
      activeAlarmColumns: [
        {
          text: "", data: "activeAlarmId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<input type='radio' name='select-alarm' value='" + data + "' class='radio-select-alarm btnDetailAlarm p-0'>";
          }
        },
        {
          text: "Alarma", data: "activeAlarmName", width: "70%", className: "tx-left align-middle p-0",
          render: (data) => {
            return data;
          }
        },
        {
          text: "Fecha Activacion", data: "activationDate", width: "50%", className: "tx-left align-middle p-0",
          render: (data) => {

            return (api.parseDateUserTimezone(data, this.session.user, this.$dayjs))
          }
        },


      ],
      targetLockerId: '',
      targetLockerData: {},
      targetSlotId: this.$route.params.targetEntity,
      slotFilter: "",
      slotList: [],
      lockerList: [],
      lockerSlotsGrid: [],
      displayType: 'locker',
      slotCardsPage: 0,
      display: {
        newSlotModal: false,
        editSlotModal: false,
        linkSlotModal: false,
        deleteSlotModal: false,
        editHistoricModal: false,
        editCommentsModal: false,
      },
      dummyGrid: Array.from({ length: 6 }, () => Array.from({ length: 6 }, () => ({}))),
      linkAction: "link",
      showSpinner: '',
      lineChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["lineChartOptions"],
      variableHistory: {},
      // COLORS
      colors: ["#9e9e9e",
        "#4e79a7",
        "#f28d2b",
        "#e05759",
        "#76b7b2",
        "#59a14f",
        "#af7aa1",
        "#b1de89",
        "#595055",
        "#edc948",
        "#1c8356",
        "#ff9da7",
        "#8d9fca",
        "#2f451e"],
      selectedStatuses: [],
      sortedIncidenceList: [],
      incidenceList: [],
      incidenceTypes: [],
      targetIncidenceId: '',
      alarmsList: {},
      activeAlarmsList: {},
      preActive: {},
      postActive: {},
      targetActiveAlarm: "",
      activeAlarmLineChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["lineChartOptions"],
    }
  },
  computed: {
    targetIncidence() {
      for (let i in this.sortedIncidenceList) {
        if (this.sortedIncidenceList[i].id === this.targetIncidenceId)
          return this.sortedIncidenceList[i];
      }
      return {};
    },
    fractionalLength() {
      return (localStorage.getItem("ClomosFractionalLength") != undefined) ? localStorage.getItem("ClomosFractionalLength") : 2;
    },
    slotCardsByPages() {
      let slotCardsByPages = []
      let i = 0;
      while (i * 12 < this.slotList.length) {
        slotCardsByPages.push(this.slotList.slice(i * 12, i * 12 + 12));
        i++;
      }
      return slotCardsByPages;
    },
    lastDayLineChartOptions() {
      let lastDayLineChartOptions;
      let now = new Date().getTime();
      let yesterday = new Date().setDate(new Date().getDate() - 1);

      lastDayLineChartOptions = JSON.parse(JSON.stringify(this.lineChartOptions));
      if (this.targetLockerData === "") return lastDayLineChartOptions;
      lastDayLineChartOptions.axisPointer = {
        label: {
          formatter: (function (dataTarget) {
            let d = new Date(dataTarget.value);
            return String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0") + ":" + String(d.getSeconds()).padStart(2, "0");
          })
        },
      }
      lastDayLineChartOptions.xAxis = {
        min: yesterday,
        max: now,
        axisLabel: {
          formatter: (function (value) {
            let d = new Date(value);
            return String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0") + ":" + String(d.getSeconds()).padStart(2, "0");
          }),
          textStyle: {
            fontSize: 10
          }
        }
      }
      lastDayLineChartOptions.yAxis = {
        type: "value"
      }
      let i = 0;
      for (let variable in this.variableHistory) {
        lastDayLineChartOptions.legend.data[i] = variable;
        lastDayLineChartOptions.series[i] = {
          data: this.variableHistory[variable],
          name: variable,
          type: "line",
          symbol: "emptyDiamond",
          symbolSize: 6,
          color: this.colors[i],
          smooth: true,
          step: false,
          areaStyle: {
            color: this.colors[i],
            opacity: 0.03
          },
        }
        i += 1;
      }
      return lastDayLineChartOptions;
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies", 'updateTargetLockerId'],
  methods: {
    filterTablebyStatus() {
      if (this.selectedStatuses.length === 0) {
        this.sortedIncidenceList = this.incidenceList;
      } else {
        this.sortedIncidenceList = this.incidenceList.filter(item =>
          this.selectedStatuses.includes(item.status.toString())
        );
      }
      this.displayIncidencesDataTable();

    },
    isActive(status) {
      return this.selectedStatuses.includes(status);
    },
    returnBack() {
      const path = this.$route.path;
      const newPath = path.replace('/locker/', '/lockers/').replace(/\/[^/]+$/, '');
      this.$router.replace({
        path: newPath
      });
    },
    moreInfo(slotData) {
      slotData.id === "" ? this.slotFilter = undefined : this.slotFilter = slotData;
    },
    async onForceSlot(event) {
      let slotID = event.target.getAttribute('data-id')
      await this.forceSlotOpening(slotID)
    },
    async forceSlotOpening(slotID) {
      this.showSpinner = true;
      let response = await api.forceSlotOpening(slotID);

      if (response["status-code"] === "200" || response["status-code"] === "304") {
        await this.getLockerData();
        await this.getSlotListByLocker();
        this.variableHistory = {};
        await this.getLockerVariableHistory();

        for (let i = 0; i < this.slotList.length; i++) {
          if (this.slotList[i].id === this.slotFilter.id) {
            this.slotFilter = this.slotList[i]
          }

        }
        let slotCardsByPages = []
        let x = 0;
        while (x * 12 < this.slotList.length) {
          slotCardsByPages.push(this.slotList.slice(x * 12, x * 12 + 12));
          x++;
        }
        if (this.displayType === "table") this.displaySlotsDataTable();
      }
      this.showSpinner = false;
    },
    async disableSlot(slot) {
      if (slot.variables?.analog?.["estado"]) {
        let data = {
          id: slot.id,
          variableId: slot.variables.analog["estado"].variableId,
          value: slot.variables.analog["estado"].value === "2" ? "0" : "2",
          label: "estado",
          type: "1"
        };
        if (slot.variables.dynamic.idPedido.value.length > 0 && slot.variables.analog["estado"].value === "2") {
          window.alert("Error")
        } else {
          let response = await api.setBundleVariable(data)
          if (response["status-code"] === "200" || response["status-code"] === "304") {
            await this.getLockerData();
            await this.getSlotListByLocker();

            for (let i = 0; i < this.slotList.length; i++) {
              if (this.slotList[i].id === this.slotFilter.id) {
                this.slotFilter = this.slotList[i]
              }

            }
            let slotCardsByPages = []
            let x = 0;
            while (x * 12 < this.slotList.length) {
              slotCardsByPages.push(this.slotList.slice(x * 12, x * 12 + 12));
              x++;
            }
            if (this.displayType === "table") this.displaySlotsDataTable();

          }
          this.displaySlotsLocker();
        }
      }
    },
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    onChangeDisplayType(type) {
      this.displayType = type;
      setTimeout(() => {
        if (type === "locker") this.displaySlotsLocker();
        if (type === "table") this.displaySlotsDataTable();
        this.setButtonFunctions();
      }, 100);
    },
    onSetCardPage(index) {
      this.slotCardsPage = index;
      this.setButtonFunctions();
    },
    async onDetaiIncidence(event) {
      console.log('DETAIL WHERE ID:');
      this.targetIncidenceId = event.target.getAttribute('data-id');

      var tr = $(event.target).closest('tr');
      var row = $("#IncidencesDataTable").DataTable().row(tr);
      if (row.child.isShown()) {
        event.target.name = "chevron-down-circle-outline";
        row.child.hide();
        tr.removeClass('shown');
      } else {
        event.target.name = "chevron-up-circle-outline";
        let content = '' +
          '<div id="resumenPedido' + this.targetIncidence.id + '" class="p-1" style="background-color: white!important" data-id="' + this.targetIncidence.id + '">' +
          '<h2>' + this.dictionary["Resumen de la incidencia"][this.session.user.language] + '<ion-icon class= "pdf-icon btn tx-24 tx-primary m-0 ms-3 p-0" name="print-outline" data-id="' + this.targetIncidence.id + '"></ion-icon>' + '</h2>' +
          '<h2 id="incidenceID">' + "Identificador: " + this.targetIncidence.id + '</h2>' +
          '<div class="container">' +
          '<ol class="progress-meter">';

        const stages = [
          {
            key: 'dateOpen',
            label: `${this.dictionary["Pendiente de revisión"][this.session.user.language]}${this.targetIncidence.dateOpen ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateOpen, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
          },
          {
            key: 'dateInvestigation',
            label: `${this.dictionary["En investigación"][this.session.user.language]}${this.targetIncidence.dateInvestigation ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateInvestigation, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
          },
          {
            key: 'dateProgress',
            label: `${this.dictionary["En proceso de resolucion"][this.session.user.language]}${this.targetIncidence.dateProgress ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateProgress, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
          },
          {
            key: 'dateSolved',
            label: `${this.dictionary["Solucionada"][this.session.user.language]}${this.targetIncidence.dateSolved ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateSolved, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
          },
          {
            key: 'dateClosed',
            label: `${this.dictionary["Cerrada"][this.session.user.language]}${this.targetIncidence.dateClosed ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateClosed, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
          }
        ];

        stages.forEach(stage => {
          const done = this.targetIncidence[stage.key] !== "";
          content += '<li class="progress-point ' + (done ? 'done' : 'todo') + '">' +
            stage.label + '</li>';
        });

        content += '</ol></div>' + '<div class =row>'
          + '<div class=col-md-6>';
        content +=
          '<table style="border-collapse: collapse; width: 100%;">' +
          '<tr>' +
          '<th style="padding:2px; text-align: left; font-size: 1.5rem; background-color: #f2f2f2;" colspan="2">' +
          this.dictionary["Información de la incidencia"][this.session.user.language] + '</th>' +
          '</tr>'


        const statusMap = {
          "0": this.dictionary["Pendiente de revisión"][this.session.user.language],
          "1": this.dictionary["En investigación"][this.session.user.language],
          "2": this.dictionary["En proceso de resolucion"][this.session.user.language],
          "3": this.dictionary["Solucionada"][this.session.user.language],
          "4": this.dictionary["Cerrada"][this.session.user.language],

        };
        const levelMap = {
          "0": this.dictionary["Muy baja"][this.session.user.language],
          "1": this.dictionary["Baja"][this.session.user.language],
          "2": this.dictionary["media"][this.session.user.language],
          "3": this.dictionary["Critica"][this.session.user.language],
        }
        const levelDescription = levelMap[this.targetIncidence.level] || this.targetIncidence.level;
        const statusDescription = statusMap[this.targetIncidence.status] || this.targetIncidence.status;
        content += '<tr style="padding:2px; text-align: left;">' +
          '<td style="padding:2px; text-align: left;">' + this.dictionary["Identificador"][this.session.user.language] + '</td>' +
          '<td style="padding:2px; text-align: left;">' + this.targetIncidence.id + '</td>' +
          '</tr>';
        content += '<tr style="padding:2px; text-align: left;">' +
          '<td style="padding:2px; text-align: left;">' + this.dictionary["Estado"][this.session.user.language] + '</td>' +
          '<td style="padding:2px; text-align: left;">' + statusDescription + '</td>' +
          '</tr>';
        content += '<tr style="padding:2px; text-align: left;">' +
          '<td style="padding:2px; text-align: left;">' + this.dictionary["Nivel de gravedad"][this.session.user.language] + '</td>' +
          '<td style="padding:2px; text-align: left;">' + levelDescription + '</td>' +
          '</tr>';
        content += '<tr>' +
          '<td style="padding:2px; text-align: left;">' + this.dictionary["Última actualización"][this.session.user.language] + '</td>' +
          '<td style="padding:2px; text-align: left;">' + api.parseDateUserTimezone(this.targetIncidence.dateNow, this.session.user, this.$dayjs).split(' ')[0]; + '</td>' +
            '</tr>';
        content += '<tr>' +
          '<td style="padding:2px; text-align: left;">' + this.dictionary["Creado por"][this.session.user.language] + ": " + '</td>' +
          '<td style="padding:2px; text-align: left;">' + this.targetIncidence.createdBy + '</td>' +
          '</tr>' + '</table>' + ' </div>';
        /*    } */

        content += ' </div>' + '<table style="border-collapse: collapse; width: 100%;">' + '<tr>' +
          '<td style="padding:2px; text-align: left;" colspan="2">' +
          '<h2 style="font-size: 1.5rem;">' + this.dictionary["Observaciones"][this.session.user.language] + '<ion-icon class= "add-comments btn tx-24 tx-primary m-0 ms-3 p-0" id="add-comments" name="add-circle-outline" ></ion-icon>' + '</h2>' +
          '<table>' +
          '<tr>' +
          '<th style="padding:2px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Fecha"][this.session.user.language] + '</th>' +
          '<th style="padding:2px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Usuario"][this.session.user.language] + '</th>' +
          '<th style="padding:2px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Comentarios"][this.session.user.language] + '</th>' +
          '</tr>';

        for (let key in this.targetIncidence.description) {
          if (Object.prototype.hasOwnProperty.call(this.targetIncidence.description, key)) {
            let observacion = this.targetIncidence.description[key];
            content += '<tr>' +
              '<td style="padding:2px; text-align: start;">' + api.parseDateUserTimezone(key, this.session.user, this.$dayjs) + '</td>' +
              '<td style="padding:2px; text-align: start;">' + observacion.usuario + '</td>' +
              '<td style="padding:2px; text-align: start;">' + observacion.texto + '</td>' +
              '</tr>';
          }
        }

        content += '</table>' +
          '</td>' +
          '</tr>' +
          '</table>' +
          '</div>' + '</div>';
        row.child(content).show();

        if (this.targetIncidenceId) {
          let detailTable = document.getElementById('detailTable' + this.targetIncidenceId);
          if (detailTable && detailTable.parentNode) {
            detailTable.parentNode.style.padding = 0;
          }
        }
        tr.addClass('shown');
      }
      this.setButtonFunctions();

    },

    captureAndPrint(content) {
      var mywindow = window.open('', 'PRINT', 'height=400,width=600');

      mywindow.document.write('<html><head><title>' + "Impresión de incidencia" + '</title>');
      mywindow.document.write('</head><body >');

      mywindow.document.write(document.getElementById(content).innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();

      this.setButtonFunctions();

      return true;
    },
    displaySlotsLocker() {
      if (this.targetLockerData.variables === undefined) return;
      if (this.targetLockerData.variables.analog.numeroFilas === undefined
        || this.targetLockerData.variables.analog.numeroColumnas === undefined)
        return;
      let rows = parseInt(this.targetLockerData.variables.analog.numeroFilas.value);
      let columns = parseInt(this.targetLockerData.variables.analog.numeroColumnas.value);
      this.lockerSlotsGrid = [];
      let r0 = 0;
      while (r0 < rows) {
        this.lockerSlotsGrid.push([]);
        let c0 = 0;
        while (c0 < columns) {
          this.lockerSlotsGrid[r0][c0] = { "id": "", "label": "", "variables": { "analog": { "estado": { "value": "" } }, "digital": { "cerrado": { "value": "" } } } };
          for (let slot in this.slotList) {
            if (this.slotList[slot].geoJSON != undefined
              && this.slotList[slot].geoJSON.geometry != undefined
              && this.slotList[slot].geoJSON.geometry.coordinates != undefined
              && this.slotList[slot].geoJSON.geometry.coordinates[0] == r0
              && this.slotList[slot].geoJSON.geometry.coordinates[1] == c0) {
              this.lockerSlotsGrid[r0][c0] = this.slotList[slot]
            }
          }
          c0 += 1;
        }
        r0 += 1;
      }
      this.setButtonFunctions();
    },
    onRefresh() {
      this.getLockerData();
      this.getSlotListByLocker(this.displaySlotsLocker);
      this.slotFilter = "";
    },
    displayIncidencesDataTable() {
      $("#IncidencesDataTable").DataTable().clear()
      for (let i in this.sortedIncidenceList) {
        $("#IncidencesDataTable").DataTable().rows.add([{
          IncidenceHistoricId: this.sortedIncidenceList[i].id,
          IncidenceHistoricStatus: this.sortedIncidenceList[i].status,
          IncidenceLevel: this.sortedIncidenceList[i].level,
          IncidenceLabel: this.sortedIncidenceList[i].incidenceLabel,
          openingDate: this.sortedIncidenceList[i].dateOpen
        }]);
      }
      $("#IncidencesDataTable").DataTable().draw();
      this.setButtonFunctions();
      if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },
    displaySlotsDataTable() {
      $("#slotsDataTable").DataTable().clear()
      for (let i in this.slotList) {
        $("#slotsDataTable").DataTable().rows.add([{
          slotId: this.slotList[i].id,
          slotEmplacement: this.slotList[i].parent,
          slotLocation: this.slotList[i].parent,
          slotLabel: this.slotList[i].label,
          slotType: this.slotList[i].type,
          slotStatus: this.slotList[i].variables.analog.estado.value,
          slotCommunicationDateTime: (this.slotList[i].communicationDateTime != undefined && this.slotList[i].communicationDateTime != "") ? new Date(this.slotList[i].communicationDateTime) : "",
          slotGeoJSON: this.slotList[i].geoJSON,
          slotToken: this.slotList[i].token
        }]);
      }
      $("#slotsDataTable").DataTable().draw();
      this.setButtonFunctions();
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
      if ($("#slotsDataTable_paginate").get()[0] != undefined) $("#slotsDataTable_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
    },
    displayActiveAlarmsDataTable() {
      const table = $("#ActiveAlarmsDataTable").DataTable();

      table.clear();

      for (let i in this.activeAlarmsList) {
        table.rows.add([{
          activeAlarmId: this.activeAlarmsList[i].id,
          activeAlarmName: this.activeAlarmsList[i].label,
          activationDate: this.activeAlarmsList[i].statusDate,
          activeAlarmSilenced: {
            isMuted: this.activeAlarmsList[i].isMuted,
            id: this.activeAlarmsList[i].id
          }
        }]);
      }

      table.draw();
      this.setButtonFunctions();

      setTimeout(() => {
        const firstRadioBtn = $("#ActiveAlarmsDataTable input[type='radio']").first();
        if (firstRadioBtn.length) {
          const firstRadioValue = firstRadioBtn.val();
          firstRadioBtn.prop("checked", true).trigger('click');
          this.onDetailActiveAlarm(firstRadioValue);
        }
      }, 300);
      if ($("#ActiveAlarmsDataTable_paginate").get()[0] != undefined) {
        $("#ActiveAlarmsDataTable_paginate").get()[0].addEventListener("click", this.setButtonFunctions);
      }
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },
    async toggleNewSlotModal() {
      if (this.display.newSlotModal) {
        if (this.$route.params.currentDisplay === 'slots' || this.targetLockerId == '') {
          await this.getBundleListByCompany();
          this.displaySlotsDataTable();
        }
        else {
          await this.getLockerData();
          await this.getSlotListByLocker();
          this.displaySlotsDataTable();
          this.variableHistory = {};
          await this.getLockerVariableHistory();
        }
      }
      this.display.newSlotModal = !this.display.newSlotModal;
    },
    async toggleLinkSlotModal() {
      if (this.display.linkSlotModal) {
        if (this.$route.params.currentDisplay === 'slots' || this.targetLockerId == '') {
          await this.getBundleListByCompany();
          this.displaySlotsDataTable();
        }
        else {

          await this.getLockerData();
          await this.getSlotListByLocker();
          this.displaySlotsDataTable();
          this.variableHistory = {};
          await this.getLockerVariableHistory();
        }
      }
      this.display.linkSlotModal = !this.display.linkSlotModal;
    },
    toggleFilter(status) {
      const index = this.selectedStatuses.indexOf(status);
      if (index > -1) {
        this.selectedStatuses.splice(index, 1);
      } else {
        this.selectedStatuses.push(status);
      }
      this.filterTablebyStatus();
    },
    async toggleEditHistoricIncidenceModal() {
      if (this.display.editHistoricModal) {
        await this.onDisplayElements();
      }
      this.display.editHistoricModal = !this.display.editHistoricModal;
    },
    async toggleEditCommentsModal() {
      if (this.display.editCommentsModal) {
        await this.onDisplayElements();
      }
      this.display.editCommentsModal = !this.display.editCommentsModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetSlot").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetSlot);
        });
        $(".btnEditSlot").get().forEach(btn => {
          btn.addEventListener('click', this.onEditSlot);
        });
        $(".btnOpen").get().forEach(btn => {
          btn.addEventListener('click', this.onForceSlot);
        });
        $(".btnLinkSlot").get().forEach(btn => {
          btn.addEventListener('click', this.onLinkSlot);
        });
        $(".btnUnlinkSlot").get().forEach(btn => {
          btn.addEventListener('click', this.onUnlinkSlot);
        });
        $(".btnDeleteSlot").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteSlot);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
        $(".btntargetIncidence").get().forEach(btn => {
          btn.addEventListener('click', this.ontargetIncidence);
        });
        $(".btnDetailIncidence").get().forEach(btn => {
          btn.addEventListener('click', this.onDetaiIncidence)
        });
        $(".btnModifyIncidence").get().forEach(btn => {
          btn.addEventListener('click', this.onModifyIncidence);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
        $(".add-comments").get().forEach(btn => {
          btn.addEventListener('click', this.onAddComents);
        });
        $(".pdf-icon").get().forEach(btn => {
          btn.addEventListener('click', this.onPrint);
        });
        $(".btnDetailAlarm").get().forEach(btn => {
          btn.addEventListener('click', (event) => {
            const radioValue = event.target.value;
            this.onDetailActiveAlarm(radioValue);
          });
        });
      }, 300);
    },
    onPrint(event) {
      this.targetIncidenceHistoryId = event.target.getAttribute('data-id');
      this.captureAndPrint('resumenPedido' + this.targetIncidenceHistoryId);
    },
    async onDetailActiveAlarm(activeAlarmId) {
      this.targetActiveAlarm = "";
      this.targetActiveAlarmId = activeAlarmId;
      this.targetActiveAlarm = this.activeAlarmsList.find((item) => item.id === this.targetActiveAlarmId);
      const startDataTimeHistorical = new Date(new Date(this.targetActiveAlarm.statusDate).setHours(new Date(this.targetActiveAlarm.statusDate).getHours() - 48)).toISOString().replace('T', ' ').substr(0, 23)
      const endDateTimeHistorical = new Date().toISOString().replace('T', ' ').substr(0, 23)
      const dataforHistorical = {
        id: this.targetActiveAlarm.id,
        startDataTimeHistorical: startDataTimeHistorical,
        endDateTimeHistorical: endDateTimeHistorical
      }
      const getHistoricalofCondition = await this.getHistoricalofCondition(dataforHistorical)
      this.targetActiveAlarm.historicIncidences = getHistoricalofCondition
        .filter(item => item[2].length > 0 && item[2] !== this.targetActiveAlarm.incidence)
        .reduce((uniqueItems, item) => {
          if (!uniqueItems.some(existingItem => existingItem[1] === item[2])) {
            uniqueItems.push([item[0], item[2]]);
          }
          return uniqueItems;
        }, []);
      let idDCL = this.targetLockerId;
      let chartVariableDisplayData = {
        active: true,
        variableLabel: this.targetActiveAlarm.variableLabel,
        variableType: this.targetActiveAlarm.type === "0" ? "1" : "0", //modificar cuando se añadan las condiciones dinamicas      
        status: this.targetActiveAlarm.status,
        statusDate: this.targetActiveAlarm.statusDate,
        id: idDCL,
        startDateTime: new Date(new Date(this.targetActiveAlarm.statusDate).setHours(new Date(this.targetActiveAlarm.statusDate).getHours() - 1)).toISOString().replace('T', ' ').substr(0, 23),
        endDateTime: new Date(new Date(this.targetActiveAlarm.statusDate).setHours(new Date(this.targetActiveAlarm.statusDate).getHours() + 47)).toISOString().replace('T', ' ').substr(0, 23),
        startDataTimeHistorical: startDataTimeHistorical,
        endDateTimeHistorical: endDateTimeHistorical,
        limit: 0,
        history: getHistoricalofCondition
      };


      if (this.targetActiveAlarm.type === "0") {
        chartVariableDisplayData.upperLimit = this.targetActiveAlarm.upperLimit;
        chartVariableDisplayData.lowerLimit = this.targetActiveAlarm.lowerLimit;
        chartVariableDisplayData.hasUpperLimit = this.targetActiveAlarm.hasUpperLimit;
        chartVariableDisplayData.hasLowerLimit = this.targetActiveAlarm.hasLowerLimit;
      } else if (this.targetActiveAlarm.type === "1") {
        chartVariableDisplayData.isActive = this.targetActiveAlarm.isActive;
      }
      this.activeAlarmLineChartOptions = await this.displayVariablesChartOptions(chartVariableDisplayData);
      //this.historicActiveAlarmlineChartOptions = await this.displayHistoricChartOptions(chartVariableDisplayData);

    },
    async displayVariablesChartOptions(displayData) {
      let variableHistoricData = [];
      try {
        let response = await api.getMachineVariableHistory(displayData);
        if (response["status-code"] === "200") {
          variableHistoricData = api.parseVariableDataFromHistoryResponse(response);
          let value = parseFloat(variableHistoricData.value).toFixed(2);
          let unit = variableHistoricData.unit;
          let resultado = `${value} ${unit}`;
          if (displayData.active === true) {
            this.targetActiveAlarm.valueNow = resultado
          } else {
            this.targetAlarm.valueNow = resultado
          }
        } else {
          throw new Error(`Unexpected status code: ${response["status-code"]}`);
        }
      } catch (error) {
        //console.error(error);
      }
      const dates = variableHistoricData.history.map(item => item[0]);
      const statusDateTimestamp = new Date(displayData.statusDate).getTime();
      if (variableHistoricData.type == "1") {
        const dataValues = variableHistoricData.history.map(item => item[1]);
        const lowerLimit = displayData.hasLowerLimit === "true" ? parseInt(displayData.lowerLimit) : null;
        const upperLimit = displayData.hasUpperLimit === "true" ? parseInt(displayData.upperLimit) : null;
        const margin = 10;
        const minValue = Math.floor(
          Math.min(
            ...dataValues,
            lowerLimit !== null ? lowerLimit : Infinity
          ) - margin
        );

        const maxValue = Math.ceil(
          Math.max(
            ...dataValues,
            upperLimit !== null ? upperLimit : -Infinity
          ) + margin
        );



        const lineChartOptions = {
          toolbox: {
            feature: {
              dataView: {
                readOnly: true
              },
              dataZoom: {
                show: true,
                yAxisIndex: null
              },
              magicType: {
                type: ['line', 'bar']
              },
              saveAsImage: {
                pixelRatio: 2
              }
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'time',
            data: dates.map(date => new Date(date).getTime()),
            axisLabel: {
              formatter: value => {
                const date = new Date(value);
                // Formateo de fecha y hora con ceros a la izquierda
                return String(date.getDate()).padStart(2, "0") + "/" +
                  String(date.getMonth() + 1).padStart(2, "0") + "/" +
                  String(date.getFullYear()) + " \n " +
                  String(date.getHours()).padStart(2, "0") + ":" +
                  String(date.getMinutes()).padStart(2, "0");
              },
              margin: 10,
              fontSize: 10,
            },
            axisTick: {
              alignWithLabel: true
            }
          },
          yAxis: {
            type: 'value',
            min: minValue,
            max: maxValue,
            axisLabel: {
              formatter: `{value} ${variableHistoricData.unit}`
            }
          },
          grid: {
            "top": "15%",
            "right": "5%",
            "bottom": "3%",
            "left": "5%",
            "containLabel": true
          },
          series: [
            {
              name: displayData.variableLabel,
              type: 'line',
              data: dataValues.map((value, index) => [new Date(dates[index]).getTime(), value]),
              symbol: 'none',
              markLine: {
                data: [
                  ...(displayData.hasUpperLimit === "true" ? [{
                    yAxis: parseInt(displayData.upperLimit),
                    name: 'Límite superior',
                    lineStyle: {
                      type: 'dashed',
                      color: 'red',
                      width: 2
                    }
                  }] : []),
                  ...(displayData.hasLowerLimit === "true" ? [{
                    yAxis: parseInt(displayData.lowerLimit),
                    name: 'Límite inferior',
                    lineStyle: {
                      type: 'dashed',
                      color: 'red',
                      width: 2
                    }
                  }] : []),
                  {
                    xAxis: statusDateTimestamp,
                    name: 'Alarma',
                    lineStyle: {
                      type: 'solid',
                      color: 'red',
                      width: 2
                    }
                  }
                ],
                symbol: 'none',
                label: {
                  formatter: params => params.name
                }
              }
            }
          ]

        };

        return lineChartOptions;
      } else if (variableHistoricData.type == "0") {
        const dataValuesDigital = variableHistoricData.history.map(item => item[1] === "true" ? 1 : 0);
        const lineChartOptions = {
          toolbox: {
            feature: {
              dataView: {
                readOnly: true
              },
              dataZoom: {
                show: true,
                yAxisIndex: null
              },
              magicType: {
                type: ['line', 'bar']
              },
              saveAsImage: {
                pixelRatio: 2
              }
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'time',
            boundaryGap: false,
            data: dates.map(date => new Date(date).getTime()),
            axisLabel: {
              formatter: value => {
                const date = new Date(value);
                return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}/n ${date.getHours()}:${date.getMinutes()}`;
              },

            }
          },
          yAxis: {
            type: 'value',
            min: 0,
            max: 1,
            axisLabel: {
              formatter: `{value} ${variableHistoricData.unit}`
            }
          },
          series: [
            {
              name: displayData.variableLabel,
              type: 'line',
              data: dataValuesDigital.map((value, index) => [new Date(dates[index]).getTime(), value]),
              symbol: 'none',
              markLine: {
                data: [
                  {
                    xAxis: statusDateTimestamp,
                    name: 'Alarma',
                    lineStyle: {
                      type: 'solid',
                      color: 'red',
                      width: 2
                    }
                  }
                ],
                symbol: 'none',
                label: {
                  formatter: params => params.name
                }
              }
            }
          ]
        };
        return lineChartOptions;
      }

    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    async onModifyIncidence(event) {
      console.log('MODIFY WHERE :');
      console.log(event.target.getAttribute('data-id'));
      this.targetIncidenceId = event.target.getAttribute('data-id');
      this.toggleEditHistoricIncidenceModal();
    },
    onAddComents(event) {
      this.targetIncidenceHistoryId = event.target.getAttribute('data-id');
      this.display.editCommentsModal = true;
    },
    async onDisplayElements() {
      await this.getHistoricIncidencesByDcl()
      this.displayIncidencesDataTable();
    },
    // GET
    async getBundleListByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getBundleListByCompany(request);
      this.slotList = api.parseBundlesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.slotList);
    },
    async getSlotListByLocker(callback) {
      let request = {
        lockerId: this.targetLockerId,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getSlotListByLocker(request);
      this.slotList = api.parseBundlesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.slotList);
    },
    async getLockerData(callback) {
      let response = await api.getLockerData(this.targetLockerId);
      this.targetLockerData = api.parseMachineFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetLockerData);
    },
    async getLockerVariableHistory(callback) {
      if (this.targetLockerData != {}) for (let variableType in this.targetLockerData.variables) {
        for (let variable in this.targetLockerData.variables[variableType]) {
          if (
            this.targetLockerData.variables[variableType][variable] == undefined ||
            (this.targetLockerData.variables[variableType][variable].saveHistory != "true" &&
              !(variableType === 'analog' || variableType === 'digital'))
          ) continue;
          let request = {
            id: this.targetLockerData.id,
            variableLabel: this.targetLockerData.variables[variableType][variable].label,
            variableType: this.targetLockerData.variables[variableType][variable].type,
            limit: 0,
            offset: 0,
            endDateTime: api.parseDateTimeToString(new Date()),
            startDateTime: api.parseDateTimeToString(new Date().setDate(new Date().getDate() - 1)),
          };
          let response = await api.getMachineVariableHistory(request);
          let parsedResponse = api.parseVariableDataFromHistoryResponse(response);
          let rawHistory = parsedResponse.history
          if (this.targetLockerData.variables && this.targetLockerData.variables[variableType] && this.targetLockerData.variables[variableType][variable]) {
            this.variableHistory[this.targetLockerData.variables[variableType][variable].label] = [];
          }


          for (let i in rawHistory) {
            this.variableHistory[this.targetLockerData.variables[variableType][variable].label][i] = [
              // DATE
              this.$dayjs(new Date(rawHistory[i][0])).utc("z").local().tz(this.session.user.dateTimeZone).$d,
              // VALUE
              new Number(rawHistory[i][1]).toFixed(this.fractionalLength),
              parsedResponse.unit
            ];
          }
          if (callback && typeof callback === "function") callback(this.deviceList);
        }
      }
    },
    async getLockersDataByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getLockerListByCompany(request);
      this.lockerList = api.parseLockersFromResponse(response);
      if (callback && typeof callback === "function") callback(this.lockerList);
    },
    async getIncidencesByCompany() {
      const path = this.$route.path;
      const segments = path.split('/');
      let request = {
        nifCif: segments[2],
        limit: 0,
        offset: 0,

      };
      let response = await api.getIncidencesByCompany(request);
      this.incidenceTypes = api.parseIncidenceListFromResponse(response);
    },
    async getHistoricIncidencesByDcl() {
      let data = { id: this.targetLockerId };
      let response = await api.getHistoricIncidencesByDcl(data);
      if (response['status-code'] === '200') {
        this.incidenceList = api.parseHistoricIncidenceListFromResponse(response);
      } else {
        this.incidenceList = []
      }
      this.incidenceList.forEach(incidence => {
        const matchingType = this.incidenceTypes.find(type => type.id === incidence.idIncidence);
        if (matchingType) {
          incidence.incidenceLabel = matchingType.label;
        } else {
          incidence.incidenceLabel = '-'
        }
      });

      this.sortedIncidenceList = this.incidenceList
    },
    async getAlarmsbyDcl(selectedDcl) {
      this.activeAlarmsList = {}
      let data = {
        idDcl: selectedDcl[0].id ? selectedDcl[0].id : selectedDcl
      };
      try {
        let response = await api.getConditionsByDcl(data)

        if (response["status-code"] === "200") {

          this.alarmsList = api.parseAlarmListFromResponse(response);
          this.activeAlarmsList = [...this.alarmsList].filter((item) => item.status === "2" && item.isAlarm === "true");
          this.preActive = [...this.alarmsList].filter((item) => item.status === "1");
          this.postActive = [...this.alarmsList].filter((item) => item.status === "3");

        } else {
          if (this.alarmsList) {
            this.alarmsList = {}
            this.activeAlarmsList = {}
          }
          throw new Error("No existen alarmas para este activo.");
        }
        this.displayActiveAlarmsDataTable()

      } catch (error) {
        console.error(error.message);
      }
    },
    async getHistoricalofCondition(data) {
      try {
        let response = await api.getHistoricalofCondition(data);
        if (response["status-code"] === "200") {
          return api.parseConditionHistoryFromResponse(response)

        }
      } catch (error) {
        //console.error("Error fetching historical:", error);
      }

    },
    // TARGET 
    onTargetSlot(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetSlotId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetSlotId } })
    },
    //UPDATE
    updateTargetLockerId(event) {
      const newValue = event.target.value;
      this.targetLockerId = newValue;
      this.$router.replace({ params: { targetEntity: this.targetLockerId } })
    },
    // SET 
    onEditSlot(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetSlotId = event.target.getAttribute('data-id');
      this.$router.replace({ params: { targetEntity: this.targetSlotId } })
      this.toggleEditSlotModal();
    },
    // LINK 
    onLinkSlot(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetSlotId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetSlotId } })
      this.linkAction = "link"
      this.toggleLinkSlotModal();
    },
    // UNLINK 
    onUnlinkSlot(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetSlotId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetSlotId } })
      this.linkAction = "unlink"
      this.toggleLinkSlotModal();
    },
    // DELETE
    async onDeleteSlot(event) {
      if (!this.display.deleteSlotModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetSlotId);
        this.targetSlotId = event.target.getAttribute('data-id');
        this.$router.replace({ params: { targetEntity: this.targetSlotId } })
      }
      else {
        let response = await api.deleteSlot(this.targetSlotId);
        if (response["status-code"] === "200") {
          window.alert("El hueco ha sido eliminado satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el hueco.");
        }
      }
      await this.toggleDeleteSlotModal();
    }
  },
  async created() {
    await this.getLockersDataByCompany();
    if (this.$route.path.includes('locker')) {
      const path = this.$route.path;
      const lockerid = path.split('locker/')[1];
      if (lockerid) {
        this.targetLockerId = lockerid;
        await this.getAlarmsbyDcl(this.targetLockerId);

      }
    }


    if (this.$route.params.currentDisplay === 'slots') {
      await this.getBundleListByCompany();
    }
    else if (this.targetLockerId != '') {
      await this.getLockerData();
      await this.getSlotListByLocker();
      this.variableHistory = {};
      await this.getLockerVariableHistory();
      await this.getIncidencesByCompany()
      await this.getHistoricIncidencesByDcl()
      this.displayIncidencesDataTable();
    }


    if (this.slotList[0] != undefined && this.targetSlotId === "")
      this.targetSlotId = this.slotList[0].id;
  },
  mounted() {
    $("#slotsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.slotsColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $("#IncidencesDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.incidenceColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        //stateSave: this.dataTableOptions.stateSave,
        order: [[2, 'desc']]
      }
    );
    $("#ActiveAlarmsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'frtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.activeAlarmColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        //stateSave: this.dataTableOptions.stateSave,
        order: [[2, 'desc']]
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions());
  }
}
</script>

<style>
.btonCopyId:hover,
.btnTargetSlot:hover,
.btnOpen:hover,
.btnLinkSlot:hover,
.btnUnlinkSlot:hover,
.btnEditSlot:hover,
.btnDeleteSlot:hover {
  color: var(--main-dark) !important;
}

.btn-custom-primary {
  font-size: 12;
  border: 2px solid #3498db;
  border-left: 10px solid #3498db;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-secondary {
  border: 2px solid #2ecc71;
  border-left: 10px solid #2ecc71;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-success {
  border: 2px solid #e67e22;
  border-left: 10px solid #e67e22;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-danger {
  border: 2px solid #e74c3c;
  border-left: 10px solid #e74c3c;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-warning {
  border: 2px solid #f39c12;
  border-left: 10px solid #f39c12;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}


.btn-custom-primary:hover {
  font-size: 12;
  border-color: #2980b9;
  background-color: #eaf0f8;
}

.btn-custom-secondary:hover {
  border-color: #27ae60;

  background-color: #eaf6e7;
}

.btn-custom-success:hover {
  border-color: #d35400;
  background-color: #f8eae2;
}

.btn-custom-danger:hover {
  border-color: #c0392b;
  background-color: #f8d7da;
}

/* */
.btn-primary-active {
  border-color: #2980b9 !important;
  background-color: #eaf0f8 !important;
  box-shadow: inset 0 0 10px #2980b9;

  font-weight: bold;
  position: relative;
}

.btn-secondary-active {
  border-color: #27ae60 !important;
  background-color: #eaf6e7 !important;
  box-shadow: inset 0 0 10px #27ae60;

  font-weight: bold;
  position: relative;
}

.btn-success-active {
  border-color: #d35400 !important;
  background-color: #f8eae2 !important;
  box-shadow: inset 0 0 10px #d35400;

  font-weight: bold;
  position: relative;
}

.btn-danger-active {
  border-color: #c0392b !important;
  background-color: #f8d7da !important;
  box-shadow: inset 0 0 10px #c0392b;

  font-weight: bold;
  position: relative;
}

.btn-warning-active {
  border-color: #e67e22 !important;
  background-color: #fff3e0 !important;
  box-shadow: inset 0 0 10px #e67e22;

  font-weight: bold;
  position: relative;
}

.btn-primary-active::after,
.btn-secondary-active::after,
.btn-success-active::after,
.btn-danger-active::after,
.btn-warning-active::after {
  content: '✓';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);


}
</style>