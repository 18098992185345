<template>
  <!-- MODAL Edit Incidence -->
  <div id="editIncidenceModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 shadow">
      <!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="warning-outline" class="tx-18 me-2 align-bottom"></ion-icon>
            {{ dictionary['Editar incidencia'][session.user.language] + ": " + editIncidenceData.label }}
          </h6>
        </div>
        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleEditIncidenceModal')">
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <!-- CONTENT Edit Device -->
      <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
        <div id="editDeviceScroll" style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="col-12 p-2 pe-0 pb-0">
            <form class="p-0 overflow-hidden d-flex flex-wrap pe-2">
              <div class="col-12 m-0 p-2 border rounded overflow-hidden">
                <div class="bg-menu text-white p-1 m-0 rounded">
                  {{ dictionary['Incidencia'][session.user.language] }}
                </div>
                <div class="row">
                  <!-- NOMBRE -->
                  <div class="form-group col-md-4 col-12  p-1">
                    <label class="col-12 text-clomos tx-14 tx-bold">
                      {{ dictionary['Nombre'][session.user.language] }}
                    </label>
                    <div class="col-12">
                      <input v-model="editIncidenceData.label" id="inputEditIncidenceLabel" type="text"
                        class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>
                   <!-- DESCRIPCIÓN -->
                   <div class="form-group col-md-8 col-12  p-1">
                    <label class="col-12 text-clomos tx-14 tx-bold">
                      {{ dictionary['Descripción'][session.user.language] }}
                    </label>
                    <div class="col-12">
                      <textarea v-model="editIncidenceData.description" id="inputEditIncidenceDescription" type="text"
                        rows="2" class="form-control form-control-line" style="color:black;" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <!-- roles -->
                  <div class="form-group col-md-4 col-12  p-1">
                    <label class="col-12 text-clomos tx-14 tx-bold">
                      {{ dictionary['Rol usuario aviso'][session.user.language] }}
                    </label>
                    <div class="col-12">
                      <select v-model="editIncidenceData.rol" id="inputEditIncidenceRol"
                        class="form-control form-control-line" style="color:black;">
                        <option value=128>
                          {{ dictionary["Administrador de la empresa"][session.user.language] }}
                        </option>
                        <option value=32>
                          {{ dictionary["Autorizado/Editor"][session.user.language] }}
                        </option>
                        <option value=2>
                          {{ dictionary["Cliente"][session.user.language] }}
                        </option>
                        <option value=255>
                          {{ dictionary["Administrador"][session.user.language] }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-md-4 col-12 p-1">
                    <label class="col-12 text-clomos tx-14 tx-bold">
                      {{ dictionary['Nivel de gravedad'][session.user.language] }}
                    </label>
                    <div class="col-12">
                      <select v-model="editIncidenceData.level" id="inputNewIncidenceLevel"
                        class="form-control form-control-line" style="color:black;">
                        <option value="0">
                          {{ dictionary['Muy baja'][session.user.language] }}
                        </option>
                        <option value="1">
                          {{ dictionary['Baja'][session.user.language] }}
                        </option>
                        <option value="2">
                          {{ dictionary['media'][session.user.language] }}
                        </option>
                        <option value="3">
                          {{ dictionary['Alta'][session.user.language] }}
                        </option>
                        <option value="4">
                          {{ dictionary['Critica'][session.user.language] }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-md-4 col-12 p-1">
                    <label class="col-12 text-clomos tx-14 tx-bold">
                      {{ dictionary['Estado envio aviso'][session.user.language] }}
                    </label>
                    <div class="col-12">
                      <select v-model="editIncidenceData.status" id="inputNewIncidenceStatus"
                        class="form-control form-control-line" style="color:black;">
                        <option value="">
                          Ninguno
                        </option>
                        <option value=0>
                          {{ dictionary["Abierta"][session.user.language] }}
                        </option>
                        <option value=1>
                          {{ dictionary["En investigación"][session.user.language] }}
                        </option>
                        <option value=2>
                          {{ dictionary["En proceso de resolucion"][session.user.language] }}
                        </option>
                        <option value=3>
                          {{ dictionary["Solucionada"][session.user.language] }}
                        </option>
                        <option value=4>
                          {{ dictionary["Cerrada"][session.user.language] }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row">

                  <!-- mail -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 text-clomos tx-14 tx-bold">
                      {{ dictionary['Aviso email'][session.user.language] }}
                    </label>
                    <div class="col-12">
                      <div id="inputEditIncidencemail" class="form-control border-0 tx-bold w-100"
                        :class="editIncidenceData.email"
                        @click="(editIncidenceData.email === 'true') ? editIncidenceData.email = 'false' : editIncidenceData.email = 'true'">
                        {{ dictionary[editIncidenceData.email][session.user.language] }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 text-clomos tx-14 tx-bold">
                      {{ dictionary['Tiempo de repetición del aviso'][session.user.language] }}
                    </label>
                    <div class= "col-12">
                    <select v-model="editIncidenceData.inputEditIncidenceSeconds" id="inputNewIncidenceLevel"
                        class="form-control form-control-line" style="color:black;">
                        <option value="0" selected>
                          {{ dictionary['Nunca'][session.user.language] }}
                        </option>
                        <option value="21600">
                          {{ dictionary['6 horas'][session.user.language] }}
                        </option>
                        <option value="43200">
                          {{ dictionary['12 horas'][session.user.language] }}
                        </option>
                        <option value="86400">
                          {{ dictionary['24 horas'][session.user.language] }}
                        </option>
                       <option value="604800">
                          {{ dictionary['Una semana'][session.user.language] }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>


                <div class="col-12 p-0 ps-1 pe-1">
                  <hr class="tx-clomos-dark ms-2 me-2">
                </div>

                <div class="col-12 mt-2 mb-2">
                  <!-- SUBMIT SETTINGS -->
                  <button type="button" @click="setIncidenceConfiguration" id="btnSubmitDeviceConfiguration"
                    class="button-ok gradient-tablas rounded-3" style="height: 2rem !important;">
                    <span class="button-text text-white">
                      <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-14 hydrated"></ion-icon>
                      <span class="text-white mt-0 tx-12">{{ dictionary['Guardar configuración'][session.user.language]
                        }}</span>
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

import api from '@/services/api';

export default {
  name: "editIncidenceModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    incidenceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editIncidenceData: {
        id: "",
        label: "",
        description: "",
        rol: "",
        email: "",
        sms: "",
        status: "",
        repetitionTime: "",
        days: "",
        minutes: "",
        seconds: "0",
        level: "",
      }
    };
  },
  computed: {

  },
  emits: ["toggleEditIncidenceModal"],
  methods: {
    async getEditIncidenceData() {

      try {
        let response = await api.getIncidencebyId(this.incidenceId);
        if (response["status-code"] === "200") {
          this.editIncidenceData = api.parseIncidenceFromResponse(response);
          this.convertRepetitionTime(this.editIncidenceData.repetitionTime);
        } else {
          window.alert("Error al editar la incidencia.");
        }
      } catch (error) {
        console.error("Error creating incidence:", error);
        window.alert("Error al crear la incidencia.");
      }
    },
    convertRepetitionTime(repetitionTime) {
      const totalSeconds = parseInt(repetitionTime, 10);
      const days = Math.floor(totalSeconds / (3600 * 24));
      const remainingSeconds = totalSeconds % (3600 * 24);
      const remainingSecondsAfterHours = remainingSeconds % 3600;
      const minutes = Math.floor(remainingSecondsAfterHours / 60);
      const seconds = remainingSecondsAfterHours % 60;

      this.editIncidenceData.days = days;
      this.editIncidenceData.minutes = minutes;
      this.editIncidenceData.seconds = seconds;
    },
    async setIncidenceConfiguration() {

      let valid = true;
      if (this.editIncidenceData.label === "") {
        $("#inputEditIncidenceLabel").addClass("missing");
        valid = false;
      }
      if (this.editIncidenceData.description === "") {
        $("#inputEditIncidenceDescription").addClass("missing");
        valid = false;
      }
      if (this.editIncidenceData.rol === "") {
        $("#inputNewIncidenceRol").addClass("missing");
        valid = false;
      }
      if (this.editIncidenceData.level === "") {
        $("#inputNewIncidenceLevel").addClass("missing");
        valid = false;
      }
      if ( this.editIncidenceData.seconds === null) {

        $("#inputNewIncidenceSeconds").addClass("missing");
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $(".missing").removeClass("missing");
        }, 3000);
        return;
      }

     this.editIncidenceData.repetitionTime = this.editIncidenceData.seconds;
      try {
        let response = await api.setIncidence(this.editIncidenceData);
        if (response["status-code"] === "200") {
          this.$emit('toggleEditIncidenceModal');
          window.alert("La incidencia ha sido actualizada correctamente.");
        } else {
          window.alert("Error al actualizar la incidencia.");
        }
      } catch (error) {
        console.error("Error al actualizar la incidencia:", error);
        window.alert("Error al actualizar la incidencia");
      }
    }
  },
  async created() {
    await this.getEditIncidenceData();
  }
}
</script>

<style>
#editIncidenceModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>