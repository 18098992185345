<template>
  <!-- SECTION Machines-->
  <div id="machinesSection" class="row col-12 m-0 p-0" v-if="!display.configurationView">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">

      <!-- COMPONENT CommandCenter -->
      <div class="col-lg-12 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
        <div class="col-12 table-responsive bg-head rounded-top-5 m-0 p-0 shadow">
          <div class="row col-12 m-0 pt-2 pb-2 bg-head text-white border-1">
            <div class="col-10 justify-content-center">
              <ion-icon :name="'git-branch-outline'" class="tx-16 mb-1 me-1 align-bottom">
              </ion-icon>
              <span class="col-12 text-center">
                {{ "Centro de mando: " + commandCenterLabel }}
              </span>
            </div>

            <div class="col-2 row justify-content-end">
              <button v-if="editingPermission" id="btnDisplayEditEraseModal"
                :title="dictionary['Editar centro de mando'][session.user.language]"
                style="width: 24px; max-height: 24px;" class="button-ok bg-white mt-0 me-2  shadow"
                @click="toggleConfigurationView">
                <span>
                  <span class="button-text text-dark">
                    <ion-icon name="create-outline" class="text-dark pt-0 me-0 tx-18 hydrated"
                      :title="dictionary['Editar centro de mando'][session.user.language]"></ion-icon>
                    <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                  </span>
                </span>
              </button>
              <button id="btnDisplayEditEraseModal" :title="dictionary['Actualizar'][session.user.language]"
                style="width: 24px; max-height: 24px;" class="button-ok bg-white mt-0 me-2 rounded shadow"
                @click="refreshView">
                <span>
                  <span class="button-text text-dark">
                    <ion-icon name="refresh-outline" class="text-dark pt-0 me-0 tx-16 hydrated"
                      :title="dictionary['Actualizar'][session.user.language]"></ion-icon>
                    <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                  </span>
                </span>
              </button>
              <button id="btnDisplayEditEraseModal" :title="dictionary['Volver'][session.user.language]"
                style="width: 24px; max-height: 24px;" class="button-ok bg-white mt-0 me-2 rounded shadow"
                @click="this.$router.go(-1)">
                <span>
                  <span class="button-text text-dark">
                    <ion-icon name="return-down-back-outline" class="text-dark pt-0 me-0 tx-16 hydrated"
                      :title="dictionary['Volver'][session.user.language]"></ion-icon>
                    <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                  </span>
                </span>
              </button>

            </div>
          </div>
        </div>
        <div class="row col-12 m-0 p-2 bg-caja justify-content-center rounded-bottom-5 shadow">
          <div v-if="machineOrganigramLoading">
            <div class="spinner-border" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
          <div v-for="machineSet in machineOrganigram" :key="machineSet"
            class="d-flex flex-column m-0 p-2 col-12 col-lg-12 col-xl-12 col-md-12 gap-1">
            <vue-tree :ref="'scaleTree' + machineSet.id"
              style="width: 100%; margin: 0; min-height:400px; height: auto; border: 1px solid gray; overflow-y: scroll;z-index: 0;border-top: 5px solid rgb(0, 87, 147); border-bottom: 2px solid rgb(0, 141, 180);"
              class="rounded-5" :dataset="machineSet" :config="treeConfig" :collapse-enabled=false linkStyle="straight">
              <template v-slot:node="{ node, collapsed }">
                <div v-if="node.type === '2'" class="node" :style="{ border: collapsed ? '2px solid lightgrey' : '' }">
                  <div class="node-top bg-primary">
                    <span :data-id="node.id"
                      class='btnTargetMachine tx-12 c-pointer tx-bold tx-clomos-white text-nowrap align-middle ps-1 pe-1'
                      style="min-width: 140px;">
                      {{ node.name }}
                    </span>
                  </div>

                  <div class="node-bot row p-1 border-1 border-top-0 bg-white">
                    <div class="col-12 m-0 p-0 tx-12 text-start ps-3">
                      <span class="p-0 m-0 tx-bold pe-3">
                        {{ dictionary["Estado"][session.user.language] + ":" }}
                      </span>
                      <span class="p-0 m-0">
                        {{ (node.status != "") ? node.status : "-" }}
                      </span>
                    </div>
                    <div class="col-12 m-0 p-0 tx-12 text-start ps-3">
                      <span class="p-0 m-0 tx-bold pe-3">
                        {{ dictionary["Conectado"][session.user.language] + ":" }}
                      </span>
                      <span class="p-0 m-0">
                        {{ (node.status != "") ? node.status : "-" }}
                      </span>
                    </div>
                  </div>

                </div>

                <div v-if="node.type === '3'" class="node" :style="{ border: collapsed ? '2px solid lightgrey' : '' }">
                  <div class="node-top bg-menu" @click="onTargetTreeDevice(node.id)">
                    <span class='tx-bold tx-12 text-white align-middle ps-1 pe-1' style="min-width: 140px;">
                      {{ node.name }}
                    </span>
                  </div>
                  <div class="node-bot row p-1 border-1 border-top-0 bg-white">
                    <div class="m-0 p-0 tx-12 text-start ps-3">
                      <span class="p-0 m-0 tx-bold">
                        {{ dictionary["Potencia activa"][session.user.language] + ":" }}
                      </span>
                      <span class="ps-2 m-0">
                        {{ (node.power != "") ? node.power : "-" }}
                      </span>
                    </div>

                    <div class="m-0 p-0 tx-12 text-start ps-3">
                      <span class="p-0 m-0 tx-bold">
                        {{ dictionary["Energía activa"][session.user.language] + ":" }}
                      </span>
                      <span class="ps-2 m-0">
                        {{ (node.energy != "") ? node.energy : "-" }}
                      </span>
                    </div>
                  </div>
                </div>

              </template>
            </vue-tree>

            <div class="d-flex flex-row m-0 p-0 gap-1" style="width: 100%;  margin: auto;">
              <div class="bg-menu rounded w-100 m-0 d-flex c-pointer" @click="controlScale(machineSet.id, 'smaller')">
                <span class=" tx-bold tx-white tx-20 m-auto">
                  -
                </span>
              </div>

              <div class="bg-menu rounded w-100 m-0 d-flex c-pointer" @click="controlScale(machineSet.id, 'restore')">
                <span class=" tx-bold tx-white m-auto">
                  1:1
                </span>
              </div>

              <div class="bg-menu rounded w-100 m-0 d-flex c-pointer" @click="controlScale(machineSet.id, 'bigger')">
                <span class=" tx-bold tx-white tx-20 m-auto">
                  +
                </span>
              </div>
            </div>
          </div>

          <div class="row col-lg-12 col-xl-12 col-md-12 col-12  pt-2" style="min-height: 200px;">
            <div class="col-12 bg-caja rounded-5 shadow"
              style=" border-top: 5px solid rgb(0, 87, 147); border-bottom: 2px solid rgb(0, 141, 180);">
              <!--  <div class="col-12 bg-caja rounded-5 shadow"
              style="z-index: 0; height: auto; border-top: 5px solid rgb(0, 87, 147); border-bottom: 2px solid rgb(0, 141, 180);">
              <h6 class="mt-1">Tabla de dispositivos asociados</h6>
              <img src="@/assets/img/dispositivos.png" class="img-fluid mt-4">
              </div> -->
              <div class="row">
                <div class="col-12  col-lg-6 col-xl-6">
                  <div class="m-0 p-3 h-auto " id="devicesTableContent" style=" overflow: scroll; ">
                    <table id="devicesDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
                      <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden overflow-x: hidden;">
                        <tr>
                          <th v-for="column in devicesColumns" :key="column.data" :width="column.width"
                            class="fw-medium text-nowrap p-2">
                            {{ dictionary[column.text][session.user.language] }}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
                <div class="col-12   col-lg-6 col-xl-6">
                  <div class=" d-flex d-row justify-content-center align-items-center">
                    <h6 class="mt-3">Graficas del dispositivo seleccionado {{ deviceData.label }}</h6>
                    <ion-icon name="refresh-outline" class="tx-18 pe-0 pt-1 btn btnSizeChart"
                      @click="chartHistory = {}">
                    </ion-icon>
                  </div>
                  <select v-model="targetVariableId" id="selectTargetDevice" data-live-search="true"
                    class="col-12 m-0 ps-1 text-nowrap" style="padding-bottom: 3px;" @change=changeChartDisplay()>
                    <option v-for="variable in historyVariables" :key="variable['variableId']"
                      :value="variable['variableId']" :selected="variable['variableId'] === targetVariableId"
                      class="text-dark">
                      {{ variable['label'][0].toUpperCase() + variable['label'].substring(1) }}
                    </option>
                  </select>
                  <div class="bg-white rounded-bottom-5 pt-3 pb-2 m-0">
                    <VueEcharts :option="chart1Options" id="Chart1Canvas" class="col-12 w-100 ps-0 pe-0"
                      style="height: 30.25rem;">
                    </VueEcharts>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="row col-lg-12 col-xl-12 col-md-12 col-12 pt-2">
            <div class="col-12 bg-caja rounded-5 shadow"
              style="z-index: 0; height: auto; border-top: 5px solid rgb(0, 87, 147); border-bottom: 2px solid rgb(0, 141, 180);">
              <h6 class="mt-3">Tabla de Incidencias del centro de mando</h6>
              <!-- <img src="@/assets/img/incidencias.png" class="img-fluid mt-4"> -->
              <div class="col-lg-12 col-md-12 p-2">
                <!-- Buttons above the table -->
                <div class="text-center">
                  <div class="row justify-content-center p-3 pt-0 pb-2">
                    <button @click="toggleFilter('0')" type="button"
                      :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('0') ? 'btn-primary-active btn-custom-primary' : 'btn-custom-primary']">
                      {{ dictionary["Pendiente de revisión"][session.user.language] }}
                    </button>
                    <button @click="toggleFilter('1')" type="button"
                      :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('1') ? 'btn-secondary-active btn-custom-secondary' : 'btn-custom-secondary']">
                      {{ dictionary["En investigación"][session.user.language] }}
                    </button>
                    <button @click="toggleFilter('2')" type="button"
                      :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('2') ? 'btn-success-active btn-custom-success' : 'btn-custom-success']">
                      {{ dictionary["En proceso de resolucion"][session.user.language] }}
                    </button>
                    <button @click="toggleFilter('3')" type="button"
                      :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('3') ? 'btn-danger-active btn-custom-danger' : 'btn-custom-danger']">
                      {{ dictionary["Solucionada"][session.user.language] }}
                    </button>
                    <button @click="toggleFilter('4')" type="button"
                      :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('4') ? 'btn-warning-active btn-custom-warning' : 'btn-custom-warning']">
                      {{ dictionary["Cerrada"][session.user.language] }}
                    </button>
                  </div>
                </div>
                <div class="table-responsive p-2">
                  <table id="IncidencesDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
                    <thead class=" tx-14 text-white text-center overflow-hidden gradient-tablas">
                      <tr>
                        <th v-for="column in incidenceColumns" :key="column.data" :width="column.width"
                          class="fw-medium text-nowrap p-2">
                          {{ dictionary[column.text][session.user.language] }}
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL New Device -->
  <NewDeviceModal v-if="display.newDeviceModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" v-on:toggleNewDeviceModal="toggleNewDeviceModal">
  </NewDeviceModal>

  <!-- MODAL Edit Device -->
  <EditDeviceModal v-if="display.editDeviceModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :deviceId="targetDeviceId"
    v-on:toggleEditDeviceModal="toggleEditDeviceModal">
  </EditDeviceModal>

  <!-- MODAL Link Text Alert -->
  <LinkDeviceModal v-if="display.linkDeviceModal" :session="session" :dictionary="dictionary"
    :availableCompanies="availableCompanies" :currentCompany="currentCompany" :targetDeviceId="targetDeviceId"
    :linkAction="linkAction" v-on:dismissAlert="toggleLinkDeviceModal">
  </LinkDeviceModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal v-if="display.deleteDeviceModal" :session="session" :dictionary="dictionary" :countries="countries"
    :alertHeader="dictionary['Dispositivo a eliminar'][session.user.language] + ': '" :alertTarget="targetDeviceId"
    v-on:acceptAlert="onDeleteDevice" v-on:dismissAlert="toggleDeleteDeviceModal">
  </TextAlertModal>


  <!--MODAL Edit Incidence-->
  <EditHistoricModal v-if="display.editHistoricModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
    :incidenceId="targetIncidenceId" :incidenceInfo="targetIncidence"
    v-on:toggleEditHistoricIncidenceModal="toggleEditHistoricIncidenceModal">
  </EditHistoricModal>
  <!--MODAL Add Comments-->
  <EditCommentsModal v-if="display.editCommentsModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
    :incidenceId="targetIncidenceId" :incidenceInfo="targetIncidence"
    v-on:toggleEditCommentsModal="toggleEditCommentsModal">
  </EditCommentsModal>
  <!-- Configuration CommandCenters -->
  <div v-if="display.configurationView">
    <CommandCenterConfigurationComponent :session="session" :dictionary="dictionary" :countries="countries"
      :visibility="visibility" :timeZones="timeZones" :currentCompany="currentCompany"
      :availableCompanies="availableCompanies" :targetEmplacementId="targetEmplacementId"
      :commandCenterLabel="commandCenterLabel" :dataTableOptions="dataTableOptions" :typesByCompany="typesByCompany"
      v-on:toggleConfigurationView="toggleConfigurationView">
    </CommandCenterConfigurationComponent>
  </div>
</template>

<script>
import { VueEcharts } from 'vue3-echarts';
import echartsOptionsJson from '@/assets/json/echarts_options.json';
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";
import $ from 'jquery';
import NewDeviceModal from '@/components/devices/NewDeviceModal';
import EditDeviceModal from '@/components/devices/EditDeviceModal';
import LinkDeviceModal from '@/components/devices/LinkDeviceModal';
import TextAlertModal from '@/components/other/TextAlertModal';
import EditHistoricModal from '@/components/incidences/EditHistoricModal';
import EditCommentsModal from '@/components/incidences/EditCommentsModal'
import CommandCenterConfigurationComponent from '@/components/electricalMonitoring/CommandCenterConfigurationComponent';

// Clomos
import api from '@/services/api';

export default {
  name: "CommandCenterComponent",
  components: { VueTree, VueEcharts, NewDeviceModal, EditDeviceModal, LinkDeviceModal, TextAlertModal, EditHistoricModal, EditCommentsModal, CommandCenterConfigurationComponent },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    },
    typesByCompany: {
      type: Object,
      required: true,
    }
  },
  watch: {
    historyVariables: {
      immediate: true,
      async handler() {
        if (!this.targetVariableId && Object.entries(this.historyVariables)[0] != undefined) {
          this.targetVariableId = Object.entries(this.historyVariables)[0][0];
          this.displayIncidencesDataTable();
        }
      }
    }
  },
  data() {
    return {
      devicesColumns: [
        {
          text: "Nombre", data: "deviceLabel", width: "15%", className: "tx-left align-middle",
          render: (data) => {
            return "<span data-id=" + data.id + " class='text-start tx-12 tx-bold w-100 btnTargetDevice c-pointer'>" + data.label + "</span>"
          }
        },
        {
          text: "Fecha de comunicación", data: "deviceCommunicationDateTime", width: "5%", className: "align-middle",
          render: (data) => {
            return (data != "")
              ? "<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
              : "<span style='display: none;'></span> <spam>-</spam>";
          }
        },
        { text: "Categoría", data: "deviceCategory", width: "5%", className: "tx-left align-middle" },
        { text: "Tipo", data: "deviceType", width: "10%", className: "tx-left align-middle" },
        {
          text: "", data: "deviceLabel", width: "5%", className: "tx-left align-middle",
          render: (data) => {
            return "<ion-icon name='link-outline' data-id=" + data.id + " class='btnGoToDevice btn tx-24 text-black tx-primary  p-0 m-0'></ion-icon>";
          }
        },


      ],
      incidenceColumns: [
        {
          text: "", data: "IncidenceHistoricId", width: "5%", className: " align-middle",
          render: (data) => {
            return "<ion-icon name='chevron-down-circle-outline' data-id=" + data + " class='btnDetailIncidence btn tx-24 text-black tx-primary  p-0 m-0'></ion-icon>";
          }
        },
        {
          text: "Incidencia", data: "IncidenceLabel", width: "10%", className: " align-middle",
          render: (data) => {
            return data;
          }
        },
        {
          text: "Estado",
          data: "IncidenceHistoricStatus",
          width: "10%",
          className: "tx-left align-middle",
          render: (data) => {
            let text;
            let color;

            switch (data) {
              case "0":
                text = this.dictionary["Pendiente de revisión"][this.session.user.language];
                color = "#3498db";
                break;
              case "1":
                text = this.dictionary["En investigación"][this.session.user.language];
                color = "#2ecc71";
                break;
              case "2":
                text = this.dictionary["En proceso de resolucion"][this.session.user.language];
                color = "#e67e22";
                break;
              case "3":
                text = this.dictionary["Solucionada"][this.session.user.language];
                color = "#e74c3c";
                break;
              case "4":
                text = this.dictionary["Cerrada"][this.session.user.language];
                color = "#f39c12";
                break;
              default:
                text = data.status;
                color = "black";
            }

            return `<span style="color: ${color};">${text}</span>`;
          }
        },

        {
          text: "Nivel de gravedad", data: "IncidenceLevel", width: "7%", className: "tx-left align-middle",
          render: (data) => {
            switch (data) {
              case "0":
                return "<div value='0' class='row'>" +
                  "<span style='opacity: 0;'>" + 0 + "</span>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: greenyellow; '></div>" +
                  "</div>";
              case "1":
                return "<div value='1' class='row'>" +
                  "<span style='opacity: 0;'>" + 1 + "</span>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: green; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: green; '></div>" +
                  "</div>";
              case "2":
                return "<div value='2' class='row'>" +
                  "<span style='opacity: 0;'>" + 2 + "</span>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: orange; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: orange; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: orange; '></div>" +
                  "</div>";
              case "3":
                return "<div value='3' class='row'>" +
                  "<span style='opacity: 0;'>" + 3 + "</span>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                  "</div>";
              case "4":
                return "<div value='4' class='row'>" +
                  "<span style='opacity: 0;'>" + 4 + "</span>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                  "</div>";
              default:
                return data.status;
            }


          }
        }, {
          text: "Fecha apertura", data: "openingDate", width: "10%", className: "tx-left align-middle",
          render: (data) => {

            return (api.parseDateUserTimezone(data, this.session.user, this.$dayjs))
          }
        },

        {
          text: "Activo", data: "IncidenceHistoricMachine", width: "7%", className: "tx-left align-middle",
          render: (data) => {
            return (data)

          }
        },
        {
          text: "Actualizar", data: "IncidenceHistoricId", width: "5%", className: " align-middle",
          render: (data) => {
            return ("<ion-icon name='add-circle-outline' data-id=" + data + " class='btnModifyIncidence btn tx-24 text-black m-0 p-0'></ion-icon>"

            )
          }
        },

      ],
      machineOrganigramLoading: false,
      targetEmplacementData: {},
      machineId: '',
      machineOrganigram: '',
      treeConfig: { nodeWidth: 240, nodeHeight: 130, levelHeight: 130 },
      devicesByCommandCenter: [],
      machinesByCommandCenter: [],
      display: {
        newDeviceModal: false,
        editDeviceModal: false,
        linkDeviceModal: false,
        deleteDeviceModal: false,
        newHistoricModal: false,
        editHistoricModal: false,
        editCommentsModal: false,
        configurationView: false,
      },
      linkAction: "link",
      targetDeviceId: '',
      selectedStatuses: [],
      sortedIncidenceList: [],
      incidenceList: [],
      incidenceTypes: [],
      targetIncidenceId: '',
      lineChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["lineChartOptions"],
      targetVariableId: '',
      deviceData: '',
      startDateTimeInput: api.parseDateTimeToString(new Date().setDate(new Date().getDate() - 1)),
      endDateTimeInput: api.parseDateTimeToString(new Date()),
      startDateTime: {
      },
      endDateTime: {
      },
      chartHistory: {
      },
      colors: ["#9e9e9e",
        "#4e79a7",
        "#f28d2b",
        "#e05759",
        "#76b7b2",
        "#59a14f",
        "#af7aa1",
        "#b1de89",
        "#595055",
        "#edc948",
        "#1c8356",
        "#ff9da7",
        "#8d9fca",
        "#2f451e"],
      checkboxTargetAxisLeft: true,
      checkboxTargetAxisRight: false,
      editingPermission: false,
      commandCenterLabel: ''
    }
  },
  emits: ["handleHasRol"],
  methods: {
    // DISPLAY
    async organizeNode(node, levelPath, newMachineOrganigram) {
      let currentLevel = levelPath.split('.').map(Number);
      let target = newMachineOrganigram;
      const checkDuplicateId = (id, target) => {
        if (target.children) {
          return target.children.some(child => child.id === id);
        }
        return false;
      };
      for (let i = 0; i < currentLevel.length; i++) {
        const level = currentLevel[i];
        if (!target.children) target.children = [];
        if (!target.children[level]) target.children[level] = { children: [] };
        if (checkDuplicateId(node.id, target)) {
          console.warn(`El ID ${node.id} ya existe en este nivel. No se asignará.`);
          return;
        }
        target = target.children[level];
      }

      target.id = node.id;
      target.name = node.label;
      target.type = node.dclClass;
      if (node.dclClass === "2") {
        target.status = (node.variables.analog["Estado"] !== undefined) ? node.variables.analog["Estado"].value : '';
        let typeCategory = this.typesByCompany?.machineTypeList?.filter(type => type.id === node.dclTypeId);
        target.category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";
      } else if (node.dclClass === "3") {
        target.power = (node.variables.analog["Potencia activa"] !== undefined) ? node.variables.analog["Potencia activa"].value : '';
        target.energy = (node.variables.analog["Energía activa"] !== undefined) ? node.variables.analog["Energía activa"].value : '';
        let typeCategory = this.typesByCompany?.deviceTypeList?.filter(type => type.id === node.dclTypeId);
        target.category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";
      }
    },
    async displayMachineOrganigram() {
      this.machineOrganigramLoading = true;
      let newMachineOrganigram = [];
      let nodesToOrganize = [];
      let processedNodes = new Set();
      let rootResponse = await api.getMachineData(this.machineId);
      let rootNode = api.parseMachineFromResponse(rootResponse);
      if (rootNode) this.commandCenterLabel = rootNode.label
      if (rootNode.dclClass === "2") {
        rootNode.nodeLevel = "0";
        nodesToOrganize.push(rootNode);
        let typeCategory = this.typesByCompany?.machineTypeList?.filter(type => type.id === rootNode.dclTypeId);
        rootNode.category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";
        this.machinesByCommandCenter.push(rootNode);
        const processNodeChildren = async (parentNode, levelPrefix) => {
          if (!parentNode.children) return;
          let index = 0;
          for (let childId of Object.keys(parentNode.children)) {
            let childResponse;
            let childNode;
            if (processedNodes.has(parentNode.children[childId].id)) {
              console.warn(`Nodo con ID ${parentNode.children[childId].id} ya fue procesado, evitando bucle infinito.`);
              continue;
            }
            if (parentNode.children[childId].claseDcl === "2") {
              childResponse = await api.getMachineData(childId);
              childNode = api.parseMachineFromResponse(childResponse);
            } else if (parentNode.children[childId].claseDcl === "3") {
              childResponse = await api.getDeviceData(childId);
              childNode = api.parseDeviceFromResponse(childResponse);
            } else {
              continue;
            }
            processedNodes.add(childNode.id);
            let currentLevelPath = `${levelPrefix}.${index}`;
            childNode.nodeLevel = currentLevelPath;
            nodesToOrganize.push(childNode);
            if (childNode.dclClass === "3") {
              let typeCategory = this.typesByCompany?.deviceTypeList?.filter(type => type.id === childNode.dclTypeId);
              childNode.category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";

              this.devicesByCommandCenter.push(childNode);
            } else if (childNode.dclClass === "2") {
              let typeCategory = this.typesByCompany?.machineTypeList?.filter(type => type.id === childNode.dclTypeId);
              childNode.category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";
              this.machinesByCommandCenter.push(childNode);
            }
            await processNodeChildren(childNode, currentLevelPath);
            index++;
          }
        };
        await processNodeChildren(rootNode, "0");
      }
      for (let node of nodesToOrganize) {
        await this.organizeNode(node, node.nodeLevel, newMachineOrganigram);

      }
      this.sortChildrenRecursively(newMachineOrganigram)
      this.machineOrganigram = newMachineOrganigram.children;
      this.machineOrganigramLoading = false;
    },
    sortChildrenRecursively(node) {
      if (node.children && node.children.length > 0) {
        node.children.sort((a, b) => a.name.localeCompare(b.name));
        node.children.forEach(child => this.sortChildrenRecursively(child));
      }
    },
    controlScale(id, command) {
      let target = this.$refs[("scaleTree" + id)][0];
      switch (command) {
        case 'bigger':
          target.zoomIn()
          break
        case 'smaller':
          target.zoomOut()
          break
        case 'restore':
          target.restoreScale()
          break
      }
    },
    returnBack() {
      const path = this.$route.path;
      const newPath = path.replace('/commandCenter/', '/commandCenters/').replace(/\/[^/]+$/, '');
      this.$router.replace({
        path: newPath
      });
    },
    async onDisplayElements() {
      this.devicesByCommandCenter = [];
      this.machinesByCommandCenter = [];

      await this.displayMachineOrganigram()
      await this.getHistoricIncidencesByDcl()
      this.displayDevicesDataTable();
      this.displayIncidencesDataTable();
    },
    async onModifyIncidence(event) {
      console.log('MODIFY WHERE :');
      console.log(event.target.getAttribute('data-id'));
      this.targetIncidenceId = event.target.getAttribute('data-id');
      this.toggleEditHistoricIncidenceModal();
    },
    onAddComents(event) {
      this.targetIncidenceHistoryId = event.target.getAttribute('data-id');
      this.display.editCommentsModal = true;
    },
    onPrint(event) {
      this.targetIncidenceHistoryId = event.target.getAttribute('data-id');
      this.captureAndPrint('resumenPedido' + this.targetIncidenceHistoryId);
    },
    onGoToDevice(event){
      this.$router.push({ params: { currentDisplay: "device", targetEntity:  event.target.getAttribute('data-id') } });
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetDevice);
        });
        $(".btnGoToDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onGoToDevice);
        });
        $(".btnEditDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onEditDevice);
        });
        $(".btnLinkDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onLinkDevice);
        });
        $(".btnUnlinkDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onUnlinkDevice);
        });
        $(".btnDeleteDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteDevice);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
        $(".btntargetIncidence").get().forEach(btn => {
          btn.addEventListener('click', this.ontargetIncidence);
        });
        $(".btnDetailIncidence").get().forEach(btn => {
          btn.addEventListener('click', this.onDetaiIncidence)
        });
        $(".btnModifyIncidence").get().forEach(btn => {
          btn.addEventListener('click', this.onModifyIncidence);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
        $(".add-comments").get().forEach(btn => {
          btn.addEventListener('click', this.onAddComents);
        });
        $(".pdf-icon").get().forEach(btn => {
          btn.addEventListener('click', this.onPrint);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    displayDevicesDataTable() {
      $("#devicesDataTable").DataTable().clear()

      for (let i in this.devicesByCommandCenter) {
        $("#devicesDataTable").DataTable().rows.add([{
          deviceId: this.devicesByCommandCenter[i].id,
          deviceLabel: this.devicesByCommandCenter[i],
          deviceCommunicationDateTime: (this.devicesByCommandCenter[i].communicationDateTime != undefined && this.devicesByCommandCenter[i].communicationDateTime != "") ? new Date(this.devicesByCommandCenter[i].communicationDateTime) : "",
          deviceType: this.devicesByCommandCenter[i].type,
          deviceParent: {
            parentId: this.devicesByCommandCenter[i].parent,
            parentLabel: (() => {
              const machine = this.machinesByCommandCenter.find(machine => machine.id === this.devicesByCommandCenter[i].parent);
              if (machine) {
                return machine.label;
              }
              const device = this.devicesByCommandCenter.find(device => device.id === this.devicesByCommandCenter[i].parent);
              return device ? device.label : "none";
            })(),
            deviceId: this.devicesByCommandCenter[i].id
          },
          deviceVersion: this.devicesByCommandCenter[i].version,
          deviceCategory: this.devicesByCommandCenter[i].category,
        }]);
      }
      $("#devicesDataTable").DataTable().draw();
      this.setButtonFunctions();
      if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },
    displayIncidencesDataTable() {
      $("#IncidencesDataTable").DataTable().clear()
      for (let i in this.sortedIncidenceList) {
        $("#IncidencesDataTable").DataTable().rows.add([{
          IncidenceHistoricId: this.sortedIncidenceList[i].id,
          IncidenceHistoricStatus: this.sortedIncidenceList[i].status,
          IncidenceLevel: this.sortedIncidenceList[i].level,
          IncidenceHistoricMachine: this.machinesByCommandCenter.find(machine => machine.id === this.sortedIncidenceList[i].idDcl) ? this.machinesByCommandCenter.find(machine => machine.id === this.sortedIncidenceList[i].idDcl)?.label : this.devicesByCommandCenter.find(device => device.id === this.sortedIncidenceList[i].idDcl) ? this.devicesByCommandCenter.find(device => device.id === this.sortedIncidenceList[i].idDcl)?.label : '-',
          IncidenceLabel: this.sortedIncidenceList[i].incidenceLabel,
          openingDate: this.sortedIncidenceList[i].dateOpen
        }]);
      }
      $("#IncidencesDataTable").DataTable().draw();
      this.setButtonFunctions();
      if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },
    async displayChartsByDistributionGroup(value) {
      let match = false;
      for (const key in this.historyVariables) {
        if (Object.prototype.hasOwnProperty.call(this.historyVariables, key)) {
          const variable = this.historyVariables[key];
          if (variable.distributionGroup === value) {
            this.targetVariableId = variable.variableId;
            await this.getDeviceVariableHistory();
            match = true;
          }
        }
      }
      if (!match) {
        if (Object.entries(this.historyVariables)[0] != undefined) {
          this.targetVariableId = Object.entries(this.historyVariables)[0][0];
        }
      }
    },
    async toggleConfigurationView() {
      if (this.display.configurationView) {
        if (this.$route.path.includes('commandCenter')) {
          const path = this.$route.path;
          const commandCenterId = path.split('commandCenter/')[1];
          if (commandCenterId) {
            this.machineId = commandCenterId;
            this.devicesByCommandCenter = [],
              this.machinesByCommandCenter = [],
              await this.getIncidencesByCompany()
            await this.displayMachineOrganigram()
            await this.getHistoricIncidencesByDcl()
            if (this.devicesByCommandCenter.length > 0) {
              this.deviceData = this.devicesByCommandCenter[0]
              this.targetDeviceId = this.devicesByCommandCenter[0].id
              await this.displayChartsByDistributionGroup('1')
            }
            this.$nextTick(() => {

              this.$nextTick(() => {
                $("#devicesDataTable").DataTable(
                  {
                    pagingType: 'full_numbers',
                    pageLength: 10,
                    processing: true,
                    dom: 'Bfrtip',
                    buttons: [
                      { "extend": 'colvis', "className": 'btn' },
                      { "extend": 'copy', "className": 'btn' },
                      { "extend": 'csv', "className": 'btn' },
                      { "extend": 'print', "className": 'btn me-0' },
                    ],
                    columns: this.devicesColumns,
                    select: this.dataTableOptions.select,
                    language: this.dataTableOptions.language[this.session.user.language],
                    stateSave: this.dataTableOptions.stateSave,
                    order: [[1, 'asc']]
                  }
                );
                $("#IncidencesDataTable").DataTable(
                  {
                    pagingType: 'full_numbers',
                    pageLength: 10,
                    processing: true,
                    dom: 'Bfrtip',
                    buttons: [
                      { "extend": 'colvis', "className": 'btn' },
                      { "extend": 'copy', "className": 'btn' },
                      { "extend": 'csv', "className": 'btn' },
                      { "extend": 'print', "className": 'btn me-0' },
                    ],
                    columns: this.incidenceColumns,
                    select: this.dataTableOptions.select,
                    language: this.dataTableOptions.language[this.session.user.language],
                    //stateSave: this.dataTableOptions.stateSave,
                    order: [[3, 'desc']]
                  }
                );
                $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);

                this.displayDevicesDataTable()
                this.displayIncidencesDataTable();
              });
            });

          }
        }
      }
      this.display.configurationView = !this.display.configurationView;

    },
    async toggleNewDeviceModal() {
      if (this.display.newDeviceModal) {
        await this.onDisplayElements();
      }
      this.display.newDeviceModal = !this.display.newDeviceModal;
    },
    async toggleEditDeviceModal() {
      if (this.display.editDeviceModal) {
        await this.onDisplayElements();
      }
      this.display.editDeviceModal = !this.display.editDeviceModal;
    },
    async toggleLinkDeviceModal() {
      if (this.display.linkDeviceModal) {
        await this.onDisplayElements();
      }
      this.display.linkDeviceModal = !this.display.linkDeviceModal;
    },
    async toggleDeleteDeviceModal() {
      if (this.display.deleteDeviceModal) {
        await this.onDisplayElements();
      }
      this.display.deleteDeviceModal = !this.display.deleteDeviceModal;
    },
    async toggleEditHistoricIncidenceModal() {
      if (this.display.editHistoricModal) {
        await this.onDisplayElements();
      }
      this.display.editHistoricModal = !this.display.editHistoricModal;
    },
    async toggleEditCommentsModal() {
      if (this.display.editCommentsModal) {
        await this.onDisplayElements();
      }
      this.display.editCommentsModal = !this.display.editCommentsModal;
    },
    toggleFilter(status) {
      const index = this.selectedStatuses.indexOf(status);
      if (index > -1) {
        this.selectedStatuses.splice(index, 1);
      } else {
        this.selectedStatuses.push(status);
      }
      this.filterTablebyStatus();
    },
    changeDeviceChartDisplay() {
      this.chartHistory = {}
      this.displayChartsByDistributionGroup('1')
    },
    changeChartDisplay() {
      this.getDeviceVariableHistory()
    },
    filterTablebyStatus() {
      if (this.selectedStatuses.length === 0) {
        this.sortedIncidenceList = this.incidenceList;
      } else {
        this.sortedIncidenceList = this.incidenceList.filter(item =>
          this.selectedStatuses.includes(item.status.toString())
        );
      }
      this.displayIncidencesDataTable();

    },
    isActive(status) {
      return this.selectedStatuses.includes(status);
    },
    onTargetTreeDevice(id) {
      this.targetDeviceId = id
      this.deviceData = this.devicesByCommandCenter.find(device => device.id === this.targetDeviceId)
      this.changeDeviceChartDisplay()
    },
    // TARGET 
    async onTargetDevice(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id')
      this.deviceData = this.devicesByCommandCenter.find(device => device.id === this.targetDeviceId)
      this.changeDeviceChartDisplay()


    },
    // SET 
    onEditDevice(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id');

      this.toggleEditDeviceModal();
    },
    // LINK 
    onLinkDevice(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id')
      this.linkAction = "link"
      this.toggleLinkDeviceModal();
    },
    // UNLINK 
    onUnlinkDevice(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id')
      this.linkAction = "unlink"
      this.toggleLinkDeviceModal();
    },
    // DELETE
    async onDeleteDevice(event) {
      if (!this.display.deleteDeviceModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetDeviceId);
        this.targetDeviceId = event.target.getAttribute('data-id');
      }
      else {
        let response = await api.deleteDevice(this.targetDeviceId);
        if (response["status-code"] === "200") {
          window.alert("El dispositivo ha sido eliminado satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el dispositivo.");
        }
      }
      await this.toggleDeleteDeviceModal();
    },
    async onDetaiIncidence(event) {
      console.log('DETAIL WHERE ID:');
      this.targetIncidenceId = event.target.getAttribute('data-id');

      var tr = $(event.target).closest('tr');
      var row = $("#IncidencesDataTable").DataTable().row(tr);
      if (row.child.isShown()) {
        event.target.name = "chevron-down-circle-outline";
        row.child.hide();
        tr.removeClass('shown');
      } else {
        event.target.name = "chevron-up-circle-outline";
        let content = '' +
          '<div id="resumenPedido' + this.targetIncidence.id + '" class="p-1" style="background-color: white!important" data-id="' + this.targetIncidence.id + '">' +
          '<h2>' + this.dictionary["Resumen de la incidencia"][this.session.user.language] + '<ion-icon class= "pdf-icon btn tx-24 tx-primary m-0 ms-3 p-0" name="print-outline" data-id="' + this.targetIncidence.id + '"></ion-icon>' + '</h2>' +
          '<h2 id="incidenceID">' + "Identificador: " + this.targetIncidence.id + '</h2>' +
          '<div class="container">' +
          '<ol class="progress-meter">';

        const stages = [
          {
            key: 'dateOpen',
            label: `${this.dictionary["Pendiente de revisión"][this.session.user.language]}${this.targetIncidence.dateOpen ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateOpen, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
          },
          {
            key: 'dateInvestigation',
            label: `${this.dictionary["En investigación"][this.session.user.language]}${this.targetIncidence.dateInvestigation ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateInvestigation, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
          },
          {
            key: 'dateProgress',
            label: `${this.dictionary["En proceso de resolucion"][this.session.user.language]}${this.targetIncidence.dateProgress ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateProgress, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
          },
          {
            key: 'dateSolved',
            label: `${this.dictionary["Solucionada"][this.session.user.language]}${this.targetIncidence.dateSolved ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateSolved, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
          },
          {
            key: 'dateClosed',
            label: `${this.dictionary["Cerrada"][this.session.user.language]}${this.targetIncidence.dateClosed ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateClosed, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
          }
        ];

        stages.forEach(stage => {
          const done = this.targetIncidence[stage.key] !== "";
          content += '<li class="progress-point ' + (done ? 'done' : 'todo') + '">' +
            stage.label + '</li>';
        });

        content += '</ol></div>' + '<div class =row>'
          + '<div class=col-md-6>';
        content +=
          '<table style="border-collapse: collapse; width: 100%;">' +
          '<tr>' +
          '<th style="padding:2px; text-align: left; font-size: 1.5rem; background-color: #f2f2f2;" colspan="2">' +
          this.dictionary["Información de la incidencia"][this.session.user.language] + '</th>' +
          '</tr>'


        const statusMap = {
          "0": this.dictionary["Pendiente de revisión"][this.session.user.language],
          "1": this.dictionary["En investigación"][this.session.user.language],
          "2": this.dictionary["En proceso de resolucion"][this.session.user.language],
          "3": this.dictionary["Solucionada"][this.session.user.language],
          "4": this.dictionary["Cerrada"][this.session.user.language],

        };
        const levelMap = {
          "0": this.dictionary["Muy baja"][this.session.user.language],
          "1": this.dictionary["Baja"][this.session.user.language],
          "2": this.dictionary["media"][this.session.user.language],
          "3": this.dictionary["Critica"][this.session.user.language],
        }
        const levelDescription = levelMap[this.targetIncidence.level] || this.targetIncidence.level;
        const statusDescription = statusMap[this.targetIncidence.status] || this.targetIncidence.status;
        content += '<tr style="padding:2px; text-align: left;">' +
          '<td style="padding:2px; text-align: left;">' + this.dictionary["Identificador"][this.session.user.language] + '</td>' +
          '<td style="padding:2px; text-align: left;">' + this.targetIncidence.id + '</td>' +
          '</tr>';
        content += '<tr style="padding:2px; text-align: left;">' +
          '<td style="padding:2px; text-align: left;">' + this.dictionary["Estado"][this.session.user.language] + '</td>' +
          '<td style="padding:2px; text-align: left;">' + statusDescription + '</td>' +
          '</tr>';
        content += '<tr style="padding:2px; text-align: left;">' +
          '<td style="padding:2px; text-align: left;">' + this.dictionary["Nivel de gravedad"][this.session.user.language] + '</td>' +
          '<td style="padding:2px; text-align: left;">' + levelDescription + '</td>' +
          '</tr>';
        content += '<tr>' +
          '<td style="padding:2px; text-align: left;">' + this.dictionary["Última actualización"][this.session.user.language] + '</td>' +
          '<td style="padding:2px; text-align: left;">' + api.parseDateUserTimezone(this.targetIncidence.dateNow, this.session.user, this.$dayjs).split(' ')[0]; + '</td>' +
            '</tr>';
        content += '<tr>' +
          '<td style="padding:2px; text-align: left;">' + this.dictionary["Creado por"][this.session.user.language] + ": " + '</td>' +
          '<td style="padding:2px; text-align: left;">' + this.targetIncidence.createdBy + '</td>' +
          '</tr>' + '</table>' + ' </div>';
        /*    } */

        content += ' </div>' + '<table style="border-collapse: collapse; width: 100%;">' + '<tr>' +
          '<td style="padding:2px; text-align: left;" colspan="2">' +
          '<h2 style="font-size: 1.5rem;">' + this.dictionary["Observaciones"][this.session.user.language] + '<ion-icon class= "add-comments btn tx-24 tx-primary m-0 ms-3 p-0" id="add-comments" name="add-circle-outline" ></ion-icon>' + '</h2>' +
          '<table>' +
          '<tr>' +
          '<th style="padding:2px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Fecha"][this.session.user.language] + '</th>' +
          '<th style="padding:2px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Usuario"][this.session.user.language] + '</th>' +
          '<th style="padding:2px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Comentarios"][this.session.user.language] + '</th>' +
          '</tr>';

        for (let key in this.targetIncidence.description) {
          if (Object.prototype.hasOwnProperty.call(this.targetIncidence.description, key)) {
            let observacion = this.targetIncidence.description[key];
            content += '<tr>' +
              '<td style="padding:2px; text-align: start;">' + api.parseDateUserTimezone(key, this.session.user, this.$dayjs) + '</td>' +
              '<td style="padding:2px; text-align: start;">' + observacion.usuario + '</td>' +
              '<td style="padding:2px; text-align: start;">' + observacion.texto + '</td>' +
              '</tr>';
          }
        }

        content += '</table>' +
          '</td>' +
          '</tr>' +
          '</table>' +
          '</div>' + '</div>';
        row.child(content).show();

        if (this.targetIncidenceId) {
          let detailTable = document.getElementById('detailTable' + this.targetIncidenceId);
          if (detailTable && detailTable.parentNode) {
            detailTable.parentNode.style.padding = 0;
          }
        }
        tr.addClass('shown');
      }
      this.setButtonFunctions();

    },

    captureAndPrint(content) {
      var mywindow = window.open('', 'PRINT', 'height=400,width=600');

      mywindow.document.write('<html><head><title>' + "Impresión de incidencia" + '</title>');
      mywindow.document.write('</head><body >');

      mywindow.document.write(document.getElementById(content).innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();

      this.setButtonFunctions();

      return true;
    },
    //GET
    async getIncidencesByCompany() {

      const path = this.$route.path;
      const segments = path.split('/');
      let request = {
        nifCif: segments[2],
        limit: 0,
        offset: 0,

      };
      let response = await api.getIncidencesByCompany(request);
      this.incidenceTypes = api.parseIncidenceListFromResponse(response);
    },
    async getHistoricIncidencesByDcl() {
      this.sortedIncidenceList = [];
      const devicePromises = this.devicesByCommandCenter.map(async (device) => {
        let data = { id: device.id };
        let response = await api.getHistoricIncidencesByDcl(data);
        if (response['status-code'] === '200') {
          let parsedResponse = api.parseHistoricIncidenceListFromResponse(response);
          return parsedResponse
        } else {
          return null;
        }

      });
      const machinePromises = this.machinesByCommandCenter.map(async (machine) => {
        let data = { id: machine.id };
        let response = await api.getHistoricIncidencesByDcl(data);
        if (response['status-code'] === '200') {
          let parsedResponse = api.parseHistoricIncidenceListFromResponse(response);
          return parsedResponse
        } else {
          return null;
        }
      });
      const allIncidences = await Promise.all([...devicePromises, ...machinePromises]);
      const nonEmptyIncidences = allIncidences
        .filter(incidence => incidence && incidence.length > 0)
        .flat();
      this.sortedIncidenceList.push(...nonEmptyIncidences);
      this.sortedIncidenceList.forEach(incidence => {
        const matchingType = this.incidenceTypes.find(type => type.id === incidence.idIncidence);
        if (matchingType) {
          incidence.incidenceLabel = matchingType.label;
        }
      });
      this.incidenceList = this.sortedIncidenceList
    },
    async getDeviceVariableHistory(callback) {
      if (!this.historyVariables[this.targetVariableId]) return
      let targetAxis = (this.checkboxTargetAxisRight) ? 'right' : 'left';
      let step = false;
      if (this.deviceData != {}) {
        let request = {
          id: this.deviceData.id,
          variableLabel: this.historyVariables[this.targetVariableId].label,
          variableType: this.historyVariables[this.targetVariableId].type,
          limit: 0,
          offset: 0,
          startDateTime: api.parseDateTimeToString(this.startDateTimeInput),
          endDateTime: api.parseDateTimeToString(this.endDateTimeInput)
        }
        let response = await api.getDeviceVariableHistory(request);
        // PARSE HISTORY RESPONSE
        let parsedResponse = api.parseVariableDataFromHistoryResponse(response);
        let rawHistory = parsedResponse.history
        this.chartHistory[this.historyVariables[this.targetVariableId].label] = [];
        for (let i in rawHistory) {
          this.chartHistory[this.historyVariables[this.targetVariableId].label][i] = [
            // DATE
            this.$dayjs(new Date(rawHistory[i][0])).utc("z").local().tz(this.session.user.dateTimeZone).$d,
            // VALUE
            new Number(rawHistory[i][1]).toFixed(this.fractionalLength),
            // POSITION
            targetAxis,
            // STEP
            step,
            parsedResponse.unit
          ]
        }
        // SET DATA BOUNDRIES
        this.startDateTime = this.startDateTimeInput;
        this.endDateTime = this.endDateTimeInput;
        // SWITCH AXIS TARGET
        this.checkboxTargetAxisRight = !this.checkboxTargetAxisRight;
        this.checkboxTargetAxisLeft = !this.checkboxTargetAxisRight;
        if (callback && typeof callback === "function") callback(this.availableDevices)
      }
    },
    checkEditingRoleCallback(hasPermission) {
      this.editingPermission = hasPermission;
    },
    async refreshView() {
      if (this.$route.path.includes('commandCenter')) {
        const path = this.$route.path;
        const commandCenterId = path.split('commandCenter/')[1];
        if (commandCenterId) {
          this.machineId = commandCenterId;
          await this.getIncidencesByCompany()
          this.devicesByCommandCenter = [];
          this.machinesByCommandCenter = [];
          await this.displayMachineOrganigram()
          await this.getHistoricIncidencesByDcl()
          if (this.devicesByCommandCenter.length > 0) {
            this.deviceData = this.devicesByCommandCenter[0]
            this.targetDeviceId = this.devicesByCommandCenter[0].id
            await this.displayChartsByDistributionGroup('1')
          }
          this.displayDevicesDataTable()
          this.displayIncidencesDataTable();
        }
      }
    }

  },
  computed: {
    targetIncidence() {
      for (let i in this.sortedIncidenceList) {
        if (this.sortedIncidenceList[i].id === this.targetIncidenceId)
          return this.sortedIncidenceList[i];
      }
      return {};
    },
    fractionalLength() {
      return (localStorage.getItem("ClomosFractionalLength") != undefined) ? localStorage.getItem("ClomosFractionalLength") : 2;
    },
    historyVariables() {
      let historyVariables = {};
      for (let variableType in this.deviceData.variables)
        for (let variable in this.deviceData.variables[variableType])
          if (this.deviceData.variables[variableType][variable].saveHistory === 'true' && (variableType === 'analog' || variableType === 'digital')) {
            historyVariables[this.deviceData.variables[variableType][variable].variableId] = this.deviceData.variables[variableType][variable];
          }
      return historyVariables;
    },

    chart1Options() {
      let chart1Options;
      chart1Options = JSON.parse(JSON.stringify(this.lineChartOptions));
      if (this.targetDeviceId == "") return chart1Options;
      chart1Options.axisPointer = {
        label: {
          formatter: ((dataTarget) => {
            let d = new Date(dataTarget.value);
            switch (this.session.user.dateTimeFormat) {
              case "DD/MM/YYYY":
                return String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              case "MM/DD/YYYY":
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              default:
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
            }
          })
        }
      }
      chart1Options.xAxis = {
        min: new Date(this.startDateTime).getTime(),
        max: new Date(this.endDateTime).getTime(),
        axisLabel: {
          formatter: ((value) => {
            let d = new Date(value);
            switch (this.session.user.dateTimeFormat) {
              case "DD/MM/YYYY":
                return String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              case "MM/DD/YYYY":
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              default:
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
            }
          }),
          textStyle: {
            fontSize: 9
          },
          axisLineShow: true
        }
      }
      let i = 0;
      for (let variable in this.chartHistory) {
        chart1Options.yAxis[i] = {
          type: "value",
          axisLine: {
            lineStyle: {
              color: this.colors[i]
            }
          },
          name: variable.substring(0, 3).toUpperCase(),
          nameTextStyle: {
            fontSize: 10
          },
          axisLabel: {
            fontSize: 9,
            formatter: `{value} ${(this.chartHistory[variable][i] != undefined)
                        ? this.chartHistory[variable][i][4]
                        : ''}`
          },
          offset: Math.floor(i / 2) * 25,
          position: (this.chartHistory[variable][i] != undefined)
            ? this.chartHistory[variable][i][2]
            : (i % 2 === 0)
              ? "left"
              : "right"
        }
        chart1Options.legend.data[i] = variable;
        chart1Options.series[i] = {
          data: this.chartHistory[variable],
          name: variable,
          yAxisIndex: i,
          type: "line",
          symbol: "emptyDiamond",
          symbolSize: 6,
          color: this.colors[i],
          smooth: false,
          step: (this.chartHistory[variable] != undefined) ? this.chartHistory[variable] : false,
          areaStyle: {
            color: this.colors[i],
            opacity: 0.03
          },
        }
        i += 1;
      }
      return chart1Options;
    },


  },
  async created() {
    if (this.$route.path.includes('commandCenter')) {
      const path = this.$route.path;
      const commandCenterId = path.split('commandCenter/')[1];
      if (commandCenterId) {
        this.machineId = commandCenterId;
        await this.getIncidencesByCompany()
        await this.displayMachineOrganigram()
        await this.getHistoricIncidencesByDcl()
        if (this.devicesByCommandCenter.length > 0) {
          this.deviceData = this.devicesByCommandCenter[0]
          this.targetDeviceId = this.devicesByCommandCenter[0].id
          await this.displayChartsByDistributionGroup('1')
        }
        this.displayDevicesDataTable()
        this.displayIncidencesDataTable();
      }
    }
  },
  mounted() {

    $("#devicesDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.devicesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
        order: [[1, 'asc']]
      }
    );
    $("#IncidencesDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.incidenceColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        //stateSave: this.dataTableOptions.stateSave,
        order: [[3, 'desc']]
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
    this.$emit("handleHasRol", "editor", this.checkEditingRoleCallback);
  }
}

</script>

<style>
.btn-custom-primary {
  font-size: 12;
  border: 2px solid #3498db;
  border-left: 10px solid #3498db;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-secondary {
  border: 2px solid #2ecc71;
  border-left: 10px solid #2ecc71;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-success {
  border: 2px solid #e67e22;
  border-left: 10px solid #e67e22;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-danger {
  border: 2px solid #e74c3c;
  border-left: 10px solid #e74c3c;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-warning {
  border: 2px solid #f39c12;
  border-left: 10px solid #f39c12;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}


.btn-custom-primary:hover {
  font-size: 12;
  border-color: #2980b9;
  background-color: #eaf0f8;
}

.btn-custom-secondary:hover {
  border-color: #27ae60;

  background-color: #eaf6e7;
}

.btn-custom-success:hover {
  border-color: #d35400;
  background-color: #f8eae2;
}

.btn-custom-danger:hover {
  border-color: #c0392b;
  background-color: #f8d7da;
}

/* */
.btn-primary-active {
  border-color: #2980b9 !important;
  background-color: #eaf0f8 !important;
  box-shadow: inset 0 0 10px #2980b9;

  font-weight: bold;
  position: relative;
}

.btn-secondary-active {
  border-color: #27ae60 !important;
  background-color: #eaf6e7 !important;
  box-shadow: inset 0 0 10px #27ae60;

  font-weight: bold;
  position: relative;
}

.btn-success-active {
  border-color: #d35400 !important;
  background-color: #f8eae2 !important;
  box-shadow: inset 0 0 10px #d35400;

  font-weight: bold;
  position: relative;
}

.btn-danger-active {
  border-color: #c0392b !important;
  background-color: #f8d7da !important;
  box-shadow: inset 0 0 10px #c0392b;

  font-weight: bold;
  position: relative;
}

.btn-warning-active {
  border-color: #e67e22 !important;
  background-color: #fff3e0 !important;
  box-shadow: inset 0 0 10px #e67e22;

  font-weight: bold;
  position: relative;
}

.btn-primary-active::after,
.btn-secondary-active::after,
.btn-success-active::after,
.btn-danger-active::after,
.btn-warning-active::after {
  content: '✓';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);


}
</style>