<template>
  <!-- MODAL Edit Emplacement -->
    <div id="editEmplacementModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
      <div class="col-12 m-0 p-0 section-container rounded-5 shadow">
  <!-- HEAD -->
        <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
          <div class="col-10">
            <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
              <ion-icon name="location" class="tx-18 me-2 align-bottom"></ion-icon>
              {{dictionary['Editar '+((this.$route.params.currentView==='emplacements')?'emplazamiento':'centro de mando')][session.user.language]+': '+editEmplacementData.label}}
            </h6>
          </div>
  
          <div class="col-2 m-0 p-0 d-flex justify-content-end">	
            <ion-icon :name="(editMode)?'lock-closed':'lock-open'" class="tx-18 btn m-0 mt-1 p-1 border-2 rounded tx-white align-bottom" @click="editMode=!editMode"></ion-icon>
            <ion-icon name="close" class="tx-30 btn m-0 p-1 pb-0 tx-white align-bottom" @click="$emit('toggleEditEmplacementModal')"></ion-icon>
          </div>
        </div>
  
  <!-- CONTENT Edit Emplacement -->
        <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
          <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
            <div class="row m-0 p-2 card-body">
              
              <div class="col-lg-12 col-md-12 col-12 m-0 p-2">
                <form class="col-12 border rounded m-0 p-1 pb-0 overflow-hidden">
                  <div class="bg-menu text-white p-1 m-0 rounded"> 
                    {{dictionary['Datos del '+((this.$route.params.currentView==='emplacements')?'emplazamiento':'centro de mando')][session.user.language]}}
                  </div>
                  <div class="row container-fluid m-0 p-1 ps-0 pe-0 justify-content-between">
                    <div class="row col-lg-6 col-md-12 col-12 m-0 p-2 rounded-5 justify-content-around" style="height: fit-content;">
                    <!--  <label class="p-0 pb-2 ps-4 text-light tx-bold tx-13">
                            {{dictionary['Geoposición'][session.user.language]}}
                         </label>-->
  <!-- CONTENT Map -->
                      <div id="emplacementMapContent" class="m-0 p-3 w-100 rounded-top-5" style="height: 208px; index: 0;">
                      </div>
                          
                      <div class="col-12 m-0 pt-2 pb-2 d-flex justify-content-around bg-menu text-white ">
  <!-- LATITUD -->
                        <div class="col-5 p-0 m-0 d-flex">
                            <label class="p-0 pe-2 pt-2 tx-11">
                              {{dictionary['Latitud'][session.user.language]}}
                            </label><br>
                            <span v-if="!editMode" class="tx-13" style="padding-top: 0.3rem; padding-left: 0.3rem;">
                              {{editEmplacementData.geoJSON.geometry.coordinates[0]}}
                            </span>
                            <input v-if="editMode" v-model="editEmplacementData.geoJSON.geometry.coordinates[0]" id="inputEditEmplacementLat" type="number" step=0.00001 
                            class="form-control form-control-line"
                            @change="onPositionChanged">
                        </div>
  <!-- LONGITUD -->
                        <div class="col-5 p-0 m-0 d-flex">
                          <label class="p-0 pe-2 pt-2 tx-11">
                            {{dictionary['Longitud'][session.user.language]}}
                          </label><br>
                          <span v-if="!editMode" class="tx-13" style="padding-top: 0.3rem; padding-left: 0.3rem;">
                            {{editEmplacementData.geoJSON.geometry.coordinates[1]}}
                          </span>
                          <input v-if="editMode" v-model="editEmplacementData.geoJSON.geometry.coordinates[1]" id="inputEditEmplacementLng" type="number" step=0.00001 
                          class="form-control form-control-line"
                          @change="onPositionChanged">
                        </div>
                      </div>
  
                      <div class="row col-12 bg-white border rounded-bottom-5 container-fluid p-0 pb-2 ">
  <!-- NOMBRE -->
                      <div class="col-lg-3 col-12 ps-2 pe-2 tx-left">
                        <label class="p-0 pt-1 text-dark tx-bold tx-12">
                          {{dictionary['Nombre'][session.user.language]}}
                        </label>
                        <span v-if="!editMode" class="tx-12">
                          {{editEmplacementData.label}}
                        </span>
                        <input v-if="editMode" v-model="editEmplacementData.label" id="inputEditEmplacementName" type="text" 
                        class="form-control form-control-line tx-12" style="color:black;">
                      </div>
  
  <!-- LOCALIZACION -->
                      <div class="col-lg-4 col-12 ps-2 pe-2 tx-left">
                        <label class="p-0 pt-1 text-dark tx-bold tx-12">
                          {{dictionary['Localización'][session.user.language]}}
                        </label>
                        <span v-if="!editMode" class="tx-12">
                          {{editEmplacementData.location}}
                        </span>
                        <textarea v-if="editMode" v-model="editEmplacementData.location" id="inputEditEmplacementLocation" type="text" 
                        class="form-control form-control-line tx-12" style="color:black; height: 2rem;"/>
                      </div>
  <!-- COORDENADAS -->
                      <div class="col-lg-5 col-12 m-0 mt-2 p-2 pt-1 pb-1">
  <!-- SUBMIT -->
                        <div v-if="editMode" class="col-12 m-0 p-0">
                          <button type="button" @click="setEditEmplacementData"
                          id="btnSubmitEmplacementData" class="button-ok gradient-tablas rounded-3">
                            <span class="button-text text-white">
                              <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-14 hydrated"></ion-icon>
                              <span class="text-white mt-0text-white mt-0 tx-12">{{dictionary['Guardar cambios'][session.user.language]}}</span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div> 
  
                  </div>
  <!-- FORM Emplacement Image -->
                  <form class="col-lg-3 col-md-12 col-12 m-0 p-2">
                    <div class="justify-content-center bg-menu text-white rounded-5 text-center m-0 p-0">
                    
                      <label class="col-12 p-2 ps-4 mt-2 tx-center tx-11">
                        {{dictionary['Imagen del '+((this.$route.params.currentView==='emplacements')?'emplazamiento':'centro de mando')][session.user.language].toUpperCase()}}
                      </label>
                      <hr class="text-white m-0 ms-3 me-3">
                      <label class="col-12 p-3 pt-0 pb-2 mt-1 tx-white tx-light tx-11">
                        {{dictionary["Tamaño recomendado"][session.user.language]+': 1000x1000px'}}
                      </label>
                      
                      <img id="emplacementImage" class="w-100 img-fluid" style="width: auto; padding: 0 1rem 1rem 1rem;" :src="displayImage(this.editEmplacementData.imgSrc)">
  
                      <div v-if="emplacementImageForm.file!=''" class="myEmplacementImagePreview w-100 ps-3 pe-3 pt-0 pb-2">
                        <div class="d-flex flex-column">
                          <button type="button" @click="onRemoveEditEmplacementImage()" title="Remove file" style="line-height: 0; width: 25px; height: 25px" class="p-1 m-0 bg-clomos-white rounded ms-auto">
                            <ion-icon name="close" class="tx-18 tx-black align-bottom"></ion-icon>
                          </button>
                          <img style="max-width: 200px; width: auto; padding: 1rem; margin: auto;" :src="displayEditEmplacementImageThumbnail(emplacementImageForm.file)" />
                        </div>
                      </div>
  
                      <div v-if="editMode" id="emplacementImageUploadFormContainer" class="p-3 pt-0">
                        <input type="file" id="emplacementImageUploadSrc" class="col-12 mb-3 file-button btn btn-secondary rounded tx-12" style="height: 2.3rem; min-width: 180px;"
                        @change="onChangeEditEmplacementImage" accept=".png, .jpg">
  
                        <button type="button" @click="setEditEmplacementImage"
                        id="emplacementImageUploadSubmit" class="button-ok gradient-tablas rounded-3">
                          <span class="button-text text-white">
                            <ion-icon name="image-outline" class="text-white pt-0 me-2 tx-14 hydrated"></ion-icon>
                            <span class="text-white mt-0 tx-12">{{dictionary['Guardar archivo'][session.user.language]}}</span>
                          </span>
                        </button>
                      </div>
  
                    </div>
                  </form>
  
  <!-- FORM Emplacement Blueprint -->
                  <form class="col-lg-3 col-md-12 col-12 m-0 p-2">
                    <div class="justify-content-center bg-menu text-white rounded-5 text-center m-0 p-0">
                      
                      <label class="col-12 p-2 ps-4 mt-2 tx-center tx-11">
                        {{dictionary["Plano"][session.user.language].toUpperCase()}}
                      </label>
                      <hr class="text-white m-0 ms-3 me-3">
                      <label class="col-12 p-3 pt-0 pb-2 tx-white tx-light tx-11  ">
                        {{dictionary["Tamaño recomendado"][session.user.language]+': 1000x1000px'}}
                      </label>
                      <img id="emplacementBlueprint" class="w-100 img-fluid" style="width: auto; padding: 0 1rem 1rem 1rem;" :src="displayBlueprint()">
  
                      <div v-if="emplacementBlueprintForm.file!=''" class="myEmplacementBlueprintPreview w-100 ps-3 pe-3 pt-0 pb-2">
                        <div class="w-100 ps-3 pe-3 pt-0 pb-2">
                          <button type="button" @click="onRemoveEditEmplacementBlueprint()" title="Remove file" style="line-height: 0; width: 25px; height: 25px" class="p-1 m-0 bg-clomos-white rounded ms-auto">
                            <ion-icon name="close" class="text-white pt-0 me-2 tx-14 hydrated md"></ion-icon>
                          </button>
                          <img style="max-width: 200px; width: auto; padding: 1rem; margin: auto;" :src="displayEditEmplacementBlueprintThumbnail(emplacementBlueprintForm.file)" />
                        </div>
                      </div>
  
                      <div v-if="editMode" id="emplacementBlueprintUploadFormContainer" class="w-100 p-3 pt-0">
                        <input type="file" id="emplacementBlueprintUploadSrc" class="col-12 mb-3 file-button btn btn-secondary rounded tx-12" style="height: 2.3rem; min-width: 180px;"
                        @change="onChangeEditEmplacementBlueprint" accept=".png, .jpg">
                        <button type="button" id="emplacementBlueprintUploadSubmit" @click="setEditEmplacementBlueprint" class="button-ok gradient-tablas rounded-3">
                          <span class="button-text text-white">
                            <ion-icon name="image-outline" class="text-white pt-0 me-2 tx-14 hydrated md"></ion-icon>
                            <span class="text-white mt-0 tx-12">{{dictionary['Guardar archivo'][session.user.language]}}</span>
                          </span>
                        </button>
                      </div>
  
                    </div>
                  </form>
  
                  </div>
                </form>
              </div>
  
            </div>
          </div>
        </div>
        
      </div>
    </div>
  
  </template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
// Clomos
import api from '@/services/api';

export default {
  name: "EditEmplacementModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    emplacementId: {
      type: String,
      required: true,
    },
    editRequest: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      editEmplacementData: {
        nifCif: this.currentCompany.nifCif,
        id: "",
        dclId: "",
        dclTypeId: "",
        label: "",
        location: "",
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0,0]
          }
        },
        imgSrc: "",
      },
      emplacementMap: undefined,
      emplacementMarker: undefined,
      emplacementImageForm: {
        id: this.emplacementId,
        nifCif: "",
        src: "",
        file: ""
      },
      emplacementBlueprintForm: {
        id: this.emplacementId,
        nifCif: "",
        src: "",
        file: ""
      }
    }
  },
  emits: ["toggleEditEmplacementModal"],
  methods: {
    // DISPLAY
    displayEmplacementMap() {
      if (this.emplacementMap != undefined) {
        this.emplacementMap.invalidateSize();
        this.emplacementMap.remove();
      }
      var emplacementMap = Leaflet.map("emplacementMapContent");
      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang='+this.session.user.language,{maxZoom: 16}).addTo(emplacementMap);
      emplacementMap.on('click', this.onSetNewCoordinates)
      
      this.emplacementMap = emplacementMap;
      
      let emplacementMapListForGroup = []; 
      if ($(".leaflet-control-attribution").get()[0] != undefined) $(".leaflet-control-attribution").get()[0].remove();
      var emplacementMarker = Leaflet.marker([this.editEmplacementData.geoJSON.geometry.coordinates[0],this.editEmplacementData.geoJSON.geometry.coordinates[1]]);
      emplacementMarker.addTo(emplacementMap);
      emplacementMapListForGroup.push(emplacementMarker);
      var emplacementMapGroup = Leaflet.featureGroup(emplacementMapListForGroup);
      this.emplacementMap.fitBounds(emplacementMapGroup.getBounds());

      this.emplacementMarker = emplacementMarker;
    },
    displayImage(src) {
      if (src!=undefined && src!='') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("ClomosAuthToken");
        }
        catch(e) { 
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    displayBlueprint() {
      let src = "";
      if (this.editEmplacementData.variables != undefined
      && this.editEmplacementData.variables.dynamic != undefined
      && this.editEmplacementData.variables.dynamic.urlPlano != undefined)
        src = this.editEmplacementData.variables.dynamic.urlPlano.value;
      if (src!=undefined && src!='') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("ClomosAuthToken");
        }
        catch(e) { 
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    displayEditEmplacementImageThumbnail(file) {
      if (file === '' || file === undefined) return "";
      let fileSrc = URL.createObjectURL(file);
        setTimeout(() => {
            URL.revokeObjectURL(fileSrc);
        }, 1000);
      return fileSrc;
    },
    onChangeEditEmplacementImage(event) {
      if (event.target.files[0] != undefined && event.target.files[0].size > 2097152) {
        alert("El tamaño del fichero debe ser inferior a 2Mb.");
        return;
      }
      this.emplacementImageForm.nifCif = this.editEmplacementData.nifCif;
      this.emplacementImageForm.file = event.target.files[0];
      this.emplacementImageForm.src = event.target.files[0].name;
    },
    onRemoveEditEmplacementImage() {
      $("#emplacementImageUploadSrc").get()[0].value="";
      this.emplacementImageForm.nifCif = "";
      this.emplacementImageForm.file = "";
      this.emplacementImageForm.src = "";
    },
    displayEditEmplacementBlueprintThumbnail(file) {
      if (file === '' || file === undefined) return "";
      let fileSrc = URL.createObjectURL(file);
        setTimeout(() => {
            URL.revokeObjectURL(fileSrc);
        }, 1000);
      return fileSrc;
    },
    onChangeEditEmplacementBlueprint(event) {
      if (event.target.files[0] != undefined && event.target.files[0].size > 2097152) {
        alert("El tamaño del fichero debe ser inferior a 2Mb.");
        return;
      }
      this.emplacementBlueprintForm.nifCif = this.editEmplacementData.nifCif;
      this.emplacementBlueprintForm.file = event.target.files[0];
      this.emplacementBlueprintForm.src = event.target.files[0].name;
    },
    onRemoveEditEmplacementBlueprint() {
      $("#emplacementBlueprintUploadSrc").get()[0].value="";
      this.emplacementBlueprintForm.nifCif = "";
      this.emplacementBlueprintForm.file = "";
      this.emplacementBlueprintForm.src = "";
    },
    onPositionChanged() {
      this.emplacementMap.removeLayer(this.emplacementMarker);
      this.emplacementMarker = Leaflet.marker([this.editEmplacementData.geoJSON.geometry.coordinates[0],this.editEmplacementData.geoJSON.geometry.coordinates[1]]).addTo(this.emplacementMap);
    },
    // GET 
    async getEditEmplacementData(callback) {
      let response = await api.getEmplacementData(this.emplacementId);
      this.editEmplacementData = api.parseEmplacementFromResponse(response);
      if (callback && typeof callback === "function") callback(this.editEmplacementData);
    },
    // SET
    async setEditEmplacementData() {
      this.newCoordinates = {};
      let response = await api.setEmplacementData(this.editEmplacementData);
      if (response["status-code"] === "200") {
        window.alert("El emplazamiento ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el emplazamiento.");
      }
      this.getEditEmplacementData();
    },
    onSetNewCoordinates(event) {
      this.editEmplacementData.geoJSON = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [Number(event.latlng.lat), Number(event.latlng.lng)]
        }
      }
      this.emplacementMap.removeLayer(this.emplacementMarker);
      this.emplacementMarker = Leaflet.marker([this.editEmplacementData.geoJSON.geometry.coordinates[0],this.editEmplacementData.geoJSON.geometry.coordinates[1]]).addTo(this.emplacementMap);
    },
    async setEditEmplacementImage() {
      let response = await api.setEmplacementImage(this.emplacementImageForm);
      console.log(response);
      this.onRemoveEditEmplacementImage();
      this.getEditEmplacementData();
    },
    async setEditEmplacementBlueprint() {
      if (this.editEmplacementData.variables != undefined
      && this.editEmplacementData.variables.dynamic != undefined
      && this.editEmplacementData.variables.dynamic.urlPlano)
        this.emplacementBlueprintForm.variableId = this.editEmplacementData.variables.dynamic.urlPlano.variableId;
      let response = await api.setEmplacementBlueprint(this.emplacementBlueprintForm);
      console.log(response);
      this.onRemoveEditEmplacementImage();
      this.getEditEmplacementData();
    }
  },
  async created() {
    this.editMode = (this.editRequest)?true:false;
    await this.getEditEmplacementData();
    setTimeout(()=>{this.displayEmplacementMap()}, 300);
  }
}
</script>

<style>

#editEmplacementModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

@media (max-width: 767px) {
  #emplacementImageUploadFormContainer {
    flex-direction: column;
  }
  #emplacementBlueprintUploadFormContainer {
    flex-direction: column;
  }
}
</style>