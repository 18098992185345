<template>
<!-- MODAL Order  -->
  <div id="orderDetailsModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 shadow">
<!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="arrow-redo-outline" class="tx-18 me-2 align-bottom"></ion-icon>
            {{dictionary["Nueva expedición"][session.user.language]}}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleOrderDetailsModal')">	
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT OrderDetails -->
<div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;" class="p-3">
          <div class="col-12 p-3 border rounded form-material" style="overflow: auto; overflow-y: hidden;">

            <form class="m-0 p-0">
                <div class="row">
    
<!-- NOMBRE-->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-13 text-nowrap">
                      {{dictionary['Nombre'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <span class="form-control form-control-line" style="color:black;"> {{orderData.summary.nombre}}</span>
                    </div>
                  </div>

<!-- TELEFONO-->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-13 text-nowrap">
                      {{dictionary['Teléfono'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <span class="form-control form-control-line" style="color:black;"> {{orderData.summary.telefono}}</span>
                    </div>
                  </div>
    
<!-- DIRECCION-->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-13 text-nowrap">
                      {{dictionary['Dirección'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <span class="form-control form-control-line" style="color:black;"> {{orderData.summary.direccion}}</span>
                    </div>
                  </div>


<!-- ENTREGA-->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-13 text-nowrap">
                      {{dictionary['Entrega'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <span class="form-control form-control-line" style="color:black;"> {{orderData.summary.entrega}}</span>
                    </div>
                  </div>

    
<!-- LOCALIDAD-->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-13 text-nowrap">
                      {{dictionary['Localidad'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <span class="form-control form-control-line" style="color:black;"> {{orderData.summary.localidad}}</span>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>



    </div>
  </div>
      
</template>

<script>
//Clomos
 import api from '@/services/api';

export default {
  name: "OrderDetailsModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
  },
  watch: { 
    currentCompany: async function() {
      await this.getOrderData();
    }
  },
  data() {
    return {
      orderData: {
        summary:{
        },
      },
    }
  },
  emiths: ["toggleOrderDetailsModal"],
  methods: {
    async getOrderData(callback) {
      let response = await api.getOrderData(this.orderId);
      this.orderData = api.parseOrderFromResponse(response);
      if (this.orderData.summary != undefined && this.orderData.summary != "") {
        this.orderData.summary = JSON.parse(this.orderData.summary);
      }
        if (callback && typeof callback === "function") callback(this.orderData);
    }
  },
 
  async created() {
    await this.getOrderData();
  },
}
</script>

<style>

#orderDetailsModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>