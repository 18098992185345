<template>
    <!-- Cabeza -->
    <section id="logInSection">
        <div class="m-0 p-0 gradient-background__noise d-flex flex-column" style="min-height: 100vh; height: 100%;">
            <div class="d-flex flex-row justify-content-around">
                <div class="col-lg-6 col-md-6 col-12 text-start p-3">
                    <img title="Logo" src="@/assets/img/logo.png" @click="this.$router.push('/');"
                    class="img-fluid m-auto" style="max-width: 250px;">
                </div>
                <div class="col-lg-6 col-md-6 col-12 text-end p-3">
                    <div v-for="(language, index) in languages" :key="index">
                        <span style="cursor: pointer;" class="tx-24 tx-white text-decoration-underline cursor-pointer"
                        v-if="language !== sessionLanguage" @click="toggleLanguage(language)">
                            {{ language.toUpperCase() }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="column mt-xl-0 m-xl-5 p-xl-5 m-sm-0 p-sm-0">
                <div class="col-md-3 col-md-12 col-12 m-0 p-3 pt-0">
                    <div class="login-box border-1 border-white box sombra-izq rounded-5">
                        <div class="card-body pt-2 p-3 rounded-5 bg-menu">
                            <!-- Language -->
                            <div class="col-md-12">
                                <div class="ms-auto me-auto mt-3 form-group text-center rounded-5">
                                    <h5 class="text-white text-decoration-underline">
                                        {{ dictionary['Seguimiento depedido'][sessionLanguage].toUpperCase() }}
                                        {{ availableLanguages(dictionary["Seguimiento de pedido"]) }}
                                    </h5>
                                </div>
                            </div>
                            <!-- FORM Iniciar Sesion -->
                            <form id="formLogIn" class="form-horizontal text-center">
                                <div class="form-group p-3 mt-0">
                                    <span class="tx-14 tx-white">{{ errorMsg }}</span><br>
                                    <div class="col-xs-10"> 
                                        {{ availableLanguages(dictionary["Introduce ID de pedido"]) }}
                                        <input v-model="idOrder" id="orderId" type="text" autofocus
                                        class="p-2 input-white form-control"
                                        :placeholder="dictionary['Introduce ID de pedido'][sessionLanguage]">
                                    </div>
                                </div>
                                <div class="form-group text-center mt-3">
                                    <div class="col-xs-12 justify-content-center d-flex">
                                        <button id="btnLogin" @click="onOrderDetails" type="button"
                                            class="button-ok gradient-tablas mt-1 rounded-5">
                                            <span>
                                                <span class="highlight-bg"></span>
                                                <span class="button-text">
                                                    {{ dictionary["Buscar pedido"][sessionLanguage] }}
                                                    {{ availableLanguages(dictionary["Buscar pedido"]) }}
                                                </span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <!-- Detalles del pedido -->
                <div id="orderResume" v-if="orderDetails" class="col-md-3 col-md-12 m-0 p-3 pt-0">
                    <div class="login-box border-1 border-white box sombra-izq rounded-5">
                        <div class="card-body pt-2 p-3 rounded-5 bg-menu">
                            <div class="col-md-12">
                                <div class=" ms-auto me-auto mt-3 form-group text-center rounded-5">
                                    <h5 class="text-white text-decoration-underline">
                                        {{ dictionary["Su pedido"][sessionLanguage] }}
                                        {{ availableLanguages(dictionary["Su pedido"]) }}
                                    </h5>

                                </div>
                                <div class="ms-auto me-auto mt-3 form-group text-center rounded-5">
                                    <p class="text-muted tx-12">
                                        {{ dictionary["Situación de su pedido"][sessionLanguage] }}
                                        {{ availableLanguages(dictionary["Situación de su pedido"]) }}
                                    </p>
                                </div>
                            </div>
                            <!-- Table  -->
                            <div id="formLogIn" class="form-horizontal text-center">
                                <div class="form-group p-3 mt-0">
                                    <span class="tx-14 text-white">{{ errorMsg }}</span><br>
                                    <div class="col-xs-10">
                                        <!-- Tabla para mostrar los datos -->
                                        <table class="table mt-3 text-white">

                                            <tbody>

                                                <tr v-if="orderDetails.idReservation">
                                                    <td>
                                                        {{ dictionary["ID reserva"][sessionLanguage] }}
                                                        {{ availableLanguages(dictionary["ID reserva"]) }}
                                                    </td>
                                                    <td>
                                                        {{ orderDetails.idReservation }}
                                                    </td>
                                                </tr>
                                                <tr v-if="orderDetails.status">
                                                    <td>
                                                        {{ dictionary["Estado"][sessionLanguage] }}
                                                        {{ availableLanguages(dictionary["Estado"]) }}
                                                    </td>
                                                    <td>
                                                        {{ getStatusText(orderDetails.status) }}
                                                    </td>
                                                </tr>
                                                <tr v-if="orderDetails.emailUser">
                                                    <td>
                                                        {{ dictionary["E-mail"][sessionLanguage] }}
                                                        {{ availableLanguages(dictionary["E-mail"]) }}
                                                    </td>
                                                    <td>
                                                        {{ orderDetails.emailUser }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr v-if="orderDetails.location">
                                                    <td>
                                                        {{ dictionary["Dirección"][sessionLanguage] }}
                                                        {{ availableLanguages(dictionary["Dirección"]) }}
                                                    </td>
                                                    <td>
                                                        {{ orderDetails.location }}
                                                    </td>
                                                </tr>
                                                <tr v-if="orderDetails.lockerLabel">
                                                    <td>
                                                        {{ dictionary["Locker"][sessionLanguage] }}
                                                        {{ availableLanguages(dictionary["Locker"]) }}
                                                    </td>
                                                    <td>
                                                        {{ orderDetails.lockerLabel }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr v-if="orderDetails.phoneSupplier">
                                                    <td>
                                                        {{ dictionary["Teléfono de contacto"][sessionLanguage] }}
                                                        {{ availableLanguages(dictionary["Teléfono de contacto"]) }}
                                                    </td>
                                                    <td>
                                                        {{ orderDetails.phoneSupplier }}
                                                    </td>
                                                </tr>
                                                <tr v-if="orderDetails.emailSupplier">
                                                    <td>
                                                        {{ dictionary["E-mail"][sessionLanguage] }}
                                                        {{ dictionary["Proveedor"][sessionLanguage] }}
                                                        {{ availableLanguages(dictionary["Proveedor"]) }}
                                                    </td>
                                                    <td>
                                                        {{ orderDetails.emailSupplier }}
                                                    </td>
                                                </tr>
                                                <tr v-if="orderDetails.emailCarrier">
                                                    <td>
                                                        {{ dictionary["E-mail"][sessionLanguage] }}
                                                        {{ dictionary["Transportista"][sessionLanguage] }}
                                                        {{ availableLanguages(dictionary["Transportista"]) }}
                                                    </td>
                                                    <td>
                                                        {{ orderDetails.emailCarrier }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr>
                                                    <td style="border: none;"></td>
                                                    <td style="border: none;"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" style="border: none;">
                                                        <div class="d-flex justify-content-center align-items-center">

                                                            <ion-icon name="print-outline"
                                                                @click="captureAndPrint('orderResume')"
                                                                class="tx-30 pt-1 pe-1 tx-white"></ion-icon>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Pié -->
        </div>
    </section>
</template>

<script>
import api from '@/services/api';
export default {
    name: "OrderTracking",
    props: {
        session: {
            type: Object,
            required: true,
        },
        dictionary: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            errorMsg: "",
            idOrder: "",
            orderDetails: "",
            languages: ["es", "en"], //add here available languages
            sessionLanguage: '',
            visibility: {
                formLogIn: true,
                formPasswordLost: false,
                formValidateClient: false,
            }
        };
    },
    created() {
        this.sessionLanguage = this.session.user.language;
    },
    methods: {
        // DISPLAY
        toggleLanguage(language) {
            this.sessionLanguage = language;
        },
        availableLanguages(value) {
            const keys = Object.keys(value);
            const allLanguagesPresent = this.languages.every(language => keys.includes(language));
            if (!allLanguagesPresent) {
                this.languages = this.languages.filter(language => keys.includes(language));
            }
        },
        // GET
        async onOrderDetails() {
            try {
                this.errorMsg=""
                let response = await api.getOrderDetails(this.idOrder);
                if(response["status-code"] === "200"){
                    this.orderDetails = api.parsePublicOrderFromResponse(response);
                    this.idOrder = "";
                } else {
                    this.errorMsg = this.dictionary["Error al obtener detalles del pedido."][this.sessionLanguage];
                    this.idOrder = "";
                }  
            } catch (error) {
                console.error("Error fetching order details:", error);
                this.errorMsg = "Error al obtener detalles del pedido.";
            }
        },
        getStatusText(status) {
            const statusDictionary = {
                "0": this.dictionary["Creada"],
                "1": this.dictionary["Planificada"],
                "2": this.dictionary["Depositado"],
                "3": this.dictionary["Anulando"],
                "4": this.dictionary["Finalizada"],
                "5": this.dictionary["Cancelada"]
            };
            this.availableLanguages(statusDictionary[status])
            return statusDictionary[status][this.sessionLanguage] || status;
        },
        captureAndPrint(content) {
            var mywindow = window.open('', 'PRINT', 'height=400,width=600');
            mywindow.document.write(`
                <html>
                <head>
                <title>${this.dictionary["Imprimir"][this.sessionLanguage]} ${this.dictionary["ID reserva"][this.sessionLanguage]}: ${this.orderDetails.idReservation}</title>
                </head>
                <body>
            `);
            mywindow.document.write(document.getElementById(content).innerHTML);
            mywindow.document.write(`
                </body>
                </html>
            `);
            mywindow.document.close();
            mywindow.focus();
            mywindow.print();
            mywindow.close();
            return true;
        }
    }
}
</script>