<template>
  <!-- SECTION Products-->
  <div id="productsSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
        <div class="row col-lg-12 col-md-12 col-12  m-0 p-0 pb-3">
          <div class="position-sticky col-12 m-0 pt-2 pb-2 bg-head text-white border-1">
            <div class="row p-0 m-0">
              <!--Primera sección-->
              <div class="row col-xl-5 col-lg-6 col-md-12 col-12 m-0 pt-0 ps-3 pe-4 text-white rounded-5 rounded-end">
                <div class="col m-0 pt-0 ps-0 text-white d-flex flex-row">
                  <h6 class="tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 d-flex flex-row">
                    <ion-icon name="location" class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ dictionary["Empresas"][session.user.language] }}
                  </h6>
                  <select  :value="currentCompany.nifCif"
                    class="form-control m-0 p-0 text-nowrap bg-white border"
                    style="border: solid 2px white ; color: black; min-width:200px; height: 34px;"
                    @change="onCurrentCompanyChanged">
                    <option v-if="availableCompanies[0] != undefined && availableCompanies.length > 1" value="showAll">
                      {{
                        dictionary['Mostrar todo'][session.user.language] }} </option>
                    <option v-for="company in availableCompanies" :key="company.nifCif" :v-if="company.nifCif != session.user.nifCif
                      && company.relation != undefined
                      && company.relation.referenceCompany === this.session.user.nifCif" :value="company.nifCif">
                      {{ (company.nifCif === session.user.nifCif) ? "✪ " + company.name : company.name }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- Fin Primera sección-->
              <!--Segunda sección-->
              <div class="row col-xl-7 col-lg-6 col-md-9 col-12 justify-content-end p-0 m-0 pe-3">
                <div class="row col-lg-8 col-md-8 col-8 justify-content-end">
                  <span class="text-end text-white mt-1 mb-2 pe-4">
                    <ion-icon name="cube-outline" class="tx-16 mb-1 me-1 align-bottom"></ion-icon>
                    {{ dictionary["Productos"][session.user.language] }}
                  </span>
                </div>
                <div class="row col-xl-2 col-lg-3 col-md-3 col-4 justify-content-end p-0 m-0 pt-1 pe-3">
                  <button v-if="this.session.user.rol === '255'" id="btnDisplayNewDeviceModal"
                    :title="dictionary['Añadir producto'][session.user.language]"
                    style="width: 24px; max-height: 24px;" class="button-ok bg-white mt-0 me-2 rounded shadow"
                    @click="toggleNewProductModal">
                    <span>
                      <span class="button-text text-dark">
                        <ion-icon name="add-circle-outline" class="text-dark pt-0 tx-18 hydrated"
                          data-bs-toggle="tooltip" data-bs-placement="bottom"
                          :title="dictionary['Añadir producto'][session.user.language]"></ion-icon>
                      </span>
                    </span>
                  </button>
                  <button id="btnDisplayEditEraseModal" style="width: 24px; max-height: 24px;"
                    :title="dictionary['Actualizar'][session.user.language]"
                    class="button-ok bg-white mt-0 me-2 rounded shadow" @click="refreshView">
                    <span>
                      <span class="button-text text-dark">
                        <ion-icon name="refresh-outline" class="text-dark pt-0 me-0 tx-16 hydrated"
                          :title="dictionary['Actualizar'][session.user.language]"></ion-icon>
                        <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- TABLE Products -->
          <div class="m-0 p-3 overflow-auto table-responsive">
            <table id="productsDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                <tr>
                  <th v-for="column in productsColumns" :key="column.data" :width="column.width"
                    class="fw-medium text-nowrap p-2">
                    {{ dictionary[column.text][session.user.language] }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- MODAL New Product -->
  <NewProductModal v-if="display.newProductModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
    v-on:toggleNewProductModal="toggleNewProductModal">
  </NewProductModal>

  <!-- MODAL Edit Product -->
  <EditProductModal v-if="display.editProductModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :productId="targetProductId"
    :availableCompanies="availableCompanies" v-on:toggleEditProductModal="toggleEditProductModal">
  </EditProductModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal v-if="display.deleteProductModal" :session="session" :dictionary="dictionary" :countries="countries"
    :alertHeader="dictionary['Producto a eliminar'][session.user.language] + ': '" :alertTarget="targetProductId"
    v-on:acceptAlert="onDeleteProduct" v-on:dismissAlert="toggleDeleteProductModal">
  </TextAlertModal>

</template>

<script>
import $ from 'jquery';
import NewProductModal from '@/components/products/NewProductModal';
import EditProductModal from '@/components/products/EditProductModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "ProductsComponent",
  components: { NewProductModal, EditProductModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    }
  },
  watch: {
    currentCompany: async function () {
      await this.getStockTypeList();
      this.displayProductsDataTable();
    }
  },
  data() {
    return {
      productsColumns: [
        {
          text: "Identificador", data: "productId", width: "10%", className: "tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id=" + data + " class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
              + "<span data-id=" + data + " class='btnTargetProduct c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>" + data + "</span>";
          }
        },
        { text: "Referencia", data: "productRef", width: "10%", className: "tx-left align-middle" },
        {
          text: "Imagen", data: "productImgSrc", width: "10%", className: "tx-left align-middle",
          render: (data) => { return this.displayProductImage(data) }
        },
        { text: "Nombre", data: "productName", width: "10%", className: "tx-left align-middle" },
        {
          text: "Empresa", data: "productCompany", width: "10%", className: "tx-left align-middle",
          render: (data) => {
            let companyName = data;
            if (this.availableCompanies.length > 0) {
              for (let company in this.availableCompanies)
                if (this.availableCompanies[company].nifCif === data)
                  companyName = this.availableCompanies[company].name;
            }
            return companyName;
          }
        },
        {
          text: "Unidad de medida", data: "productUnit", width: "10%", className: "tx-left align-middle",
          render: (data) => {
            let unit = "";
            switch (data) {
              case "Kg":
                unit = this.dictionary["Kilo"][this.session.user.language]
                break;
              case "M":
                unit = this.dictionary["Metro"][this.session.user.language]
                break;
              case "L":
                unit = this.dictionary["Litro"][this.session.user.language]
                break;
              case "unidades":
                unit = this.dictionary["Unidad"][this.session.user.language]
                break;
              default:
                break;
            }
            return unit;
          }
        },
        {
          text: "Conservación", data: "productConservationTemperature", width: "10%", className: "tx-left align-middle",
          render: (data) => {
            let conservationValue = "";
            switch (data) {
              case "frio":
                conservationValue = this.dictionary["Frío"][this.session.user.language]
                break;
              case "ambiente":
                conservationValue = this.dictionary["Ambiente"][this.session.user.language]
                break;
              case "ultracongelado":
                conservationValue = this.dictionary["Ultracongelado"][this.session.user.language]
                break;
              default:
                conservationValue = data;
                break;
            }
            return conservationValue;
          }
        },
        {
          text: "Prioridad", data: "productPriority", width: "10%", className: "tx-left align-middle",
          render: (data) => {
            let priority = "";
            switch (data) {
              case "FIFO":
                priority = this.dictionary["Fecha de entrada"][this.session.user.language]
                break;
              case "LIFO":
                priority = this.dictionary["Fecha de salida"][this.session.user.language]
                break;
              case "FEFO":
                priority = this.dictionary["Fecha de caducidad"][this.session.user.language]
                break;
              default:
                break;
            }
            return priority;
          }
        },
        {
          text: "Editar", data: "productId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='create-outline' data-id=" + data + " class='btnEditProduct btn tx-18 tx-primary p-auto m-auto'></ion-icon>";
          }
        },
        {
          text: "Eliminar", data: "productId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id=" + data + " class='btnDeleteProduct btn tx-18 tx-danger m-0 p-0'></ion-icon>";
          }
        }
      ],
      targetProductId: this.$route.params.targetEntity,
      productList: [],
      display: {
        newProductModal: false,
        editProductModal: false,
        deleteProductModal: false
      }
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$emit('setCurrentCompany', value);
    },
    displayProductImage(src) {
      if (src != undefined && src != '') {
        src = this.$clientConfig.api + '/ficheros/ficheros.php?fichero=' + src + '&identificadorusuario=' + this.session.user.id + '&Token=' + localStorage.getItem("ClomosAuthToken");
        return '<img style="width:100%;height:auto;margin:auto;" src="' + src + '" />';
      }
      src = require('/src/assets/img/placeholder_image.jpg');
      return '<img style="width:100%;height:auto;margin:auto;" src="' + src + '" />';

    },
    displayProductsDataTable() {
      let currentPage = $("#productsDataTable").DataTable().page();
      $("#productsDataTable").DataTable().clear()
      for (let i in this.productList) {
        $("#productsDataTable").DataTable().rows.add([{
          productId: this.productList[i].id,
          productCompany: this.productList[i].nifCif,
          productRef: (this.productList[i].variables.dynamic.referencia != undefined) ? this.productList[i].variables.dynamic.referencia.value : "",
          productName: this.productList[i].label,
          productUnit: (this.productList[i].variables.dynamic.unidad) ? this.productList[i].variables.dynamic.unidad.value : "",
          productConservationTemperature: (this.productList[i].variables.dynamic.temperaturaConservacion != undefined) ? this.productList[i].variables.dynamic.temperaturaConservacion.value : "",
          productPriority: (this.productList[i].variables.dynamic.prioridadSalida != undefined) ? this.productList[i].variables.dynamic.prioridadSalida.value : "",
          productImgSrc: (this.productList[i].variables.dynamic.urlImagen != undefined) ? this.productList[i].variables.dynamic.urlImagen.value : undefined
        }]);
      }
      $("#productsDataTable").DataTable().page(currentPage);
      $("#productsDataTable").DataTable().draw();
      this.setButtonFunctions();
      if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },
    async toggleNewProductModal() {
      if (this.display.newProductModal) {
        await this.getStockTypeList();
        this.displayProductsDataTable();
      }
      this.display.newProductModal = !this.display.newProductModal;
    },
    async toggleEditProductModal() {
      if (this.display.editProductModal) {
        await this.getStockTypeList();
        this.displayProductsDataTable();
      }
      this.display.editProductModal = !this.display.editProductModal;
    },
    async toggleDeleteProductModal() {
      if (this.display.deleteProductModal) {
        await this.getStockTypeList();
        this.displayProductsDataTable();
      }
      this.display.deleteProductModal = !this.display.deleteProductModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetProduct").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetProduct);
        });
        $(".btnEditProduct").get().forEach(btn => {
          btn.addEventListener('click', this.onEditProduct);
        });
        $(".btnRegularizeStock").get().forEach(btn => {
          btn.addEventListener('click', this.onRegularizeStock);
        });
        $(".btnDeleteProduct").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteProduct);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    async refreshView(){
      await this.getStockTypeList();
      this.$emit('getAvailableCompanies', (this.displayProductsDataTable));
    },
    // GET
    async getStockTypeList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getStockTypeList(request);
      if (response != undefined) this.productList = api.parseStockTypesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.productList);
    },
    // TARGET 
    onTargetProduct(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetProductId = event.target.getAttribute('data-id');
      this.$router.replace({ params: { targetEntity: this.targetProductId } })
    },
    // SET
    onEditProduct(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetProductId = event.target.getAttribute('data-id');
      this.$router.replace({ params: { targetEntity: this.targetProductId } })
      this.toggleEditProductModal();
    },
    // DELETE
    async onDeleteProduct(event) {
      if (!this.display.deleteProductModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetProductId = event.target.getAttribute('data-id');
        this.$router.replace({ params: { targetEntity: this.targetProductId } })
      }
      else {
        let response = await api.deleteProduct(this.targetProductId);
        if (response["status-code"] === "200") {
          window.alert("El producto ha sido eliminado satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el producto.");
        }
      }
      this.toggleDeleteProductModal();
    }
  },
  async created() {
    await this.getStockTypeList();
    this.$emit('getAvailableCompanies', (this.displayProductsDataTable));
  },
  mounted() {
    $("#productsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.productsColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
/*.btnTargetProduct:hover,*/
.btnCopyId:hover,
.btnEditProduct:hover,
.btnRegularizeStock:hover,
.btnDeleteProduct:hover {
  color: var(--main-dark) !important;
}
</style>