<template>
  <!-- MODAL Edit Device -->
  <div id="viewDeviceModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 shadow">
      <!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="hardware-chip" class="tx-18 me-2 align-bottom"></ion-icon>
            {{ dictionary['Información del dispositivo'][session.user.language] + ": " + editDeviceData.label }}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleViewDeviceModal')">
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <!-- CONTENT Edit Device -->
      <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
        <div id="editDeviceScroll" style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="col-12 p-2 pe-0 pb-0">
            <!-- CONTENT Edit Device -->
            <form class="p-0 overflow-hidden d-flex flex-wrap pe-2">
              <div class="col-12 m-0 p-2">
                <form class="col-12 border rounded m-0 p-1 overflow-hidden">
                  <div class="row col-lg-12 col-12 container-fluid p-0 pt-2 m-0">
                    <div class="col-lg-3 col-12 container-fluid p-2 m-0">
                      <!-- NOMBRE -->
                      <div class="col-lg-12 col-12 p-0 tx-left">
                        <label class="p-0 text-dark tx-bold tx-12">
                          {{ dictionary['Nombre'][session.user.language] }}
                        </label>
                        <input :value="editDeviceData.label" disabled id="inputEditDeviceLabel" type="text"
                          class="form-control form-control-line" style="color:black;">
                      </div>
                      <!-- TIPO -->
                      <div class="col-lg-12 col-12 p-0 tx-left">
                        <label class="p-0 pt-1 text-dark tx-bold tx-12">
                          {{ dictionary['Localización'][session.user.language] }}
                        </label>
                        <input :value="editDeviceData.emplacement" disabled id="inputEditDeviceEmplacement" type="text"
                          class="form-control form-control-line" style="color:black;">
                      </div>
                      <div class="col-lg-12 col-12 p-0 tx-left">
                        <label class="p-0 pt-1 text-dark tx-bold tx-12">
                          {{ dictionary['Categoría'][session.user.language] }}
                        </label>
                        <input :value="category" disabled id="inputEditDeviceEmplacement" type="text"
                          class="form-control form-control-line" style="color:black;">
                      </div>
                      <div class="col-lg-12 col-12 p-0 tx-left">
                        <label class="p-0 pt-1 text-dark tx-bold tx-12">
                          {{ dictionary['Tipo'][session.user.language] }}
                        </label>
                        <input :value="editDeviceData.type" disabled id="inputEditDeviceEmplacement" type="text"
                          class="form-control form-control-line" style="color:black;">
                      </div>
                      <div class="col-lg-12 col-12 p-0 tx-left">
                        <label class="p-0 pt-1 text-dark tx-bold tx-12">
                          {{ dictionary['Versión'][session.user.language] }}
                        </label>
                        <input :value="editDeviceData.version" disabled id="inputEditDeviceEmplacement" type="text"
                          class="form-control form-control-line" style="color:black;">
                      </div>
                      <div class="col-lg-12 col-12 p-0 tx-left">
                        <label class="p-0 pt-1 text-dark tx-bold tx-12">
                          {{ dictionary['Fecha de comunicación'][session.user.language] }}
                        </label>
                        <input :value="editDeviceData.communicationDateTime !== undefined &&
                          editDeviceData.communicationDateTime !== ''
                          ? this.$dayjs(new Date(editDeviceData.communicationDateTime))
                            .utc('z')
                            .local()
                            .tz(this.session.user.dateTimeZone)
                            .format(this.session.user.dateTimeFormat+ ' ' + 'HH:mm:ss')
                          : '-'" disabled id="inputEditDeviceEmplacement" type="text" class="form-control form-control-line"
                          style="color:black;">
                      </div>
                    </div>
                    <div class="col-lg-6 col-12 p-2">
                      <div class="col-12 m-0 p-0">
                        <div class="row bg-menu text-white m-0 p-3 pb-0 rounded-5 justify-content-around">
                          <!-- CONTENT Blueprint -->
                          <label class="p-0 pb-2 ps-1 text-light tx-bold tx-13">
                            {{ dictionary['Geoposición'][session.user.language] }}
                          </label>

                          <!-- CONTENT Map -->
                          <div id="deviceMapContent" class="m-0 p-3 rounded-3" style="width: 100%; height: 370px; ">
                          </div>

                          <!-- LATITUD -->
                          <div class="col-5 m-2 p-0 d-flex">
                            <label class="p-0 pe-2 pt-2 tx-11">
                              {{ dictionary['Latitud'][session.user.language] }}
                            </label>
                            <input v-model="editDeviceData.geoJSON.geometry.coordinates[0]" id="inputEditDeviceLat"
                              type="number" step=0.00001 class="form-control form-control-line text-dark" disabled>
                          </div>

                          <!-- LONGITUD -->
                          <div class="col-5 m-2 p-0 d-flex">
                            <label class="p-0 pe-2 pt-2 tx-11">
                              {{ dictionary['Longitud'][session.user.language] }}
                            </label>
                            <input v-model="editDeviceData.geoJSON.geometry.coordinates[1]" id="inputEditDeviceLng"
                              type="number" step=0.00001 class="form-control form-control-line text-dark" disabled>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-12 m-0 p-2">
                      <div class="col-12 p-0 m-0 bg-menu rounded-5 text-white">

                        <div v-for="variable in editDeviceData.variables.analog" :key="variable.analogVariableId"
                          class="col-12 p-0">
                          <div v-if="variable.isConfiguration === 'true'" class="m-0 ps-2 pe-2">
                            <label class="col-12 tx-11 p-0 pt-2">
                              {{ variable.label }}
                              <span class="text-nowrap ms-auto"
                                v-if="variable.unit != undefined && variable.unit != ''"> {{ "(" + variable.unit + ")"
                                }}
                              </span>
                            </label>
                            <input v-model="editDeviceData.variables.analog[variable.label].value" disabled
                              :id="(variable.isConfiguration === 'false') ? ('editDevice' + variable.label) : 'inputEditDevice' + variable.label"
                              type="text" :data-id="editDeviceData.variables.analog[variable.label].variableId"
                              :data-type="editDeviceData.variables.analog[variable.label].type"
                              class="inputEditDevice form-control form-control-line m-0 p-1"
                              style="font-size: 0.7rem !important;">
                          </div>
                        </div>

                        <div v-for="variable in editDeviceData.variables.digital" :key="variable.digitalVariableId"
                          class="col-12 p-0">
                          <div v-if="variable.isConfiguration === 'true'" class="m-0 p-0 w-100">
                            <label class="col-12 tx-11 p-0 pt-2">
                              {{ variable.label }}
                            </label>
                            <div :class="editDeviceData.variables.digital[variable.label].value"
                              :id="(variable.isConfiguration === 'false') ? ('editDevice' + variable.label) : 'inputEditDevice' + variable.label"
                              type="text" :disabled="variable.isConfiguration === 'false'"
                              :data-id="editDeviceData.variables.digital[variable.label].variableId"
                              :data-type="editDeviceData.variables.digital[variable.label].type"
                              :data-value="editDeviceData.variables.digital[variable.label].value"
                              class="inputEditDevice form-control form-control-line m-0 p-1 tx-10"
                              @click="(editDeviceData.variables.digital[variable.label].value === 'true') ? editDeviceData.variables.digital[variable.label].value = 'false' : editDeviceData.variables.digital[variable.label].value = 'true'">
                              {{
                                dictionary[editDeviceData.variables.digital[variable.label].value][session.user.language]
                              }}
                            </div>
                          </div>
                        </div>

                        <div v-for="variable in editDeviceData.variables.dynamic" :key="variable.dynamicVariableId"
                          class="col-12 p-0"
                          :style="((variable.isConfiguration === 'false') || (variable.isConfiguration === 'true' && variable.label === 'configuracion')) ? 'display:none' : 'display:block'">
                          <div class="m-0 ps-2 pe-2">
                            <label class="col-12 tx-11 p-0 pt-2">
                              {{ variable.label }}
                            </label>
                            <input v-model="editDeviceData.variables.dynamic[variable.label].value" disabled
                              :id="(variable.isConfiguration === 'false') ? ('editDevice' + variable.label) : 'inputEditDevice' + variable.label"
                              type="text" :data-id="editDeviceData.variables.dynamic[variable.label].variableId"
                              :data-type="editDeviceData.variables.dynamic[variable.label].type"
                              class="inputEditDevice form-control form-control-line m-0 p-1">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
// Clomos
import api from '@/services/api';

export default {
  name: "viewDeviceModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    deviceId: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editDeviceData: {
        id: "",
        nifCif: "",
        dataConsumed: "",
        label: "",
        dclId: "",
        dclTypeId: "",
        dclClass: "",
        type: "",
        distributionGroup: "",
        parent: "",
        isBase: "",
        isPublic: 0,
        token: "",
        imgSrc: "",
        emplacement: "",
        fechaUltimaComunicacion: "",
        tieneAlarmaActivada: "",
        version: "",
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0, 0]
          }
        },
        variables: {
          analog: {},
          digital: {},
          dynamic: {},
        }
      },
      deviceConfigurationForm: {
        id: "",
        variableId: "",
        value: ""
      },
      deviceMap: undefined,
      deviceMarker: undefined
    }
  },
  computed: {
    bluePrintMarkerPosition() {
      let markerX = 0;
      let markerY = 0;
      let blueprintCanvas = document.getElementById('editDeviceBlueprint');
      if (blueprintCanvas != null) {
        let totalScrollLeft = document.getElementById('editDeviceScroll').scrollLeft;
        let totalScrollTop = document.getElementById('editDeviceScroll').scrollTop;

        let ratioX = blueprintCanvas.naturalWidth / blueprintCanvas.offsetWidth;
        let ratioY = blueprintCanvas.naturalHeight / blueprintCanvas.offsetHeight;

        let domX = this.editDeviceData.geoJSON.geometry.coordinates[0];
        let domY = this.editDeviceData.geoJSON.geometry.coordinates[1];

        markerX = Math.floor(domX / ratioX) + totalScrollLeft;
        markerY = Math.floor(domY / ratioY) + totalScrollTop;
      }
      return "color: red; position: absolute; top: " + markerY + "px; left: " + markerX + "px; margin-top: 2.2rem; margin-left: 0.4rem;"
    }
  },
  emits: ["toggleViewDeviceModal"],
  methods: {
    // DISPLAY
    displayDeviceMap() {
      if (this.deviceMap != undefined) {
        this.deviceMap.invalidateSize();
        this.deviceMap.remove();
      }
      var deviceMap = Leaflet.map("deviceMapContent");
      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang=' + this.session.user.language, { maxZoom: 19 }).addTo(deviceMap);
      deviceMap.on('click', this.onSetNewCoordinates)

      this.deviceMap = deviceMap;

      let deviceMapListForGroup = [];
      if ($(".leaflet-control-attribution").get()[0] != undefined) $(".leaflet-control-attribution").get()[0].remove();
      var deviceMarker = Leaflet.marker([this.editDeviceData.geoJSON.geometry.coordinates[0], this.editDeviceData.geoJSON.geometry.coordinates[1]]);
      deviceMarker.addTo(deviceMap);
      deviceMapListForGroup.push(deviceMarker);
      var deviceMapGroup = Leaflet.featureGroup(deviceMapListForGroup);
      this.deviceMap.fitBounds(deviceMapGroup.getBounds());

      this.deviceMarker = deviceMarker;
    },
    displayBlueprint() {
      setTimeout(() => {
        this.onPositionChanged();
      }, 300);
      let src = "";
      if (this.editDeviceData.parent != ""
        && this.editDeviceData.geoJSON != undefined
        && this.editDeviceData.geoJSON.properties != undefined
        && this.editDeviceData.geoJSON.properties.srcId != undefined)
        src = this.editDeviceData.geoJSON.properties.srcId;
      if (src != undefined && src != '') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero=' + src + '&identificadorusuario=' + this.session.user.id + '&Token=' + localStorage.getItem("ClomosAuthToken");
        }
        catch (e) {
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },

    // SET  


    // GET 
    async getEditDeviceData(callback) {
      let response = await api.getDeviceData(this.deviceId);
      this.editDeviceData = api.parseDeviceFromResponse(response);
      this.deviceConfigurationForm.id = this.editDeviceData.id;
      if (this.editDeviceData.variables.dynamic['configuracion'] != undefined)
        this.deviceConfigurationForm = {
          id: this.editDeviceData.id,
          variableId: this.editDeviceData.variables.dynamic['configuracion'].variableId,
          value: this.editDeviceData.variables.dynamic['configuracion'].value
        }
      else
        this.deviceConfigurationForm = {
          id: this.editDeviceData.id,
          variableId: "",
          value: ""
        }
      if (callback && typeof callback === "function") callback(this.editDeviceData);
    }
  },
  async created() {
    await this.getEditDeviceData();
    setTimeout(() => { this.displayDeviceMap() }, 300);
  }
}
</script>

<style>
#viewDeviceModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.inputEditDevice:disabled {
  color: dimGrey;
}
</style>