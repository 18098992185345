<template>
  <!-- SECTION Devices-->
  <div id="devicesSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
        <div class="row col-lg-12 col-md-12 col-12 table-responsive m-0 p-0 pb-3" style="overflow: hidden;">

          <div class="position-sticky col-12 m-0 p-1 ps-4 bg-head text-white text-start border-1">
            <div class="row p-0 m-0">
              <!--Primera sección-->
              <div class="row col-lg-3 col-md-3 col-12 m-0 pt-0 ps-3 pe-4 bg-lat text-white rounded-5 rounded-end">
                <div class="col m-0 pt-0 ps-0 bg-lat text-white d-flex flex-row">
                  <h6 class="tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 d-flex flex-row">
                    <ion-icon name="location" class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ dictionary["Dispositivos"][session.user.language] }}
                  </h6>                 
                </div>
              </div>
              <!-- Fin Primera sección-->                          
              <div class="row col justify-content-end p-0 m-0 pe-4">
                <span class="text-end text-white mt-1 mb-2 pe-4">
                  <ion-icon name="location-outline" class="tx-16 mb-1 me-1 align-bottom"></ion-icon>
                  {{ dictionary["Configuración"][session.user.language] }}
                </span>
              </div>
              <div class="row justify-content-end p-0 m-0 pt-1 pe-4" style="width: 200px !important;">
                <button v-if="this.session.user.rol === '255'" id="btnDisplayNewMachineModal"
                  style="width: 24px; max-height: 24px;" class="button-ok bg-white mt-0 me-2 rounded shadow"
                  @click="toggleNewDeviceModal">
                  <span>
                    <span class="button-text text-dark">
                      <ion-icon name="add-circle-outline" class="text-dark pt-0 tx-18 hydrated" data-bs-toggle="tooltip"
                        data-bs-placement="bottom" title="Añadir"></ion-icon>
                      <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                    </span>
                  </span>
                </button>
                <button id="btnDisplayVincularModal"
                  style="width: 24px; max-height: 24px;" class="button-ok bg-white mt-0 me-2 rounded shadow"
                  @click="this.$emit('toggleConfigurationView')">
                  <span>
                    <span class="button-text text-dark">
                      <ion-icon name="return-down-back-outline" class="text-dark pt-0 me-0 tx-18 hydrated"></ion-icon>
                      <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                    </span>
                  </span>
                </button>
              
               

                
              </div>
            </div>
          </div>




          <!-- TABLE Devices -->
          <div  class="m-0 p-3 h-auto" id="devicesTableContent"
            :style="(!display.showEverything) ? 'height: 15rem !important; overflow: scroll; overflow-x: hidden;' : ''">
            <table id="devicesConfigurationDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                <tr>
                  <th v-for="column in devicesColumns" :key="column.data" :width="column.width"
                    class="fw-medium text-nowrap p-2">
                    {{ dictionary[column.text][session.user.language] }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>

        </div>

      </div>

      
    </div>
  </div>



  <!-- MODAL New Device -->
  <NewDeviceModal v-if="display.newDeviceModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" v-on:toggleNewDeviceModal="toggleNewDeviceModal">
  </NewDeviceModal>

  <!-- MODAL Edit Device -->
  <EditDeviceModal v-if="display.editDeviceModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :deviceId="targetDeviceId"
    v-on:toggleEditDeviceModal="toggleEditDeviceModal">
  </EditDeviceModal>

  <!-- MODAL Link Text Alert -->
  <LinkDeviceModal v-if="display.linkDeviceModal" :session="session" :dictionary="dictionary"
    :availableCompanies="availableCompanies" :currentCompany="currentCompany" :targetDeviceId="targetDeviceId"
    :linkAction="linkAction" v-on:dismissAlert="toggleLinkDeviceModal">
  </LinkDeviceModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal v-if="display.deleteDeviceModal" :session="session" :dictionary="dictionary" :countries="countries"
    :alertHeader="dictionary['Dispositivo a eliminar'][session.user.language] + ': '" :alertTarget="targetDeviceId"
    v-on:acceptAlert="onDeleteDevice" v-on:dismissAlert="toggleDeleteDeviceModal">
  </TextAlertModal>
</template>

<script>
import $ from 'jquery';
import NewDeviceModal from '@/components/devices/NewDeviceModal';
import EditDeviceModal from '@/components/devices/EditDeviceModal';
import LinkDeviceModal from '@/components/devices/LinkDeviceModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "DevicesConfigurationComponent",
  components: {  NewDeviceModal, EditDeviceModal, LinkDeviceModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    targetEmplacementId: {
      type: String,
      required: true,
    },
    targetMachineId: {
      type: String,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    },
    typesByCompany: {
      type: Object,
      required: true,
    }
  },
  watch: {
    currentCompany: async function () {
      if (this.$route.params.currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
        await this.getDevicesDataByCompany();
      }
      else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
        await this.getEmplacementData();
        await this.getDevicesDataByEmplacement();
      }
      else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
        await this.getDevicesDataByMachine();
      }
      this.onChangeDisplayType(this.displayType)
      if (this.deviceList[0] != undefined && this.targetDeviceId === "")
        this.targetDeviceId = this.deviceList[0].id;
    },
    targetEmplacementId: async function () {
      if (this.targetEmplacementId != "") {
        await this.getEmplacementData();
        await this.getDevicesDataByEmplacement();
      }
      this.onChangeDisplayType(this.displayType)
      if (this.deviceList[0] != undefined && this.targetDeviceId === "")
        this.targetDeviceId = this.deviceList[0].id;
    },
    targetMachineId: async function () {
      if (this.targetMachineId != "") {
        await this.getDevicesDataByMachine();
      }
      this.onChangeDisplayType(this.displayType)
      if (this.deviceList[0] != undefined && this.targetDeviceId === "")
        this.targetDeviceId = this.deviceList[0].id;
    }
  },
  data() {
    return {
      devicesColumns: [
        {
          text: "Identificador", data: "deviceId", width: "25%", className: "tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id=" + data + " class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
              + "<span data-id=" + data + " class='btnEditDevice c-pointer c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>" + data + "</span>";
          }
        },
        { text: "Nombre", data: "deviceLabel", width: "15%", className: "tx-left align-middle" },
        { text: "Tipo", data: "deviceType", width: "10%", className: "tx-left align-middle" },
        { text: "Categoría", data: "deviceCategory", width: "5%", className: "tx-left align-middle" },
        {
          text: "Fecha de comunicación", data: "deviceCommunicationDateTime", width: "10%", className: "align-middle",
          render: (data) => {
            return (data != "")
              ? "<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
              : "<span style='display: none;'></span> <spam>-</spam>";
          }
        },
       
        { text: "Versión", data: "deviceVersion", width: "5%", className: "tx-left align-middle" },
        {
          text: "Vincular", data: "deviceParent", width: "5%", className: "align-middle",
          render: (data) => {
            return (data.parentId == "")
              ? "<ion-icon name='log-in-outline' data-id=" + data.deviceId + " class='btnLinkDevice btn tx-18 tx-primary'></ion-icon>"
              : (data.parentLabel == "") ? data.parentId : data.parentLabel;
          }
        },
        {
          text: "Desvincular", data: "deviceParent", width: "5%", className: "align-middle",
          render: (data) => {
            return (data.parentId != "")
              ? "<ion-icon name='log-out-outline' data-id=" + data.deviceId + " class='btnUnlinkDevice btn tx-18 tx-primary'></ion-icon>"
              : "<ion-icon name='log-out-outline' data-id=" + data.deviceId + " class='tx-18 tx-gray-400'></ion-icon>"
          }
        },
        {
          text: "Configuración", data: "deviceId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id=" + data + " class='btnEditDevice btn tx-18 tx-primary'></ion-icon>"
          }
        },
        {
          text: "Eliminar", data: "deviceId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id=" + data + " class='btnDeleteDevice btn tx-18 tx-danger'></ion-icon>"
          }
        },
      ],
      targetEmplacementData: {},
      targetMachineData: {},
      targetDeviceId: this.$route.params.targetEntity,
      deviceList: [],
      emplacementList: [],      
      displayType: "table",
      deviceCardsPage: 0,
      display: {
        showEverything: true,
        newDeviceModal: false,
        editDeviceModal: false,
        linkDeviceModal: false,
        deleteDeviceModal: false,
      },
      linkAction: "link",
      treeConfig: { nodeWidth: 250, nodeHeight: 120, levelHeight: 120 }
    }
  },
  computed: {
    viewpoint() {
      let viewpoint = {
        center: { lng: 0, lat: 0 },
        zoom: 5,
      }
      let l = undefined;
      let r = undefined;
      let t = undefined;
      let b = undefined;
      if (this.deviceList[1] != undefined) { // Multiple emplacements
        for (let i in this.deviceList) if (this.deviceList[i].geoJSON.geometry != undefined && this.deviceList[i].geoJSON.geometry.type === "Point") {
          if (this.deviceList[i].geoJSON.geometry.coordinates[0] >= t || t === undefined) {
            t = Number(this.deviceList[i].geoJSON.geometry.coordinates[0])
          }
          if (this.deviceList[i].geoJSON.geometry.coordinates[0] <= b || b === undefined) {
            b = Number(this.deviceList[i].geoJSON.geometry.coordinates[0]);
          }
          if (this.deviceList[i].geoJSON.geometry.coordinates[1] >= r || r === undefined) {
            r = Number(this.deviceList[i].geoJSON.geometry.coordinates[1])
          }
          if (this.deviceList[i].geoJSON.geometry.coordinates[1] <= l || l === undefined) {
            l = Number(this.deviceList[i].geoJSON.geometry.coordinates[1]);
          }
        }
        // CENTER
        viewpoint.center.lng = (r + l) / 2;
        viewpoint.center.lat = (t + b) / 2;
      }
      else if (this.deviceList[0] != undefined) { // Single emplacement
        viewpoint.center = { lng: parseFloat(this.deviceList[0].geoJSON.geometry.coordinates[0]), lat: parseFloat(this.deviceList[0].geoJSON.geometry.coordinates[1]) };
      }
      return viewpoint;
    },
    deviceCardsByPages() {
      let deviceCardsByPages = []
      let i = 0;
      while (i * 12 < this.deviceList.length) {
        deviceCardsByPages.push(this.deviceList.slice(i * 12, i * 12 + 12));
        i++;
      }
      return deviceCardsByPages;
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies",'toggleConfigurationView'],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    onChangeDisplayType(type) {
      this.displayType = type;
      setTimeout(() => {       
        if (type === "table") this.displaydevicesConfigurationDataTable();
        this.setButtonFunctions();
      }, 100);
    },
    onSetCardPage(index) {
      this.deviceCardsPage = index;
      this.setButtonFunctions();
    },
    displayDevicesBlueprint() {
      setTimeout(() => {
        $(".deviceBlueprintMarker").get().forEach(marker => {
          let markerId = marker.getAttribute("data-id");
          for (let i in this.deviceList) {
            if (this.deviceList[i].id === markerId)
              marker.style = this.bluePrintMarkerPosition(this.deviceList[i]);
          }
        })
      }, 1000);
      let src = "";
      if (this.targetEmplacementData.variables != undefined
        && this.targetEmplacementData.variables.dynamic != undefined
        && this.targetEmplacementData.variables.dynamic.urlPlano != undefined)
        src = this.targetEmplacementData.variables.dynamic.urlPlano.value;
      if (src != undefined && src != '') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero=' + src + '&identificadorusuario=' + this.session.user.id + '&Token=' + localStorage.getItem("ClomosAuthToken");
        }
        catch (e) {
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    bluePrintMarkerPosition(device) {
      let markerX = 0;
      let markerY = 0;
      let blueprintCanvas = document.getElementById('emplacementBlueprintForDevices');
      if (blueprintCanvas != null) {
        let ratioX = blueprintCanvas.naturalWidth / blueprintCanvas.offsetWidth;
        let ratioY = blueprintCanvas.naturalHeight / blueprintCanvas.offsetHeight;

        let domX = device.geoJSON.geometry.coordinates[0] - blueprintCanvas.offsetLeft + 7;
        let domY = device.geoJSON.geometry.coordinates[1] - blueprintCanvas.offsetTop + 7;

        markerX = Math.floor(domX / ratioX);
        markerY = Math.floor(domY / ratioY);
      }
      return "position: absolute; top: " + markerY + "px; left: " + markerX + "px;"
    },    
    displaydevicesConfigurationDataTable() {
      $("#devicesConfigurationDataTable").DataTable().clear()
      for (let i in this.deviceList) {
        let parentLabel = "";
        if (this.targetMachineId != "") {
          parentLabel = this.targetMachineData.label;
        }
        else {
          for (let ii in this.emplacementList) {
            if (this.emplacementList[ii].id === this.deviceList[i].parent) {
              parentLabel = this.emplacementList[ii].label;
              break;
            }
          }
          if (parentLabel === "") for (let ii in this.machineList) {
            if (this.machineList[ii].id === this.deviceList[i].parent) {
              parentLabel = this.machineList[ii].label;
              break;
            }
          }
          if (parentLabel === "") for (let ii in this.bundleList) {
            if (this.bundleList[ii].id === this.deviceList[i].parent) {
              parentLabel = this.bundleList[ii].label;
              break;
            }
          }
          if (parentLabel === "") for (let ii in this.deviceList) {
            if (this.deviceList[ii].id === this.deviceList[i].parent) {
              parentLabel = this.deviceList[ii].label;
              break;
            }
          }
        }
        $("#devicesConfigurationDataTable").DataTable().rows.add([{
          deviceId: this.deviceList[i].id,
          deviceLabel: this.deviceList[i].label,
          deviceCommunicationDateTime: (this.deviceList[i].communicationDateTime != undefined && this.deviceList[i].communicationDateTime != "") ? new Date(this.deviceList[i].communicationDateTime) : "",
          deviceType: this.deviceList[i].type,
          deviceParent: { parentId: this.deviceList[i].parent, parentLabel: parentLabel, deviceId: this.deviceList[i].id },
          deviceVersion: this.deviceList[i].version,
          deviceCategory: this.deviceList[i].category
        }]);
      }
      $("#devicesConfigurationDataTable").DataTable().draw();
      this.setButtonFunctions();
      if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },
    async toggleNewDeviceModal() {
      if (this.display.newDeviceModal) {
        if (this.$route.params.currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
          await this.getDevicesDataByCompany();
        }
        else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
        }
        else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
          await this.getDevicesDataByMachine();
        }
        this.displaydevicesConfigurationDataTable();
      }
      this.display.newDeviceModal = !this.display.newDeviceModal;
    },
    async toggleEditDeviceModal() {
      if (this.display.editDeviceModal) {
        if (this.$route.params.currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
          await this.getDevicesDataByCompany();
        }
        else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
        }
        else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
          await this.getDevicesDataByMachine();
        }
        this.displaydevicesConfigurationDataTable();
      }
      
      this.display.editDeviceModal = !this.display.editDeviceModal;
    },
    async toggleLinkDeviceModal() {
      if (this.display.linkDeviceModal) {
        if (this.$route.params.currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
          await this.getDevicesDataByCompany();
        }
        else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
        }
        else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
          await this.getDevicesDataByMachine();
        }
        this.displaydevicesConfigurationDataTable();
      }
      
      this.display.linkDeviceModal = !this.display.linkDeviceModal;
    },
    async toggleDeleteDeviceModal() {
      if (this.display.deleteDeviceModal) {
        if (this.$route.params.currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
          await this.getDevicesDataByCompany();
        }
        else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
        }
        else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
          await this.getDevicesDataByMachine();
        }
       
        if (this.deviceList[0] != undefined) this.targetDeviceId = this.deviceList[0].id;
        this.displaydevicesConfigurationDataTable();
      }
      this.display.deleteDeviceModal = !this.display.deleteDeviceModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetDevice);
        });
        $(".btnEditDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onEditDevice);
        });
        $(".btnLinkDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onLinkDevice);
        });
        $(".btnUnlinkDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onUnlinkDevice);
        });
        $(".btnDeleteDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteDevice);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getEmplacementList(callback) {
      if (
        this.session.authorisation.availableModules[this.$route.params.currentModule]["emplacements"] === undefined
      ) return;

      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    async getMachineList(callback) {
      if (
        this.session.authorisation.availableModules[this.$route.params.currentModule]["machines"] === undefined
        && this.session.authorisation.availableModules[this.$route.params.currentModule]["commandCenters"] === undefined
      ) return;

      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getMachineListByCompany(request);
      this.machineList = api.parseMachinesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.machineList);
    },
    async getBundleList(callback) {
      if (
        this.session.authorisation.availableModules[this.$route.params.currentModule]["bundles"] === undefined
      ) return;

      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getBundleListByCompany(request);
      this.bundleList = api.parseBundlesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.bundleList);
    },
    async getDevicesDataByCompany(callback) {
      if (
        this.session.authorisation.availableModules[this.$route.params.currentModule]["devices"] === undefined
      ) return;

      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getDeviceListByCompany(request);
      this.deviceList = api.parseDevicesFromResponse(response);
      for (let i = 0; i < this.deviceList.length; i++) {
        let typeCategory = this.typesByCompany?.deviceTypeList?.filter(type => type.id === this.deviceList[i].dclTypeId);
        this.deviceList[i].category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";        
      }
      if (callback && typeof callback === "function") callback(this.deviceList);
    },
    async getDevicesDataByMachine(callback) {
      if (
        this.session.authorisation.availableModules[this.$route.params.currentModule]["devices"] === undefined
      ) return;

      this.deviceList = [];
      let response = await api.getMachineData(this.targetMachineId);
      this.targetMachineData = api.parseMachineFromResponse(response);
      let childResponse;
      let child = this.targetMachineData;
      let parent;
      // LVL 0
      if (this.targetMachineData.children != "") {
        parent = { ...child };
        let n = 0
        for (let childId in child.children) {
          if (child.children != "" && child.children[childId].claseDcl != "3") {
            n += 1;
            continue;
          }
          childResponse = await api.getDeviceData(childId);
          child = api.parseDeviceFromResponse(childResponse);
          if (child.dclClass === "3") {
            this.deviceList.push(child);
            // LVL 1
            if (child.children != "") {
              parent = { ...child };
              let nn = 0;
              for (let childId in child.children) {
                if (child.children != "" && child.children[childId].claseDcl != "3") {
                  nn += 1;
                  continue;
                }
                childResponse = await api.getDeviceData(childId);
                child = api.parseDeviceFromResponse(childResponse);
                if (child.dclClass === "3") {
                  this.deviceList.push(child);
                  // LVL 2
                  if (child.children != "") {
                    parent = { ...child };
                    let nnn = 0;
                    for (let childId in child.children) {
                      if (child.children != "" && child.children[childId].claseDcl != "3") {
                        nnn += 1;
                        continue;
                      }
                      childResponse = await api.getDeviceData(childId);
                      child = api.parseDeviceFromResponse(childResponse);
                      if (child.dclClass === "3") {
                        this.deviceList.push(child);
                        // LVL 3
                        if (child.children != "") {
                          parent = { ...child };
                          let nnnn = 0;
                          for (let childId in child.children) {
                            if (child.children != "" && child.children[childId].claseDcl != "3") {
                              nnnn += 1;
                              continue;
                            }
                            childResponse = await api.getDeviceData(childId);
                            child = api.parseDeviceFromResponse(childResponse);
                            if (child.dclClass === "3") {
                              this.deviceList.push(child);
                              // LVL 4
                              if (child.children != "") {
                                parent = { ...child };
                                let nnnnn = 0;
                                for (let childId in child.children) {
                                  if (child.children != "" && child.children[childId].claseDcl != "3") {
                                    nnnnn += 1;
                                    continue;
                                  }
                                  childResponse = await api.getDeviceData(childId);
                                  child = api.parseDeviceFromResponse(childResponse);
                                  if (child.dclClass === "3") {
                                    this.deviceList.push(child);
                                  }
                                  nnnnn += 1;
                                  if (Object.keys(child) === nnnnn) child = { ...parent };
                                }
                              }
                            }
                            nnnn += 1;
                            if (Object.keys(child) === nnnn) child = { ...parent };
                          }
                        }
                      }
                      nnn += 1;
                      if (Object.keys(child) === nnn) child = { ...parent };
                    }
                  }
                }
                nn += 1;
                if (Object.keys(child) === nn) child = { ...parent };
              }
            }
          }
          n += 1;
          if (Object.keys(child) === n) child = { ...parent };
        }
      }
      if (callback && typeof callback === "function") callback(this.deviceList);
    },
    async getDevicesDataByEmplacement(callback) {
      if (
        this.session.authorisation.availableModules[this.$route.params.currentModule]["devices"] === undefined
      ) return;

      let request = {
        emplacementId: this.targetEmplacementId,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getDeviceListByEmplacement(request);
      this.deviceList = api.parseDevicesFromResponse(response);
      for (let i = 0; i < this.deviceList.length; i++) {
        let typeCategory = this.typesByCompany?.deviceTypeList?.filter(type => type.id === this.deviceList[i].dclTypeId);
        this.deviceList[i].category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";        
      }
      if (callback && typeof callback === "function") callback(this.deviceList);
    },
    async getEmplacementData(callback) {
      let response = await api.getEmplacementData(this.targetEmplacementId);
      this.targetEmplacementData = api.parseEmplacementFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetEmplacementData);
    },
    // TARGET 
    onTargetDevice(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id')
      this.$router.push({ params: { currentDisplay: "device", targetEntity: this.targetDeviceId } });
    },
    // SET 
    onEditDevice(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id');
      this.$router.replace({ params: { targetEntity: this.targetDeviceId } })
      this.toggleEditDeviceModal();
    },
    // LINK 
    onLinkDevice(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetDeviceId } })
      this.linkAction = "link"
      this.toggleLinkDeviceModal();
    },
    // UNLINK 
    onUnlinkDevice(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetDeviceId } })
      this.linkAction = "unlink"
      this.toggleLinkDeviceModal();
    },
    // DELETE
    async onDeleteDevice(event) {
      if (!this.display.deleteDeviceModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetDeviceId);
        this.targetDeviceId = event.target.getAttribute('data-id');
        this.$router.replace({ params: { targetEntity: this.targetDeviceId } })
      }
      else {
        let response = await api.deleteDevice(this.targetDeviceId);
        if (response["status-code"] === "200") {
          window.alert("El dispositivo ha sido eliminado satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el dispositivo.");
        }
      }
      await this.toggleDeleteDeviceModal();
    }
  },
  async created() {
    await this.getEmplacementList();
    await this.getMachineList();

    if (this.$route.params.currentDisplay === 'devices' || (this.targetEmplacementId == '' && this.targetMachineId == '')) {
      await this.getDevicesDataByCompany();
    }
    else if (this.targetEmplacementId != "" && this.targetMachineId == '') {
      await this.getEmplacementData();
      await this.getDevicesDataByEmplacement();
    }
    else if (this.targetMachineId != "" && this.targetEmplacementId == '') {
      await this.getDevicesDataByMachine();
    }
    this.$emit('getAvailableCompanies', this.onChangeDisplayType(this.displayType));
    if (this.deviceList[0] != undefined && this.targetDeviceId === "")
      this.targetDeviceId = this.deviceList[0].id;
      
  },
  mounted() {
    $("#devicesConfigurationDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 30,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.devicesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btonCopyId:hover,
.btnTargetDevice:hover,
.btnLinkDevice:hover,
.btnUnlinkDevice:hover,
.btnEditDevice:hover,
.btnDeleteDevice:hover {
  color: var(--main-dark) !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  padding: 0.5rem !important;
}

#organizationChart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.device-node {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.device-node-top {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px 5px 0 0;
}

.device-node-bot {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  color: black;
  border-radius: 0 0 5px 5px;
}
</style>