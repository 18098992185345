<template>
  <div class="home-container">

    <div v-if="this.session.user.nifCif != ''">
      <!-- MENU Side -->
      <SideMenuComponent :session="session" :dictionary="dictionary" :visibility="visibility"
        :availableModules="session.authorisation.availableModules" v-on:toggleSideMenu="toggleSideMenu"
        v-on:logOutRequest="logOutRequest">
      </SideMenuComponent>

      <!-- MENU Top -->
      <TopMenuComponent :session="session" :dictionary="dictionary" :visibility="visibility"
        :currentCompany="currentCompany" v-on:toggleSideMenu="toggleSideMenu">
      </TopMenuComponent>

      <!-- SECTION Profile -->
      <div v-if="this.$route.params.currentDisplay === 'profile'" class="module-contaier">
        <ProfileComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" v-on:getCurrentUserData="getCurrentUserData"
          v-on:getCurrentCompanyData="getCurrentCompanyData">
        </ProfileComponent>
      </div>

      <!-- SECTION Dashboard -->
      <div v-if="this.$route.params.currentDisplay === 'dashboard'" class="module-contaier">
        <DashboardComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :targetEmplacementId="targetEmplacementId" :currentCompany="currentCompany"
          :availableCompanies="availableCompanies" :dataTableOptions="dataTableOptions"
          v-on:handleHasRol="handleHasRol">
        </DashboardComponent>
      </div>

      <!-- SECTION Companies -->
      <div v-if="this.$route.params.currentDisplay === 'companies'" class="module-contaier">
        <CompaniesComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </CompaniesComponent>
      </div>

      <!-- SECTION Emplacements -->
      <div v-if="this.$route.params.currentDisplay === 'emplacements'" class="module-contaier">
        <EmplacementsComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :dataTableOptions="dataTableOptions" v-on:handleHasRol="handleHasRol"
          v-on:setCurrentCompany="setCurrentCompany" v-on:getAvailableCompanies="getAvailableCompanies">
        </EmplacementsComponent>
      </div>
        <!-- SECTION Machines -->
        <div v-if="this.$route.params.currentDisplay === 'reports'" class="module-contaier">
        <ReportsComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies" :typesByCompany="typesByCompany"
          :targetEmplacementId="targetEmplacementId" :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany" v-on:handleHasRol="handleHasRol"
          v-on:setEmplacement="setEmplacement" v-on:getAvailableCompanies="getAvailableCompanies">
        </ReportsComponent>
      </div>

      <!-- SECTION Machines -->
      <div v-if="this.$route.params.currentDisplay === 'machines'" class="module-contaier">
        <MachinesComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies" :typesByCompany="typesByCompany"
          :targetEmplacementId="targetEmplacementId" :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany" v-on:handleHasRol="handleHasRol"
          v-on:setEmplacement="setEmplacement" v-on:getAvailableCompanies="getAvailableCompanies">
        </MachinesComponent>
      </div>
       <!-- SECTION Machine -->
       <div v-if="this.$route.params.currentDisplay === 'machine'" class="module-contaier">
        <MachineComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"  :typesByCompany="typesByCompany"
          :dataTableOptions="dataTableOptions" v-on:handleHasRol="handleHasRol"
          >
        </MachineComponent>
      </div>
      <div v-if="this.$route.params.currentDisplay === 'bundle'" class="module-contaier">
        <MachineComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"  :typesByCompany="typesByCompany"
          :dataTableOptions="dataTableOptions" v-on:handleHasRol="handleHasRol"
          >
        </MachineComponent>
      </div>

      <!-- SECTION CommandCenters -->
      <div v-if="this.$route.params.currentDisplay === 'commandCenters'" class="module-contaier">
        <CommandCentersComponent :session="session" :dictionary="dictionary" :countries="countries"
          :visibility="visibility" :timeZones="timeZones" :currentCompany="currentCompany"
          :availableCompanies="availableCompanies" :targetEmplacementId="targetEmplacementId"
          :dataTableOptions="dataTableOptions" v-on:handleHasRol="handleHasRol"
          v-on:setCurrentCompany="setCurrentCompany" v-on:setEmplacement="setEmplacement"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </CommandCentersComponent>
      </div>
      <!-- SECTION CommandCenter -->
      <div v-if="this.$route.params.currentDisplay === 'commandCenter'" class="module-contaier">
        <CommandCenterComponent :session="session" :dictionary="dictionary" :countries="countries"
          :visibility="visibility" :timeZones="timeZones" :currentCompany="currentCompany"
          :availableCompanies="availableCompanies" :dataTableOptions="dataTableOptions" :typesByCompany="typesByCompany"
          v-on:handleHasRol="handleHasRol">
        </CommandCenterComponent>
      </div>

      <!-- SECTION Lockers -->
      <div v-if="this.$route.params.currentDisplay === 'lockers'" class="module-contaier">
        <LockersComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :targetEmplacementId="''" :dataTableOptions="dataTableOptions" v-on:handleHasRol="handleHasRol"
          v-on:setCurrentCompany="setCurrentCompany" v-on:getAvailableCompanies="getAvailableCompanies">
        </LockersComponent>
      </div>
      <div v-if="this.$route.params.currentDisplay === 'locker'" class="module-contaier">
        <SlotsComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
           :dataTableOptions="dataTableOptions"
         >
        </SlotsComponent>
      </div>
      <!-- SECTION Devices -->
      <div v-if="this.$route.params.currentDisplay === 'bundles'" class="module-contaier">
        <BundlesComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :targetMachineId="''" :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </BundlesComponent>
      </div>
      <!-- SECTION Slots -->
      <div v-if="this.$route.params.currentDisplay === 'slots'" class="module-contaier">
        <SlotsComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :targetMachineId="''" :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </SlotsComponent>
      </div>

      <!-- SECTION Devices -->
      <div v-if="this.$route.params.currentDisplay === 'device'" class="module-contaier">
        <DeviceComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"  :typesByCompany="typesByCompany" v-on:handleHasRol="handleHasRol"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies" :dataTableOptions="dataTableOptions"
           v-on:setCurrentCompany="setCurrentCompany">
        </DeviceComponent>
      </div>
       <!-- SECTION Devices -->
       <div v-if="this.$route.params.currentDisplay === 'devices'" class="module-contaier">
        <DevicesComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :targetEmplacementId="''" :targetMachineId="''" :dataTableOptions="dataTableOptions"
          :typesByCompany="typesByCompany" v-on:handleHasRol="handleHasRol" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </DevicesComponent>
      </div>

      <!-- SECTION Status -->
      <div v-if="this.$route.params.currentDisplay === 'status'" class="module-contaier">
        <StatusComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :requestedDeviceId="''" v-on:setCurrentCompany="setCurrentCompany">
        </StatusComponent>
      </div>

      <!-- SECTION Products -->
      <div v-if="this.$route.params.currentDisplay === 'products'" class="module-contaier">
        <ProductsComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </ProductsComponent>
      </div>

      <!-- SECTION Stock -->
      <div v-if="this.$route.params.currentDisplay === 'stocks'" class="module-contaier">
        <StockComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </StockComponent>
      </div>

      <!-- SECTION Receptions -->
      <div v-if="this.$route.params.currentDisplay === 'receptions'" class="module-contaier">
        <ReceptionsComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </ReceptionsComponent>
      </div>

      <!-- SECTION Expeditions -->
      <div v-if="this.$route.params.currentDisplay === 'expeditions'" class="module-contaier">
        <ExpeditionsComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </ExpeditionsComponent>
      </div>

      <!-- SECTION Orders -->
      <div v-if="this.$route.params.currentDisplay === 'orders'" class="module-contaier">
        <OrdersComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :dataTableOptions="dataTableOptions">
        </OrdersComponent>
      </div>

      <!-- SECTION Locking -->
      <div v-if="this.$route.params.currentDisplay === 'lockings'" class="module-contaier">
        <LockingsComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </LockingsComponent>
      </div>

      <!-- SECTION Charts -->
      <div v-if="this.$route.params.currentDisplay === 'charts'" class="module-contaier">
        <ChartsComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          v-on:setCurrentCompany="setCurrentCompany">
        </ChartsComponent>
      </div>


      <!-- SECTION Users -->
      <div v-if="this.$route.params.currentDisplay === 'users'" class="module-contaier">
        <UsersComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </UsersComponent>
      </div>
      <!-- SECTION Incidences -->
      <div v-if="this.$route.params.currentDisplay === 'incidences'" class="module-contaier">
        <IncidencesComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :targetEmplacementId="''" :dataTableOptions="dataTableOptions" v-on:handleHasRol="handleHasRol"
          v-on:setCurrentCompany="setCurrentCompany" v-on:getAvailableCompanies="getAvailableCompanies">
        </IncidencesComponent>
      </div>
      <!-- SECTION Alarms -->
      <div v-if="this.$route.params.currentDisplay === 'alarms'" class="module-contaier">
        <AlarmsComponent :session="session" :dictionary="dictionary" :countries="countries" :timeZones="timeZones"
          :visibility="visibility" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
          :targetEmplacementId="''" :dataTableOptions="dataTableOptions" v-on:setCurrentCompany="setCurrentCompany"
          v-on:getAvailableCompanies="getAvailableCompanies">
        </AlarmsComponent>
      </div>
      
      <!--
      <div class="p-3">
        <small>Copyright &copy; 2022. D&A Innovative Systems.</small>
      </div>
      -->

    </div>

  </div>
</template>

<script>
import SideMenuComponent from '@/components/navigation/SideMenuComponent';
import TopMenuComponent from '@/components/navigation/TopMenuComponent';
import ProfileComponent from '@/components/profile/ProfileComponent';
import DashboardComponent from '@/components/dashboard/DashboardComponent';
import EmplacementsComponent from '@/components/emplacements/EmplacementsComponent';
import MachinesComponent from '@/components/machines/MachinesComponent';
import MachineComponent from '@/components/machines/MachineComponent';
import BundlesComponent from '@/components/bundles/BundlesComponent';
import DevicesComponent from '@/components/devices/DevicesComponent';
import DeviceComponent from '@/components/devices/DeviceComponent';
import StatusComponent from '@/components/status/StatusComponent';
import CompaniesComponent from '@/components/companies/CompaniesComponent';
import ProductsComponent from '@/components/products/ProductsComponent';
import StockComponent from '@/components/stock/StockComponent';
import ReceptionsComponent from '@/components/receptions/ReceptionsComponent';
import ExpeditionsComponent from '@/components/expeditions/ExpeditionsComponent';
import OrdersComponent from '@/components/orders/OrdersComponent';
import LockingsComponent from '@/components/lockers/LockingsComponent';
import LockersComponent from '@/components/lockers/LockersComponent';
import SlotsComponent from '@/components/lockers/SlotsComponent';
import ChartsComponent from '@/components/charts/ChartsComponent';
import UsersComponent from '@/components/users/UsersComponent';
import IncidencesComponent from '@/components/incidences/IncidencesComponent';
import AlarmsComponent from '@/components/conditions/AlarmsComponent'
import CommandCentersComponent from '@/components/electricalMonitoring/CommandCentersComponent';
import CommandCenterComponent from '@/components/electricalMonitoring/CommandCenterComponent';
import ReportsComponent from '@/components/electricalMonitoring/ReportsComponent';
// Clomos
import api from '@/services/api';

export default {
  name: "ClomosView",
  components: {
    TopMenuComponent,
    SideMenuComponent,
    ProfileComponent,
    DashboardComponent,
    EmplacementsComponent,
    MachinesComponent,
    BundlesComponent,
    DevicesComponent,
    DeviceComponent,
    StatusComponent,
    CompaniesComponent,
    ProductsComponent,
    StockComponent,
    ReceptionsComponent,
    ExpeditionsComponent,
    OrdersComponent,
    LockingsComponent,
    LockersComponent,
    ChartsComponent,
    UsersComponent,
    SlotsComponent,
    IncidencesComponent,
    AlarmsComponent,
    CommandCentersComponent,
    CommandCenterComponent,
    MachineComponent,
    ReportsComponent
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    }
  },
  watch: {
    session: async function () {
      if (this.session.user.nifCif == "") {
        this.$emit('getCurrentSessionData', (async () => {
          await this.getCurrentCompanyData();
          await this.getAvailableCompanies();
        }));
      } else {
        await this.getCurrentCompanyData();
        await this.getAvailableCompanies();
      }
    },
    currentCompany: async function () {
      await this.getTypesByCompany()
    }
  },
  data() {
    return {
      visibility: {
        sideMenu: true,
        availableMenu: true,
      },
      currentCompanyNifCif: this.$route.params.currentCompanyNifCif,
      currentCompany: {},
      availableCompanies: [],
      targetEmplacementId: "",
      typesByCompany: {}
    }
  },
  emits: ["getCurrentUserData", "getCurrentSessionData", "getCurrentCompanyData", "logOutRequest"],
  methods: {
    // DISPLAY
    toggleSideMenu() {
      this.visibility.sideMenu = !this.visibility.sideMenu;
    },
    // PERMISSIONS
    handleHasRol(roleName, callback) {
      const hasPermission = this.hasRol(this.session.user.rol, roleName);
      callback(hasPermission);
      return hasPermission;
    },
    hasRol(userMask, rolName) {
      const ROLES = {
        ninguno: 0x00,
        reservado_1: 0x01,
        gestor: 0x02,
        agente: 0x04,
        lector: 0x08,
        reservado_2: 0x10,
        editor: 0x20,
        reservado_3: 0x40,
        admin: 0x80,
        root: 0xFF
      };
      userMask = parseInt(userMask, 10);
      const rolMask = ROLES[rolName.toLowerCase()];
      if (rolMask === undefined) {
        console.error(`Rol "${rolName}" no encontrado.`);
        return false;
      }
      return (userMask & rolMask) > 0;
    },
    // GET
    async getDeviceTypeList() {
      try {
        const request = {
          nifCif: this.currentCompany.nifCif,
          limit: 0,
          offset: 0,
          where: "",
        };
        const response = await api.getDeviceTypeList(request);
        if (!response.tiposDcl || response.tiposDcl.length === 0) return;
        const deviceTypeList = response.tiposDcl.map(tipo => ({
          id: tipo.idTipoDcl,
          label: tipo.etiqueta,
          category: tipo.categoria
        }));
        return deviceTypeList;
      } catch (error) {
        console.error("Error fetching device types:", error);
      }
    },

    async getMachineTypeList() {
      try {
        const request = {
          nifCif: this.currentCompany.nifCif,
          limit: 0,
          offset: 0,
          where: "",
        };
        const response = await api.getMachineTypeList(request);
        if (!response.tiposDcl || response.tiposDcl.length === 0) return;
        const machineTypeList = response.tiposDcl.map(machine => ({
          id: machine.idTipoDcl,
          label: machine.etiqueta,
          category: machine.categoria
        }));
        return machineTypeList;
      } catch (error) {
        console.error("Error fetching machine types:", error);
      }
    },
    async getBundleTypeList(){
      try {
        const request = {
          nifCif: this.currentCompany.nifCif,
          limit: 0,
          offset: 0,
          where: "",
        };
        const response = await api.getBundleTypeList(request);
        if (!response.tiposDcl || response.tiposDcl.length === 0) return;
        const bundleTypeList = response.tiposDcl.map(bundle => ({
          id: bundle.idTipoDcl,
          label: bundle.etiqueta,
          category: bundle.categoria
        }));
        return bundleTypeList;
      } catch (error) {
        console.error("Error fetching machine types:", error);
      }
    },
    async getPartTypeList(){
    try {
        const request = {
          nifCif: this.currentCompany.nifCif,
          limit: 0,
          offset: 0,
          where: "",
        };
        const response = await api.getPartTypeList(request);
        if (!response.tiposDcl || response.tiposDcl.length === 0) return;
        const partTypeList = response.tiposDcl.map(part => ({
          id: part.idTipoDcl,
          label: part.etiqueta,
          category: part.categoria
        }));
        return partTypeList;
      } catch (error) {
        console.error("Error fetching parts types:", error);
      }
    },
    async getTypesByCompany() {
      try {
        const [deviceTypeList, machineTypeList, bundleTypeList, partTypeList] = await Promise.all([
          this.getDeviceTypeList(),
          this.getMachineTypeList(),
          this.getBundleTypeList(),
          this.getPartTypeList(),
        ]);
        this.typesByCompany = {
          deviceTypeList: deviceTypeList || [],
          machineTypeList: machineTypeList || [],
          bundleTypeList: bundleTypeList || [],
          partTypeList: partTypeList || [],
        };
      } catch (error) {
        console.error("Error fetching types by company:", error);
      }
    },
    // SESION
    getCurrentUserData(callback) {
      this.$emit('getCurrentUserData', callback);
    },
    async getCurrentCompanyData(callback) {
      if (this.session.user.nifCif === "") return;
      let response = await api.getCompanyData(this.currentCompanyNifCif);
      this.currentCompany = api.parseCompanyFromResponse(response);
      if (callback && typeof callback === "function") callback(this.currentCompany);
    },
    async getAvailableCompanies(callback, forced) {
      if (this.availableCompanies.length > 0 && forced === undefined) {
        if (callback && typeof callback === "function") {
          callback(this.availableCompanies);
        }
        return;
      }
      let availableCompanies = [];
      let request = {
        nifCif: this.session.user.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let responseUserCompany = await api.getCompanyData(this.session.user.nifCif);
      let uc = api.parseCompanyFromResponse(responseUserCompany);
      availableCompanies.push(uc);
      if (this.hasRol(this.session.user.rol, 'agente')) {
        let responseAvailableCompanies = await api.getCompanyList(request);
        let ac = api.parseAvailableCompaniesFromResponse(responseAvailableCompanies);
        for (let c in ac) availableCompanies.push(ac[c]);
        this.availableCompanies = availableCompanies;
      } else {
        this.availableCompanies = availableCompanies;
      }

      if (callback && typeof callback === "function") callback(this.availableCompanies);
    },
    // SET
    async setCurrentCompany(nifCif, callback) {
      if (nifCif === "showAll") {
        this.currentCompanyNifCif = "showAll";
        this.currentCompany = {
          nifCif: "showAll",
          name: "Show All"
        }
      }
      else {
        this.currentCompanyNifCif = nifCif;
        let response = await api.getCompanyData(nifCif);
        this.currentCompany = api.parseCompanyFromResponse(response);
      }
      this.$router.replace({ params: { currentCompanyNifCif: this.currentCompanyNifCif, targetEntity: "" } })
      if (callback && typeof callback === "function") callback(this.currentCompany);
    },
    async setEmplacement(emplacementId, callback) {
      this.targetEmplacementId = emplacementId;
      if (callback && typeof callback === "function") callback(this.currentCompany);
    },
    logOutRequest() {
      this.$emit('logOutRequest');
    }
  },
  async created() {
    if (this.session.user.nifCif == "") {
      this.$emit('getCurrentSessionData', (async () => {
        await this.getCurrentCompanyData();
        await this.getAvailableCompanies();
      }));
    } else {
      await this.getCurrentCompanyData();
      await this.getAvailableCompanies();
    }
    api.pointAtModule(this.$route.params.currentModule);
  }
}
</script>