<template>
  <!-- SECTION Slots-->
  <div id="slotsSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
        <div class="row col-lg-12 col-md-12 col-12 table-responsive m-0 p-0 pb-3">
          <div class="position-sticky col-12 m-0 pt-2 pb-2 bg-head text-white border-1">
            <div class="row p-0 m-0">
              <!--Primera sección-->
              <div class="row col-xl-6 col-lg-6 col-md-12 col-12 m-0 pt-0 ps-3 pe-4 text-white rounded-5 rounded-end">
                <div class="col m-0 pt-0 ps-0 text-white d-flex flex-row">
                  <h6 class="tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 d-flex flex-row">
                    <ion-icon name="file-tray-stacked" class="tx-16 me-2 align-bottom"></ion-icon>
                    {{ dictionary["Empresas"][session.user.language] }}
                  </h6>
                  <select v-if="this.$route.params.currentDisplay === 'lockers'" :value="currentCompany.nifCif"
                    class="form-control m-0 p-0 text-nowrap bg-white border"
                    style="border: solid 2px white ; color: black; min-width:200px; height: 34px;"
                    @change="onCurrentCompanyChanged">
                    <option v-if="availableCompanies[0] != undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                    <option v-for="company in availableCompanies" :key="company.nifCif" :v-if="company.nifCif != session.user.nifCif
                      && company.relation != undefined
                      && company.relation.referenceCompany === this.session.user.nifCif" :value="company.nifCif">
                      {{ (company.nifCif === session.user.nifCif) ? "✪ " + company.name : company.name }}
                    </option>
                  </select>
                </div>
                <div class="col m-0 pt-0 ps-0 text-white d-flex flex-row">
                  <h6 class="tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 d-flex flex-row">
                    <ion-icon name="location" class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ dictionary["Lockers"][session.user.language] }}
                  </h6>
                  <select v-if="this.$route.params.currentDisplay === 'lockers'" :value="targetLockerId"
                    class="form-control m-0 p-0 text-nowrap bg-white border"
                    style="border: solid 2px white ; color: black; min-width:200px; height: 34px;"
                    @change="emitTargetLockerId">
                    
                    <option v-for="locker in lockerList" :key="locker.id" :value="locker.id">
                      {{ locker.label }}
                    </option>
                  </select>


                </div>
               
              </div>
              <!-- Fin Primera sección-->
              <!--Segunda sección-->
              <div class="row col-xl-6 col-lg-6 col-md-9 col-12 justify-content-end p-0 m-0 pe-3">
                <div class="row col-lg-8 col-md-8 col-8 justify-content-end">
                  <span class="text-end text-white mt-1 mb-2 pe-4">
                    <ion-icon name="grid" class="tx-16 mb-1 me-1 align-bottom"></ion-icon>
                    {{ dictionary["Huecos"][session.user.language] }}
                  </span>

                </div>
                <div class="row col-xl-2 col-lg-3 col-md-3 col-4 justify-content-end p-0 m-0 pt-1 pe-3">
                  <button v-if="this.session.user.rol === '255'" id="btnDisplayNewSlotModal"
                    :title="dictionary['Añadir emplazamiento'][session.user.language]"
                    style="width: 24px; max-height: 24px;" class="button-ok bg-white mt-0 me-2 rounded shadow"
                    @click="toggleNewSlotModal">
                    <span>
                      <span class="button-text text-dark">
                        <ion-icon name="add-circle-outline" class="text-dark pt-0 tx-18 hydrated"
                          data-bs-toggle="tooltip" data-bs-placement="bottom"
                          :title="dictionary['Añadir hueco'][session.user.language]"></ion-icon>
                      </span>
                    </span>
                  </button>

                  <button id="btnDisplayEditEraseModal" style="width: 24px; max-height: 24px;"
                    :title="dictionary['Actualizar'][session.user.language]"
                    class="button-ok bg-white mt-0 me-2 rounded shadow" @click="refreshView">
                    <span>
                      <span class="button-text text-dark">
                        <ion-icon name="refresh-outline" @click="onRefresh" class="text-dark pt-0 me-0 tx-16 hydrated"
                          :title="dictionary['Actualizar'][session.user.language]"></ion-icon>
                        <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- COMPONENT HUECOS -->
          <div class=" col-md-12 col-12 m-0 ps-4 p-3 pt-0 pb-0 mt-2 h-auto ">
            <!-- TABLE HUECOS -->
            <div v-show="displayType === 'table'" class="col-12 bg-caja rounded-5 shadow pt-2 pb-4"
              id="lockersTableContent"
              style="z-index: 0; height: auto;border-top: solid 5px #005793;border-bottom: solid 2px #008db4;">
              <table id="slotsDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
                <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                  <tr>
                    <th v-for="column in slotsColumns" :key="column.data" :width="column.width"
                      class="fw-medium text-nowrap p-2">
                      {{ dictionary[column.text][session.user.language] }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <!-- cards huecos-->
       
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- SECTION Slots -->
  <!-- <StatusComponent v-if="this.$route.params.currentDisplay==='slots'"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :requestedSlotId="targetSlotId"
  :dataTableOptions="dataTableOptions">
  </StatusComponent> -->

  <!-- MODAL New Slot -->
  <NewSlotModal v-if="display.newSlotModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :targetLockerId="targetLockerId"
    v-on:toggleNewSlotModal="toggleNewSlotModal">
  </NewSlotModal>


  <!-- MODAL Edit Slot -->
  <EditSlotModal v-if="display.editSlotModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :targetLockerId="targetLockerId" :slotId="targetSlotId"
    v-on:toggleEditSlotModal="toggleEditSlotModal">
  </EditSlotModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal v-if="display.deleteSlotModal" :session="session" :dictionary="dictionary" :countries="countries"
    :alertHeader="dictionary['Hueco a eliminar'][session.user.language] + ': '" :alertTarget="targetSlotId"
    v-on:acceptAlert="onDeleteSlot" v-on:dismissAlert="toggleDeleteSlotModal">
  </TextAlertModal>
</template>

<script>
import $ from 'jquery';
//import StatusComponent from '@/components/StatusComponent';
import NewSlotModal from '@/components/lockers/NewSlotModal';
import EditSlotModal from '@/components/lockers/EditSlotModal';
import TextAlertModal from '@/components/other/TextAlertModal';

// Clomos
import api from '@/services/api';

export default {
  name: "SlotConfigurationComponent",
  components: { /* StatusComponent,*/ EditSlotModal, NewSlotModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    targetLockerId: {
      type: String,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    }
  },
  watch: {
    currentCompany: async function () {
      if (this.$route.params.currentDisplay === 'slots' || this.targetLockerId == '') {
        await this.getBundleListByCompany();
      }
      else {
        await this.getLockerData();
        await this.getSlotListByLocker();
        this.variableHistory = {};
        await this.getLockerVariableHistory();
      }
      this.onChangeDisplayType(this.displayType);
      if (this.slotList[0] != undefined && this.targetSlotId === "")
        this.targetSlotId = this.slotList[0].id;
    },
    targetLockerId: async function () {
      if (this.$route.params.currentDisplay === 'slots' || this.targetLockerId == '') {
        await this.getBundleListByCompany();
      }
      else {
        await this.getLockerData();
        await this.getSlotListByLocker();
        this.variableHistory = {};
        await this.getLockerVariableHistory()
      }
      this.onChangeDisplayType(this.displayType);
      if (this.slotList[0] != undefined && this.targetSlotId === "")
        this.targetSlotId = this.slotList[0].id;
    }
  },
  data() {
    return {
      slotsColumns: [
        {
          text: "Identificador", data: "slotId", width: "25%", className: "tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id=" + data + " class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
              + "<span data-id=" + data + " class='btnTargetProduct c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>" + data + "</span>";
          }
        },
        { text: "Etiqueta", data: "slotLabel", width: "15%", className: "tx-left align-middle" },
        /*  { text: "Tipo", data: "slotType", width: "10%", className: "tx-left align-middle" }, */
        {
          text: "Emplazamiento", data: "slotEmplacement", width: "10%", className: "tx-left align-middle",
          render: (data) => {
            let emplacementLabel = data;
            if (this.lockerList.length > 0) {
              for (let emplacement in this.lockerList)
                if (this.lockerList[emplacement].id === data)
                  emplacementLabel = this.lockerList[emplacement].label;
            }
            return emplacementLabel;
          }
        },
        { text: "Token", data: "slotToken", width: "15%", className: "tx-left align-middle" },
        {
          text: "Fecha de comunicación", data: "slotCommunicationDateTime", width: "10%", className: "align-middle",
          render: (data) => {
            return (data != "")
              ? "<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
              : "<span style='display: none;'></span> <spam>-</spam>";
          }
        },
        {
          text: "Coordenadas", data: "slotGeoJSON", width: "15%", className: "tx-left align-middle",
          render: (data) => {
            if (data.geometry != undefined && data.geometry.type === "Point") return "<div class='d-flex pt-1'><span class='me-2 ms-2 tx-black'>Lat:</span> <span>" + Number(data.geometry.coordinates[0]) + "</span> <span class='me-2 ms-2 tx-black'>Lon:</span> <span>" + Number(data.geometry.coordinates[1])
            else return "<span class='me-2 ms-2 tx-primary'>Lat:</span> <span>" + 0 + "</span>  <span class='me-2 ms-2 tx-primary'> Lon:</span></div> " + 0
          }
        },
        {
          text: "Apertura remota", data: "slotId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='exit-outline' data-id=" + data + " class='btnOpen btn tx-18 tx-primary'></ion-icon>";
          }
        },
        {
          text: "Configuración", data: "slotId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id=" + data + " class='btnEditSlot btn tx-18 tx-primary'></ion-icon>"
          }
        },
        {
          text: "Eliminar", data: "slotId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id=" + data + " class='btnDeleteSlot btn tx-18 tx-danger'></ion-icon>"
          }
        },
      ],
      targetLockerData: {},
      targetSlotId: this.$route.params.targetEntity,
      slotFilter: "",
      slotList: [],
      lockerList: [],
      lockerSlotsGrid: [],
      displayType: 'table',
      slotCardsPage: 0,
      display: {
        newSlotModal: false,
        editSlotModal: false,
        linkSlotModal: false,
        deleteSlotModal: false,
      },
      linkAction: "link",
      showSpinner: '',      
      variableHistory:{},
      // COLORS
      colors: ["#9e9e9e",
        "#4e79a7",
        "#f28d2b",
        "#e05759",
        "#76b7b2",
        "#59a14f",
        "#af7aa1",
        "#b1de89",
        "#595055",
        "#edc948",
        "#1c8356",
        "#ff9da7",
        "#8d9fca",
        "#2f451e"],
        
    }
  },
  computed: {
    fractionalLength() {
      return (localStorage.getItem("ClomosFractionalLength") != undefined) ? localStorage.getItem("ClomosFractionalLength") : 2;
    },
    slotCardsByPages() {
      let slotCardsByPages = []
      let i = 0;
      while (i * 12 < this.slotList.length) {
        slotCardsByPages.push(this.slotList.slice(i * 12, i * 12 + 12));
        i++;
      }
      return slotCardsByPages;
    },
    lastDayLineChartOptions() {
      let lastDayLineChartOptions;
      let now = new Date().getTime();
      let yesterday = new Date().setDate(new Date().getDate() - 1);

      lastDayLineChartOptions = JSON.parse(JSON.stringify(this.lineChartOptions));
      if (this.targetLockerData === "") return lastDayLineChartOptions;
      lastDayLineChartOptions.axisPointer = {
        label: {
          formatter: (function (dataTarget) {
            let d = new Date(dataTarget.value);
            return String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0") + ":" + String(d.getSeconds()).padStart(2, "0");
          })
        },
      }
      lastDayLineChartOptions.xAxis = {
        min: yesterday,
        max: now,
        axisLabel: {
          formatter: (function (value) {
            let d = new Date(value);
            return String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0") + ":" + String(d.getSeconds()).padStart(2, "0");
          }),
          textStyle: {
            fontSize: 10
          }
        }
      }
      lastDayLineChartOptions.yAxis = {
        type: "value"
      }
      let i = 0;
      for (let variable in this.variableHistory) {
        lastDayLineChartOptions.legend.data[i] = variable;
        lastDayLineChartOptions.series[i] = {
          data: this.variableHistory[variable],
          name: variable,
          type: "line",
          symbol: "emptyDiamond",
          symbolSize: 6,
          color: this.colors[i],
          smooth: true,
          step: false,
          areaStyle: {
            color: this.colors[i],
            opacity: 0.03
          },
        }
        i += 1;
      }
      return lastDayLineChartOptions;
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies", 'updateTargetLockerId'],
  methods: {
    moreInfo(slotData) {
      slotData.id === "" ? this.slotFilter = undefined : this.slotFilter = slotData;
    },
    async onForceSlot(event) {
      let slotID = event.target.getAttribute('data-id')
      await this.forceSlotOpening(slotID)
    },
    async forceSlotOpening(slotID) {
      this.showSpinner = true;
      let response = await api.forceSlotOpening(slotID);

      if (response["status-code"] === "200" || response["status-code"] === "304") {
        await this.getLockerData();
        await this.getSlotListByLocker();
        this.variableHistory = {};
        await this.getLockerVariableHistory();

        for (let i = 0; i < this.slotList.length; i++) {
          if (this.slotList[i].id === this.slotFilter.id) {
            this.slotFilter = this.slotList[i]
          }

        }
        let slotCardsByPages = []
        let x = 0;
        while (x * 12 < this.slotList.length) {
          slotCardsByPages.push(this.slotList.slice(x * 12, x * 12 + 12));
          x++;
        }
        if (this.displayType === "table") this.displaySlotsDataTable();

      }
      this.showSpinner = false;
    },
    async disableSlot(slot) {
      if (slot.variables?.analog?.["estado"]) {
        let data = {
          id: slot.id,
          variableId: slot.variables.analog["estado"].variableId,
          value: slot.variables.analog["estado"].value === "2" ? "0" : "2",
          label: "estado",
          type: "1"
        };
        if (slot.variables.dynamic.idPedido.value.length > 0 && slot.variables.analog["estado"].value === "2") {
          window.alert("Error")
        } else {
          let response = await api.setBundleVariable(data)
          if (response["status-code"] === "200" || response["status-code"] === "304") {
            await this.getLockerData();
            await this.getSlotListByLocker();

            for (let i = 0; i < this.slotList.length; i++) {
              if (this.slotList[i].id === this.slotFilter.id) {
                this.slotFilter = this.slotList[i]
              }

            }
            let slotCardsByPages = []
            let x = 0;
            while (x * 12 < this.slotList.length) {
              slotCardsByPages.push(this.slotList.slice(x * 12, x * 12 + 12));
              x++;
            }
            if (this.displayType === "table") this.displaySlotsDataTable();

          }
          this.displaySlotsLocker();
        }

      }

    },
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    onChangeDisplayType(type) {
      this.displayType = type;
      setTimeout(() => {
        if (type === "locker") this.displaySlotsLocker();
        if (type === "table") this.displaySlotsDataTable();
        this.setButtonFunctions();
      }, 100);
    },
    onSetCardPage(index) {
      this.slotCardsPage = index;
      this.setButtonFunctions();
    },
    displaySlotsLocker() {
      if (this.targetLockerData.variables === undefined) return;
      if (this.targetLockerData.variables.analog.numeroFilas === undefined
        || this.targetLockerData.variables.analog.numeroColumnas === undefined)
        return;
      let rows = parseInt(this.targetLockerData.variables.analog.numeroFilas.value);
      let columns = parseInt(this.targetLockerData.variables.analog.numeroColumnas.value);
      this.lockerSlotsGrid = [];
      let r0 = 0;
      while (r0 < rows) {
        this.lockerSlotsGrid.push([]);
        let c0 = 0;
        while (c0 < columns) {
          this.lockerSlotsGrid[r0][c0] = { "id": "", "label": "", "variables": { "analog": { "estado": { "value": "" } }, "digital": { "cerrado": { "value": "" } } } };
          for (let slot in this.slotList) {
            if (this.slotList[slot].geoJSON != undefined
              && this.slotList[slot].geoJSON.geometry != undefined
              && this.slotList[slot].geoJSON.geometry.coordinates != undefined
              && this.slotList[slot].geoJSON.geometry.coordinates[0] == r0
              && this.slotList[slot].geoJSON.geometry.coordinates[1] == c0) {
              this.lockerSlotsGrid[r0][c0] = this.slotList[slot]
            }
          }
          c0 += 1;
        }
        r0 += 1;
      }
      this.setButtonFunctions();
    },
    onRefresh() {
      this.getLockerData();
      this.getSlotListByLocker(this.displaySlotsLocker);
      this.slotFilter = "";
    },
    displaySlotsDataTable() {
      $("#slotsDataTable").DataTable().clear()
      for (let i in this.slotList) {
        $("#slotsDataTable").DataTable().rows.add([{
          slotId: this.slotList[i].id,
          slotEmplacement: this.slotList[i].parent,
          slotLocation: this.slotList[i].parent,
          slotLabel: this.slotList[i].label,
          slotType: this.slotList[i].type,
          slotStatus: this.slotList[i].variables.analog.estado.value,
          slotCommunicationDateTime: (this.slotList[i].communicationDateTime != undefined && this.slotList[i].communicationDateTime != "") ? new Date(this.slotList[i].communicationDateTime) : "",
          slotGeoJSON: this.slotList[i].geoJSON,
          slotToken: this.slotList[i].token
        }]);
      }
      $("#slotsDataTable").DataTable().draw();
      this.setButtonFunctions();
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
      if ($("#slotsDataTable_paginate").get()[0] != undefined) $("#slotsDataTable_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
    },
    async toggleNewSlotModal() {
      if (this.display.newSlotModal) {
        if (this.$route.params.currentDisplay === 'slots' || this.targetLockerId == '') {
          await this.getBundleListByCompany();
          this.displaySlotsDataTable();
        }
        else {
          await this.getLockerData();
          await this.getSlotListByLocker();
          this.displaySlotsDataTable();
          this.variableHistory = {};
          await this.getLockerVariableHistory();
        }
      }
      this.display.newSlotModal = !this.display.newSlotModal;
    },
    async toggleEditSlotModal() {
      if (this.display.editSlotModal) {
        if (this.$route.params.currentDisplay === 'slots' || this.targetLockerId == '') {
          await this.getBundleListByCompany();
          this.displaySlotsDataTable();
        }
        else {
          await this.getLockerData();
          await this.getSlotListByLocker();
          this.displaySlotsDataTable();
          this.variableHistory = {};
          await this.getLockerVariableHistory();
        }
      }
      this.display.editSlotModal = !this.display.editSlotModal;
    },
    async toggleLinkSlotModal() {
      if (this.display.linkSlotModal) {
        if (this.$route.params.currentDisplay === 'slots' || this.targetLockerId == '') {
          await this.getBundleListByCompany();
          this.displaySlotsDataTable();
        }
        else {

          await this.getLockerData();
          await this.getSlotListByLocker();
          this.displaySlotsDataTable();
          this.variableHistory = {};
          await this.getLockerVariableHistory();
        }
      }
      this.display.linkSlotModal = !this.display.linkSlotModal;
    },
    async toggleDeleteSlotModal() {
      if (this.display.deleteSlotModal) {
        if (this.$route.params.currentDisplay === 'slots' || this.targetLockerId == '') {
          await this.getBundleListByCompany();
          this.displaySlotsDataTable();
        }
        else {
          await this.getLockerData();
          await this.getSlotListByLocker();
          this.displaySlotsDataTable();
          this.variableHistory = {};
          await this.getLockerVariableHistory();
        }
        if (this.slotList[0] != undefined) this.targetSlotId = this.slotList[0].id;
      }
      this.display.deleteSlotModal = !this.display.deleteSlotModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetSlot").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetSlot);
        });
        $(".btnEditSlot").get().forEach(btn => {
          btn.addEventListener('click', this.onEditSlot);
        });
        $(".btnOpen").get().forEach(btn => {
          btn.addEventListener('click', this.onForceSlot);
        });
        $(".btnLinkSlot").get().forEach(btn => {
          btn.addEventListener('click', this.onLinkSlot);
        });
        $(".btnUnlinkSlot").get().forEach(btn => {
          btn.addEventListener('click', this.onUnlinkSlot);
        });
        $(".btnDeleteSlot").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteSlot);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getBundleListByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getBundleListByCompany(request);
      this.slotList = api.parseBundlesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.slotList);
    },
    async getSlotListByLocker(callback) {
      let request = {
        lockerId: this.targetLockerId,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getSlotListByLocker(request);
      this.slotList = api.parseBundlesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.slotList);
    },
    async getLockerData(callback) {
      let response = await api.getLockerData(this.targetLockerId);
      this.targetLockerData = api.parseMachineFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetLockerData);
    },
    async getLockerVariableHistory(callback) {
      if (this.targetLockerData != {}) for (let variableType in this.targetLockerData.variables) {
        for (let variable in this.targetLockerData.variables[variableType]) {
          if (this.targetLockerData.variables[variableType][variable] == undefined || this.targetLockerData.variables[variableType][variable].saveHistory != "true") continue;
          let request = {
            id: this.targetLockerData.id,
            variableLabel: this.targetLockerData.variables[variableType][variable].label,
            variableType: this.targetLockerData.variables[variableType][variable].type,
            limit: 0,
            offset: 0,
            endDateTime: api.parseDateTimeToString(new Date()),
            startDateTime: api.parseDateTimeToString(new Date().setDate(new Date().getDate() - 1)),
          };
          let response = await api.getMachineVariableHistory(request);
          let rawHistory = api.parseVariableHistoryFromResponse(response);
          if (this.targetLockerData.variables && this.targetLockerData.variables[variableType] && this.targetLockerData.variables[variableType][variable]) {
            this.variableHistory[this.targetLockerData.variables[variableType][variable].label] = [];
          }


          for (let i in rawHistory) {
            this.variableHistory[this.targetLockerData.variables[variableType][variable].label][i] = [
              // DATE
              this.$dayjs(new Date(rawHistory[i][0])).utc("z").local().tz(this.session.user.dateTimeZone).$d,
              // VALUE
              new Number(rawHistory[i][1]).toFixed(this.fractionalLength)
            ];
          }
          if (callback && typeof callback === "function") callback(this.deviceList);
        }
      }
    },
    async getLockersDataByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getLockerListByCompany(request);
      this.lockerList = api.parseLockersFromResponse(response);
      if (callback && typeof callback === "function") callback(this.lockerList);
    },
    // TARGET 
    onTargetSlot(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetSlotId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetSlotId } })
    },
    //UPDATE
    emitTargetLockerId(event) {
      const newValue = event.target.value;
      this.$emit('updateTargetLockerId', newValue);
    },
    // SET 
    onEditSlot(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetSlotId = event.target.getAttribute('data-id');
      this.$router.replace({ params: { targetEntity: this.targetSlotId } })
      this.toggleEditSlotModal();
    },
    // LINK 
    onLinkSlot(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetSlotId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetSlotId } })
      this.linkAction = "link"
      this.toggleLinkSlotModal();
    },
    // UNLINK 
    onUnlinkSlot(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetSlotId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetSlotId } })
      this.linkAction = "unlink"
      this.toggleLinkSlotModal();
    },
    // DELETE
    async onDeleteSlot(event) {
      if (!this.display.deleteSlotModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetSlotId);
        this.targetSlotId = event.target.getAttribute('data-id');
        this.$router.replace({ params: { targetEntity: this.targetSlotId } })
      }
      else {
        let response = await api.deleteSlot(this.targetSlotId);
        if (response["status-code"] === "200") {
          window.alert("El hueco ha sido eliminado satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el hueco.");
        }
      }
      await this.toggleDeleteSlotModal();
    }
  },
  async created() {
    await this.getLockersDataByCompany();
    if (this.$route.params.currentDisplay === 'slots') {
      await this.getBundleListByCompany();
    }
    else if (this.targetLockerId != '') {
      await this.getLockerData();
      await this.getSlotListByLocker();
      this.variableHistory = {};
      await this.getLockerVariableHistory();
    }
    this.$emit('getAvailableCompanies', this.onChangeDisplayType(this.displayType));
    if (this.slotList[0] != undefined && this.targetSlotId === "")
      this.targetSlotId = this.slotList[0].id;
  },
  mounted() {
    $("#slotsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.slotsColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btonCopyId:hover,
.btnTargetSlot:hover,
.btnOpen:hover,
.btnLinkSlot:hover,
.btnUnlinkSlot:hover,
.btnEditSlot:hover,
.btnDeleteSlot:hover {
  color: var(--main-dark) !important;
}
</style>