<template>
  <!-- KPIS SECTION -->
  <div class="col-lg-12 col-md-12 col-12 m-0 p-2 overflow-show justify-content-center">
    <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 mb-1">
      <div class="position-sticky col-12 m-0 pt-2 pb-2 text-dark" style="border-bottom:solid 1px #000;">
        <ion-icon name="alert-circle-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
        {{
          (requestedIncidenceLabel ? requestedIncidenceLabel : this.dictionary["Todas las incidencias"][this.session.user.language])
          + " " +
          (requestedSearch.StartDate ?
            (this.dictionary["De"][this.session.user.language] + " " +
              this.$dayjs(requestedSearch.StartDate).format('DD/MM/YYYY') + " " +
              this.dictionary["A"][this.session.user.language] + " " +
              this.$dayjs(requestedSearch.EndDate).format('DD/MM/YYYY')) :
            (this.dictionary["De"][this.session.user.language] + " " +
              this.$dayjs().subtract(7, 'day').format('DD/MM/YYYY') + " " +
              this.dictionary["A"][this.session.user.language] + " " +
              this.$dayjs(displayDates.formattedToday).format('DD/MM/YYYY'))
          )
        }}
      </div>

      <!-- KPI Row -->
      <div class="row m-2 p-2">
        <div class="col-lg-3 col-md-6 col-12 p-2 pt-0">
          <div class="kpi-card bg-light text-center p-1 rounded shadow">
            <span class="kpi-title tx-14">{{ this.dictionary["Total de incidencias abiertas"][this.session.user.language] +
        ":" }}</span>
            <p class="kpi-value tx-24 mt-2 tx-bold">{{ totalOpenIncidences }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 p-2 pt-0">
          <div class="kpi-card bg-light text-center p-1 rounded shadow">
            <span class="kpi-title tx-14">{{ this.dictionary["Tiempo promedio de resolución (días)"][this.session.user.language]+ ": "}}</span>
            <p class="kpi-value tx-24 mt-2 tx-bold">{{ avgResolutionTime }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 p-2 pt-0">
          <div class="kpi-card bg-light text-center p-1 rounded shadow">
            <span class="kpi-title tx-14">{{ this.dictionary["Porcentaje de incidencias cerradas"][this.session.user.language]+ ": "}}</span>
            <p class="kpi-value tx-24 mt-2 tx-bold">{{ closedPercentage }}%</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 p-2 pt-0">
          <div class="kpi-card bg-light text-center p-1 rounded shadow">
            <span class="kpi-title tx-14">{{ this.dictionary["Porcentaje de incidencias abiertas"][this.session.user.language]+ ": "}}</span>
            <p class="kpi-value tx-24 mt-2 tx-bold">{{ openPercentage }}%</p>
          </div>
        </div>

      </div>
    </div>

    <!-- CHART INCIDENCES -->
    <div
      class="col-12 table-responsive bg-caja m-0 p-0 mb-3 shadow"
      style="overflow: hidden;">
      <div class="row m-0 p-0">
        <div class="col-lg-9 col-md-12 p-2">
          <!-- Buttons above the table -->
          <div class="text-center">
            <div class="row justify-content-center p-3 pt-0 pb-2">
              <button @click="toggleFilter('0')" type="button"
                :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('0') ? 'btn-primary-active btn-custom-primary' : 'btn-custom-primary']">
                {{ dictionary["Pendiente de revisión"][session.user.language] }}
              </button>
              <button @click="toggleFilter('1')" type="button"
                :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('1') ? 'btn-secondary-active btn-custom-secondary' : 'btn-custom-secondary']">
                {{ dictionary["En investigación"][session.user.language] }}
              </button>
              <button @click="toggleFilter('2')" type="button"
                :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('2') ? 'btn-success-active btn-custom-success' : 'btn-custom-success']">
                {{ dictionary["En proceso de resolucion"][session.user.language] }}
              </button>
              <button @click="toggleFilter('3')" type="button"
                :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('3') ? 'btn-danger-active btn-custom-danger' : 'btn-custom-danger']">
                {{ dictionary["Solucionada"][session.user.language] }}
              </button>
              <button @click="toggleFilter('4')" type="button"
                :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('4') ? 'btn-warning-active btn-custom-warning' : 'btn-custom-warning']">
                {{ dictionary["Cerrada"][session.user.language] }}
              </button>
            </div>
          </div>
          <div class="table-responsive p-2">
            <table id="IncidencesDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class=" tx-14 text-white text-center overflow-hidden gradient-tablas">
                <tr>
                  <th v-for="column in incidenceColumns" :key="column.data" :width="column.width"
                    class="fw-medium text-nowrap p-2">
                    {{ dictionary[column.text][session.user.language] }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 mt-2 pt-0 ps-2 pe-2">
          <VueEcharts :option="lineChartOptions" id="Chart1Canvas" class="col-12 border border-1 mb-2 p-2"
            style="height: 280px;">
          </VueEcharts>
          <VueEcharts :option="pieChartOptions" id="Chart2Canvas" class="col-12 border border-1 p-2 mb-2"
            style="height: 240px;">
          </VueEcharts>
        </div>
      </div>
    </div>
  </div>

  <!--MODAL Edit Incidence-->
  <EditHistoricModal v-if="display.editHistoricModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
    :incidenceId="targetIncidenceId" :incidenceInfo="targetIncidence"
    v-on:toggleEditHistoricIncidenceModal="toggleEditHistoricIncidenceModal">
  </EditHistoricModal>
  <!--MODAL Add Comments-->
  <EditCommentsModal v-if="display.editCommentsModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
    :incidenceId="targetIncidenceId" :incidenceInfo="targetIncidence"
    v-on:toggleEditCommentsModal="toggleEditCommentsModal">
  </EditCommentsModal>



</template>

<script>
import $ from 'jquery';
import EditHistoricModal from '@/components/incidences/EditHistoricModal';
import EditCommentsModal from '@/components/incidences/EditCommentsModal'
// Clomos
import api from '@/services/api';
// Import charts
import { VueEcharts } from 'vue3-echarts';

export default {
  name: "HistoricIncidencesComponent",
  components: { EditHistoricModal, EditCommentsModal, VueEcharts },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    requestedIncidenceId: {
      type: String,
      requiered: true,
    },
    requestedIncidenceLabel: {
      type: String,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    },
    incidenceTypes: {
      type: Object,
      requiered: true,
    },
    requestedSearch: {
      type: Object,
      requiered: true,
    }
  },
  watch: {
    currentCompany: async function () {

      await this.getIncidenceList();
      this.displayIncidencesDataTable();
    },
    requestedSearch: async function (newVal) {

      await this.getIncidenceList(newVal);
      this.displayIncidencesDataTable();
    },
    incidenceTypes: async function () {
      await this.getIncidenceList();
      this.displayIncidencesDataTable();
    }
  },
  data() {
    return {
      incidenceColumns: [
        {
          text: "", data: "IncidenceHistoricId", width: "5%", className: " align-middle",
          render: (data) => {
            return "<ion-icon name='chevron-down-circle-outline' data-id=" + data + " class='btnDetailIncidence btn tx-24 text-black tx-primary  p-0 m-0'></ion-icon>";
          }
        },
        {
          text: "Incidencia", data: "IncidenceLabel", width: "10%", className: " align-middle",
          render: (data) => {
            return data;
          }
        },
        {
          text: "Estado",
          data: "IncidenceHistoricStatus",
          width: "10%",
          className: "tx-left align-middle",
          render: (data) => {
            let text;
            let color;

            switch (data) {
              case "0":
                text = this.dictionary["Pendiente de revisión"][this.session.user.language];
                color = "#3498db";
                break;
              case "1":
                text = this.dictionary["En investigación"][this.session.user.language];
                color = "#2ecc71";
                break;
              case "2":
                text = this.dictionary["En proceso de resolucion"][this.session.user.language];
                color = "#e67e22";
                break;
              case "3":
                text = this.dictionary["Solucionada"][this.session.user.language];
                color = "#e74c3c";
                break;
              case "4":
                text = this.dictionary["Cerrada"][this.session.user.language];
                color = "#f39c12";
                break;
              default:
                text = data.status;
                color = "black";
            }

            return `<span style="color: ${color};">${text}</span>`;
          }
        },

        {
          text: "Nivel de gravedad", data: "IncidenceLevel", width: "7%", className: "tx-left align-middle",
          render: (data) => {
            switch (data) {
              case "0":
                return "<div value='0' class='row'>" +
                  "<span style='opacity: 0;'>" + 0 + "</span>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: greenyellow; '></div>" +
                  "</div>";
              case "1":
                return "<div value='1' class='row'>" +
                  "<span style='opacity: 0;'>" + 1 + "</span>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: green; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: green; '></div>" +
                  "</div>";
              case "2":
                return "<div value='2' class='row'>" +
                  "<span style='opacity: 0;'>" + 2 + "</span>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: orange; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: orange; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: orange; '></div>" +
                  "</div>";
              case "3":
                return "<div value='3' class='row'>" +
                  "<span style='opacity: 0;'>" + 3 + "</span>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                  "</div>";
              case "4":
                return "<div value='4' class='row'>" +
                  "<span style='opacity: 0;'>" + 4 + "</span>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                  "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                  "</div>";
              default:
                return data.status;
            }


          }
        }, {
          text: "Fecha apertura", data: "openingDate", width: "10%", className: "tx-left align-middle",
          render: (data) => {

            return (api.parseDateUserTimezone(data, this.session.user, this.$dayjs))
          }
        },

        {
          text: "Activo", data: "IncidenceHistoricMachine", width: "7%", className: "tx-left align-middle",
          render: (data) => {
            return (data)

          }
        },
        {
          text: "Actualizar", data: "IncidenceHistoricId", width: "5%", className: " align-middle",
          render: (data) => {
            return ("<ion-icon name='add-circle-outline' data-id=" + data + " class='btnModifyIncidence btn tx-24 text-black m-0 p-0'></ion-icon>"

            )
          }
        },

      ],
      targetIncidenceId: "",
      targetMachine: "",
      incidenceList: [],
      sortedIncidenceList: [],
      alertValue: '',
      bulgeInput: {
        volume: {
          height: 0,
          width: 0,
          depth: 0
        },
        units: 0
      },
      lineChartOptions: {},
      pieChartOptions: {},
      display: {
        newHistoricModal: false,
        editHistoricModal: false,
        editCommentsModal: false
      },
      totalOpenIncidences: "",
      avgResolutionTime: "",
      incidencesByLevel: "",
      closedPercentage: "",
      openPercentage: "",
      selectedStatuses: []

    }
  },
  computed: {
    targetIncidence() {
      for (let i in this.incidenceList) {
        if (this.incidenceList[i].id === this.targetIncidenceId)
          return this.incidenceList[i];
      }
      return {};
    },
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    displayIncidencesDataTable() {
      $("#IncidencesDataTable").DataTable().clear()
      for (let i in this.sortedIncidenceList) {
        $("#IncidencesDataTable").DataTable().rows.add([{
          IncidenceHistoricId: this.sortedIncidenceList[i].id,
          IncidenceHistoricStatus: this.sortedIncidenceList[i].status,
          IncidenceLevel: this.sortedIncidenceList[i].level,
          IncidenceHistoricMachine: this.sortedIncidenceList[i].label ? this.sortedIncidenceList[i].label : "-",
          openingDate: this.sortedIncidenceList[i].dateOpen,
          IncidenceLabel: this.sortedIncidenceList[i].incidenceLabel? this.sortedIncidenceList[i].incidenceLabel : this.sortedIncidenceList[i].idIncidence
        }]);
      }
      $("#IncidencesDataTable").DataTable().draw();
      this.setButtonFunctions();
      if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },

    async toggleEditHistoricIncidenceModal() {
      if (this.display.editHistoricModal) {
        await this.getIncidenceList();
        
      }
      this.display.editHistoricModal = !this.display.editHistoricModal;
    },
    async toggleEditCommentsModal() {
      if (this.display.editCommentsModal) {
        await this.getIncidenceList();
        
      }
      this.display.editCommentsModal = !this.display.editCommentsModal;
    },
    displayDates() {
      const today = new Date();
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(today.getDate() - 7);
      today.setHours(23, 59, 59, 0);
      sevenDaysAgo.setHours(0, 0, 0, 0);
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
      };

      const formattedToday = formatDate(today);
      const formattedSevenDaysAgo = formatDate(sevenDaysAgo);

      let dates = {
        formattedToday,
        formattedSevenDaysAgo
      };
      
      return dates;
    },
    displayChart() {
      function generateDateRange(startDate, endDate) {
        const dates = [];
        let currentDate = new Date(startDate);
        const lastDate = new Date(endDate);

        while (currentDate <= lastDate) {
          dates.push(currentDate.toISOString().split('T')[0]); // Obtiene solo la parte de la fecha
          currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
      }

      // Formatear fechas
      const formatDate = (dateTime) => {
        return new Date(dateTime).toISOString().split('T')[0];
      };

      // Establecer fechas de inicio y fin
      let startDate, endDate;
      if (this.requestedSearch.StartDate && this.requestedSearch.EndDate) {
        // Si hay fechas en requestedSearch
        startDate = formatDate(this.requestedSearch.StartDate);
        endDate = formatDate(this.requestedSearch.EndDate);
      } else {
        // Usar fechas predeterminadas si no hay fechas en requestedSearch
        startDate = this.$dayjs(this.displayDates.formattedToday).subtract(7, 'day').format('YYYY-MM-DD');
        endDate = this.$dayjs(this.displayDates.formattedToday).format('YYYY-MM-DD');
      }

      const dateOpenCounts = this.incidenceList.reduce((acc, item) => {
        const date = formatDate(item.dateOpen);
        if (date >= startDate && date <= endDate) {
          acc[date] = (acc[date] || 0) + 1;
        }
        return acc;
      }, {});

      const fullDateRange = generateDateRange(startDate, endDate);
      const completeDateOpenCounts = fullDateRange.map(date => {
        return {
          date: date,
          count: dateOpenCounts[date] || 0
        };
      });

      const dates = completeDateOpenCounts.map(item => item.date);
      const counts = completeDateOpenCounts.map(item => item.count);


      this.lineChartOptions = {
        title: {
          text: `${this.dictionary["Incidencias abiertas por dia"][this.session.user.language]}`,
          left: 'center',
          textStyle: {
            fontSize: '14'
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            return `${params[0].name}: ${params[0].value}`;
          }
        },
        grid: {
            top: "15%",
            right: "5%",
            bottom: "3%",
            left: "5%",
            containLabel: true
        },
        xAxis: {
          type: 'category',
          data: dates,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: counts,
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#5470c6',
            },
            itemStyle: {
              color: '#5470c6',
            },
          },
        ],
      };


      const statusCounts = this.incidenceList.reduce((acc, item) => {
        acc[item.status] = (acc[item.status] || 0) + 1;
        return acc;
      }, {});


      this.pieChartOptions = {
        title: {
          text: `${this.dictionary["Estado de incidencias"][this.session.user.language]}`,
          left: 'center',
          textStyle: {
            fontSize: '14'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: 'Estado',
            type: 'pie',
            radius: '50%',
            data: [
              { value: statusCounts[0], name: this.dictionary["Pendiente de revisión"][this.session.user.language], itemStyle: { color: '#5470c6' } },
              { value: statusCounts[1], name: this.dictionary["En investigación"][this.session.user.language], itemStyle: { color: '#fac858' } },
              { value: statusCounts[2], name: this.dictionary["En proceso de resolucion"][this.session.user.language], itemStyle: { color: '#73c0de' } },
              { value: statusCounts[3], name: this.dictionary["Solucionada"][this.session.user.language], itemStyle: { color: '#ee6666' } },
              { value: statusCounts[4], name: this.dictionary["Cerrada"][this.session.user.language], itemStyle: { color: '#91cc75' } },
            ].filter(item => item.value > 0),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
    }
    ,
    calculateKPIs() {
      // Número total de incidencias abiertas (status no es "3" ni "4")
      const totalOpenIncidences = this.incidenceList.filter(item => item.status !== "3" && item.status !== "4").length;


      // Filtrar incidencias resueltas para calcular el tiempo de resolución
      const resolvedIncidences = this.incidenceList.filter(item => item.dateSolved || item.dateClosed);

      // Calcular el tiempo promedio de resolución (en días)
      const totalResolutionTime = resolvedIncidences.reduce((acc, item) => {
        const openDate = new Date(item.dateOpen);
        const closeDate = new Date(item.dateSolved || item.dateClosed);
        const resolutionTime = (closeDate - openDate) / (1000 * 60 * 60 * 24);
        return acc + resolutionTime;
      }, 0);
      const avgResolutionTime = resolvedIncidences.length > 0 ? totalResolutionTime / resolvedIncidences.length : 0;

      // Distribución de incidencias por nivel
      const incidencesByLevel = this.incidenceList.reduce((acc, item) => {
        acc[item.level] = (acc[item.level] || 0) + 1;
        return acc;
      }, {});

      // Porcentaje de incidencias cerradas vs abiertas
      const closedIncidences = this.incidenceList.filter(item => item.status === "4" || item.status === "3").length;
      const closedPercentage = this.incidenceList.length > 0 ? (closedIncidences / this.incidenceList.length) * 100 : 0;
      const openPercentage = this.incidenceList.length > 0 ? 100 - closedPercentage : 0;


      this.totalOpenIncidences = totalOpenIncidences;
      this.avgResolutionTime = avgResolutionTime.toFixed(2)
      this.incidencesByLevel = incidencesByLevel;
      this.closedPercentage = Math.round(closedPercentage);
      this.openPercentage = Math.round(openPercentage);
    },
    //FILTERS
    toggleFilter(status) {
      const index = this.selectedStatuses.indexOf(status);
      if (index > -1) {
        this.selectedStatuses.splice(index, 1);
      } else {
        this.selectedStatuses.push(status);
      }
      this.filterTablebyStatus();
    },
    filterTablebyStatus() {
      if (this.selectedStatuses.length === 0) {
        this.sortedIncidenceList = this.incidenceList;
      } else {
        this.sortedIncidenceList = this.incidenceList.filter(item =>
          this.selectedStatuses.includes(item.status.toString())
        );
      }
      this.displayIncidencesDataTable();
      
    },
    isActive(status) {
      return this.selectedStatuses.includes(status);
    },
    onAddComents(event) {
      this.targetIncidenceHistoryId = event.target.getAttribute('data-id');
      this.display.editCommentsModal = true;
    },
    onPrint(event) {
      this.targetIncidenceHistoryId = event.target.getAttribute('data-id');
      this.captureAndPrint('resumenPedido'+this.targetIncidenceHistoryId);
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(() => {
        $(".btntargetIncidence").get().forEach(btn => {
          btn.addEventListener('click', this.ontargetIncidence);
        });
        $(".btnDetailIncidence").get().forEach(btn => {
          btn.addEventListener('click', this.onDetaiIncidence)
        });
        $(".btnModifyIncidence").get().forEach(btn => {
          btn.addEventListener('click', this.onModifyIncidence);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
        $(".add-comments").get().forEach(btn => {
          btn.addEventListener('click', this.onAddComents);
        });
        $(".pdf-icon").get().forEach(btn => {
          btn.addEventListener('click', this.onPrint);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getIncidenceList(searchData) {
    try {
      let response;
      if (searchData) {
        response = await api.getHistoricIncidences(searchData);
      } else {
        let dates = this.displayDates();
        const data = {
          incidenceId: this.requestedIncidenceId,
          StartDate: dates.formattedSevenDaysAgo,
          nifCif: this.currentCompany.nifCif,
          EndDate: dates.formattedToday
        };
        response = await api.getHistoricIncidences(data);
      }

      this.incidenceList = api.parseHistoricIncidenceListFromResponse(response);
      this.incidenceList.forEach(incidence => {
        const matchingType = this.incidenceTypes.find(type => type.id === incidence.idIncidence);

        if (matchingType) {
          incidence.idIncidence = matchingType.label;
          incidence.incidenceLabel = matchingType.label;
        }
      });

      this.sortedIncidenceList = this.incidenceList;

      this.displayChart();
      this.calculateKPIs();

      for (const element of this.incidenceList) {
        if (this.requestedIncidenceLabel) element.idIncidence = this.requestedIncidenceLabel;

        if (element.idDcl) {
          try {
            
            let response = await api.getEmplacementData(element.idDcl);

            if (response["status-code"] === "200") {
              const parsed = api.parseEmplacementFromResponse(response);
              element.label = parsed.label;
              continue; 
            }

            
            response = await api.getMachineData(element.idDcl);

            if (response["status-code"] === "200") {
              const parsed = api.parseMachineFromResponse(response);
              element.label = parsed.label;
              continue;
            }

            response = await api.getDeviceData(element.idDcl);

            if (response["status-code"] === "200") {
              const parsed = api.parseDeviceFromResponse(response);
              element.label = parsed.label;
              continue;
            }

            response = await api.getBundleData(element.idDcl);

            if (response["status-code"] === "200") {
              const parsed = api.parseBundleFromResponse(response);
              element.label = parsed.label;
            }

          } catch (error) {
            console.error("Error al obtener datos:", error);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching incidence list:", error);
    }

    this.displayIncidencesDataTable();
  },
    // TARGET 
    ontargetIncidence(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetIncidenceId = event.target.getAttribute('data-id');
      this.targetIncidenceOrder = event.target.getAttribute('data-id');
    },
    // PLAN
    onUpdateAlertValue(value) {
      this.alertValue = value;
    },
    async onModifyIncidence(event) {
      console.log('MODIFY WHERE :');
      console.log(event.target.getAttribute('data-id'));
      this.targetIncidenceId = event.target.getAttribute('data-id');
      this.toggleEditHistoricIncidenceModal();
    },

    // DETAILS
    async onDetaiIncidence(event) {
      console.log('DETAIL WHERE ID:');
      this.targetIncidenceId = event.target.getAttribute('data-id');
     /*  if (!this.targetIncidence.idDcl) return;

      try {
        
        let response = await api.getEmplacementData(this.targetIncidence.idDcl);
        console.log("Respuesta de getEmplacementData:", response);
        if (response["status-code"] === "200") {
          this.targetMachine = api.parseEmplacementFromResponse(response);
         
          return;
        }

        
        response = await api.getMachineData(this.targetIncidence.idDcl);
        console.log("Respuesta de getMachineData:", response);
        if (response["status-code"] === "200") {
          this.targetMachine = api.parseMachineFromResponse(response);
          
          return; 
        }

      
        response = await api.getDeviceData(this.targetIncidence.idDcl);
        console.log("Respuesta de getDeviceData:", response);
        if (response["status-code"] === "200") {
          this.targetMachine = api.parseDeviceFromResponse(response);
        
          return; 
        }


        response = await api.getBundleData(this.targetIncidence.idDcl);
        console.log("Respuesta de getBundleData:", response);
        if (response["status-code"] === "200") {
          this.targetMachine = api.parseBundleFromResponse(response);
        
        }

      } catch (error) {
        console.error("Error al obtener datos:", error);
      } */
      var tr = $(event.target).closest('tr');
      var row = $("#IncidencesDataTable").DataTable().row(tr);
      if (row.child.isShown()) {
        event.target.name = "chevron-down-circle-outline";
        row.child.hide();
        tr.removeClass('shown');
      } else {
        event.target.name = "chevron-up-circle-outline";
        let content = '' +
        '<div id="resumenPedido'+this.targetIncidence.id+'" class="p-1" style="background-color: white!important" data-id="' + this.targetIncidence.id + '">' +
          '<h2>' + this.dictionary["Resumen de la incidencia"][this.session.user.language] + '<ion-icon class= "pdf-icon btn tx-24 tx-primary m-0 ms-3 p-0" name="print-outline" data-id="' + this.targetIncidence.id + '"></ion-icon>' + '</h2>' +
          '<h2 id="incidenceID">' + "Identificador: " + this.targetIncidence.id + '</h2>' +    
        '<div class="container">' +
        '<ol class="progress-meter">';

        const stages = [
          {
            key: 'dateOpen',
            label: `${this.dictionary["Pendiente de revisión"][this.session.user.language]}${this.targetIncidence.dateOpen ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateOpen, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
          },
          {
            key: 'dateInvestigation',
            label: `${this.dictionary["En investigación"][this.session.user.language]}${this.targetIncidence.dateInvestigation ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateInvestigation, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
          },
          {
            key: 'dateProgress',
            label: `${this.dictionary["En proceso de resolucion"][this.session.user.language]}${this.targetIncidence.dateProgress ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateProgress, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
          },
          {
            key: 'dateSolved',
            label: `${this.dictionary["Solucionada"][this.session.user.language]}${this.targetIncidence.dateSolved ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateSolved, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
          },
          {
            key: 'dateClosed',
            label: `${this.dictionary["Cerrada"][this.session.user.language]}${this.targetIncidence.dateClosed ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateClosed, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
          }
        ];

        stages.forEach(stage => {
          const done = this.targetIncidence[stage.key] !== "";
          content += '<li class="progress-point ' + (done ? 'done' : 'todo') + '">' +
            stage.label + '</li>';
        });

        content += '</ol></div>' + '<div class =row>'
          + '<div class=col-md-6>';

      /*   if (this.targetIncidence.idDcl && !this.targetIncidence.variable) {

          content += '<table style="border-collapse: collapse; width: 100%;">' +
            '<tr>' +
            '<th style="padding:2px; text-align: left; font-size: 1.5rem; background-color: #f2f2f2;" colspan="2">' +
            this.dictionary["Información de la máquina"][this.session.user.language] + '</th>' +
            '</tr>' +
            '<tr style="padding:2px; text-align: left;">' +
            '<td style="padding:2px; text-align: left;">' + this.dictionary["Nombre"][this.session.user.language] + '</td>' +
            '<td style="padding:2px; text-align: left;">' + this.targetMachine.label + '</td>' +
            '</tr>' +
            '<tr style="padding:2px; text-align: left;">' +
            '<td style="padding:2px; text-align: left;">' + this.dictionary["Tipo"][this.session.user.language] + '</td>' +
            '<td style="padding:2px; text-align: left;">' + this.targetMachine.dclTypeId + '</td>' +
            '</tr>' +
            '<tr style="padding:2px; text-align: left;">' +
            '<td style="padding:2px; text-align: left;">' + this.dictionary["Alarma"][this.session.user.language] + '</td>' +
            '<td style="padding:2px; text-align: left;">' + (this.targetMachine.tieneAlarmaActivada === "true" ? this.dictionary["Si"][this.session.user.language] : this.dictionary["No"][this.session.user.language]) + '</td>' +
            '</tr>' +
            '</table>' +
            '</div>' +
            '<div class="col-md-6">' +
            '<table style="border-collapse: collapse; width: 100%;">' +
            '<tr>' +
            '<th> </th>' +
            '</tr>' +
            '<tr>' +
            '<th> </th>' +
            '</tr>' +
            '<tr>' +
            '<th style="padding:2px; text-align: left; font-size: 1rem; " colspan="2">' +
            this.dictionary["Variables"][this.session.user.language] + ":" + '</th>' +
            '</tr>';

          if (this.targetMachine.variables.analog) {
            for (const analog in this.targetMachine.variables.analog) {
              if (Object.hasOwnProperty.call(this.targetMachine.variables.analog, analog)) {
                const value = this.targetMachine.variables.analog[analog].value;
                const unit = this.targetMachine.variables.analog[analog].unit || '';
                const label = this.targetMachine.variables.analog[analog].label || '';

                // Construir el contenido HTML
                content += '<tr style="padding:2px; text-align: left;">' +
                  '<td style="padding:2px; text-align: left;">' + label + '</td>' +
                  '<td style="padding:2px; text-align: left;">' +
                  (value
                    ? (isNaN(parseFloat(value))
                      ? 'Valor no válido'
                      : parseFloat(value) % 1 !== 0
                        ? parseFloat(value).toFixed(localStorage.getItem("ClomosFractionalLength") ? parseInt(localStorage.getItem("ClomosFractionalLength"), 10) : 2)
                        : parseFloat(value)
                    )
                    + " " + unit
                    : '') +
                  '</td>' +
                  '</tr>';
              }
            }
          }
          if (this.targetMachine.variables.digital) {
            for (const digital in this.targetMachine.variables.digital) {
              if (Object.hasOwnProperty.call(this.targetMachine.variables.digital, digital) && this.targetMachine.variables.digital[digital].value) {
                content += '<tr style="padding:2px; text-align: left;">' +
                  '<td style="padding:2px; text-align: left;">' + this.targetMachine.variables.digital[digital].label + '</td>' +
                  '<td style="padding:2px; text-align: left;">' + (this.targetMachine.variables.digital[digital].value === "true" ? this.dictionary["Si"][this.session.user.language] : this.dictionary["No"][this.session.user.language]) + '</td>' +
                  '</tr>';
              }
            }
          }

          if (this.targetMachine.variables.dynamic) {
            for (const dynamic in this.targetMachine.variables.dynamic) {
              if (Object.hasOwnProperty.call(this.targetMachine.variables.dynamic, dynamic) && this.targetMachine.variables.dynamic[dynamic].value) {
                content += '<tr style="padding:2px; text-align: left;">' +
                  '<td style="padding:2px; text-align: left;">' + this.targetMachine.variables.dynamic[dynamic].label + '</td>' +
                  '<td style="padding:2px; text-align: left;">' + this.targetMachine.variables.dynamic[dynamic].value + '</td>' +
                  '</tr>';
              }
            }
          }

          if (this.targetMachine.imgSrc) {
            content += '<tr>' +
              '<th style="padding:2px; text-align: left; font-size: 1rem; " colspan="2">' +
              this.dictionary["Imagen"][this.session.user.language] + ":" + '</th>' +
              '</tr>' +
              '<tr style="padding:2px; text-align: left;">' +
              '<td style="padding:2px; text-align: left;">' + this.targetMachine.imgSrc + '</td>' +
              '</tr>';
          }

          content += '</table>' +
            '</div>';

        } else { */
          content +=
            '<table style="border-collapse: collapse; width: 100%;">' +
            '<tr>' +
            '<th style="padding:2px; text-align: left; font-size: 1.5rem; background-color: #f2f2f2;" colspan="2">' +
            this.dictionary["Información de la incidencia"][this.session.user.language] + '</th>' +
            '</tr>'


          const statusMap = {
            "0": this.dictionary["Pendiente de revisión"][this.session.user.language],
            "1": this.dictionary["En investigación"][this.session.user.language],
            "2": this.dictionary["En proceso de resolucion"][this.session.user.language],
            "3": this.dictionary["Solucionada"][this.session.user.language],
            "4": this.dictionary["Cerrada"][this.session.user.language],

          };
          const levelMap = {
            "0": this.dictionary["Muy baja"][this.session.user.language],
            "1": this.dictionary["Baja"][this.session.user.language],
            "2": this.dictionary["media"][this.session.user.language],
            "3": this.dictionary["Critica"][this.session.user.language],
          }
          const levelDescription = levelMap[this.targetIncidence.level] || this.targetIncidence.level;
          const statusDescription = statusMap[this.targetIncidence.status] || this.targetIncidence.status;
          content += '<tr style="padding:2px; text-align: left;">' +
            '<td style="padding:2px; text-align: left;">' + this.dictionary["Identificador"][this.session.user.language] + '</td>' +
            '<td style="padding:2px; text-align: left;">' + this.targetIncidence.id + '</td>' +
            '</tr>';
          content += '<tr style="padding:2px; text-align: left;">' +
            '<td style="padding:2px; text-align: left;">' + this.dictionary["Estado"][this.session.user.language] + '</td>' +
            '<td style="padding:2px; text-align: left;">' + statusDescription + '</td>' +
            '</tr>';
          content += '<tr style="padding:2px; text-align: left;">' +
            '<td style="padding:2px; text-align: left;">' + this.dictionary["Nivel de gravedad"][this.session.user.language] + '</td>' +
            '<td style="padding:2px; text-align: left;">' + levelDescription + '</td>' +
            '</tr>';
          content += '<tr>' +
            '<td style="padding:2px; text-align: left;">' + this.dictionary["Última actualización"][this.session.user.language] + '</td>' +
            '<td style="padding:2px; text-align: left;">' + api.parseDateUserTimezone(this.targetIncidence.dateNow, this.session.user, this.$dayjs).split(' ')[0]; + '</td>' +
              '</tr>';
          content += '<tr>' +
            '<td style="padding:2px; text-align: left;">' + this.dictionary["Creado por"][this.session.user.language] + ": " + '</td>' +
            '<td style="padding:2px; text-align: left;">' + this.targetIncidence.createdBy + '</td>' +
            '</tr>';
          content += '<tr>' +
            '<td style="padding:2px; text-align: left;">' + this.dictionary["Notificado a"][this.session.user.language] + ": " + '</td>' +
            '<td style="padding:2px; text-align: left;">' + (this.targetIncidence.emailsNotified? this.targetIncidence.emailsNotified: "-") + '</td>' +
            '</tr>' + '</table>' + ' </div>';



     /*    } */

        content += ' </div>' + '<table style="border-collapse: collapse; width: 100%;">' + '<tr>' +
          '<td style="padding:2px; text-align: left;" colspan="2">' +
          '<h2 style="font-size: 1.5rem;">' + this.dictionary["Observaciones"][this.session.user.language] + '<ion-icon class= "add-comments btn tx-24 tx-primary m-0 ms-3 p-0" id="add-comments" name="add-circle-outline" ></ion-icon>' + '</h2>' +
          '<table>' +
          '<tr>' +
          '<th style="padding:2px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Fecha"][this.session.user.language] + '</th>' +
          '<th style="padding:2px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Usuario"][this.session.user.language] + '</th>' +
          '<th style="padding:2px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Comentarios"][this.session.user.language] + '</th>' +
          '</tr>';

        for (let key in this.targetIncidence.description) {
          if (Object.prototype.hasOwnProperty.call(this.targetIncidence.description, key)) {
            let observacion = this.targetIncidence.description[key];
            content += '<tr>' +
              '<td style="padding:2px; text-align: start;">' + api.parseDateUserTimezone(key, this.session.user, this.$dayjs) + '</td>' +
              '<td style="padding:2px; text-align: start;">' + observacion.usuario + '</td>' +
              '<td style="padding:2px; text-align: start;">' + observacion.texto + '</td>' +
              '</tr>';
          }
        }


        content += '</table>' +
          '</td>' +
          '</tr>' +
          '</table>' +
          '</div>' + '</div>';
        row.child(content).show();

        if (this.targetIncidenceId) {
          let detailTable = document.getElementById('detailTable' + this.targetIncidenceId);
          if (detailTable && detailTable.parentNode) {
            detailTable.parentNode.style.padding = 0;
          }
        }
        tr.addClass('shown');
      }
      this.setButtonFunctions();

    },
    captureAndPrint(content) {
      var mywindow = window.open('', 'PRINT', 'height=400,width=600');

      mywindow.document.write('<html><head><title>' + "Impresión de incidencia" + '</title>');
      mywindow.document.write('</head><body >');

      mywindow.document.write(document.getElementById(content).innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();

      this.setButtonFunctions();

      return true;
    },


  },
  async created() {
    await this.getIncidenceList()
    
    //this.$emit('getAvailableCompanies', (this.displayIncidencesDataTable));
    
  },
  mounted() {

    $("#IncidencesDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.incidenceColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        //stateSave: this.dataTableOptions.stateSave,
        order: [[3, 'desc']]
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  },

}
</script>
<style>
.btnCopyId:hover,
.btnDetailIncidence:hover,
.btnModifyIncidence:hover {
  color: var(--main-dark) !important;
}

.container {
  padding-top: 20px;
  width: 900px;
  margin: 0 auto;
}

.progress-meter {
  padding: 0;
}

ol.progress-meter {
  padding-bottom: 9.5px;
  list-style-type: none;
}

ol.progress-meter li {
  display: inline-block;
  text-align: center;
  text-indent: -17px;
  height: 36px;
  width: 17.99%;
  font-size: 12px;
  border-bottom-width: 4px;
  border-bottom-style: solid;
}

ol.progress-meter li:before {
  position: relative;
  float: left;
  text-indent: 0;
  left: -webkit-calc(50% - 9.5px);
  left: -moz-calc(50% - 9.5px);
  left: -ms-calc(50% - 9.5px);
  left: -o-calc(50% - 9.5px);
  left: calc(50% - 9.5px);
}

ol.progress-meter li.done {
  font-size: 12px;
}

ol.progress-meter li.done:before {
  content: "\2713";
  height: 19px;
  width: 19px;
  line-height: 21.85px;
  bottom: -28.175px;
  border: none;
  border-radius: 19px;
}

ol.progress-meter li.todo {
  font-size: 12px;
}

ol.progress-meter li.todo:before {
  content: "\2B24";
  font-size: 17.1px;
  bottom: -26.95px;
  line-height: 18.05px;
}

ol.progress-meter li.done {
  color: black;
  border-bottom-color: yellowgreen;
}

ol.progress-meter li.done:before {
  color: white;
  background-color: yellowgreen;
}

ol.progress-meter li.todo {
  color: silver;
  border-bottom-color: silver;
}

ol.progress-meter li.todo:before {
  color: silver;
}

.tooltip {
  visibility: hidden;
  background-color: #242e47;

  color: #f2f2f2;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  margin-left: 15px;
  position: absolute;
  z-index: 1;
  width: 136px;

  opacity: 0;
  transition: opacity 0.3s;
}

.progress-point:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

#incidenceID {
  visibility: hidden;
}

@media screen and (max-width: 990px) {
  .container {
    width: 100%;
    padding: 10px;
  }

  ol.progress-meter li {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    text-align: left;
    text-indent: 0;
    border-bottom-color: transparent !important;
  }

  ol.progress-meter li:before {
    position: static;
    float: none;
    margin-right: 10px;

  }
}

.btn-custom-primary {
  font-size: 12;
  border: 2px solid #3498db;
  border-left: 10px solid #3498db;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-secondary {
  border: 2px solid #2ecc71;
  border-left: 10px solid #2ecc71;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-success {
  border: 2px solid #e67e22;
  border-left: 10px solid #e67e22;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-danger {
  border: 2px solid #e74c3c;
  border-left: 10px solid #e74c3c;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-warning {
  border: 2px solid #f39c12;
  border-left: 10px solid #f39c12;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}


.btn-custom-primary:hover {
  font-size: 12;
  border-color: #2980b9;
  background-color: #eaf0f8;
}

.btn-custom-secondary:hover {
  border-color: #27ae60;

  background-color: #eaf6e7;
}

.btn-custom-success:hover {
  border-color: #d35400;
  background-color: #f8eae2;
}

.btn-custom-danger:hover {
  border-color: #c0392b;
  background-color: #f8d7da;
}

/* */
.btn-primary-active {
  border-color: #2980b9 !important;
  background-color: #eaf0f8 !important;
  box-shadow: inset 0 0 10px #2980b9;

  font-weight: bold;
  position: relative;
}

.btn-secondary-active {
  border-color: #27ae60 !important;
  background-color: #eaf6e7 !important;
  box-shadow: inset 0 0 10px #27ae60;

  font-weight: bold;
  position: relative;
}

.btn-success-active {
  border-color: #d35400 !important;
  background-color: #f8eae2 !important;
  box-shadow: inset 0 0 10px #d35400;

  font-weight: bold;
  position: relative;
}

.btn-danger-active {
  border-color: #c0392b !important;
  background-color: #f8d7da !important;
  box-shadow: inset 0 0 10px #c0392b;

  font-weight: bold;
  position: relative;
}

.btn-warning-active {
  border-color: #e67e22 !important;
  background-color: #fff3e0 !important;
  box-shadow: inset 0 0 10px #e67e22;

  font-weight: bold;
  position: relative;
}

.btn-primary-active::after,
.btn-secondary-active::after,
.btn-success-active::after,
.btn-danger-active::after,
.btn-warning-active::after {
  content: '✓';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);


}

/* */


/* Estilos para estado focus/activo */
.btn-custom-primary:focus,
.btn-custom-primary:active {
  font-size: 12;
  border-color: #2980b9;
  color: black;
  box-shadow: 0 0 0 0.2rem rgba(41, 128, 185, 0.5);
}

.btn-custom-secondary:focus,
.btn-custom-secondary:active {
  border-color: #27ae60;
  color: black;
  box-shadow: 0 0 0 0.2rem rgba(46, 204, 113, 0.5);
}

.btn-custom-success:focus,
.btn-custom-success:active {
  border-color: #d35400;
  color: black;
  box-shadow: 0 0 0 0.2rem rgba(211, 84, 0, 0.5);
}

.btn-custom-danger:focus,
.btn-custom-danger:active {
  border-color: #c0392b;
  color: black;
  box-shadow: 0 0 0 0.2rem rgba(192, 57, 43, 0.5);
}

.btn-custom-warning:focus,
.btn-custom-warning:active {
  border-color: #e67e22;
  color: black;
  box-shadow: 0 0 0 0.2rem rgba(230, 126, 34, 0.5);
}
</style>