<template>
  <!-- SECTION Companies -->
  <div id="companiesSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
        <div class="row col-lg-12 col-md-12 col-12 table-responsive m-0 p-0 pb-3">
          <div class="position-sticky col-12 m-0 pt-2 pb-2 bg-head text-white border-1">
            <div class="row p-0 m-0">
              <!--Primera sección-->
              <div class="row col-xl-5 col-lg-6 col-md-12 col-12 m-0 pt-0 ps-3 pe-4 text-white rounded-5 rounded-end">
                <div class="col m-0 pt-0 ps-0 text-white d-flex flex-row">
                  <h6 class="tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 d-flex flex-row">
                    <ion-icon name="location" class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ dictionary["Empresas"][session.user.language] }}
                  </h6>
                  <select :value="currentCompany.nifCif" class="form-control m-0 p-0 text-nowrap bg-white border"
                    style="border: solid 2px white ; color: black; min-width:200px; max-width:400px; height: 34px;"
                    @change="onCurrentCompanyChanged">
                    <option v-if="availableCompanies[0] != undefined && availableCompanies.length > 1" value="showAll">
                      {{
                        dictionary['Mostrar todo'][session.user.language] }} </option>
                    <option v-for="company in availableCompanies" :key="company.nifCif" :v-if="company.nifCif != session.user.nifCif
                      && company.relation != undefined
                      && company.relation.referenceCompany === this.session.user.nifCif" :value="company.nifCif">
                      {{ (company.nifCif === session.user.nifCif) ? "✪ " + company.name : company.name }}
                    </option>
                  </select>
                </div>

              </div>
              <!-- Fin Primera sección-->
              <div class="row col-xl-7 col-lg-6 col-md-9 col-12 justify-content-end p-0 m-0 pe-3">
                <div class="row col-lg-8 col-md-8 col-8 justify-content-end">
                  <span class="text-end text-white mt-1 mb-2 pe-4">
                    <ion-icon name="briefcase-outline" class="tx-16 mb-1 me-1 align-bottom"></ion-icon>
                    {{ dictionary["Empresas relacionadas"][session.user.language] }}
                  </span>
                </div>
                
                <button id="btnDisplayNewUserModal" style="width: 24px; max-height: 24px;"
                  :title="dictionary['Añadir empresa'][session.user.language]"
                  class="button-ok bg-white mt-0 me-2 rounded shadow  " @click="toggleNewCompanyModal">
                  <span>
                    <span class="button-text text-dark ">
                      <ion-icon name="add-circle-outline" class="text-dark pt-0 me-0 tx-16 hydrated"></ion-icon>
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 shadow">
          <div class="m-0 p-3 overflow-auto">
            <table id="companyDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                <tr>
                  <th v-for="column in companiesColumns" :key="column.data" :width="column.width"
                    class="tx-medium fw-medium text-nowrap">
                    {{ dictionary[column.text][session.user.language] }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>


    </div>
  </div>

  <!-- MODAL Edit Company -->
  <NewCompanyModal v-if="display.newCompanyModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" v-on:toggleNewCompanyModal="toggleNewCompanyModal">
  </NewCompanyModal>

  <!-- MODAL New Company -->
  <EditCompanyModal v-if="display.editCompanyModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :companyId="targetCompanyId" :editRequest="editRequest"
    v-on:toggleEditCompanyModal="toggleEditCompanyModal">
  </EditCompanyModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal v-if="display.deleteCompanyModal" :session="session" :dictionary="dictionary" :countries="countries"
    :alertHeader="dictionary['Empresa a eliminar'][session.user.language] + ': '" :alertTarget="targetCompanyId"
    v-on:acceptAlert="onDeleteCompany" v-on:dismissAlert="toggleDeleteCompanyModal">
  </TextAlertModal>

</template>

<script>
import $ from 'jquery';
import NewCompanyModal from '@/components/companies/NewCompanyModal';
import EditCompanyModal from '@/components/companies/EditCompanyModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "CompaniesComponent",
  components: { NewCompanyModal, EditCompanyModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    }
  },
  watch: {
    currentCompany: async function () {
      await this.getCompanyList();
      this.displayCompaniesDataTable();
    }
  },
  data() {
    return {
      companiesColumns: [
        {
          text: "NIF/CIF", data: "companyNifCif", width: "10%", className: "tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id=" + data + " class='btnCopyId btn tx-18 tx-clomos-midtone'></ion-icon>"
              + "<span data-id=" + data + " class='btnShowCompany c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>" + data + "</span>";
          }
        },
        { text: "Nombre", data: "companyName", width: "20%", className: "tx-left align-middle dt-nowrap" },
        { text: "Dirección", data: "companyAdress", width: "20%", className: "tx-left align-middle" },
        {
          text: "Sector", data: "companySector", width: "20%", className: "tx-left align-middle dt-nowrap",
          render: (data) => {
            return data.charAt(0).toUpperCase() + data.slice(1)
          }
        },
        {
          text: "Relación", data: "companyRelation", width: "20%", className: "tx-left align-middle dt-nowrap",
          render: (data) => {
            let ref = "";
            let dest = "";
            if (this.availableCompanies.length > 0) {
              for (let company in this.availableCompanies) {
                if (this.availableCompanies[company].nifCif === data.reference)
                  ref = this.availableCompanies[company].name;
                if (this.availableCompanies[company].nifCif === data.destination)
                  dest = this.availableCompanies[company].name;
              }
              return ref + "<span class='tx-primary text-nowrap'> ➜ </span>" + dest;
            } else {
              return data.reference + "<span class='tx-primary text-nowrap'> ➜ </span>" + data.destination;
            }
          }
        },
        {
          text: "Editar", data: "companyId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='create-outline' data-id=" + data + " class='btnEditCompany btn tx-18 tx-primary m-auto p-auto'></ion-icon>"
          }
        },
        {
          text: "Eliminar", data: "companyId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id=" + data + " class='btnDeleteCompany btn tx-18 tx-danger m-auto p-auto'></ion-icon>"
          }
        }
      ],
      targetCompanyId: this.$route.params.targetEntity,
      companyList: [],
      display: {
        newCompanyModal: false,
        editCompanyModal: false,
        deleteCompanyModal: false,
      }
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    displayCompaniesDataTable() {
      $("#companyDataTable").DataTable().clear()
      for (let i in this.companyList) {
        $("#companyDataTable").DataTable().rows.add([{
          companyId: this.companyList[i].nifCif,
          companyNifCif: this.companyList[i].nifCif,
          companyName: this.companyList[i].name,
          companyAdress: this.companyList[i].adress,
          companySector: this.companyList[i].sector,
          companyRelation: { reference: this.companyList[i].relation.referenceCompany, destination: this.companyList[i].relation.destinationCompany }
        }]);
      }
      $("#companyDataTable").DataTable().draw();
      this.setButtonFunctions();
      if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },
    async toggleNewCompanyModal() {
      if (this.display.newCompanyModal) {
        await this.getCompanyList();
        this.$emit('getAvailableCompanies', (this.displayCompaniesDataTable));
      }
      this.display.newCompanyModal = !this.display.newCompanyModal;
    },
    async toggleEditCompanyModal() {
      if (this.display.editCompanyModal) {
        await this.getCompanyList();
        this.$emit('getAvailableCompanies', (this.displayCompaniesDataTable));
        this.editRequest = false;
      }
      this.display.editCompanyModal = !this.display.editCompanyModal;
    },
    async toggleDeleteCompanyModal() {
      if (this.display.deleteCompanyModal) {
        await this.getCompanyList();
        this.$emit('getAvailableCompanies', (this.displayCompaniesDataTable));
      }
      this.display.deleteCompanyModal = !this.display.deleteCompanyModal;
    },
    // BUTTONS 
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnShowCompany").get().forEach(btn => {
          btn.addEventListener('click', this.onShowCompany);
        });
        $(".btnEditCompany").get().forEach(btn => {
          btn.addEventListener('click', this.onEditCompany);
        });
        $(".btnDeleteCompany").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteCompany);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getCompanyList(callback) {
      if (this.currentCompany.nifCif === "" || this.currentCompany.nifCif === undefined) return;
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getCompanyList(request);
      this.companyList = api.parseCompaniesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.companyList);
    },
    // TARGET 
    onTargetCompany(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetCompanyId = event.target.getAttribute('data-id');
      this.$router.replace({ params: { targetEntity: this.targetCompanyId } });
    },
    // SHOW
    onShowCompany(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetCompanyId = event.target.getAttribute('data-id');
      this.$router.replace({ params: { targetEntity: this.targetCompanyId } });
      this.editRequest = false;
      this.toggleEditCompanyModal();
    },
    // SET
    onEditCompany(event) {
      console.log('SET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetCompanyId = event.target.getAttribute('data-id');
      this.$router.replace({ params: { targetEntity: this.targetCompanyId } });
      this.editRequest = true;
      this.toggleEditCompanyModal();
    },
    // DELETE
    async onDeleteCompany(event) {
      if (!this.display.deleteCompanyModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetCompanyId = event.target.getAttribute('data-id');
        this.$router.replace({ params: { targetEntity: this.targetCompanyId } });
      }
      else {
        let response = await api.deleteCompany(this.targetCompanyId);
        console.log(response);
      }
      this.toggleDeleteCompanyModal();
    }
  },
  async created() {
    await this.getCompanyList();
    this.$emit('getAvailableCompanies', (this.displayCompaniesDataTable))
  },
  mounted() {
    $("#companyDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.companiesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btnShowCompany:hover,
.btnCopyId:hover,
.btnDeleteCompany:hover,
.btnEditCompany:hover {
  color: var(--main-dark) !important;
}
</style>