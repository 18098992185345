<template>
    <!-- MODAL create condition -->
    <div id="historicIncidenceModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
        <div class="col-12 m-0 p-0 section-container rounded-5 shadow">
            <!-- HEAD -->
            <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
                <div class="col-10">
                    <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
                        <ion-icon name="warning-outline" class="tx-18 me-2 align-bottom"></ion-icon>
                        {{ dictionary['Crear condiciones'][session.user.language] }}
                    </h6>
                </div>
                <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleCreateConditionModal')">
                    <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
                </div>
            </div>
            <div class="bg-caja col-12 p-0 w-100 rounded-bottom-5 pe-3">
                <div style="overflow: scroll; overflow-x: hidden; max-height: 70vh;">
                    <div class="card-body">
                        <div class="track">
                            <div :class="['step', currentStep >= 1 ? 'active' : '']">
                                <span class="icon"><i class="fa fa-check"></i>1</span>
                                <span class="text">{{ dictionary["Seleccione un activo"][session.user.language]
                                    }}</span>
                            </div>
                            <div :class="['step', currentStep >= 2 ? 'active' : '']">
                                <span class="icon"><i class="fa fa-user"></i>2</span>
                                <span class="text">{{ dictionary["Añadir condición"][session.user.language] }}</span>
                            </div>
                            <div :class="['step', currentStep >= 3 ? 'active' : '']">
                                <span class="icon"><i class="fa fa-box"></i>3</span>
                                <span class="text">{{ dictionary["Confirmar condición"][session.user.language] }}</span>
                            </div>
                        </div>

                        <form class="row col-12 p-1 m-0 mt-5 border rounded justify-content-center form-material">
                            <!-- Paso 1: Seleccionar Activo -->
                            <div v-if="currentStep === 1" class="col-5">
                                <div class="form-group col-12 p-1 mb-5">
                                    <label class="col-12 tx-13 text-dark text-nowrap">
                                        <p class="tx-16">{{ dcl.length > 0 ?  dictionary["Activo seleccionado"][session.user.language]:dictionary["Seleccione un activo"][session.user.language] }}</p>
                                    </label>
                                    <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark tx-bold">
                    {{ dictionary['Tipo'][session.user.language] + ": " }}
                  </label>
                  <div class="col-12">
                    <select v-model="parentType" class="form-control form-control-line" style="color:black;"
                      @change="onChangeParentType">
                      <option v-if="this.$route.params.currentModule === 'devices'
                        || this.$route.params.currentModule === 'electricalMonitoring'" value="device">
                        {{ dictionary['Dispositivo'][session.user.language] }}
                      </option>
                      <option v-if="this.$route.params.currentModule === 'fabric'
                        " value="bundle">
                        {{ dictionary['Conjunto'][session.user.language] }}
                      </option>
                      <option v-if="this.$route.params.currentModule === 'fabric'
                        || this.$route.params.currentModule === 'smartLockers'
                        || this.$route.params.currentModule === 'electricalMonitoring'" value="machine">
                         {{ this.$route.params.currentModule === 'smartLockers'? dictionary['Locker'][session.user.language] : this.$route.params.currentModule === 'electricalMonitoring'? dictionary['Centro'][session.user.language] : dictionary['Máquina'][session.user.language] }}
                      </option>
                    <!--   <option v-if="this.$route.params.currentModule === 'devices'
                        || this.$route.params.currentModule === 'fabric'
                        || this.$route.params.currentModule === 'storage'
                        || this.$route.params.currentModule === 'smartLockers'
                        || this.$route.params.currentModule === 'electricalMonitoring'" value="emplacement">
                        {{ dictionary['Emplazamiento'][session.user.language] }}
                      </option> -->
                    </select>
                  

                 <!--  <label v-if="parentType === 'device'" class="col-12 m-0 p-0 tx-bold tx-14">
                    {{ dictionary['Dispositivo'][session.user.language] + ": " }}
                  </label>
                  <input v-if="parentType === 'device'" type="text" v-model="searchQuery" placeholder="Buscar"
                    class="form-control mt-2 mb-2 text-dark" />
                  <select v-if="parentType === 'device'" class="form-control form-control-line"
                    style="color:black; line-height:" v-model="targetEntityId">
                    <option v-for="device in filteredDevices" :key="device" :value="device.id"
                      :selected="device.id === targetEntityId">
                      {{ device.label }}
                    </option>
                  </select> -->

                  <span v-if="parentType === 'bundle'" class="col-12 m-0 p-0 tx-bold tx-14">
                    {{ dictionary['Conjuntos'][session.user.language] + ": " }}
                  </span>
                  <input v-if="parentType === 'bundle'" type="text" v-model="searchQuery" placeholder="Buscar"
                    class="form-control mt-2 mb-2 text-dark" />
                  <select v-if="parentType === 'bundle'" class="form-control form-control-line"
                    style="color:black; line-height:" v-model="targetEntityId">
                    <option v-for="bundle in filteredBundles" :key="bundle" :selected="bundle.id === targetEntityId"
                      :value="bundle.id">
                      {{ bundle.label }}
                    </option>
                  </select>

                  <span v-if="parentType === 'machine'" class="col-12 m-0 p-0 tx-bold tx-14">
                    {{ this.$route.params.currentModule === 'smartLockers'? dictionary['Lockers'][session.user.language] : this.$route.params.currentModule === 'electricalMonitoring'? dictionary['Centros'][session.user.language] : dictionary['Máquinas'][session.user.language]+ ": "  }}
                  </span>
                  <input v-if="parentType === 'machine'" type="text" v-model="searchQuery" placeholder="Buscar"
                  class="form-control mt-2 mb-2 text-dark" />
                  <select v-if="parentType === 'machine'" id="selectTargetDevice" class="form-control form-control-line"
                    style="color:black; line-height:" v-model="targetEntityId">
                    <option v-for="machine in filteredMachines" :key="machine.id" :value="machine.id"
                      :selected="machine.id === targetEntityId">
                      {{ machine.label }}
                    </option>
                  </select>

                  <span v-if="parentType === 'emplacement'" class="col-12 m-0 p-0 tx-bold tx-14">
                    {{ dictionary['Emplazamiento'][session.user.language] + ": " }}
                  </span>
                  <input v-if="parentType === 'emplacement'" type="text" v-model="searchQuery" placeholder="Buscar"
                    class="form-control mt-2 mb-2 text-dark" />
                  <select v-if="parentType === 'emplacement'" class="form-control form-control-line"
                    style="color:black; line-height:" v-model="targetEntityId">
                    <option v-for="emplacement in filteredEmplacements" :key="emplacement"
                      :selected="emplacement.id === targetEntityId" :value="emplacement.id">
                      {{ emplacement.label }}
                    </option>
                  </select>
                </div>
            </div>
                                </div>
                            </div>

                            <!-- Paso 2: Añadir Condición -->
                            <div v-if="currentStep === 2" class="row">
                                <div class="form-group col-6 p-1">
                                    <label class="col-12 tx-13 text-dark text-nowrap">
                                        {{ dictionary['Nombre condición'][session.user.language] }}
                                    </label>
                                    <div class="col-12">
                                        <input class="form-control" type="text" style="color:black;" id="nameCondition"
                                            v-model="newCondition.name" :disabled="newCondition.editedCondition" />
                                    </div>
                                </div>
                                <div class="form-group col-6 p-1">
                                    <label class="col-12 tx-13 text-dark text-nowrap">
                                        {{ dictionary['Seleccione variable'][session.user.language] }}
                                    </label>
                                    <div class="col-12">
                                        <select v-model="newCondition.selectedVariable" id="variableId"
                                            class="form-control form-control-line" style="color:black;">
                                            <option value="" disabled>{{ dictionary['Seleccione una opción'][session.user.language] }}</option>
                                            <option v-for="item in variableList" :key="item.variableId" :value="item">
                                                {{ item.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div v-if="isDigitalVariable" class="form-group col-lg-12 p-1">
                                    <label class="col-12 tx-13 text-dark text-nowrap">
                                        {{ dictionary['Es activa'][session.user.language] }}
                                    </label>
                                    <div class="col-12">
                                        <select v-model="newCondition.isActiveWhen" id="inputNewIncidence"
                                            class="form-control form-control-line" style="color:black;">
                                            <option value="true">{{ dictionary['Si'][session.user.language] }}</option>
                                            <option value="false">{{ dictionary['No'][session.user.language] }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div v-if="isAnalogVariable" class="form-group col-lg-6 p-1">
                                    <label class="col-12 tx-13 text-dark text-nowrap">
                                        {{ dictionary['Límite superior'][session.user.language] }}
                                    </label>
                                    <div class="col-12">
                                        <input class="form-control" type="number" style="color:black;" id="upperLimit"
                                            v-model="newCondition.upperLimit" />
                                    </div>
                                </div>
                                <div v-if="isAnalogVariable" class="form-group  col-lg-6 p-1">
                                    <label class="col-12 tx-13 text-dark text-nowrap">
                                        {{ dictionary['Límite inferior'][session.user.language] }}
                                    </label>
                                    <div class="col-12">
                                        <input class="form-control" type="number" style="color:black;" id="lowerLimit"
                                            v-model="newCondition.lowerLimit" />
                                    </div>
                                </div>
                                <div class="form-group col-lg-6 p-1">
                                    <label class="col-12 tx-13 text-dark text-nowrap">
                                        {{ dictionary['Alarma'][session.user.language] }}
                                    </label>
                                    <div class="col-12">
                                        <select v-model="newCondition.alarm" id="inputNewIncidence"
                                            class="form-control form-control-line" style="color:black;">
                                            <option value="true">{{ dictionary['Si'][session.user.language] }}</option>
                                            <option value="false">{{ dictionary['No'][session.user.language] }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group col-lg-6 p-1"
                                    :class="{ 'disabled-div': !newCondition.alarm || newCondition.alarm === 'false' }">
                                    <label class="col-12 tx-13 text-dark text-nowrap">
                                        {{ dictionary['Alarma Silenciada'][session.user.language] }}
                                    </label>
                                    <div class="col-12">
                                        <select v-model="newCondition.silencedAlarm" id="inputNewIncidence"
                                            class="form-control form-control-line" style="color:black;"
                                            :disabled="!newCondition.alarm || newCondition.alarm === 'false'">
                                            <option value="true">{{ dictionary['Si'][session.user.language] }}</option>
                                            <option value="false">{{ dictionary['No'][session.user.language] }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row justify-content-center p-2">
                                    <div class="form-group col-6 p-1">
                                        <label class="col-12 tx-13 text-dark text-nowrap">
                                            {{ dictionary['Seleccione un tipo de incidencia (si procede)'][session.user.language] }}
                                        </label>
                                        <div class="col-12">
                                            <select v-model="newCondition.selectedIncidence" id="incidenceId"
                                                class="form-control form-control-line" style="color:black;">
                                                <option value="">Ninguna</option>
                                                <option v-for="item in incidenceList" :key="item.id" :value="item">
                                                    {{ item.label }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-6">
                                        <label class="col-12 tx-13 ps-4 text-dark text-nowrap">
                                            <p class="tx-16 text-decoration-underline">{{ dictionary['Tiempo de activacion'][session.user.language] }}</p>
                                        </label>
                                        <!-- DIAS -->
                                        <div class="form-group col-md-12 col-12 p-1">

                                            <div class="col-12">
                                                <input v-model="newCondition.activationTime.days"
                                                    id="inputactivationTimeDays" type="number" min=0
                                                    class="form-control form-control-line" style="color:black;"
                                                    :placeholder="dictionary['Dias'][session.user.language]">
                                            </div>
                                        </div>

                                        <!-- MINUTOS -->
                                        <div class="form-group col-md-12 col-12 p-1">

                                            <div class="col-12">
                                                <input v-model="newCondition.activationTime.minutes"
                                                    :placeholder="dictionary['minutos'][session.user.language]"
                                                    id="inputactivationTimeMinutes" type="number" min=0
                                                    class="form-control form-control-line" style="color:black;">
                                            </div>
                                        </div>

                                        <!-- SEGUNDOS -->
                                        <div class="form-group col-md-12 col-12 p-1">

                                            <div class="col-12">
                                                <input v-model="newCondition.activationTime.seconds"
                                                    id="inputactivationTimeSeconds" type="number" min=0
                                                    class="form-control form-control-line" style="color:black;"
                                                    :placeholder="dictionary['Segundos'][session.user.language]">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <label class="col-12 tx-13 ps-4 text-dark text-nowrap">
                                            <p class="tx-16 text-decoration-underline">{{ dictionary['Tiempo de postactivacion'][session.user.language] }}</p>
                                        </label>
                                        <!-- DIAS -->
                                        <div class="form-group col-md-12 col-12 p-1">

                                            <div class="col-12">
                                                <input v-model="newCondition.postActivationTime.days"
                                                    id="inputPostActivationTimeDays" type="number" min=0
                                                    class="form-control form-control-line" style="color:black;"
                                                    :placeholder="dictionary['Dias'][session.user.language]">
                                            </div>
                                        </div>

                                        <!-- MINUTOS -->
                                        <div class="form-group col-md-12 col-12 p-1">

                                            <div class="col-12">
                                                <input v-model="newCondition.postActivationTime.minutes"
                                                    :placeholder="dictionary['minutos'][session.user.language]"
                                                    id="inputPostActivationTimeMinutes" type="number" min=0
                                                    class="form-control form-control-line" style="color:black;">
                                            </div>
                                        </div>

                                        <!-- SEGUNDOS -->
                                        <div class="form-group col-md-12 col-12 p-1">

                                            <div class="col-12">
                                                <input v-model="newCondition.postActivationTime.seconds"
                                                    id="inputPostActivationTimeSeconds" type="number" min=0
                                                    class="form-control form-control-line" style="color:black;"
                                                    :placeholder="dictionary['Segundos'][session.user.language]">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="form-group col-lg-12 p-1 pt-3">
                                    <button type="button" id="btnSubmitCreateNewLocking"
                                        class="button-ok tx-14 tx-clomos-dark rounded-3 text-center"
                                        :class="view ? 'bg-clomos' : 'bg-primary'" @click="addCondition"
                                        style="vertical-align: middle; width: 80%; height: 2.2rem;">
                                        {{ !newCondition.editedCondition ?
                                            dictionary['Validar'][session.user.language] : dictionary['Actualizar'][session.user.language]
                                        }}
                                    </button>
                                </div>
                            </div>

                            <!-- Paso 3: Confirmar Condición -->
                            <div v-if="currentStep === 3" class="row">
                                <div class="form-group col-12 p-1 ps-3 pe-3">
                                    <p class="col-12 tx-13 text-dark text-nowrap">
                                        {{ dictionary['Confirmar detalles y enviar'][session.user.language] }}
                                    </p>
                                    <!-- Mostrar detalles a confirmar -->
                                    <p>{{ dictionary['Activo seleccionado'][session.user.language] + ': ' +
                                        getActiveLabel(newCondition.dcl) }}</p>
                                    <table class="table table-striped col-lg-12 p-1">
                                        <thead>
                                            <tr>
                                                <th scope="col">{{ dictionary['Condicion'][session.user.language] }}
                                                </th>
                                                <th scope="col">{{ dictionary['Variable'][session.user.language] }}</th>
                                                <th scope="col">{{ dictionary['Es activa'][session.user.language] }}
                                                </th>
                                                <th scope="col">{{ dictionary['Límite superior'][session.user.language]
                                                    }}</th>
                                                <th scope="col">{{ dictionary['Límite inferior'][session.user.language]
                                                    }}</th>
                                                <th scope="col">{{ dictionary['Tiempo de activacion'][session.user.language] }}</th>
                                                <th scope="col">{{ dictionary['Tiempo de postactivacion'][session.user.language] }}</th>
                                                <th scope="col">{{ dictionary['Incidencia'][session.user.language] }}
                                                </th>
                                                <th scope="col">{{ dictionary['Alarma'][session.user.language] }}</th>
                                                <th scope="col">{{ dictionary['Alarma Silenciada'][session.user.language] }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="condition in conditions" :key="condition.name">
                                                <td class="text-break">{{ condition.name }}</td>
                                                <td>{{ condition.selectedVariable.label }}</td>
                                                <td>{{
                                                    condition.isActiveWhen === 'true' ? dictionary['Si'][session.user.language]:dictionary['No'][session.user.language]
                                                    }}</td>
                                                <td>{{ typeof condition.upperLimit === 'number' ? condition.upperLimit :
                                                    (condition.upperLimit !== '' ? condition.upperLimit : '-') }}</td>
                                                <td>{{ typeof condition.lowerLimit === 'number' ? condition.lowerLimit :
                                                    (condition.lowerLimit !== '' ? condition.lowerLimit : '-') }}</td>
                                                <td>{{ condition.activationTime.total + "sec" }}</td>
                                                <td>{{ condition.postActivationTime.total + "sec" }}</td>
                                                <td>{{ condition.selectedIncidence ? condition.selectedIncidence.label :
                                                    '-'}}</td>
                                                <td>{{ condition.alarm
                                                    === 'true' ? dictionary['Si'][session.user.language]:dictionary['No'][session.user.language]}}
                                                </td>
                                                <td>{{ condition.silencedAlarm
                                                    === 'true' ? dictionary['Si'][session.user.language]:dictionary['No'][session.user.language]}}
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </form>

                        <!-- Resumen listado -->
                        <div class="row justify-content-center" v-if="conditions.length > 0 && currentStep === 2">
                            <div class="col-12 m-0 p-0 mt-2">
                                <label class=" col-12 m-0 p-0 tx-bold tx-clomos-dark text-nowrap">
                                    <p class="tx-16 text-decoration-underline m-0">{{ dictionary['Condiciones añadidas'][session.user.language]+ ": " + conditions.length }}</p>
                                </label>
                            </div>

                            <div class=" col-lg-6 col-12 row m-1 p-2 border" v-for="(condition, index) in conditions"
                                :key="index">
                                <ul class="m-0 p-0">
                                    <li class="w-100 d-flex">
                                        <div class="form-group col-8 text-start m-0 mt-auto mb-auto p-0">
                                            <span class=" col-12 ">{{ condition.name }}</span>
                                        </div>
                                        <div class="form-group col-2 m-0 mt-auto mb-auto p-0">
                                            <ion-icon name="settings-outline"
                                                class=" tx-dark c-pointer targets-icons m-0 mt-auto mb-auto p-0 border rounded-circle tx-24 text-bold "
                                                style="vertical-align: middle;"
                                                @click="editCondition(condition.name)"></ion-icon>
                                        </div>
                                        <div class="form-group col-2 m-0 mt-auto mb-auto p-0">
                                            <ion-icon name="remove-circle-outline"
                                                class="bg-danger tx-white c-pointer targets-icons m-0 mt-auto mb-auto p-0 border rounded-circle tx-24 text-bold "
                                                style="vertical-align: middle;"
                                                @click="removeCondition(index)"></ion-icon>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <hr>
                        </div>
                        <!-- Botones de Navegación -->
                        <div class="row">
                            <div class="col-12 text-end p-2">
                                <button type="button" class="btn btn-secondary me-3" @click="prevStep"
                                    :disabled="currentStep === 1">
                                    {{ dictionary['Atrás'][session.user.language] }}
                                </button>
                                <button type="button" class="btn btn-success" @click="nextStep"
                                    :disabled="isNextDisabled">
                                    {{ currentStep < 3 ? dictionary['Siguiente'][session.user.language] :
                                        dictionary['Enviar'][session.user.language] }} </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/services/api';
import $ from 'jquery';
export default {
    name: "NewConditionModal",
    props: {
        currentCompany: {
            type: Object,
            required: true,
        },
        session: {
            type: Object,
            required: true,
        },
        dictionary: {
            type: Object,
            required: true,
        },
        dcl: {
            type: String,
        }
    },
    watch: {
        'newCondition.dcl': function () {
            this.targetEntityId=this.newCondition.dcl
           this.onTargetEntity()
        },
        'newCondition.selectedVariable': function (newVal) {
            if (newVal) {
                this.newCondition.variableId = newVal.variableId;
                this.newCondition.variableLabel = newVal.label;
            } else {
                this.newCondition.variableId = '';
                this.newCondition.variableLabel = '';
            }
        },
        filteredMachines(newVal) {
      if (newVal.length > 0) {
        this.targetEntityId = newVal[0].id;
      }
    },
    filteredDevices(newVal) {
      if (newVal.length > 0) {
        this.targetEntityId = newVal[0].id;
      }
    },
    filteredBundles(newVal) {
      if (newVal.length > 0) {
        this.targetEntityId = newVal[0].id;
      }
    },
    filteredEmplacements(newVal) {
      if (newVal.length > 0) {
        this.targetEntityId = newVal[0].id;
      }
    }
    },
    data() {
        return {
            previousDcl: null,
            machineList: [],
            variableList: [],
            incidenceList: [],
            newCondition: {
                dcl: '',
                alarm: 'false',
                details: '',
                name: '',
                isActiveWhen: 'false',
                upperLimit: '',
                lowerLimit: '',
                variableId: '',
                variableLabel: '',
                incidenceId: '',
                incidenceLabel: '',
                selectedVariable: null,
                selectedIncidence: '',
                silencedAlarm: 'false',
                activationTime: {
                    days: '',
                    minutes: '',
                    seconds: '',
                    total: ''
                },
                postActivationTime: {
                    days: '',
                    minutes: '',
                    seconds: '',
                    total: ''
                },
                editedCondition: false,
                selectedEntity: "",

            },
            currentStep: 1,
            conditions: [],
            view: "",
            targetEntityId: "",
            targetEntity: "",
            parentType: "machine",
            searchQuery: "",
            availableDevices: [],
            availableBundles: [],
            availableMachines: [],
            availableEmplacements: [],
        }
    },
    computed: {
        isNextDisabled() {
            if (this.currentStep === 1 && !this.targetEntityId) {
                return true
            } else if (this.currentStep === 2 && this.conditions.length === 0) {
                return true

            } else {
                return false
            }
        },
        isAnalogVariable() {
            const variable = this.variableList.find(v => v.variableId === this.newCondition.variableId);
            return variable && variable.type === 'analog';
        },
        isDigitalVariable() {
            const variable = this.variableList.find(v => v.variableId === this.newCondition.variableId);
            return variable && variable.type === 'digital';
        },
        filteredMachines() {
      if (!Array.isArray(this.availableMachines) || this.availableMachines.length === 0) {
        return [];
      }
      return this.availableMachines.filter(machine => {
        const label = machine.label;
        return typeof label === 'string' && label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchQuery
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });

    },
    filteredDevices() {
      if (!Array.isArray(this.availableDevices) || this.availableDevices.length === 0) {
        return [];
      }
      return this.availableDevices.filter(device => {
        const label = device.label;
        return typeof label === 'string' && label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchQuery
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });

    },
    filteredBundles() {
      if (!Array.isArray(this.availableBundles) || this.availableBundles.length === 0) {
        return [];
      }
      return this.availableBundles.filter(bundle => {
        const label = bundle.label;
        return typeof label === 'string' && label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchQuery
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });


    },
    filteredEmplacements() {
      if (!Array.isArray(this.availableEmplacements) || this.availableEmplacements.length === 0) {
        return [];
      }
      return this.availableEmplacements.filter(emplacement => {
        const label = emplacement.label;
        return typeof label === 'string' && label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchQuery
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });

    },
    },
    mounted() {
        this.previousDcl = this.newCondition.dcl;
    },
    methods: {
        IsDclChanged() {
            if (this.conditions.length > 0) {
                const confirmChange = window.confirm(
                    "Se está cambiando el activo, las condiciones creadas se borrarán. ¿Desea continuar?"
                );

                if (!confirmChange) {
                    this.newCondition.dcl = this.previousDcl;
                } else {
                    this.conditions = [];
                    this.previousDcl = this.newCondition.dcl;
                }
            } else {
                this.previousDcl = this.newCondition.dcl;
            }
        },
        addCondition() {
            if (this.newCondition.name.length < 1) {
                $("#nameCondition").get()[0].classList.add("missing")
                return
            }
            if (this.newCondition.variableId.length < 1) {
                $("#variableId").get()[0].classList.add("missing")
                return
            }
            if (this.isAnalogVariable && (typeof this.newCondition.upperLimit === "string" && typeof this.newCondition.lowerLimit === "string")) {
                window.alert("La condicion debe tener al menos un limite superior o inferior")
                return
            }

            const activationDays = parseFloat(this.newCondition.activationTime.days) || 0;
            const activationMinutes = parseFloat(this.newCondition.activationTime.minutes) || 0;
            const activationSeconds = parseFloat(this.newCondition.activationTime.seconds) || 0;

            if ((activationDays <= 0 && activationMinutes <= 0 && activationSeconds <= 0)) {
                window.alert("Debe ingresar al menos un valor de tiempo de activación mayor que 0 en días, minutos o segundos");

                if (activationDays <= 0) {
                    $("#inputactivationTimeDays").get()[0].classList.add("missing");
                }
                if (activationMinutes <= 0) {
                    $("#inputactivationTimeMinutes").get()[0].classList.add("missing");
                }
                if (activationSeconds <= 0) {
                    $("#inputactivationTimeSeconds").get()[0].classList.add("missing");
                }
                return;
            }

            const postActivationDays = parseFloat(this.newCondition.postActivationTime.days) || 0;
            const postActivationMinutes = parseFloat(this.newCondition.postActivationTime.minutes) || 0;
            const postActivationSeconds = parseFloat(this.newCondition.postActivationTime.seconds) || 0;

            if ((postActivationDays <= 0 && postActivationMinutes <= 0 && postActivationSeconds <= 0)) {
                window.alert("Debe ingresar al menos un valor de tiempo de postactivación mayor que 0 en días, minutos o segundos");

                if (postActivationDays <= 0) {
                    $("#inputPostActivationTimeDays").get()[0].classList.add("missing");
                }
                if (postActivationMinutes <= 0) {
                    $("#inputPostActivationTimeMinutes").get()[0].classList.add("missing");
                }
                if (postActivationSeconds <= 0) {
                    $("#inputPostActivationTimeSeconds").get()[0].classList.add("missing");
                }
                return;
            }

            const activationTotalSeconds = (activationDays * 86400) + (activationMinutes * 60) + activationSeconds;
            this.newCondition.activationTime.total = activationTotalSeconds;

            const postActivationTotalSeconds = (postActivationDays * 86400) + (postActivationMinutes * 60) + postActivationSeconds;
            this.newCondition.postActivationTime.total = postActivationTotalSeconds;
            if (this.newCondition.editedCondition) {
                const index = this.conditions.findIndex(item => item.name === this.newCondition.name);
                if (index !== -1) {
                    this.conditions.splice(index, 1);
                }
            }
            let duplicateCondition = null;
            let duplicateValues = [];
            const isDuplicateCondition = this.conditions.some(condition => {
                let isDuplicate = false;
                duplicateValues = [];
                if (condition.name === this.newCondition.name) {
                    duplicateValues.push(`Nombre: ${condition.name}`);
                    isDuplicate = true;
                }
                if (isDuplicate) {
                    duplicateCondition = condition;
                }
                return isDuplicate;
            });

            if (isDuplicateCondition) {
                const duplicateValuesText = duplicateValues.join(', ');
                window.alert(`Ya existe una condición duplicada con el nombre: ${duplicateCondition.name}. Valores duplicados: ${duplicateValuesText}`);
            } else {
                this.conditions.push({ ...this.newCondition });
                this.newCondition = {
                    dcl: this.newCondition.dcl,
                    alarm: 'false',
                    details: '',
                    name: '',
                    isActiveWhen: 'false',
                    upperLimit: '',
                    lowerLimit: '',
                    variableId: '',
                    variableLabel: '',
                    selectedVariable: null,
                    selectedIncidence: null,
                    silencedAlarm: 'false',
                    activationTime: {
                        days: '',
                        minutes: '',
                        seconds: '',
                        total: ''
                    },
                    postActivationTime: {
                        days: '',
                        minutes: '',
                        seconds: '',
                        total: ''
                    },
                    editedCondition: false
                };
            }
        },
        async getIncidencesByCompany() {
            const url = new URL(window.location.href);
            const pathParts = url.pathname.split('/');
            let request = {
                nifCif: pathParts[2] === this.session.user.nifCif ? this.session.user.nifCif : pathParts[2],
                limit: 0,
                offset: 0,

            };
            let response = await api.getIncidencesByCompany(request);
            this.incidenceList = api.parseIncidenceListFromResponse(response);
        },
        async getMachine() {
            try {
                let response = await api.getMachineData(this.targetEntityId);
                if (response["status-code"] === "200") {
                    let parsed = api.parseMachineFromResponse(response);
                    this.targetEntity= parsed;         
                } 

            } catch (error) {
                console.error(error)
            }

        },
        async getDeviceData() {
            try {
                let response = await api.getDeviceData(this.targetEntityId);
                if (response["status-code"] === "200") {
                    let parsed = api.parseDeviceFromResponse(response);
                    this.targetEntity= parsed;                 
                } 

            } catch (error) {
                console.error(error)
            }

        },
        async getBundleData(){
            try {
                let response = await api.getBundleData(this.targetEntityId);
                if (response["status-code"] === "200") {
                    let parsed = api.parseBundleFromResponse(response);
                    this.targetEntity= parsed;                 
                } 

            } catch (error) {
                console.error(error)
            }

        },
        async getEmplacementData(){
            try {
                let response = await api.getEmplacementData(this.targetEntityId);
                if (response["status-code"] === "200") {
                    let parsed = api.parseEmplacementFromResponse(response);
                    this.targetEntity= parsed;                 
                } 

            } catch (error) {
                console.error(error)
            }
        },
        async onChangeParentType() {
            this.targetEntity = {};
            this.searchQuery = ''
            if (this.parentType === 'device') {
               await this.getDeviceList();

            }
            if (this.parentType === 'bundle') {
                await this.getBundleList();
            }
            if (this.parentType === 'machine') {
                await this.getMachineList();
            }
            if (this.parentType === 'emplacement') {
                await this.getEmplacementList();
            }
            
        },
        async onTargetEntity(){
            if (this.parentType === 'device') {
                await this.getDeviceData();
            }
            if (this.parentType === 'bundle') {
               await this.getBundleData();
            }
            if (this.parentType === 'machine') {
                await this.getMachine();
            }
            if (this.parentType === 'emplacement') {
                await this.getEmplacementData();
            }
            this.getVariables();
        },
        async getDeviceList(callback) {
            let request = {
                nifCif: this.currentCompany.nifCif,
                limit: 0,
                offset: 0,
                where: "",
            };
            let response = await api.getDeviceListByCompany(request);
            let allAvailableDevices = api.parseDevicesFromResponse(response);
            this.availableDevices = [];
            for (let i in allAvailableDevices) {
                if (allAvailableDevices[i].id != this.targetDeviceId) this.availableDevices.push(allAvailableDevices[i])
            }
            if (this.availableDevices[0] != undefined) {
                this.targetEntityId = this.availableDevices[0].id;
                this.targetEntity = this.availableDevices[0];
            }
            if (callback && typeof callback === "function") callback(this.availableDevices);
        },
        async getBundleList(callback) {
            let request = {
                nifCif: this.currentCompany.nifCif,
                limit: 0,
                offset: 0,
                where: "",
            };
            let response = await api.getBundleListByCompany(request);
            this.availableBundles = api.parseBundlesFromResponse(response);
            if (this.availableBundles[0] != undefined) {
                this.targetEntityId = this.availableBundles[0].id;
                this.targetEntity = this.availableBundles[0];
            }
            if (callback && typeof callback === "function") callback(this.availableBundles);
        },
        async getMachineList(callback) {
            let request = {
                nifCif: this.currentCompany.nifCif,
                limit: 0,
                offset: 0,
                where: "",
            };
            let response = await api.getMachineListByCompany(request);
            this.availableMachines = api.parseMachinesFromResponse(response);
            if (this.availableMachines[0] != undefined) {
                this.targetEntityId = this.availableMachines[0].id;
                this.targetEntity = this.availableMachines[0];
            } if (callback && typeof callback === "function") callback(this.availableMachines);
        },
        async getEmplacementList(callback) {
            let request = {
                nifCif: this.currentCompany.nifCif,
                limit: 0,
                offset: 0,
                where: "",
            };
            let response = await api.getEmplacementList(request);
            this.availableEmplacements = api.parseEmplacementsFromResponse(response);
            if (this.availableEmplacements[0] != undefined) {
                this.targetEntityId = this.availableEmplacements[0].id;
                this.targetEntity = this.availableEmplacements[0];
            } if (callback && typeof callback === "function") callback(this.availableEmplacements);
        },


        getVariables() {

            if (this.targetEntity && this.targetEntity.variables) {
                this.variableList = [];
                for (const type in this.targetEntity.variables) {
                    if (type === 'dynamic') continue;

                    const variablesOfType = this.targetEntity.variables[type];
                    for (const variableName in variablesOfType) {
                        const variable = variablesOfType[variableName];
                        this.variableList.push({
                            label: variable.label,
                            variableId: variable.variableId,
                            type: type
                        });
                    }
                }
            } else {
                this.variableList = [];
            }
        },
        async nextStep() {
            if (this.currentStep === 1) {
                await this.onTargetEntity();
                this.currentStep++;
            }
            else if(this.currentStep < 3) {
                this.currentStep++;
                
            }else {
                this.submitCondition();
            }
        },
        prevStep() {
            if (this.currentStep > 1) {
                this.currentStep--;
            }
        },
        async submitCondition() {
            let failedConditions = [];

            for (let condition of this.conditions) {
                try {
                    let response;


                    if (condition.selectedVariable.type === 'analog') {
                        response = await api.postNewAnalogCondition(condition);
                    } else if (condition.selectedVariable.type === 'digital') {
                        response = await api.postNewDigitalCondition(condition);
                    } else {
                        console.error("Condition type not supported");
                        continue;
                    }


                    if (response["status-code"] === "200" || response["status-code"] === "201") {
                        condition.sent = true;
                    } else {
                        condition.sent = false;
                        failedConditions.push(condition);
                    }

                } catch (error) {

                    console.error("Error sending condition:", condition, error);
                    condition.sent = false;
                    failedConditions.push(condition);
                }
            }


            if (failedConditions.length > 0) {
                console.warn("Some conditions failed to send:", failedConditions);
                this.notifyUserOfFailedConditions(failedConditions);
            }


            this.$emit('toggleCreateConditionModal');
        },

        notifyUserOfFailedConditions(failedConditions) {
            let failedList = failedConditions.map(cond => cond.label || cond.name).join(", ");
            alert(`Las siguientes condiciones no se enviaron correctamente: ${failedList}. Por favor, inténtelo de nuevo.`);
        },
        editCondition(conditionName) {

            const conditionToEdit = this.conditions.find(condition => condition.name === conditionName)
            if (conditionToEdit && (this.newCondition.name || this.newCondition.selectedVariable)) {
                const confirmDelete = window.confirm(
                    "Se va a editar la condición " + conditionToEdit.name + ", la condicion que estas creando se borrará. ¿Desea continuar?"
                );
                if (confirmDelete)
                    this.newCondition = {
                        dcl: this.newCondition.dcl,
                        alarm: conditionToEdit.alarm,
                        details: '',
                        name: conditionToEdit.name,
                        isActiveWhen: conditionToEdit.isActiveWhen,
                        upperLimit: conditionToEdit.upperLimit,
                        lowerLimit: conditionToEdit.lowerLimit,
                        selectedVariable: conditionToEdit.selectedVariable,
                        silencedAlarm: conditionToEdit.silencedAlarm,
                        activationTime: {
                            days: conditionToEdit.activationTime.days,
                            minutes: conditionToEdit.activationTime.minutes,
                            seconds: conditionToEdit.activationTime.seconds,
                            total: conditionToEdit.activationTime.total
                        },
                        postActivationTime: {
                            days: conditionToEdit.postActivationTime.days,
                            minutes: conditionToEdit.postActivationTime.minutes,
                            seconds: conditionToEdit.postActivationTime.seconds,
                            total: conditionToEdit.postActivationTime.total
                        },
                        editedCondition: true
                    };

            } else {
                this.newCondition = {
                    dcl: this.newCondition.dcl,
                    alarm: conditionToEdit.alarm,
                    details: '',
                    name: conditionToEdit.name,
                    isActiveWhen: conditionToEdit.isActiveWhen,
                    upperLimit: conditionToEdit.upperLimit,
                    lowerLimit: conditionToEdit.lowerLimit,
                    selectedVariable: conditionToEdit.selectedVariable,
                    selectedIncidence: conditionToEdit.selectedIncidence,
                    silencedAlarm: conditionToEdit.silencedAlarm,
                    activationTime: {
                        days: conditionToEdit.activationTime.days,
                        minutes: conditionToEdit.activationTime.minutes,
                        seconds: conditionToEdit.activationTime.seconds,
                        total: conditionToEdit.activationTime.total
                    },
                    postActivationTime: {
                        days: conditionToEdit.postActivationTime.days,
                        minutes: conditionToEdit.postActivationTime.minutes,
                        seconds: conditionToEdit.postActivationTime.seconds,
                        total: conditionToEdit.postActivationTime.total
                    },
                    editedCondition: true
                };
            }
        },
        removeCondition(index) {
            this.conditions.splice(index, 1);
        },
        getActiveLabel(id) {
            const active = this.machineList.find(item => item.id === id);
            return active ? active.label : '';
        }
    },
    async created() {
        await this.getMachineList();
        await this.getIncidencesByCompany();
    }
}
</script>
<style>
.track {
    position: relative;
    background-color: #ddd;
    height: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 60px;
    margin-top: 50px
}

.track .step {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 25%;
    margin-top: -18px;
    text-align: center;
    position: relative
}

.track .step.active:before {
    background: #abb8cb;
}

.track .step::before {
    height: 7px;
    position: absolute;
    content: "";
    width: 100%;
    left: 0;
    top: 18px
}

.track .step.active .icon {
    background: green;
    color: #fff
}

.track .icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: relative;
    border-radius: 100%;
    background: #ddd
}

.track .step.active .text {
    font-weight: 400;
    color: #000
}

.track .text {
    display: block;
    margin-top: 7px
}

.disabled-div {
    opacity: 0.5;
    pointer-events: none;
}
</style>