<template>
<!-- MODAL New Reception -->
  <div id="newReceptionModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 shadow">
<!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="arrow-redo-outline" class="tx-18 me-2 align-bottom"></ion-icon>
            {{dictionary["Nueva recepción"][session.user.language]}}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleNewReceptionModal')">	
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT Prepare Reception -->
      <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;" class="p-3">
          <div class="col-12 p-3 border rounded form-material" style="overflow: auto; overflow-y: hidden; display: flex; flex-flow: wrap;">

<!-- CONTENT New Reception -->
            <form class="m-0 p-0">
                
              <div class="col-12 m-0 p-0">
                <label class="col-12 m-0 p-0 tx-bold tx-clomos-dark text-nowrap">
                  {{dictionary['Orden'][session.user.language]}}
                </label>
              </div>

              <div class="row m-0 p-0 border-2 rounded">
<!-- PRODUCTO -->
                <div class="form-group col-lg-8 col-12 p-1 ps-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Identificador'][session.user.language] }}
                  </label>
                  <div class="col-12 p-0">
                    <input v-model="targetProduct.id" id="inputSearchProductId" type="text" autocomplete="off"
                    list="productStocks" class="form-control form-control-line ps-0 pe-0" style="color:black;"
                    @change="(event)=>{onTargetProduct(event)}"
                    @input="(event)=>{filterProductList(event)}">
                  </div>
                  <datalist id="productStocks">
                    <option v-for="product in filteredProductList" :key="product"
                    :value="product.id"> 
                      {{ product.label }}
                    </option>
                  </datalist>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Unidades'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="inputNewReceptionAmmount" id="inputNewReceptionAmmount" type="number" min=0
                    class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Catálogo'][session.user.language] }}
                  </label>
                  <div class="col-12 p-0 bg-clomos rounded-3 pt-1 pb-1 btn tx-white"
                  :class="(isRequesting)?'opacity-50':''"
                  @click="(!isRequesting)?getStockList():''">
                    <ion-icon name="reload-outline" class="loader tx-22 align-bottom" :class="(isRequesting===true)?'requesting':'tx-white'"></ion-icon>
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Lote'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="inputNewReceptionBatch" id="inputNewReceptionBatch" type="text" :disabled="!hasBatch"
                    class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Nº de serie'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="inputNewReceptionSerialNumber" id="inputNewReceptionSerialNumber" type="text" :disabled="!hasSerialNumber"
                    class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Talla'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="inputNewReceptionSize" id="inputNewReceptionSize" type="text" :disabled="!hasSize"
                    class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>
                
                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Color'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="inputNewReceptionColor" id="inputNewReceptionColor" type="text" :disabled="!hasColor"
                    class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Caducidad'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="inputNewReceptionExpirationDate" id="inputNewReceptionExpirationDate" type="date" :disabled="!hasExpirationDate"
                    class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-0 pt-2 m-0">
                  <ion-icon name="add-circle-outline" class="tx-clomos-dark targets-icons m-0 p-0 pt-2 tx-36" style="vertical-align: middle;"
                  @click="onAppendProduct()">
                  </ion-icon>
                  <ion-icon name="close-circle-outline" class="tx-clomos-dark targets-icons m-0 p-0 pt-2 tx-36" style="vertical-align: middle;"
                  @click="onClearTarget()">
                  </ion-icon>
                </div>

              </div>

              <div class="row m-0 mt-3 p-0 border-2 rounded" v-for="target in newReceptionData.targetList" :key="target">
<!-- TARGETS -->
                <div class="form-group col-lg-6 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Identificador'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                    {{ target.id }}
                    </div>
                  </div>
                </div>
                
                <div class="form-group col-lg-4 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Nombre'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                    {{ target.label }}
                    </div>
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Unidades'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                    {{ target.ammount }}
                    </div>
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Lote'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1" style="color:black;">
                    {{ (target.batch != undefined) ?(target.batch != "") ?target.batch : "Sin definir" :"-" }}
                    </div>
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Nº de serie'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1" style="color:black;">
                    {{ (target.serialNumber != undefined) ?(target.serialNumber != "") ?target.serialNumber : "Sin definir" :"-" }}
                    </div>
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Talla'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1" style="color:black;">
                    {{ (target.size != undefined) ?(target.size != "") ?target.size : "Sin definir" :"-" }}
                    </div>
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Color'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1" style="color:black;">
                    {{ (target.color != undefined) ?(target.color != "") ?target.color : "Sin definir" :"-" }}
                    </div>
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Caducidad'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1" style="color:black;">
                    {{ (target.expirationDate != undefined) ?(target.expirationDate != "") ?target.expirationDate : "Sin definir" :"-" }}
                    </div>
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 m-0 p-0">
                  <ion-icon name="remove-circle-outline" class="tx-clomos-dark targets-icons m-0 p-0 pt-2 tx-36" style="vertical-align: middle;"
                  @click="this.newReceptionData.targetList.splice(target,1)"></ion-icon>
                </div>

              </div>

              <div class="row">
<!-- SUBMIT -->

                <div class="form-group col-lg-6 col-12 p-0 pt-3 m-0 ms-auto me-auto">
                  <div class="col-md-12">
                    <button type="button" id="btnSubmitCreateNewReception" class="button-ok gradient-tablas rounded-3"
                    @click="setNewReceptionData">
                      {{ dictionary['Crear nueva recepción'][session.user.language] }}
                    </button>
                  </div>
                </div>

              </div>


            </form>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
import logisticProfilesJson from "/src/assets/json/logistic_profiles.json";
// Clomos
import api from '@/services/api';

export default {
  name: "NewReceptionModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    productId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newReceptionData: {
        userId: this.session.user.id,
        targetList: []
      },
      targetProduct: {
        id: "",
        reference: "",
        label: "",
        unit: "",
        logisticProfile: "",
        zone: ""
      },
      availableProductList: [],
      inputNewReceptionBatch: "",
      inputNewReceptionSerialNumber: "",
      inputNewReceptionSize: "",
      inputNewReceptionColor: "",
      inputNewReceptionExpirationDate: "",
      inputNewReceptionAmmount: 0,
      hasBatch: false,
      hasSerialNumber: false,
      hasSize: false,
      hasColor: false,
      hasExpirationDate: false,
      isRequesting: false,
      isFiltering: undefined,
      filteredProductList: [],
      logisticProfiles: JSON.parse(JSON.stringify(logisticProfilesJson))
    }
  },
  emits: ["toggleNewReceptionModal"],
  methods: {
    // GET
    async getStockList() {
      this.isRequesting = true;
      let request = {
        nifCif: "showAll",
        limit: 0,
        offset: 0,  
        where: ""
      }
      let response = await api.getStockTypeList(request);
      this.isRequesting = false;
      this.availableProductList = api.parseStockTypesFromResponse(response);
      localStorage.setItem("AvailableProductList", JSON.stringify(this.availableProductList));
      this.isFiltering = false;
    },
    // SET
    async setNewReceptionData() {
      let valid = true;
      if (this.newReceptionData.targetList.length == 0) {
        $("#inputSearchProductId").get()[0].classList.add("missing")
        $("#inputNewReceptionBatch").get()[0].classList.add("missing")
        $("#inputNewReceptionSerialNumber").get()[0].classList.add("missing")
        $("#inputNewReceptionSize").get()[0].classList.add("missing")
        $("#inputNewReceptionColor").get()[0].classList.add("missing")
        $("#inputNewReceptionExpirationDate").get()[0].classList.add("missing")
        $("#inputNewReceptionAmmount").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputSearchProductId").get()[0].classList.remove("missing")
          $("#inputNewReceptionBatch").get()[0].classList.remove("missing")
          $("#inputNewReceptionSerialNumber").get()[0].classList.remove("missing")
          $("#inputNewReceptionSize").get()[0].classList.remove("missing")
          $("#inputNewReceptionColor").get()[0].classList.remove("missing")
          $("#inputNewReceptionExpirationDate").get()[0].classList.remove("missing")
          $("#inputNewReceptionAmmount").get()[0].classList.remove("missing")
        }, 3000);
        return;
      }
      let response = await api.postNewReception(this.newReceptionData);
      if (response["status-code"] === "200") {
        this.$emit('toggleNewReceptionModal');
        window.alert("La recepción ha sido creada satisfactoriamente.");
      }
      else {
        window.alert("Error al crear la recepción.");
      }
    },
  // FILTER
    filterProductList(event) {
      let targetId = event.target.value;
      this.isFiltering = true;
      this.filteredProductList = [];
      for (let i in this.availableProductList)
        if ( this.availableProductList[i].variables.dynamic.perfilLogistico.value != ""
        && this.availableProductList[i].variables.dynamic.zona.value != ""
        && this.availableProductList[i].variables.dynamic.huecosHabilitados.value != ""
        && (targetId != undefined || this.targetProduct.id === ""))
          this.filteredProductList.push(this.availableProductList[i]);
      this.isFiltering = false;
    },
  // TARGET
    onTargetProduct(event) {
      let targetId = event.target.value;
      this.inputNewReceptionBatch = "";
      this.inputNewReceptionSerialNumber = "";
      this.inputNewReceptionSize = "";
      this.inputNewReceptionColor = "";
      this.inputNewReceptionExpirationDate = "";
      let match = false;
      for (let i in this.availableProductList) {
        if (this.availableProductList[i].variables.dynamic.referencia != undefined
        && this.availableProductList[i].id === targetId) {
          this.targetProduct = {
            id: this.availableProductList[i].id,
            reference: this.availableProductList[i].variables.dynamic.referencia.value,
            label: this.availableProductList[i].label,
            unit: this.availableProductList[i].variables.dynamic.unidad.value,
            logisticProfile: this.availableProductList[i].variables.dynamic.perfilLogistico.value,
            zone: this.availableProductList[i].variables.dynamic.zona.value,
          }
          let variables = {
            batch: false ,
            serialNumber: false ,
            size: false ,
            color: false,
            expirationDate: false 
          }
          for (let i in this.logisticProfiles[this.targetProduct.logisticProfile].variables) {
            variables[this.logisticProfiles[this.targetProduct.logisticProfile].variables[i]] = true;
          }
          this.hasBatch = variables.batch;
          this.hasSerialNumber = variables.serialNumber;
          this.hasSize = variables.size;
          this.hasColor = variables.color;
          this.hasExpirationDate = variables.expirationDate;
        
          match = true;
        }
        if (match) 
          break;
      }
      if (!match || targetId === "") {
        this.hasBatch = false;
        this.hasSerialNumber = false;
        this.hasSize = false;
        this.hasColor = false;
        this.hasExpirationDate = false;
      }
    },
    onClearTarget() {
      this.targetProduct = {
        id: "",
        reference: "",
        label: "",
        unit: "",
        logisticProfile: "",
        zone: ""
      };
      this.inputNewReceptionAmmount = 0;
      this.inputNewReceptionBatch = "";
      this.inputNewReceptionSerialNumber = "";
      this.inputNewReceptionSize = "";
      this.inputNewReceptionColor = "";
      this.inputNewReceptionExpirationDate = "";
      this.hasBatch = false;
      this.hasSerialNumber = false;
      this.hasSize = false;
      this.hasColor = false;
      this.hasExpirationDate = false;
    },
    onAppendProduct() {
      let valid = true;
      if (this.inputNewReceptionAmmount<=0) {
        $("#inputNewReceptionAmmount").get()[0].classList.add("missing");
        valid = false;
      }
      if (this.targetProduct.id==="") {
        $("#inputSearchProductId").get()[0].classList.add("missing");
        valid = false;
      }
      let match= false;
      for (let i in this.availableProductList) {
        if (this.availableProductList[i].id === this.targetProduct.id)
          match = true
        if (match)
          break;
      }
      if (!match) {
        $("#inputSearchProductId").get()[0].classList.add("missing");
      }
      if (!valid || !match) {
        setTimeout(() => {
          $("#inputNewReceptionAmmount").get()[0].classList.remove("missing")
        }, 3000);
        setTimeout(() => {
          $("#inputSearchProductId").get()[0].classList.remove("missing")
        }, 3000);
        return;
      } 
      let target = {
      'id': this.targetProduct.id, 
      'reference': this.targetProduct.reference, 
      'label': this.targetProduct.label, 
      'zone': this.targetProduct.zone,
      'unit': this.targetProduct.unit,
      'logisticProfile': this.targetProduct.logisticProfile,
      'ammount': this.inputNewReceptionAmmount
      }
      if (this.hasBatch) target['batch'] = this.inputNewReceptionBatch;
      if (this.hasSerialNumber) target['serialNumber'] = this.inputNewReceptionSerialNumber;
      if (this.hasExpirationDate) target['expirationDate'] = this.inputNewReceptionExpirationDate;
      if (this.hasSize) target['size'] = this.inputNewReceptionSize;
      if (this.hasColor) target['color'] = this.inputNewReceptionColor;

      this.newReceptionData.targetList.push(target)
    }
  },
  async created() {
    this.availableProductList = (localStorage.getItem("AvailableProductList")!=undefined)?JSON.parse(localStorage.getItem("AvailableProductList")):[];
    await this.getStockList();
  }
}
</script>

<style>

#newReceptionModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.targets-icons:hover {
  color: var(--main-dark-50);
}

#inputSearchProductId.missing, #inputNewReceptionAmmount.missing {
  box-shadow: inset 0px 0px 0px 2px #ce7d0a;
}

@media (max-width: 767px) {
  #stockImgUploadFormContainer {
    flex-direction: column;
  }
}

.loader {
  color: white !important;
  visibility: visible;
  transition: all 0.3s;
}

.requesting {
  animation: spin 3s infinite linear;
  transition: all 0.3s;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>