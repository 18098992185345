<template>
    <!-- SECTION Machines-->
    <div id="machinesSection" class="row col-12 m-0 p-0" v-if="!display.configurationView">
        <div class="row col-12 p-2 m-0 section-container justify-content-center">

            <!-- COMPONENT CommandCenter -->
            <div class="col-lg-12 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
                <div class="col-12 table-responsive rounded-top-5 m-0 p-0 shadow">
                    <div class="row col-12 m-0 pt-2 pb-2 bg-head text-white border-1">
                        <div class="col-10 justify-content-center">
                            <ion-icon
                                :name="this.$route.params.currentDisplay.includes('bundle') ? 'file-tray-stacked' : 'git-branch-outline'"
                                class="tx-16 mb-1 me-1 align-bottom">
                            </ion-icon>
                            <span class="col-12 text-center">
                                {{ (this.$route.params.currentDisplay.includes('bundle') ? 'Conjunto: ' : "Máquina: ") +
                                    ((targetEntityData != undefined) ? targetEntityData.label :
                                        "-") }}
                            </span>
                        </div>
                        <div class="col-2 row justify-content-end">
                            <button id="btnDisplayEditEraseModal"
                                :title="dictionary['Incidencias'][session.user.language]"
                                style="width: 24px; max-height: 24px;"
                                class="button-ok bg-white mt-0 me-2 rounded shadow" @click="scrollToIncidences">
                                <span>
                                    <span class="button-text text-dark">
                                        <ion-icon name="alert-circle-outline" class="text-dark pt-0 me-0 tx-16 hydrated"
                                            :title="dictionary['Incidencias'][session.user.language]"></ion-icon>
                                    </span>
                                </span>
                            </button>
                            <button v-if="targetEntityData && targetEntityData.activeAlarms != '0'"
                                id="btnDisplayEditEraseModal" :title="dictionary['Alarmas'][session.user.language]"
                                style="width: 24px; max-height: 24px;"
                                class="button-ok bg-white mt-0 me-2 rounded shadow" @click="scrollToAlarms">
                                <span>
                                    <span class="button-text text-dark">
                                        <ion-icon name="warning-outline" class="text-dark pt-0 me-0 tx-16 hydrated"
                                            :title="dictionary['Alarmas'][session.user.language]"></ion-icon>
                                    </span>
                                </span>
                            </button>
                            <button v-if="editingPermission && this.$route.params.currentDisplay.includes('machine')"
                                id="btnDisplayEditEraseModal" :title="dictionary['Editar'][session.user.language]"
                                style="width: 24px; max-height: 24px;" class="button-ok bg-white mt-0 me-2  shadow"
                                @click="toggleEditMachineModal">
                                <span>
                                    <span class="button-text text-dark">
                                        <ion-icon name="create-outline" class="text-dark pt-0 me-0 tx-18 hydrated"
                                            :title="dictionary['Editar'][session.user.language]"></ion-icon>
                                    </span>
                                </span>
                            </button>
                            <button v-if="editingPermission && this.$route.params.currentDisplay.includes('bundle')"
                                id="btnDisplayEditEraseModal" :title="dictionary['Editar'][session.user.language]"
                                style="width: 24px; max-height: 24px;" class="button-ok bg-white mt-0 me-2  shadow"
                                @click="toggleEditBundleModal">
                                <span>
                                    <span class="button-text text-dark">
                                        <ion-icon name="create-outline" class="text-dark pt-0 me-0 tx-18 hydrated"
                                            :title="dictionary['Editar'][session.user.language]"></ion-icon>
                                    </span>
                                </span>
                            </button>
                            <button id="btnDisplayEditEraseModal"
                                :title="dictionary['Actualizar'][session.user.language]"
                                style="width: 24px; max-height: 24px;"
                                class="button-ok bg-white mt-0 me-2 rounded shadow" @click="refreshView">
                                <span>
                                    <span class="button-text text-dark">
                                        <ion-icon name="refresh-outline" class="text-dark pt-0 me-0 tx-16 hydrated"
                                            :title="dictionary['Actualizar'][session.user.language]"></ion-icon>
                                        <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                                    </span>
                                </span>
                            </button>
                            <button id="btnDisplayEditEraseModal" :title="dictionary['Volver'][session.user.language]"
                                style="width: 24px; max-height: 24px;"
                                class="button-ok bg-white mt-0 me-2 rounded shadow" @click="this.$router.go(-1)">
                                <span>
                                    <span class="button-text text-dark">
                                        <ion-icon name="return-down-back-outline"
                                            class="text-dark pt-0 me-0 tx-16 hydrated"
                                            :title="dictionary['Volver'][session.user.language]"></ion-icon>
                                        <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                                    </span>
                                </span>
                            </button>

                        </div>
                    </div>
                </div>
                <div class="row col-12 m-0 p-2 bg-caja justify-content-center rounded-bottom-5 shadow">
                    <div class="row col-lg-12 col-xl-12 col-md-12 col-12  pt-2" style="min-height: 200px;">
                        <div class="col-12 bg-caja rounded-5 shadow"
                            style=" border-top: 5px solid rgb(0, 87, 147); border-bottom: 2px solid rgb(0, 141, 180);">

                            <div class="row">
                                <div class="col-12 col-lg-6 col-md-12" style="min-height: 300px;">


                                    <div id="waggonDetails"
                                        class="d-flex flex-wrap m-0 p-2 border-clomos-light bg-real-white">
                                        <img v-if="displayedImage.length > 1" class="col-lg-8 col-12 m-auto p-0"
                                            :src="displayedImage" style="max-height:130px; width: auto;" />
                                        <img v-else class="col-lg-8 col-12 m-auto p-0"
                                            src="@/assets/img/placeholder_image.jpg"
                                            style="max-height:130px; width: auto;" />
                                        <div class="col-lg-5 col-12 m-0 mt-5 
                                        p-1" style="height: auto;">
                                           <!--  <div
                                                class="m-0 mb-1 p-0 d-flex flex-column bg-clomos-white border-clomos-light rounded">
                                                <span class="m-0 mb-0 tx-start tx-bold">
                                                    {{ (dictionary['Datos'][session.user.language] + ": ").toUpperCase()
                                                    }}
                                                </span>
                                            </div> -->

                                            <div class="m-0 p-2 d-flex flex-column ">

                                             <!--    <div class="m-0 p-0 d-flex flex-row">
                                                    <span class="m-auto ms-0 tx-start">
                                                        {{ (dictionary['Nombre'][session.user.language] + ": ") }}
                                                    </span>
                                                    <span class="m-auto me-0 tx-bold ">
                                                        {{ (targetEntityData != undefined) ? targetEntityData.label :
                                                            "-" }}
                                                    </span>
                                                </div> -->

                                                <div class="m-0 p-0 d-flex flex-row">
                                                    <span class="m-auto ms-0 tx-start">
                                                        {{ (dictionary['Tipo'][session.user.language] + ": ") }}
                                                    </span>
                                                    <span class="m-auto me-0 tx-bold ">
                                                        {{ (targetEntityData != undefined) ? targetEntityData.type : "-"
                                                        }}
                                                    </span>
                                                </div>

                                                <div class="m-0 p-0 d-flex flex-row">
                                                    <span class="m-auto ms-0 tx-start">
                                                        {{ (dictionary['Categoría'][session.user.language] + ": ") }}
                                                    </span>
                                                    <span class="m-auto me-0 tx-bold ">
                                                        {{ (targetEntityData != undefined) ? targetEntityData.category :
                                                            "-" }}
                                                    </span>
                                                </div>

                                                <div class="m-0 p-0 d-flex flex-row">
                                                    <span class="m-auto ms-0 tx-start">
                                                        {{ (dictionary['Posición'][session.user.language] + ": ") }}
                                                    </span>
                                                    <span class="m-auto me-0 tx-bold ">
                                                        {{
                                                            targetEntityData?.geoJSON?.geometry?.coordinates
                                                                ? 
                                                                (Number(targetEntityData.geoJSON.geometry.coordinates[0]) === 0
                                                                    ? Number(targetEntityData.geoJSON.geometry.coordinates[0])
                                                                    :
                                                                    Number(targetEntityData.geoJSON.geometry.coordinates[0]).toFixed(2))
                                                                + " / " +
                                                                (Number(targetEntityData.geoJSON.geometry.coordinates[1]) === 0
                                                                    ? Number(targetEntityData.geoJSON.geometry.coordinates[1])
                                                                    :
                                                                    Number(targetEntityData.geoJSON.geometry.coordinates[1]).toFixed(2))
                                                                : ""
                                                        }}
                                                    </span>
                                                </div>

                                            </div>
                                        </div>


                                        <div class="row col-12 m-0 p-0" style="height: 5rem;">
                                            <div v-for="(variable, index) in paddedHistoryVariables" :key="index"
                                                class="col-lg-4 col-md-4 col-12 m-0 p-1" style="height: 5rem;">
                                                <div
                                                    class="m-0 p-0 h-100 d-flex flex-column bg-white border border-info rounded-3">
                                                    <span class="m-auto mb-0 tx-start tx-14">
                                                        {{ variable.label ? (variable.label[0].toUpperCase() +
                                                            variable.label.substring(1)) : "-" }}
                                                    </span>
                                                    <span class="m-auto mt-0 tx-bold">
                                                        {{ variable.value ? variable.value + (variable.unit || "") : "-"
                                                        }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col-lg-6 col-md-6 col-12 m-0 p-1" style="height: 3rem;">
                                            <div @click="scrollToIncidences"
                                                class="m-0 p-0 h-100 d-flex flex-row bg-white border border-info rounded-3">
                                                <span class="m-auto tx-start tx-14">
                                                    {{ (dictionary['Incidencias'][session.user.language] + ": ").toUpperCase()}}
                                                </span>
                                                <div class="icon-container"
                                                    :style="targetEntityData.openedIncidences !== '0' ? 'color: red;' : 'color: gray;'">
                                                    <ion-icon name="ellipse" class="m-auto tx-26"></ion-icon>
                                                    <span v-if="targetEntityData.openedIncidences !== '0'"
                                                        class="alarm-count">
                                                        {{ targetEntityData.openedIncidences }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-12 m-0 p-1" style="height: 3rem;">
                                            <div @click="scrollToAlarms"
                                                class="m-0 p-0 h-100 d-flex flex-row bg-white border border-info rounded-3">
                                                <span class="m-auto tx-start tx-14">
                                                    {{ (dictionary['Alarmas'][session.user.language] + ": ").toUpperCase()}}
                                                </span>
                                                <div class="icon-container"
                                                    :style="targetEntityData.activeAlarms !== '0' ? 'color: red;' : 'color: gray;'">
                                                    <ion-icon name="ellipse" class="m-auto tx-26"></ion-icon>
                                                    <span v-if="targetEntityData.activeAlarms !== '0'"
                                                        class="alarm-count">
                                                        {{ targetEntityData.activeAlarms }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class=" col-12 col-lg-6 col-md-12">
                                    <div class="m-0 p-3 h-auto " id="bundlesTableContent" style=" overflow: scroll; ">
                                        <table id="childrenEntityDataTable"
                                            class="table table-striped p-0 pb-3 bg-caja w-100">
                                            <thead
                                                class="gradient-tablas tx-14 text-white text-center overflow-hidden overflow-x: hidden;">
                                                <tr>
                                                    <th v-for="column in childrenEntityColumns" :key="column.data"
                                                        :width="column.width" class="fw-medium text-nowrap p-2">
                                                        {{
                                                            dictionary[column.text][session.user.language]
                                                        }}
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-lg-12 col-xl-12 col-md-12 col-12 pt-2" style="min-height: 200px;">

                        <div class="col-12 bg-caja rounded-5 shadow"
                            style=" border-top: 5px solid rgb(0, 87, 147); border-bottom: 2px solid rgb(0, 141, 180);">

                            <div class="row justify-content-around">
                                <!-- CARD Variables -->
                                <div class="col-lg-3 col-12 m-0 p-0 pt-3 pe-1" style="min-height: 100%">
                                    <div class="row mb-1 justify-content-around">
                                        <div v-for="variableOptions in statusGaugeChartOptions"
                                            class=" col-md-6 col-lg-4 p-2 col-12 p-2" :key="variableOptions">
                                            <div class="bg-white border border-info rounded-3 pt-2"
                                                style="min-height: 80px;">
                                                <p class="tx-12 mb-0 text-start w-100 text-center">
                                                    <strong>{{
                                                        variableOptions.series[0].data[0].name }} </strong>
                                                </p>
                                                <span
                                                    v-if="variableOptions.series[0].data[0].name === 'Posicion' || variableOptions.series[0].data[0].type === 'digital'">
                                                    {{
                                                        (variableOptions.series[0].data[0].value === 'true' &&
                                                            variableOptions.valueDateTime != "") ?
                                                            dictionary['true'][session.user.language] :
                                                            (variableOptions.series[0].data[0].value === 'false' &&
                                                                variableOptions.valueDateTime != "") ?
                                                                dictionary['false'][session.user.language] :
                                                                variableOptions.valueDateTime != "" ?
                                                                    variableOptions.series[0].data[0].value : "--"

                                                    }}
                                                </span>
                                                <span v-else> {{
                                                    (variableOptions.valueDateTime != "" ?
                                                        variableOptions.series[0].data[0].value :
                                                        "--") +
                                                    variableOptions.series[0].detail.formatter.replace("{value}",
                                                        "")
                                                }}
                                                </span>
                                                <div class="d-flex d-row justify-content-around"
                                                    style="min-height: 20px;">
                                                    <span class="tx-11" style="color: #c5c6c7;">
                                                        {{ (variableOptions.series[0].data[0].name ===
                                                            'Posicion' ||
                                                            variableOptions.series[0].data[0].type ===
                                                            'digital') ? "" :
                                                            !(variableOptions.series[0].min === 0 &&
                                                                variableOptions.series[0].max === 0)
                                                                ? "min:" + variableOptions.series[0].min
                                                                : "" }}
                                                    </span>
                                                    <span class="tx-11" style="color: #c5c6c7;">
                                                        {{ (variableOptions.series[0].data[0].name ===
                                                            'Posicion' ||
                                                            variableOptions.series[0].data[0].type ===
                                                            'digital') ? "" :
                                                            !(variableOptions.series[0].min === 0 &&
                                                                variableOptions.series[0].max === 0)
                                                                ? "max:" + variableOptions.series[0].max
                                                                : "" }}
                                                    </span>
                                                </div>

                                                <span class="tx-11" style="color: #c5c6c7;">
                                                    {{

                                                        (variableOptions.valueDateTime === "" ? "-" :
                                                            this.$dayjs(new
                                                                Date(variableOptions.valueDateTime)).utc("z").local().tz(this.session.user.dateTimeZone).format(this.session.user.dateTimeFormat
                                                                    +
                                                                    " HH:mm"))
                                                    }}
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- CARD Last Day -->
                                <div class="row col-12 col-lg-8  border-start mt-4">
                                    <div class="d-flex d-row justify-content-around align-items-center">
                                        <div class="col-lg-6 col-12">
                                            <select v-model="targetVariableId" id="selectTargetDevice"
                                                data-live-search="true" class="col-12 m-0 ps-1 text-nowrap"
                                                style="padding-bottom: 3px;" @change=changeChartDisplay()>
                                                <option v-for="variable in historyVariables"
                                                    :key="variable['variableId']" :value="variable['variableId']"
                                                    :selected="variable['variableId'] === targetVariableId"
                                                    class="text-dark">
                                                    {{ variable['label'][0].toUpperCase() +
                                                        variable['label'].substring(1) }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-lg-5 col-12 m-0 p-0">
                                            <div class="d-flex m-0 p-0 align-items-center">
                                                <!-- Input group -->
                                                <div class="d-flex align-items-center ">
                                                    <input v-model="startDateTimeInput" id="inputStartDate"
                                                        class=" form-control tx-10 rounded text-nowrap text-dark me-2"
                                                        :type="(session.browser === 'firefox') ? 'date' : 'datetime-local'"
                                                        style="max-width: 120px; max-height: 34px;">
                                                    <input v-model="endDateTimeInput" id="inputEndDate"
                                                        class=" form-control tx-10 rounded text-nowrap text-dark"
                                                        :type="(session.browser === 'firefox') ? 'date' : 'datetime-local'"
                                                        style="max-width: 120px; max-height: 34px;">
                                                </div>

                                                <!-- Icon group -->
                                                <div class="d-flex align-items-start">
                                                    <ion-icon name="refresh-outline"
                                                        class="tx-18 pe-0 pt-1 btn btnSizeChart "
                                                        @click="changeDisplayInfo()">
                                                    </ion-icon>
                                                    <ion-icon name="trash-outline"
                                                        class="tx-18 pe-0 pt-1 btn btnSizeChart"
                                                        @click="chartHistory = {}">
                                                    </ion-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="bg-white pt-3 pb-2 m-0 ">
                                        <VueEcharts :option="chart1Options" id="Chart1Canvas"
                                            class="col-12 w-100 ps-0 pe-0" style="height: 16.25rem;">
                                        </VueEcharts>
                                    </div>
                                    <div v-if="this.deviceData?.variables?.dynamic?.Posicion"
                                        class=" col-md-12 col-12 m-0 ps-4 p-3 pt-0 pb-0 mt-2 mb-3 h-auto ">
                                        <div class="p-0 text-end text-dark rounded"
                                            style="z-index: 11;  position: relative;">
                                            <span class="me-1 bg-white border-2 border-primary p-1"
                                                @click="togglechangeDisplaySize()">
                                                <ion-icon v-if="!fullSize" name="expand-outline"
                                                    class="tx-16 mb-1 align-bottom"></ion-icon>
                                                <ion-icon v-if="fullSize" name="contract-outline"
                                                    class="tx-16 mb-1 align-bottom"></ion-icon>
                                            </span>
                                        </div>
                                        <div id="positionMapContent" class="col-12 bg-caja rounded-5 shadow"
                                            :style="!fullSize ? 'min-height: 14.6rem;' : 'min-height: 30.6rem;'"
                                            style="z-index: 0;  height: auto; margin-top: -20px;">
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div v-if="targetEntityData && targetEntityData.activeAlarms != '0'"
                        class="col-md-12 col-12 row m-0 ps-2 p-3 pt-0 pb-0 mt-2 h-auto" id="alarms">
                        <div class=" col-col-12 bg-caja rounded-5 shadow"
                            style="z-index: 0; height: auto; border-top: 5px solid rgb(0, 87, 147); border-bottom: 2px solid rgb(0, 141, 180);">
                            <h6 class="mt-3">Tabla de Alarmas activas: {{ deviceData.label }}</h6>
                            <div class="row justify-content-around" id="activeAlarms">
                                <div id="tableContent"
                                    class=" mt-2 mb-2 col-lg-4 col-12  table-responsive m-0 p-3  mb-1   overflow-hidden">
                                    <table id="ActiveAlarmsDataTable"
                                        class="col-12 m-0 p-0 dataTables_wrapper no-footer" style="width: 100%;">
                                        <thead class="gradient-tablas tx-14 text-white text-center"
                                            style="position: -webkit-sticky; position: sticky; top: 0; background-color: #343a40; z-index: 1;">
                                            <tr>
                                                <th v-for="column in activeAlarmColumns" :key="column.data"
                                                    :width="column.width" class="fw-medium text-nowrap p-1">
                                                    {{ dictionary[column.text][session.user.language] }}
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div class="m-0 mt-2 mb-2 p-0 col-lg-6 col-12 ">
                                    <VueEcharts :option="activeAlarmLineChartOptions" id="Chart2Canvas"
                                        class="col-12 ps-0 pe-0" style="height: 300px;">
                                    </VueEcharts>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row col-lg-12 col-xl-12 col-md-12 col-12 pt-2" id="incidences">
                        <div class="col-12 bg-caja rounded-5 shadow"
                            style="z-index: 0; height: auto; border-top: 5px solid rgb(0, 87, 147); border-bottom: 2px solid rgb(0, 141, 180);">
                            <h6 class="mt-3">Tabla de Incidencias</h6>
                            <!-- <img src="@/assets/img/incidencias.png" class="img-fluid mt-4"> -->
                            <div class="col-lg-12 col-md-12 p-2">
                                <!-- Buttons above the table -->
                                <div class="text-center">
                                    <div class="row justify-content-center p-3 pt-0 pb-2">
                                        <button @click="toggleFilter('0')" type="button"
                                            :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('0') ? 'btn-primary-active btn-custom-primary' : 'btn-custom-primary']">
                                            {{ dictionary["Pendiente de revisión"][session.user.language] }}
                                        </button>
                                        <button @click="toggleFilter('1')" type="button"
                                            :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('1') ? 'btn-secondary-active btn-custom-secondary' : 'btn-custom-secondary']">
                                            {{ dictionary["En investigación"][session.user.language] }}
                                        </button>
                                        <button @click="toggleFilter('2')" type="button"
                                            :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('2') ? 'btn-success-active btn-custom-success' : 'btn-custom-success']">
                                            {{ dictionary["En proceso de resolucion"][session.user.language]
                                            }}
                                        </button>
                                        <button @click="toggleFilter('3')" type="button"
                                            :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('3') ? 'btn-danger-active btn-custom-danger' : 'btn-custom-danger']">
                                            {{ dictionary["Solucionada"][session.user.language] }}
                                        </button>
                                        <button @click="toggleFilter('4')" type="button"
                                            :class="['col btn btn-sm m-1 mt-0 mb-0', isActive('4') ? 'btn-warning-active btn-custom-warning' : 'btn-custom-warning']">
                                            {{ dictionary["Cerrada"][session.user.language] }}
                                        </button>
                                    </div>
                                </div>
                                <div class="table-responsive p-2">
                                    <table id="IncidencesDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
                                        <thead class=" tx-14 text-white text-center overflow-hidden gradient-tablas">
                                            <tr>
                                                <th v-for="column in incidenceColumns" :key="column.data"
                                                    :width="column.width" class="fw-medium text-nowrap p-2">
                                                    {{ dictionary[column.text][session.user.language] }}
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- MODAL Edit Machine -->
    <EditMachineModal v-if="display.editMachineModal" :session="session" :dictionary="dictionary" :countries="countries"
        :timeZones="timeZones" :currentCompany="currentCompany" :machineId="entityId"
        v-on:toggleEditMachineModal="toggleEditMachineModal">
    </EditMachineModal>

    <!--MODAL Edit Incidence-->
    <EditHistoricModal v-if="display.editHistoricModal" :session="session" :dictionary="dictionary"
        :countries="countries" :timeZones="timeZones" :currentCompany="currentCompany"
        :availableCompanies="availableCompanies" :incidenceId="targetIncidenceId" :incidenceInfo="targetIncidence"
        v-on:toggleEditHistoricIncidenceModal="toggleEditHistoricIncidenceModal">
    </EditHistoricModal>
    <!--MODAL Add Comments-->
    <EditCommentsModal v-if="display.editCommentsModal" :session="session" :dictionary="dictionary"
        :countries="countries" :timeZones="timeZones" :currentCompany="currentCompany"
        :availableCompanies="availableCompanies" :incidenceId="targetIncidenceId" :incidenceInfo="targetIncidence"
        v-on:toggleEditCommentsModal="toggleEditCommentsModal">
    </EditCommentsModal>
    <EditBundleModal v-if="display.editBundleModal" :session="session" :dictionary="dictionary" :countries="countries"
        :timeZones="timeZones" :currentCompany="currentCompany" :targetMachineId="''" :bundleId="entityId"
        v-on:toggleEditBundleModal="toggleEditBundleModal">
    </EditBundleModal>

</template>



<script>
import { VueEcharts } from 'vue3-echarts';
import echartsOptionsJson from '@/assets/json/echarts_options.json';
import $ from 'jquery';
import EditHistoricModal from '@/components/incidences/EditHistoricModal';
import EditCommentsModal from '@/components/incidences/EditCommentsModal'
import EditMachineModal from '@/components/machines/EditMachineModal';
import EditBundleModal from '@/components/bundles/EditBundleModal';
// Clomos
import api from '@/services/api';

export default {
    name: "MachineComponent",
    components: { VueEcharts, EditBundleModal, EditHistoricModal, EditCommentsModal, EditMachineModal },
    props: {
        session: {
            type: Object,
            required: true,
        },
        dictionary: {
            type: Object,
            required: true,
        },
        countries: {
            type: Object,
            required: true,
        },
        timeZones: {
            type: Object,
            requiered: true,
        },
        visibility: {
            type: Object,
            required: true,
        },
        currentCompany: {
            type: Object,
            required: true,
        },
        availableCompanies: {
            type: Object,
            required: true,
        },
        dataTableOptions: {
            type: Object,
            required: true,
        },
        typesByCompany: {
            type: Object,
            required: true,
        }
    },
    watch: {
        '$route.params.targetEntity': async function (newTargetEntity) {
            if (newTargetEntity) {
                this.entityId = newTargetEntity;
                await this.getMachineData();
                this.displaychildrenEntityDataTable();
                await this.getIncidencesByCompany();
                await this.getHistoricIncidencesByDcl();
                this.displayIncidencesDataTable();
                await this.getAlarmsbyDcl(this.entityId);
                await this.displayChartsByPosition();
            }
        },
        historyVariables: {
            immediate: true,
            async handler() {
                if (!this.targetVariableId && Object.entries(this.historyVariables)[0] != undefined) {
                    this.targetVariableId = Object.entries(this.historyVariables)[0][0];
                    this.displayIncidencesDataTable();
                }
            }
        }
    },
    data() {
        return {
            childrenEntityColumns: [
                {
                    text: "Nombre", data: "entity", width: "15%", className: "tx-left align-middle",
                    render: (data) => {
                        return "<span data-id=" + data.id + " class='text-start tx-12 tx-bold w-100 btnGoToEntity c-pointer'>" + data.label + "</span>"
                    }
                },


                { text: "Categoría", data: "entityCategory", width: "5%", className: "tx-left align-middle" },
                { text: "Tipo", data: "entityType", width: "10%", className: "tx-left align-middle" },

            ],
            activeAlarmColumns: [
                {
                    text: "", data: "activeAlarmId", width: "5%", className: "align-middle",
                    render: (data) => {
                        return "<input type='radio' name='select-alarm' value='" + data + "' class='radio-select-alarm btnDetailAlarm p-0'>";
                    }
                },
                {
                    text: "Alarma", data: "activeAlarmName", width: "70%", className: "tx-left align-middle p-0",
                    render: (data) => {
                        return data;
                    }
                },
                {
                    text: "Fecha Activacion", data: "activationDate", width: "50%", className: "tx-left align-middle p-0",
                    render: (data) => {

                        return (api.parseDateUserTimezone(data, this.session.user, this.$dayjs))
                    }
                },
            ],
            incidenceColumns: [
                {
                    text: "", data: "IncidenceHistoricId", width: "5%", className: " align-middle",
                    render: (data) => {
                        return "<ion-icon name='chevron-down-circle-outline' data-id=" + data + " class='btnDetailIncidence btn tx-24 text-black tx-primary  p-0 m-0'></ion-icon>";
                    }
                },
                {
                    text: "Incidencia", data: "IncidenceLabel", width: "10%", className: " align-middle",
                    render: (data) => {
                        return data;
                    }
                },
                {
                    text: "Estado",
                    data: "IncidenceHistoricStatus",
                    width: "10%",
                    className: "tx-left align-middle",
                    render: (data) => {
                        let text;
                        let color;

                        switch (data) {
                            case "0":
                                text = this.dictionary["Pendiente de revisión"][this.session.user.language];
                                color = "#3498db";
                                break;
                            case "1":
                                text = this.dictionary["En investigación"][this.session.user.language];
                                color = "#2ecc71";
                                break;
                            case "2":
                                text = this.dictionary["En proceso de resolucion"][this.session.user.language];
                                color = "#e67e22";
                                break;
                            case "3":
                                text = this.dictionary["Solucionada"][this.session.user.language];
                                color = "#e74c3c";
                                break;
                            case "4":
                                text = this.dictionary["Cerrada"][this.session.user.language];
                                color = "#f39c12";
                                break;
                            default:
                                text = data.status;
                                color = "black";
                        }

                        return `<span style="color: ${color};">${text}</span>`;
                    }
                },

                {
                    text: "Nivel de gravedad", data: "IncidenceLevel", width: "7%", className: "tx-left align-middle",
                    render: (data) => {
                        switch (data) {
                            case "0":
                                return "<div value='0' class='row'>" +
                                    "<span style='opacity: 0;'>" + 0 + "</span>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: greenyellow; '></div>" +
                                    "</div>";
                            case "1":
                                return "<div value='1' class='row'>" +
                                    "<span style='opacity: 0;'>" + 1 + "</span>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: green; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: green; '></div>" +
                                    "</div>";
                            case "2":
                                return "<div value='2' class='row'>" +
                                    "<span style='opacity: 0;'>" + 2 + "</span>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: orange; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: orange; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: orange; '></div>" +
                                    "</div>";
                            case "3":
                                return "<div value='3' class='row'>" +
                                    "<span style='opacity: 0;'>" + 3 + "</span>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: red; '></div>" +
                                    "</div>";
                            case "4":
                                return "<div value='4' class='row'>" +
                                    "<span style='opacity: 0;'>" + 4 + "</span>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                                    "<div style='margin: 2px; width: 15px; height: 15px; background-color: black; '></div>" +
                                    "</div>";
                            default:
                                return data.status;
                        }


                    }
                }, {
                    text: "Fecha apertura", data: "openingDate", width: "10%", className: "tx-left align-middle",
                    render: (data) => {

                        return (api.parseDateUserTimezone(data, this.session.user, this.$dayjs))
                    }
                },

                {
                    text: "Activo", data: "IncidenceHistoricMachine", width: "7%", className: "tx-left align-middle",
                    render: (data) => {
                        return (data)

                    }
                },
                {
                    text: "Actualizar", data: "IncidenceHistoricId", width: "5%", className: " align-middle",
                    render: (data) => {
                        return ("<ion-icon name='add-circle-outline' data-id=" + data + " class='btnModifyIncidence btn tx-24 text-black m-0 p-0'></ion-icon>"

                        )
                    }
                },

            ],

            targetEmplacementData: {},
            entityId: '',
            machineOrganigram: '',
            treeConfig: { nodeWidth: 240, nodeHeight: 130, levelHeight: 130 },
            childrenEntityList: [],
            machinesByCommandCenter: [],
            display: {

                editHistoricModal: false,
                editCommentsModal: false,
                configurationView: false,
                editMachineModal: false,
                editBundleModal: false,
            },
            linkAction: "link",
            targetDeviceId: '',
            selectedStatuses: [],
            sortedIncidenceList: [],
            incidenceList: [],
            incidenceTypes: [],
            targetIncidenceId: '',
            lineChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["lineChartOptions"],
            gaugeChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["gaugeChartOptions"],
            activeAlarmLineChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["lineChartOptions"],
            targetVariableId: '',
            deviceData: '',
            startDateTimeInput: api.parseDateTimeToString(new Date().setDate(new Date().getDate() - 1)),
            endDateTimeInput: api.parseDateTimeToString(new Date()),
            startDateTime: {
            },
            endDateTime: {
            },
            chartHistory: {
            },
            colors: ["#9e9e9e",
                "#4e79a7",
                "#f28d2b",
                "#e05759",
                "#76b7b2",
                "#59a14f",
                "#af7aa1",
                "#b1de89",
                "#595055",
                "#edc948",
                "#1c8356",
                "#ff9da7",
                "#8d9fca",
                "#2f451e"],
            checkboxTargetAxisLeft: true,
            checkboxTargetAxisRight: false,
            editingPermission: false,
            machineLabel: '',
            displayedImage: '',
            targetEntityData: [],
            editVisualization: false,
        }
    },
    emits: ["handleHasRol"],
    methods: {

        scrollToIncidences() {
            const element = document.getElementById("incidences");
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            } else {
                console.warn("El elemento con ID 'activeAlarms' no se encontró.");
            }
        },
        scrollToAlarms() {
            const element = document.getElementById("alarms");
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            } else {
                console.warn("El elemento con ID 'activeAlarms' no se encontró.");
            }
        },
        async onDisplayElements() {
            await this.getHistoricIncidencesByDcl()
            this.displaychildrenEntityDataTable();
            this.displayIncidencesDataTable();
        },
        async onModifyIncidence(event) {
            console.log('MODIFY WHERE :');
            console.log(event.target.getAttribute('data-id'));
            this.targetIncidenceId = event.target.getAttribute('data-id');
            this.toggleEditHistoricIncidenceModal();
        },
        onAddComents(event) {
            this.targetIncidenceHistoryId = event.target.getAttribute('data-id');
            this.display.editCommentsModal = true;
        },
        onPrint(event) {
            this.targetIncidenceHistoryId = event.target.getAttribute('data-id');
            this.captureAndPrint('resumenPedido' + this.targetIncidenceHistoryId);
        },
        onGoToEntity(event) {
            let entityData = this.childrenEntityList.find(device => device.id === event.target.getAttribute('data-id'))
            switch (true) {
                case entityData.dclClass === '2':
                    return this.$router.push({ params: { currentDisplay: "machine", targetEntity: event.target.getAttribute('data-id') } });
                case entityData.dclClass === '3':
                    return this.$router.push({ params: { currentDisplay: "device", targetEntity: event.target.getAttribute('data-id') } });
                case entityData.dclClass === '4':
                    return this.$router.push({ params: { currentDisplay: "bundle", targetEntity: event.target.getAttribute('data-id') } });
                default:
                    return null;
            }

            //this.$router.push({ params: { currentDisplay: "bundle", targetEntity: event.target.getAttribute('data-id') } });
        },
        // BUTTONS
        setButtonFunctions() {
            setTimeout(() => {
                $(".btnTargetDevice").get().forEach(btn => {
                    btn.addEventListener('click', this.onTargetDevice);
                });
                $(".btnGoToEntity").get().forEach(btn => {
                    btn.addEventListener('click', this.onGoToEntity);
                });
                $(".btnEditDevice").get().forEach(btn => {
                    btn.addEventListener('click', this.onEditDevice);
                });
                $(".btnLinkDevice").get().forEach(btn => {
                    btn.addEventListener('click', this.onLinkDevice);
                });
                $(".btnUnlinkDevice").get().forEach(btn => {
                    btn.addEventListener('click', this.onUnlinkDevice);
                });
                $(".btnDeleteDevice").get().forEach(btn => {
                    btn.addEventListener('click', this.onDeleteDevice);
                });
                $(".btnCopyId").get().forEach(btn => {
                    btn.addEventListener('click', this.onCopyId);
                });
                $(".btntargetIncidence").get().forEach(btn => {
                    btn.addEventListener('click', this.ontargetIncidence);
                });
                $(".btnDetailIncidence").get().forEach(btn => {
                    btn.addEventListener('click', this.onDetaiIncidence)
                });
                $(".btnModifyIncidence").get().forEach(btn => {
                    btn.addEventListener('click', this.onModifyIncidence);
                });
                $(".btnCopyId").get().forEach(btn => {
                    btn.addEventListener('click', this.onCopyId);
                });
                $(".add-comments").get().forEach(btn => {
                    btn.addEventListener('click', this.onAddComents);
                });
                $(".pdf-icon").get().forEach(btn => {
                    btn.addEventListener('click', this.onPrint);
                });
            }, 300);
        },
        onCopyId(event) {
            navigator.clipboard.writeText(event.target.getAttribute('data-id'));
        },
        displaychildrenEntityDataTable() {
            $("#childrenEntityDataTable").DataTable().clear();
            for (let i in this.childrenEntityList) {
                $("#childrenEntityDataTable").DataTable().rows.add([{
                    entityId: this.childrenEntityList[i].id,
                    entity: this.childrenEntityList[i],
                    entityCommunicationDateTime: (this.childrenEntityList[i].communicationDateTime != undefined && this.childrenEntityList[i].communicationDateTime != "") ? new Date(this.childrenEntityList[i].communicationDateTime) : "",
                    entityType: this.childrenEntityList[i].type,
                    entityVersion: this.childrenEntityList[i].version,
                    entityCategory: this.childrenEntityList[i].category,

                }]);
            }
            $("#childrenEntityDataTable").DataTable().draw();
            this.setButtonFunctions();
            if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
            $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
        },
        displayIncidencesDataTable() {
            $("#IncidencesDataTable").DataTable().clear()
            for (let i in this.sortedIncidenceList) {
                $("#IncidencesDataTable").DataTable().rows.add([{
                    IncidenceHistoricId: this.sortedIncidenceList[i].id,
                    IncidenceHistoricStatus: this.sortedIncidenceList[i].status,
                    IncidenceLevel: this.sortedIncidenceList[i].level,
                    IncidenceHistoricMachine: this.machinesByCommandCenter.find(machine => machine.id === this.sortedIncidenceList[i].idDcl) ? this.machinesByCommandCenter.find(machine => machine.id === this.sortedIncidenceList[i].idDcl)?.label : this.childrenEntityList.find(device => device.id === this.sortedIncidenceList[i].idDcl) ? this.childrenEntityList.find(device => device.id === this.sortedIncidenceList[i].idDcl)?.label : '-',
                    IncidenceLabel: this.sortedIncidenceList[i].incidenceLabel,
                    openingDate: this.sortedIncidenceList[i].dateOpen
                }]);
            }
            $("#IncidencesDataTable").DataTable().draw();
            this.setButtonFunctions();
            if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
            $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
        },
        displayActiveAlarmsDataTable() {
            const table = $("#ActiveAlarmsDataTable").DataTable();

            table.clear();

            for (let i in this.activeAlarmsList) {
                table.rows.add([{
                    activeAlarmId: this.activeAlarmsList[i].id,
                    activeAlarmName: this.activeAlarmsList[i].label,
                    activationDate: this.activeAlarmsList[i].statusDate,
                    activeAlarmSilenced: {
                        isMuted: this.activeAlarmsList[i].isMuted,
                        id: this.activeAlarmsList[i].id
                    }
                }]);
            }

            table.draw();
            this.setButtonFunctions();

            setTimeout(() => {
                const firstRadioBtn = $("#ActiveAlarmsDataTable input[type='radio']").first();
                if (firstRadioBtn.length) {
                    const firstRadioValue = firstRadioBtn.val();
                    firstRadioBtn.prop("checked", true).trigger('click');
                    this.onDetailActiveAlarm(firstRadioValue);
                }
            }, 300);
            if ($("#ActiveAlarmsDataTable_paginate").get()[0] != undefined) {
                $("#ActiveAlarmsDataTable_paginate").get()[0].addEventListener("click", this.setButtonFunctions);
            }
            $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
        },
        async displayChartsByPosition() {
            let count = 0;
            for (const key in this.historyVariables) {
                if (Object.prototype.hasOwnProperty.call(this.historyVariables, key)) {
                    if (count >= 3) break;
                    const variable = this.historyVariables[key];
                    this.targetVariableId = variable.variableId;
                    await this.getMachineVariableHistory();
                    count++;
                }
            }
        },
        async displayImage(src) {
            if (src != undefined && src != '') {
                try {
                    let response = this.$clientConfig.api + '/ficheros/ficheros.php?fichero=' + src + '&identificadorusuario=' + this.session.user.id + '&Token=' + localStorage.getItem("ClomosAuthToken");
                    if (response) this.displayedImage = response

                }
                catch (e) {
                    console.warn(e);
                }
            }

        },
        async toggleEditMachineModal() {
            if (this.display.editMachineModal) {
                await this.getMachineData();
                this.displaychildrenEntityDataTable();
                await this.getIncidencesByCompany();
                await this.getHistoricIncidencesByDcl();
                this.displayIncidencesDataTable();
                await this.getAlarmsbyDcl(this.entityId);
                await this.displayChartsByPosition();

            }
            this.display.editMachineModal = !this.display.editMachineModal;
        },
        async toggleEditBundleModal() {
            if (this.display.editBundleModal) {
                await this.getBundleData();
                this.displaychildrenEntityDataTable();
                await this.getIncidencesByCompany();
                await this.getHistoricIncidencesByDcl();
                this.displayIncidencesDataTable();
                await this.getAlarmsbyDcl(this.entityId);
                await this.displayChartsByPosition();
            }
            this.display.editBundleModal = !this.display.editBundleModal;
        },
        async toggleConfigurationView() {
            if (this.display.configurationView) {
                if (this.$route.path.includes('commandCenter')) {
                    const path = this.$route.path;
                    const commandCenterId = path.split('commandCenter/')[1];
                    if (commandCenterId) {
                        this.entityId = commandCenterId;
                        this.childrenEntityList = [],
                            this.machinesByCommandCenter = [],
                            await this.getIncidencesByCompany()

                        await this.getHistoricIncidencesByDcl()
                        if (this.childrenEntityList.length > 0) {
                            this.deviceData = this.childrenEntityList[0]
                            this.targetDeviceId = this.childrenEntityList[0].id
                            await this.displayChartsByPosition()
                        }
                        this.$nextTick(() => {

                            this.$nextTick(() => {
                                $("#childrenEntityDataTable").DataTable(
                                    {
                                        pagingType: 'full_numbers',
                                        pageLength: 10,
                                        processing: true,
                                        dom: 'Bfrtip',
                                        buttons: [
                                            { "extend": 'colvis', "className": 'btn' },
                                            { "extend": 'copy', "className": 'btn' },
                                            { "extend": 'csv', "className": 'btn' },
                                            { "extend": 'print', "className": 'btn me-0' },
                                        ],
                                        columns: this.childrenEntityColumns,
                                        select: this.dataTableOptions.select,
                                        language: this.dataTableOptions.language[this.session.user.language],
                                        stateSave: this.dataTableOptions.stateSave,
                                        order: [[1, 'asc']]
                                    }
                                );
                                $("#IncidencesDataTable").DataTable(
                                    {
                                        pagingType: 'full_numbers',
                                        pageLength: 10,
                                        processing: true,
                                        dom: 'Bfrtip',
                                        buttons: [
                                            { "extend": 'colvis', "className": 'btn' },
                                            { "extend": 'copy', "className": 'btn' },
                                            { "extend": 'csv', "className": 'btn' },
                                            { "extend": 'print', "className": 'btn me-0' },
                                        ],
                                        columns: this.incidenceColumns,
                                        select: this.dataTableOptions.select,
                                        language: this.dataTableOptions.language[this.session.user.language],
                                        //stateSave: this.dataTableOptions.stateSave,
                                        order: [[3, 'desc']]
                                    }
                                );
                                $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);

                                this.displaychildrenEntityDataTable()
                                this.displayIncidencesDataTable();
                            });
                        });

                    }
                }
            }
            this.display.configurationView = !this.display.configurationView;

        },

        async toggleEditHistoricIncidenceModal() {
            if (this.display.editHistoricModal) {
                await this.onDisplayElements();
            }
            this.display.editHistoricModal = !this.display.editHistoricModal;
        },
        async toggleEditCommentsModal() {
            if (this.display.editCommentsModal) {
                await this.onDisplayElements();
            }
            this.display.editCommentsModal = !this.display.editCommentsModal;
        },
        toggleFilter(status) {
            const index = this.selectedStatuses.indexOf(status);
            if (index > -1) {
                this.selectedStatuses.splice(index, 1);
            } else {
                this.selectedStatuses.push(status);
            }
            this.filterTablebyStatus();
        },

        async changeChartDisplay() {

            await this.getMachineVariableHistory()
        },
        async changeDisplayInfo() {
            this.chartHistory = {}
            await this.getMachineVariableHistory();

        },
        filterTablebyStatus() {
            if (this.selectedStatuses.length === 0) {
                this.sortedIncidenceList = this.incidenceList;
            } else {
                this.sortedIncidenceList = this.incidenceList.filter(item =>
                    this.selectedStatuses.includes(item.status.toString())
                );
            }
            this.displayIncidencesDataTable();

        },
        isActive(status) {
            return this.selectedStatuses.includes(status);
        },

        // TARGET        
       
        async onDetaiIncidence(event) {
            console.log('DETAIL WHERE ID:');
            this.targetIncidenceId = event.target.getAttribute('data-id');

            var tr = $(event.target).closest('tr');
            var row = $("#IncidencesDataTable").DataTable().row(tr);
            if (row.child.isShown()) {
                event.target.name = "chevron-down-circle-outline";
                row.child.hide();
                tr.removeClass('shown');
            } else {
                event.target.name = "chevron-up-circle-outline";
                let content = '' +
                    '<div id="resumenPedido' + this.targetIncidence.id + '" class="p-1" style="background-color: white!important" data-id="' + this.targetIncidence.id + '">' +
                    '<h2>' + this.dictionary["Resumen de la incidencia"][this.session.user.language] + '<ion-icon class= "pdf-icon btn tx-24 tx-primary m-0 ms-3 p-0" name="print-outline" data-id="' + this.targetIncidence.id + '"></ion-icon>' + '</h2>' +
                    '<h2 id="incidenceID">' + "Identificador: " + this.targetIncidence.id + '</h2>' +
                    '<div class="container">' +
                    '<ol class="progress-meter">';

                const stages = [
                    {
                        key: 'dateOpen',
                        label: `${this.dictionary["Pendiente de revisión"][this.session.user.language]}${this.targetIncidence.dateOpen ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateOpen, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
                    },
                    {
                        key: 'dateInvestigation',
                        label: `${this.dictionary["En investigación"][this.session.user.language]}${this.targetIncidence.dateInvestigation ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateInvestigation, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
                    },
                    {
                        key: 'dateProgress',
                        label: `${this.dictionary["En proceso de resolucion"][this.session.user.language]}${this.targetIncidence.dateProgress ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateProgress, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
                    },
                    {
                        key: 'dateSolved',
                        label: `${this.dictionary["Solucionada"][this.session.user.language]}${this.targetIncidence.dateSolved ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateSolved, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
                    },
                    {
                        key: 'dateClosed',
                        label: `${this.dictionary["Cerrada"][this.session.user.language]}${this.targetIncidence.dateClosed ? ` <span class='tooltip'>${api.parseDateUserTimezone(this.targetIncidence.dateClosed, this.session.user, this.$dayjs).split(' ')[0]}</span>` : ''}`
                    }
                ];

                stages.forEach(stage => {
                    const done = this.targetIncidence[stage.key] !== "";
                    content += '<li class="progress-point ' + (done ? 'done' : 'todo') + '">' +
                        stage.label + '</li>';
                });

                content += '</ol></div>' + '<div class =row>'
                    + '<div class=col-md-6>';
                content +=
                    '<table style="border-collapse: collapse; width: 100%;">' +
                    '<tr>' +
                    '<th style="padding:2px; text-align: left; font-size: 1.5rem; background-color: #f2f2f2;" colspan="2">' +
                    this.dictionary["Información de la incidencia"][this.session.user.language] + '</th>' +
                    '</tr>'


                const statusMap = {
                    "0": this.dictionary["Pendiente de revisión"][this.session.user.language],
                    "1": this.dictionary["En investigación"][this.session.user.language],
                    "2": this.dictionary["En proceso de resolucion"][this.session.user.language],
                    "3": this.dictionary["Solucionada"][this.session.user.language],
                    "4": this.dictionary["Cerrada"][this.session.user.language],

                };
                const levelMap = {
                    "0": this.dictionary["Muy baja"][this.session.user.language],
                    "1": this.dictionary["Baja"][this.session.user.language],
                    "2": this.dictionary["media"][this.session.user.language],
                    "3": this.dictionary["Critica"][this.session.user.language],
                }
                const levelDescription = levelMap[this.targetIncidence.level] || this.targetIncidence.level;
                const statusDescription = statusMap[this.targetIncidence.status] || this.targetIncidence.status;
                content += '<tr style="padding:2px; text-align: left;">' +
                    '<td style="padding:2px; text-align: left;">' + this.dictionary["Identificador"][this.session.user.language] + '</td>' +
                    '<td style="padding:2px; text-align: left;">' + this.targetIncidence.id + '</td>' +
                    '</tr>';
                content += '<tr style="padding:2px; text-align: left;">' +
                    '<td style="padding:2px; text-align: left;">' + this.dictionary["Estado"][this.session.user.language] + '</td>' +
                    '<td style="padding:2px; text-align: left;">' + statusDescription + '</td>' +
                    '</tr>';
                content += '<tr style="padding:2px; text-align: left;">' +
                    '<td style="padding:2px; text-align: left;">' + this.dictionary["Nivel de gravedad"][this.session.user.language] + '</td>' +
                    '<td style="padding:2px; text-align: left;">' + levelDescription + '</td>' +
                    '</tr>';
                content += '<tr>' +
                    '<td style="padding:2px; text-align: left;">' + this.dictionary["Última actualización"][this.session.user.language] + '</td>' +
                    '<td style="padding:2px; text-align: left;">' + api.parseDateUserTimezone(this.targetIncidence.dateNow, this.session.user, this.$dayjs).split(' ')[0]; + '</td>' +
                        '</tr>';
                content += '<tr>' +
                    '<td style="padding:2px; text-align: left;">' + this.dictionary["Creado por"][this.session.user.language] + ": " + '</td>' +
                    '<td style="padding:2px; text-align: left;">' + this.targetIncidence.createdBy + '</td>' +
                    '</tr>' + '</table>' + ' </div>';
                /*    } */

                content += ' </div>' + '<table style="border-collapse: collapse; width: 100%;">' + '<tr>' +
                    '<td style="padding:2px; text-align: left;" colspan="2">' +
                    '<h2 style="font-size: 1.5rem;">' + this.dictionary["Observaciones"][this.session.user.language] + '<ion-icon class= "add-comments btn tx-24 tx-primary m-0 ms-3 p-0" id="add-comments" name="add-circle-outline" ></ion-icon>' + '</h2>' +
                    '<table>' +
                    '<tr>' +
                    '<th style="padding:2px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Fecha"][this.session.user.language] + '</th>' +
                    '<th style="padding:2px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Usuario"][this.session.user.language] + '</th>' +
                    '<th style="padding:2px; text-align: left; background-color: #f2f2f2;">' + this.dictionary["Comentarios"][this.session.user.language] + '</th>' +
                    '</tr>';

                for (let key in this.targetIncidence.description) {
                    if (Object.prototype.hasOwnProperty.call(this.targetIncidence.description, key)) {
                        let observacion = this.targetIncidence.description[key];
                        content += '<tr>' +
                            '<td style="padding:2px; text-align: start;">' + api.parseDateUserTimezone(key, this.session.user, this.$dayjs) + '</td>' +
                            '<td style="padding:2px; text-align: start;">' + observacion.usuario + '</td>' +
                            '<td style="padding:2px; text-align: start;">' + observacion.texto + '</td>' +
                            '</tr>';
                    }
                }

                content += '</table>' +
                    '</td>' +
                    '</tr>' +
                    '</table>' +
                    '</div>' + '</div>';
                row.child(content).show();

                if (this.targetIncidenceId) {
                    let detailTable = document.getElementById('detailTable' + this.targetIncidenceId);
                    if (detailTable && detailTable.parentNode) {
                        detailTable.parentNode.style.padding = 0;
                    }
                }
                tr.addClass('shown');
            }
            this.setButtonFunctions();

        },

        captureAndPrint(content) {
            var mywindow = window.open('', 'PRINT', 'height=400,width=600');

            mywindow.document.write('<html><head><title>' + "Impresión de incidencia" + '</title>');
            mywindow.document.write('</head><body >');

            mywindow.document.write(document.getElementById(content).innerHTML);
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();

            this.setButtonFunctions();

            return true;
        },
        async onDetailActiveAlarm(activeAlarmId) {
            this.targetActiveAlarm = "";
            this.targetActiveAlarmId = activeAlarmId;
            this.targetActiveAlarm = this.activeAlarmsList.find((item) => item.id === this.targetActiveAlarmId);
            const startDataTimeHistorical = new Date(new Date(this.targetActiveAlarm.statusDate).setHours(new Date(this.targetActiveAlarm.statusDate).getHours() - 48)).toISOString().replace('T', ' ').substr(0, 23)
            const endDateTimeHistorical = new Date().toISOString().replace('T', ' ').substr(0, 23)



            let idDCL = this.entityId;
            let chartVariableDisplayData = {
                active: true,
                variableLabel: this.targetActiveAlarm.variableLabel,
                variableType: this.targetActiveAlarm.type === "0" ? "1" : "0", //modificar cuando se añadan las condiciones dinamicas      
                status: this.targetActiveAlarm.status,
                statusDate: this.targetActiveAlarm.statusDate,
                id: idDCL,
                startDateTime: new Date(new Date(this.targetActiveAlarm.statusDate).setHours(new Date(this.targetActiveAlarm.statusDate).getHours() - 1)).toISOString().replace('T', ' ').substr(0, 23),
                endDateTime: new Date(new Date(this.targetActiveAlarm.statusDate).setHours(new Date(this.targetActiveAlarm.statusDate).getHours() + 47)).toISOString().replace('T', ' ').substr(0, 23),
                startDataTimeHistorical: startDataTimeHistorical,
                endDateTimeHistorical: endDateTimeHistorical,
                limit: 0,

            };


            if (this.targetActiveAlarm.type === "0") {
                chartVariableDisplayData.upperLimit = this.targetActiveAlarm.upperLimit;
                chartVariableDisplayData.lowerLimit = this.targetActiveAlarm.lowerLimit;
                chartVariableDisplayData.hasUpperLimit = this.targetActiveAlarm.hasUpperLimit;
                chartVariableDisplayData.hasLowerLimit = this.targetActiveAlarm.hasLowerLimit;
            } else if (this.targetActiveAlarm.type === "1") {
                chartVariableDisplayData.isActive = this.targetActiveAlarm.isActive;
            }
            this.activeAlarmLineChartOptions = await this.displayVariablesChartOptions(chartVariableDisplayData);
            //this.historicActiveAlarmlineChartOptions = await this.displayHistoricChartOptions(chartVariableDisplayData);

        },
        async displayVariablesChartOptions(displayData) {
            let variableHistoricData = [];
            try {
                let response = await api.getMachineVariableHistory(displayData);
                if (response["status-code"] === "200") {
                    variableHistoricData = api.parseVariableDataFromHistoryResponse(response);
                    let value = parseFloat(variableHistoricData.value).toFixed(2);
                    let unit = variableHistoricData.unit;
                    let resultado = `${value} ${unit}`;
                    if (displayData.active === true) {
                        this.targetActiveAlarm.valueNow = resultado
                    } else {
                        this.targetAlarm.valueNow = resultado
                    }
                } else {
                    throw new Error(`Unexpected status code: ${response["status-code"]}`);
                }
            } catch (error) {
                //console.error(error);
            }
            const dates = variableHistoricData.history.map(item => item[0]);
            const statusDateTimestamp = new Date(displayData.statusDate).getTime();
            if (variableHistoricData.type == "1") {
                const dataValues = variableHistoricData.history.map(item => item[1]);
                const lowerLimit = displayData.hasLowerLimit === "true" ? parseInt(displayData.lowerLimit) : null;
                const upperLimit = displayData.hasUpperLimit === "true" ? parseInt(displayData.upperLimit) : null;
                const margin = 10;
                const minValue = Math.floor(
                    Math.min(
                        ...dataValues,
                        lowerLimit !== null ? lowerLimit : Infinity
                    ) - margin
                );

                const maxValue = Math.ceil(
                    Math.max(
                        ...dataValues,
                        upperLimit !== null ? upperLimit : -Infinity
                    ) + margin
                );



                const lineChartOptions = {
                    toolbox: {
                        feature: {
                            dataView: {
                                readOnly: true
                            },
                            dataZoom: {
                                show: true,
                                yAxisIndex: null
                            },
                            magicType: {
                                type: ['line', 'bar']
                            },
                            saveAsImage: {
                                pixelRatio: 2
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'time',
                        data: dates.map(date => new Date(date).getTime()),
                        axisLabel: {
                            formatter: value => {
                                const date = new Date(value);
                                // Formateo de fecha y hora con ceros a la izquierda
                                return String(date.getDate()).padStart(2, "0") + "/" +
                                    String(date.getMonth() + 1).padStart(2, "0") + "/" +
                                    String(date.getFullYear()) + " \n " +
                                    String(date.getHours()).padStart(2, "0") + ":" +
                                    String(date.getMinutes()).padStart(2, "0");
                            },
                            margin: 10,
                            fontSize: 10,
                        },
                        axisTick: {
                            alignWithLabel: true
                        }
                    },
                    yAxis: {
                        type: 'value',
                        min: minValue,
                        max: maxValue,
                        axisLabel: {
                            formatter: `{value} ${variableHistoricData.unit}`
                        }
                    },
                    grid: {
                        "top": "15%",
                        "right": "5%",
                        "bottom": "3%",
                        "left": "5%",
                        "containLabel": true
                    },
                    series: [
                        {
                            name: displayData.variableLabel,
                            type: 'line',
                            data: dataValues.map((value, index) => [new Date(dates[index]).getTime(), value]),
                            symbol: 'none',
                            markLine: {
                                data: [
                                    ...(displayData.hasUpperLimit === "true" ? [{
                                        yAxis: parseInt(displayData.upperLimit),
                                        name: 'Límite superior',
                                        lineStyle: {
                                            type: 'dashed',
                                            color: 'red',
                                            width: 2
                                        }
                                    }] : []),
                                    ...(displayData.hasLowerLimit === "true" ? [{
                                        yAxis: parseInt(displayData.lowerLimit),
                                        name: 'Límite inferior',
                                        lineStyle: {
                                            type: 'dashed',
                                            color: 'red',
                                            width: 2
                                        }
                                    }] : []),
                                    {
                                        xAxis: statusDateTimestamp,
                                        name: 'Alarma',
                                        lineStyle: {
                                            type: 'solid',
                                            color: 'red',
                                            width: 2
                                        }
                                    }
                                ],
                                symbol: 'none',
                                label: {
                                    formatter: params => params.name
                                }
                            }
                        }
                    ]

                };

                return lineChartOptions;
            } else if (variableHistoricData.type == "0") {
                const dataValuesDigital = variableHistoricData.history.map(item => item[1] === "true" ? 1 : 0);
                const lineChartOptions = {
                    toolbox: {
                        feature: {
                            dataView: {
                                readOnly: true
                            },
                            dataZoom: {
                                show: true,
                                yAxisIndex: null
                            },
                            magicType: {
                                type: ['line', 'bar']
                            },
                            saveAsImage: {
                                pixelRatio: 2
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'time',
                        boundaryGap: false,
                        data: dates.map(date => new Date(date).getTime()),
                        axisLabel: {
                            formatter: value => {
                                const date = new Date(value);
                                return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}/n ${date.getHours()}:${date.getMinutes()}`;
                            },

                        }
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: 1,
                        axisLabel: {
                            formatter: `{value} ${variableHistoricData.unit}`
                        }
                    },
                    series: [
                        {
                            name: displayData.variableLabel,
                            type: 'line',
                            data: dataValuesDigital.map((value, index) => [new Date(dates[index]).getTime(), value]),
                            symbol: 'none',
                            markLine: {
                                data: [
                                    {
                                        xAxis: statusDateTimestamp,
                                        name: 'Alarma',
                                        lineStyle: {
                                            type: 'solid',
                                            color: 'red',
                                            width: 2
                                        }
                                    }
                                ],
                                symbol: 'none',
                                label: {
                                    formatter: params => params.name
                                }
                            }
                        }
                    ]
                };
                return lineChartOptions;
            }

        },
        async getAlarmsbyDcl(selectedDcl) {
            this.activeAlarmsList = {}
            let data = {
                idDcl: selectedDcl[0].id ? selectedDcl[0].id : selectedDcl
            };
            try {
                let response = await api.getConditionsByDcl(data)

                if (response["status-code"] === "200") {
                    this.alarmsList = api.parseAlarmListFromResponse(response);
                    this.activeAlarmsList = [...this.alarmsList].filter((item) => item.status === "2" && item.isAlarm === "true");
                    this.preActive = [...this.alarmsList].filter((item) => item.status === "1");
                    this.postActive = [...this.alarmsList].filter((item) => item.status === "3");
                } else {
                    if (this.alarmsList) {
                        this.alarmsList = {}
                        this.activeAlarmsList = {}
                    }
                    throw new Error("No existen alarmas para este activo.");
                }
                this.displayActiveAlarmsDataTable()

            } catch (error) {
                // console.error(error.message);
            }
        },
        //GET

        getChildrenList(response) {
            this.childrenEntityList = api.parseChildrenFromResponse(response);
            for (let i = 0; i < this.childrenEntityList.length; i++) {
                let typeCategory = null;
                if (this.childrenEntityList[i].dclClass === '4') {
                    typeCategory = this.typesByCompany?.bundleTypeList?.filter(type => type.id === this.childrenEntityList[i].dclTypeId);
                } else if (this.childrenEntityList[i].dclClass === '2') {
                    typeCategory = this.typesByCompany?.machineTypeList?.filter(type => type.id === this.childrenEntityList[i].dclTypeId);
                } else if (this.childrenEntityList[i].dclClass === '3') {
                    typeCategory = this.typesByCompany?.deviceTypeList?.filter(type => type.id === this.childrenEntityList[i].dclTypeId);
                }
                this.childrenEntityList[i].category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";
            }            
        },
        async getMachineData(callback) {
            let response = await api.getMachineData(this.entityId);
            this.targetEntityData = api.parseMachineFromResponse(response);
            let typeCategory = this.typesByCompany?.machineTypeList?.filter(type => type.id === this.targetEntityData.dclTypeId);
            this.targetEntityData.category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";
            if(this.targetEntityData.children)
            this.getChildrenList(response)
            await this.displayImage(this.targetEntityData.imgSrc)
            if (callback && typeof callback === "function") callback(this.targetEntityData);
        },
        async getBundleData() {
            try {
                let response = await api.getBundleData(this.entityId);
                if (response["status-code"] === "200") {
                    let parsed = api.parseBundleFromResponse(response);
                    this.targetEntityData = parsed;
                    let typeCategory = this.typesByCompany?.bundleTypeList?.filter(type => type.id === this.targetEntityData.dclTypeId);
                    this.targetEntityData.category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";
                    if(this.targetEntityData.children)
                    this.getChildrenList(response)
                    await this.displayImage(this.targetEntityData.imgSrc)
                }

            } catch (error) {
                console.error(error)
            }

        },

        async getIncidencesByCompany() {
            const path = this.$route.path;
            const segments = path.split('/');
            let request = {
                nifCif: segments[2],
                limit: 0,
                offset: 0,

            };
            let response = await api.getIncidencesByCompany(request);
            this.incidenceTypes = api.parseIncidenceListFromResponse(response);
        },
        async getHistoricIncidencesByDcl() {
            let data = { id: this.entityId };
            let response = await api.getHistoricIncidencesByDcl(data);
            if (response['status-code'] === '200') {
                this.incidenceList = api.parseHistoricIncidenceListFromResponse(response);
            } else {
                this.incidenceList = []
            }
            this.incidenceList.forEach(incidence => {
                const matchingType = this.incidenceTypes.find(type => type.id === incidence.idIncidence);
                if (matchingType) {
                    incidence.incidenceLabel = matchingType.label;
                } else {
                    incidence.incidenceLabel = '-'
                }
            });

            this.sortedIncidenceList = this.incidenceList
        },

        async getMachineVariableHistory(callback) {
            if (!this.historyVariables[this.targetVariableId]) return
            let targetAxis = (this.checkboxTargetAxisRight) ? 'right' : 'left';
            let step = false;
            if (this.targetEntityData != {}) {
                let request = {
                    id: this.targetEntityData.id,
                    variableLabel: this.historyVariables[this.targetVariableId].label,
                    variableType: this.historyVariables[this.targetVariableId].type,
                    limit: 0,
                    offset: 0,
                    startDateTime: api.parseDateTimeToString(this.startDateTimeInput),
                    endDateTime: api.parseDateTimeToString(this.endDateTimeInput)
                }
                let response = await api.getMachineVariableHistory(request);
                // PARSE HISTORY RESPONSE
                let parsedResponse = api.parseVariableDataFromHistoryResponse(response);
                let rawHistory = parsedResponse.history
                this.chartHistory[this.historyVariables[this.targetVariableId].label] = [];
                for (let i in rawHistory) {
                    this.chartHistory[this.historyVariables[this.targetVariableId].label][i] = [
                        // DATE
                        this.$dayjs(new Date(rawHistory[i][0])).utc("z").local().tz(this.session.user.dateTimeZone).$d,
                        // VALUE
                        new Number(rawHistory[i][1]).toFixed(this.fractionalLength),
                        // POSITION
                        targetAxis,
                        // STEP
                        step,
                        parsedResponse.unit
                    ]
                }

                // SET DATA BOUNDRIES
                this.startDateTime = this.startDateTimeInput;
                this.endDateTime = this.endDateTimeInput;
                // SWITCH AXIS TARGET
                this.checkboxTargetAxisRight = !this.checkboxTargetAxisRight;
                this.checkboxTargetAxisLeft = !this.checkboxTargetAxisRight;
                if (callback && typeof callback === "function") callback(this.availableDevices)
            }
        },
        checkEditingRoleCallback(hasPermission) {
            this.editingPermission = hasPermission;
        },
        async refreshView() {
            if (this.$route.params.currentDisplay.includes('machine')) {
                const targetEntity = this.$route.params.targetEntity
                if (targetEntity) {
                    this.entityId = targetEntity;

                    await this.getMachineData();

                }
            } else if (this.$route.params.currentDisplay.includes('bundle')) {
                const targetEntity = this.$route.params.targetEntity
                if (targetEntity) {
                    this.entityId = targetEntity;
                    await this.getBundleData();


                }
            }
            this.displaychildrenEntityDataTable();
            await this.getIncidencesByCompany();
            await this.getHistoricIncidencesByDcl();
            this.displayIncidencesDataTable();
            await this.getAlarmsbyDcl(this.entityId);
            await this.displayChartsByPosition();
        }
    },
    computed: {
        statusGaugeChartOptions() {
            let statusGaugeChartOptions = {};
            let rawDeviceData = JSON.parse(JSON.stringify(this.targetEntityData));
            if (rawDeviceData.variables === undefined) return statusGaugeChartOptions;
            for (let i in rawDeviceData.variables.analog) {
                if (rawDeviceData.variables.analog[i].isConfiguration === 'false') {
                    statusGaugeChartOptions[i] = JSON.parse(JSON.stringify(this.gaugeChartOptions));
                    statusGaugeChartOptions[i].series[0].detail.formatter = "{value} " + rawDeviceData.variables.analog[i].unit;
                    if (rawDeviceData.variables.analog[i].unit === "%") {
                        statusGaugeChartOptions[i].series[0].max = 100;
                        statusGaugeChartOptions[i].series[0].min = 0;
                    }
                    else {
                        statusGaugeChartOptions[i].series[0].max = Number(rawDeviceData.variables.analog[i].max);
                        statusGaugeChartOptions[i].series[0].min = Number(rawDeviceData.variables.analog[i].min);
                    }
                    statusGaugeChartOptions[i].series[0].data[0] = {
                        name: rawDeviceData.variables.analog[i].label.charAt(0).toUpperCase() + rawDeviceData.variables.analog[i].label.slice(1),
                        value: Number(rawDeviceData.variables.analog[i].value).toFixed(this.fractionalLength)
                    };
                    statusGaugeChartOptions[i].position = rawDeviceData.variables.analog[i].position
                    statusGaugeChartOptions[i].valueDateTime = rawDeviceData.variables.analog[i].valueDateTime;
                }
            }
            for (let i in rawDeviceData.variables.digital) {
                if (rawDeviceData.variables.digital[i].isConfiguration === 'false') {
                    statusGaugeChartOptions[i] = JSON.parse(JSON.stringify(this.gaugeChartOptions));
                    statusGaugeChartOptions[i].series[0].detail.formatter = "{value} " + rawDeviceData.variables.digital[i].unit;


                    statusGaugeChartOptions[i].series[0].max = Number(rawDeviceData.variables.digital[i].max);
                    statusGaugeChartOptions[i].series[0].min = Number(rawDeviceData.variables.digital[i].min);
                    statusGaugeChartOptions[i].series[0].data[0] = {
                        name: rawDeviceData.variables.digital[i].label.charAt(0).toUpperCase() + rawDeviceData.variables.digital[i].label.slice(1),
                        value: rawDeviceData.variables.digital[i].value,
                        type: 'digital'
                    };
                    statusGaugeChartOptions[i].valueDateTime = rawDeviceData.variables.digital[i].valueDateTime;
                    statusGaugeChartOptions[i].position = rawDeviceData.variables.digital[i].position
                }
            }
            if (rawDeviceData.variables.dynamic?.Posicion) {
                const value = api.parseStringToGeoJSON(rawDeviceData.variables.dynamic.Posicion.value)
                const [lat, lon] = value.geometry.coordinates;
                const formattedCoordinates = `${lat.toFixed(2)}/${lon.toFixed(2)}`;
                statusGaugeChartOptions["Posicion"] = JSON.parse(JSON.stringify(this.gaugeChartOptions));
                statusGaugeChartOptions["Posicion"].series[0].data[0] = {
                    name: rawDeviceData.variables.dynamic.Posicion.label.charAt(0).toUpperCase() + rawDeviceData.variables.dynamic.Posicion.label.slice(1),
                    value: formattedCoordinates,

                };
                statusGaugeChartOptions["Posicion"].valueDateTime = rawDeviceData.variables.dynamic.Posicion.valueDateTime;
            }
            return statusGaugeChartOptions;
        },
        targetIncidence() {
            for (let i in this.sortedIncidenceList) {
                if (this.sortedIncidenceList[i].id === this.targetIncidenceId)
                    return this.sortedIncidenceList[i];
            }
            return {};
        },
        fractionalLength() {
            return (localStorage.getItem("ClomosFractionalLength") != undefined) ? localStorage.getItem("ClomosFractionalLength") : 2;
        },
        paddedHistoryVariables() {
            const variables = Array.isArray(this.historyVariables)
                ? this.historyVariables
                : Object.values(this.historyVariables);
            const maxLength = 3;
            const slicedVariables = variables.slice(0, maxLength);
            const dummyCount = maxLength - slicedVariables.length;
            const dummyVariables = Array(dummyCount).fill({ label: "-", value: "-", unit: "" });
            return [...slicedVariables, ...dummyVariables];
        },
        historyVariables() {
            let historyVariables = {};


            for (let variableType in this.targetEntityData.variables)
                for (let variable in this.targetEntityData.variables[variableType])
                    if (this.targetEntityData.variables[variableType][variable].saveHistory === 'true' &&
                        this.targetEntityData.variables[variableType][variable].isConfiguration === 'false' &&
                        (variableType === 'analog' || variableType === 'digital')) {

                        historyVariables[this.targetEntityData.variables[variableType][variable].variableId] = this.targetEntityData.variables[variableType][variable];
                    }


            let sortedHistoryVariables = Object.values(historyVariables).sort((a, b) => {
                let posA = a.position;
                let posB = b.position;
                posA = (posA === undefined || posA === '' || posA === 'undefined') ? Infinity : parseInt(posA, 10);
                posB = (posB === undefined || posB === '' || posB === 'undefined') ? Infinity : parseInt(posB, 10);
                return posA - posB;
            });
            return sortedHistoryVariables.reduce((acc, curr) => {
                acc[curr.variableId] = curr;
                return acc;
            }, {});
        },
        chart1Options() {
            let chart1Options;
            chart1Options = JSON.parse(JSON.stringify(this.lineChartOptions));
            if (this.targetEntityData.id == "") return chart1Options;
            chart1Options.axisPointer = {
                label: {
                    formatter: ((dataTarget) => {
                        let d = new Date(dataTarget.value);
                        switch (this.session.user.dateTimeFormat) {
                            case "DD/MM/YYYY":
                                return String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
                            case "MM/DD/YYYY":
                                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
                            default:
                                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
                        }
                    })
                }
            }
            chart1Options.xAxis = {
                min: new Date(this.startDateTime).getTime(),
                max: new Date(this.endDateTime).getTime(),
                axisLabel: {
                    formatter: ((value) => {
                        let d = new Date(value);
                        switch (this.session.user.dateTimeFormat) {
                            case "DD/MM/YYYY":
                                return String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
                            case "MM/DD/YYYY":
                                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
                            default:
                                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
                        }
                    }),
                    textStyle: {
                        fontSize: 9
                    },
                    axisLineShow: true
                }
            }
            let i = 0;
            for (let variable in this.chartHistory) {
                chart1Options.yAxis[i] = {
                    type: "value",
                    axisLine: {
                        lineStyle: {
                            color: this.colors[i]
                        }
                    },
                    name: variable.substring(0, 3).toUpperCase(),
                    nameTextStyle: {
                        fontSize: 10
                    },
                    axisLabel: {
                        fontSize: 9,
                        formatter: `{value} ${(this.chartHistory[variable][i] != undefined)
                            ? this.chartHistory[variable][i][4]
                            : ''}`
                    },
                    offset: Math.floor(i / 2) * 25,
                    position: (this.chartHistory[variable][i] != undefined)
                        ? this.chartHistory[variable][i][2]
                        : (i % 2 === 0)
                            ? "left"
                            : "right"
                }
                chart1Options.legend.data[i] = variable;
                chart1Options.series[i] = {
                    data: this.chartHistory[variable],
                    name: variable,
                    yAxisIndex: i,
                    type: "line",
                    symbol: "emptyDiamond",
                    symbolSize: 6,
                    color: this.colors[i],
                    smooth: false,
                    step: (this.chartHistory[variable] != undefined) ? this.chartHistory[variable] : false,
                    areaStyle: {
                        color: this.colors[i],
                        opacity: 0.03
                    },
                }
                i += 1;
            }
            return chart1Options;
        },
    },
    async created() {
        if (this.$route.params.currentDisplay.includes('machine')) {
            const targetEntity = this.$route.params.targetEntity
            if (targetEntity) {
                this.entityId = targetEntity;
                await this.getMachineData();
                //await this.getBundleListByMachine();

            }
        } else if (this.$route.params.currentDisplay.includes('bundle')) {
            const targetEntity = this.$route.params.targetEntity
            if (targetEntity) {
                this.entityId = targetEntity;
                await this.getBundleData();

                //await this.getPartList();
            }
        }
        this.displaychildrenEntityDataTable();
        await this.getIncidencesByCompany();
        await this.getHistoricIncidencesByDcl();
        this.displayIncidencesDataTable();
        await this.getAlarmsbyDcl(this.entityId);
        await this.displayChartsByPosition();
    },
    mounted() {
        $("#childrenEntityDataTable").DataTable(
            {
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Bfrtip',
                buttons: [
                    { "extend": 'colvis', "className": 'btn' },
                    { "extend": 'copy', "className": 'btn' },
                    { "extend": 'csv', "className": 'btn' },
                    { "extend": 'print', "className": 'btn me-0' },
                ],
                columns: this.childrenEntityColumns,
                select: this.dataTableOptions.select,
                language: this.dataTableOptions.language[this.session.user.language],
                stateSave: this.dataTableOptions.stateSave,
                order: [[1, 'asc']]
            }
        );
        $("#IncidencesDataTable").DataTable(
            {
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Bfrtip',
                buttons: [
                    { "extend": 'colvis', "className": 'btn' },
                    { "extend": 'copy', "className": 'btn' },
                    { "extend": 'csv', "className": 'btn' },
                    { "extend": 'print', "className": 'btn me-0' },
                ],
                columns: this.incidenceColumns,
                select: this.dataTableOptions.select,
                language: this.dataTableOptions.language[this.session.user.language],
                //stateSave: this.dataTableOptions.stateSave,
                order: [[2, 'desc']]
            }
        );
        $("#ActiveAlarmsDataTable").DataTable(
            {
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'frtip',
                buttons: [
                    { "extend": 'colvis', "className": 'btn' },
                    { "extend": 'copy', "className": 'btn' },
                    { "extend": 'csv', "className": 'btn' },
                    { "extend": 'print', "className": 'btn me-0' },
                ],
                columns: this.activeAlarmColumns,
                select: this.dataTableOptions.select,
                language: this.dataTableOptions.language[this.session.user.language],
                //stateSave: this.dataTableOptions.stateSave,
                order: [[2, 'desc']]
            }
        );
        $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
        this.$emit("handleHasRol", "editor", this.checkEditingRoleCallback);
    }
}

</script>

<style>
.btn-custom-primary {
    font-size: 12;
    border: 2px solid #3498db;
    border-left: 10px solid #3498db;
    color: black;
    background-color: #fff;
    transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-secondary {
    border: 2px solid #2ecc71;
    border-left: 10px solid #2ecc71;
    color: black;
    background-color: #fff;
    transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-success {
    border: 2px solid #e67e22;
    border-left: 10px solid #e67e22;
    color: black;
    background-color: #fff;
    transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-danger {
    border: 2px solid #e74c3c;
    border-left: 10px solid #e74c3c;
    color: black;
    background-color: #fff;
    transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-warning {
    border: 2px solid #f39c12;
    border-left: 10px solid #f39c12;
    color: black;
    background-color: #fff;
    transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}


.btn-custom-primary:hover {
    font-size: 12;
    border-color: #2980b9;
    background-color: #eaf0f8;
}

.btn-custom-secondary:hover {
    border-color: #27ae60;

    background-color: #eaf6e7;
}

.btn-custom-success:hover {
    border-color: #d35400;
    background-color: #f8eae2;
}

.btn-custom-danger:hover {
    border-color: #c0392b;
    background-color: #f8d7da;
}

/* */
.btn-primary-active {
    border-color: #2980b9 !important;
    background-color: #eaf0f8 !important;
    box-shadow: inset 0 0 10px #2980b9;

    font-weight: bold;
    position: relative;
}

.btn-secondary-active {
    border-color: #27ae60 !important;
    background-color: #eaf6e7 !important;
    box-shadow: inset 0 0 10px #27ae60;

    font-weight: bold;
    position: relative;
}

.btn-success-active {
    border-color: #d35400 !important;
    background-color: #f8eae2 !important;
    box-shadow: inset 0 0 10px #d35400;

    font-weight: bold;
    position: relative;
}

.btn-danger-active {
    border-color: #c0392b !important;
    background-color: #f8d7da !important;
    box-shadow: inset 0 0 10px #c0392b;

    font-weight: bold;
    position: relative;
}

.btn-warning-active {
    border-color: #e67e22 !important;
    background-color: #fff3e0 !important;
    box-shadow: inset 0 0 10px #e67e22;
    font-weight: bold;
    position: relative;
}

.btn-primary-active::after,
.btn-secondary-active::after,
.btn-success-active::after,
.btn-danger-active::after,
.btn-warning-active::after {
    content: '✓';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);


}

.icon-container {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alarm-count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-weight: bold;
    color: white;
}
</style>