<template>
  <!-- SECTION Machines-->
  <div id="machinesSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">

      <!-- COMPONENT CommandCenter -->
      <div class="col-lg-12 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
        <div class="col-12 table-responsive bg-head rounded-top-5 m-0 p-0 shadow">
          <div class="row col-12 m-0 pt-2 pb-2 bg-head text-white border-1">
            <div class="col-11 justify-content-center">
              <ion-icon :name="'git-branch-outline'" class="tx-16 mb-1 me-1 align-bottom">
              </ion-icon>
              <span class="col-12 text-center">
                {{ "Centro de mando: " + commandCenterLabel }}
              </span>
            </div>
            <div class="col-1 justify-content-center">
              <ion-icon :name="'return-up-back-outline'" class="tx-16 tx-bold mb-1 me-1 align-bottom">
              </ion-icon>
              <span @click="this.$emit('toggleConfigurationView')" class="c-pointer">
                {{ "Volver" }}
              </span>
            </div>
          </div>
        </div>
        <div class="row col-12 m-0 p-2 bg-caja justify-content-center rounded-bottom-5 shadow">
          <div class="row col-lg-12 col-xl-12 col-md-12 col-12  pt-2" style="min-height: 200px;">
            <div class="col-12 bg-caja rounded-5 shadow"
              style=" border-top: 5px solid rgb(0, 87, 147); border-bottom: 2px solid rgb(0, 141, 180);">
              <!--  <div class="col-12 bg-caja rounded-5 shadow"
              style="z-index: 0; height: auto; border-top: 5px solid rgb(0, 87, 147); border-bottom: 2px solid rgb(0, 141, 180);">
              <h6 class="mt-1">Tabla de dispositivos asociados</h6>
              <img src="@/assets/img/dispositivos.png" class="img-fluid mt-4">
            </div> -->

              <div class="col-12  col-lg-12 col-xl-12">
                <div class="m-0 p-3 h-auto " id="devicesTableContent" style=" overflow: scroll; ">
                  <div class="d-flex justify-content-start w-100">
                    <span class="tx-24 text-dark">Dispositivos</span>
                  </div>
                  <table id="devicesDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
                    <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden overflow-x: hidden;">
                      <tr>
                        <th v-for="column in devicesColumns" :key="column.data" :width="column.width"
                          class="fw-medium text-nowrap p-2">
                          {{ dictionary[column.text][session.user.language] }}
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL New Device -->
  <NewDeviceModal v-if="display.newDeviceModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" v-on:toggleNewDeviceModal="toggleNewDeviceModal">
  </NewDeviceModal>

  <!-- MODAL Edit Device -->
  <EditDeviceModal v-if="display.editDeviceModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :deviceId="targetDeviceId"
    v-on:toggleEditDeviceModal="toggleEditDeviceModal">
  </EditDeviceModal>

  <!-- MODAL Link Text Alert -->
  <LinkDeviceModal v-if="display.linkDeviceModal" :session="session" :dictionary="dictionary"
    :availableCompanies="availableCompanies" :currentCompany="currentCompany" :targetDeviceId="targetDeviceId"
    :linkAction="linkAction" v-on:dismissAlert="toggleLinkDeviceModal">
  </LinkDeviceModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal v-if="display.deleteDeviceModal" :session="session" :dictionary="dictionary" :countries="countries"
    :alertHeader="dictionary['Dispositivo a eliminar'][session.user.language] + ': '" :alertTarget="targetDeviceId"
    v-on:acceptAlert="onDeleteDevice" v-on:dismissAlert="toggleDeleteDeviceModal">
  </TextAlertModal>


  <!--MODAL Edit Incidence-->
  <EditHistoricModal v-if="display.editHistoricModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
    :incidenceId="targetIncidenceId" :incidenceInfo="targetIncidence"
    v-on:toggleEditHistoricIncidenceModal="toggleEditHistoricIncidenceModal">
  </EditHistoricModal>
  <!--MODAL Add Comments-->
  <EditCommentsModal v-if="display.editCommentsModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :availableCompanies="availableCompanies"
    :incidenceId="targetIncidenceId" :incidenceInfo="targetIncidence"
    v-on:toggleEditCommentsModal="toggleEditCommentsModal">
  </EditCommentsModal>
  <!-- MODAL Edit Machine -->
  <EditMachineModal v-if="display.editMachineModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" :machineId="targetMachineId"
    v-on:toggleEditMachineModal="toggleEditMachineModal">
  </EditMachineModal>

  <!-- MODAL Link Text Alert -->
  <LinkMachineModal v-if="display.linkMachineModal" :session="session" :dictionary="dictionary"
    :availableCompanies="availableCompanies" :currentCompany="currentCompany" :targetMachineId="targetMachineId"
    :linkAction="linkAction" v-on:dismissAlert="toggleLinkMachineModal">
  </LinkMachineModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal v-if="display.deleteMachineModal" :session="session" :dictionary="dictionary" :countries="countries"
    :alertHeader="dictionary['Máquina a eliminar'][session.user.language] + ': '" :alertTarget="targetMachineId"
    v-on:acceptAlert="onDeleteMachine" v-on:dismissAlert="toggleDeleteMachineModal">
  </TextAlertModal>

</template>

<script>

import $ from 'jquery';
import NewDeviceModal from '@/components/devices/NewDeviceModal';
import EditDeviceModal from '@/components/devices/EditDeviceModal';
import LinkDeviceModal from '@/components/devices/LinkDeviceModal';
import TextAlertModal from '@/components/other/TextAlertModal';
import EditHistoricModal from '@/components/incidences/EditHistoricModal';
import EditCommentsModal from '@/components/incidences/EditCommentsModal'
import EditMachineModal from '@/components/machines/EditMachineModal';
import LinkMachineModal from '@/components/machines/LinkMachineModal';


// Clomos
import api from '@/services/api';

export default {
  name: "CommandCenterConfigurationComponent",
  components: { LinkMachineModal, EditMachineModal, NewDeviceModal, EditDeviceModal, LinkDeviceModal, TextAlertModal, EditHistoricModal, EditCommentsModal },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    },
    commandCenterLabel: {
      type: String,
      required: true,
    },
    typesByCompany: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      machinesColumns: [
        {
          text: "Identificador", data: "machineId", width: "15%", className: "tx-left align-middle dt-nowrap tx-11",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id=" + data + " class='btnCopyId btn tx-16 tx-clomos-midtone'></ion-icon>"
              + "<span data-id=" + data + " class='btnTargetMachine c-pointer tx-11 tx-bold tx-clomos-midtone text-nowrap align-middle d-inline-block text-truncate' style='max-width:120px;'>" + data + "</span>";
          }
        },
        {
          text: "Nombre", data: "machine", width: "15%", className: "tx-left tx-11 align-middle",
          render: (data) => {
            return "<span data-id=" + data.id + " class='text-start tx-12 tx-bold w-100  btnTargetMachine c-pointer'>" + data.label + "</span>"
          }
        },
        {
          text: "Padre", data: "machineParent", width: "6%", className: "tx-left tx-11 align-middle",
          render: (data) => {
            return data.parentLabel;
          }
        },
        {
          text: "Configuración", data: "machineId", width: "8%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id=" + data + " class='btnEditMachine btn tx-18 tx-primary'></ion-icon>"
          }
        },
        {
          text: "Eliminar", data: "machineId", width: "8%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id=" + data + " class='btnDeleteMachine btn tx-18 tx-danger'></ion-icon>"
          }
        },
      ],
      devicesColumns: [
        {
          text: "Identificador", data: "deviceId", width: "5%", className: "tx-left align-middle dt-nowrap c-pointer",

          render: (data) => {
            let dataShorted = data;
            if (data.length > 8) {
              dataShorted = data.substring(0, 8) + '...';
            }
            return "<ion-icon name='copy-outline' data-id='" + data + "' class='btnCopyId btn tx-18 tx-clomos-midtone' ></ion-icon>" +
              "<span data-id='" + data + "' class='btnTargetDevice tx-bold tx-clomos-midtone text-nowrap align-middle' " +
              "title='" + data + "'>" + dataShorted + "</span>";
          }
        },
        { text: "Nombre", data: "deviceLabel", width: "15%", className: "tx-left align-middle" },
        { text: "Categoría", data: "deviceCategory", width: "5%", className: "tx-left align-middle" },
        {
          text: "Fecha de comunicación", data: "deviceCommunicationDateTime", width: "5%", className: "align-middle",
          render: (data) => {
            return (data != "")
              ? "<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
              : "<span style='display: none;'></span> <spam>-</spam>";
          }
        },
        { text: "Tipo", data: "deviceType", width: "10%", className: "tx-left align-middle" },
        { text: "Versión", data: "deviceVersion", width: "5%", className: "tx-left align-middle" },
        {
          text: "Vincular", data: "deviceParent", width: "5%", className: "align-middle",
          render: (data) => {
            return (data.parentId == "")
              ? "<ion-icon name='log-in-outline' data-id=" + data.deviceId + " class='btnLinkDevice btn tx-24 tx-primary'></ion-icon>"
              : (data.parentLabel == "") ? data.parentId : data.parentLabel;
          }
        },
        {
          text: "Desvincular", data: "deviceParent", width: "5%", className: "align-middle",
          render: (data) => {
            return (data.parentId != "")
              ? "<ion-icon name='log-out-outline' data-id=" + data.deviceId + " class='btnUnlinkDevice btn tx-24 tx-primary'></ion-icon>"
              : "<ion-icon name='log-out-outline' data-id=" + data.deviceId + " class='tx-24 tx-gray-400'></ion-icon>"
          }
        },
        {
          text: "Configuración", data: "deviceId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id=" + data + " class='btnEditDevice btn tx-24 tx-primary'></ion-icon>"
          }
        },
        {
          text: "Eliminar", data: "deviceId", width: "5%", className: "align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id=" + data + " class='btnDeleteDevice btn tx-24 tx-danger'></ion-icon>"
          }
        },
      ],

      machineOrganigramLoading: false,
      targetEmplacementData: {},
      machineId: '',
      machineOrganigram: '',
      treeConfig: { nodeWidth: 240, nodeHeight: 130, levelHeight: 130 },
      devicesByCommandCenter: [],
      machinesByCommandCenter: [],
      display: {
        newDeviceModal: false,
        editDeviceModal: false,
        linkDeviceModal: false,
        deleteDeviceModal: false,
        newHistoricModal: false,
        editHistoricModal: false,
        editCommentsModal: false,
        editMachineModal: false,
        linkMachineModal: false,
        deleteMachineModal: false,
      },
      linkAction: "link",
      targetDeviceId: '',
      selectedStatuses: [],
      sortedIncidenceList: [],
      incidenceList: [],
      incidenceTypes: [],
      targetIncidenceId: '',
      targetVariableId: '',
      deviceData: '',
      emplacementList: '',
      startDateTime: {
      },
      endDateTime: {
      },
      chartHistory: {
      },
      colors: ["#9e9e9e",
        "#4e79a7",
        "#f28d2b",
        "#e05759",
        "#76b7b2",
        "#59a14f",
        "#af7aa1",
        "#b1de89",
        "#595055",
        "#edc948",
        "#1c8356",
        "#ff9da7",
        "#8d9fca",
        "#2f451e"],
      checkboxTargetAxisLeft: true,
      checkboxTargetAxisRight: false,
    }
  },
  emits: ['toggleConfigurationView'],
  methods: {
    // DISPLAY
    displaymachinesConfigurationDataTable() {
      $("#machinesConfigurationDataTable").DataTable().clear()
      for (let i in this.machinesByCommandCenter) {
        let parentLabel = "";

        for (let ii in this.emplacementList) {
          if (this.emplacementList[ii].id === this.machinesByCommandCenter[i].parent) {
            parentLabel = this.emplacementList[ii].label;
            break;
          }
        }
        if (parentLabel === "") for (let ii in this.machinesByCommandCenter) {
          if (this.machinesByCommandCenter[ii].id === this.machinesByCommandCenter[i].parent) {
            parentLabel = this.machinesByCommandCenter[ii].label;
            break;
          }
        }

        $("#machinesConfigurationDataTable").DataTable().rows.add([{
          machineId: this.machinesByCommandCenter[i].id,
          machineEmplacement: this.machinesByCommandCenter[i].parent,
          machine: this.machinesByCommandCenter[i],
          machineLocation: this.machinesByCommandCenter[i].parent,
          machineType: this.machinesByCommandCenter[i].type,
          machineParent: { parentId: this.machinesByCommandCenter[i].parent, parentLabel: parentLabel, machineId: this.machinesByCommandCenter[i].id },
          machineStatus: this.machinesByCommandCenter[i].status,
          machineCommunicationDateTime: (this.machinesByCommandCenter[i].communicationDateTime != undefined && this.machinesByCommandCenter[i].communicationDateTime != "") ? new Date(this.machinesByCommandCenter[i].communicationDateTime) : "",
          machineVersion: this.machinesByCommandCenter[i].version,
        }]);
      }
      $("#machinesConfigurationDataTable").DataTable().draw();
      this.setButtonFunctions();
      if ($("#machinesConfigurationDataTable_paginate").get()[0] != undefined) $("#machinesConfigurationDataTable_paginate").get()[0].addEventListener("click", this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },
    async organizeNode(node, levelPath, newMachineOrganigram) {
      let currentLevel = levelPath.split('.').map(Number);
      let target = newMachineOrganigram;
      const checkDuplicateId = (id, target) => {
        if (target.children) {
          return target.children.some(child => child.id === id);
        }
        return false;
      };
      for (let i = 0; i < currentLevel.length; i++) {
        const level = currentLevel[i];
        if (!target.children) target.children = [];
        if (!target.children[level]) target.children[level] = { children: [] };
        if (checkDuplicateId(node.id, target)) {
          console.warn(`El ID ${node.id} ya existe en este nivel. No se asignará.`);
          return;
        }
        target = target.children[level];
      }

      target.id = node.id;
      target.name = node.label;
      target.type = node.dclClass;
      if (node.dclClass === "2") {
        target.status = (node.variables.analog["Estado"] !== undefined) ? node.variables.analog["Estado"].value : '';
        let typeCategory = this.typesByCompany?.machineTypeList?.filter(type => type.id === node.dclTypeId);
        target.category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";
      } else if (node.dclClass === "3") {
        target.power = (node.variables.analog["Potencia activa"] !== undefined) ? node.variables.analog["Potencia activa"].value : '';
        target.energy = (node.variables.analog["Energía activa"] !== undefined) ? node.variables.analog["Energía activa"].value : '';
        let typeCategory = this.typesByCompany?.deviceTypeList?.filter(type => type.id === node.dclTypeId);
        target.category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";
      }
    },
    async displayMachineOrganigram() {
      this.machineOrganigramLoading = true;
      let newMachineOrganigram = [];
      let nodesToOrganize = [];
      let processedNodes = new Set();
      let rootResponse = await api.getMachineData(this.machineId);
      let rootNode = api.parseMachineFromResponse(rootResponse);
      if (rootNode.dclClass === "2") {
        rootNode.nodeLevel = "0";
        nodesToOrganize.push(rootNode);
        let typeCategory = this.typesByCompany?.machineTypeList?.filter(type => type.id === rootNode.dclTypeId);
        rootNode.category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";
        this.machinesByCommandCenter.push(rootNode);
        const processNodeChildren = async (parentNode, levelPrefix) => {
          if (!parentNode.children) return;
          let index = 0;
          for (let childId of Object.keys(parentNode.children)) {
            let childResponse;
            let childNode;
            if (processedNodes.has(parentNode.children[childId].id)) {
              console.warn(`Nodo con ID ${parentNode.children[childId].id} ya fue procesado, evitando bucle infinito.`);
              continue;
            }
            if (parentNode.children[childId].claseDcl === "2") {
              childResponse = await api.getMachineData(childId);
              childNode = api.parseMachineFromResponse(childResponse);
            } else if (parentNode.children[childId].claseDcl === "3") {
              childResponse = await api.getDeviceData(childId);
              childNode = api.parseDeviceFromResponse(childResponse);
            } else {
              continue;
            }
            processedNodes.add(childNode.id);
            let currentLevelPath = `${levelPrefix}.${index}`;
            childNode.nodeLevel = currentLevelPath;
            nodesToOrganize.push(childNode);
            if (childNode.dclClass === "3") {
              let typeCategory = this.typesByCompany?.deviceTypeList?.filter(type => type.id === childNode.dclTypeId);
              childNode.category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";

              this.devicesByCommandCenter.push(childNode);
            } else if (childNode.dclClass === "2") {
              let typeCategory = this.typesByCompany?.machineTypeList?.filter(type => type.id === childNode.dclTypeId);
              childNode.category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";
              this.machinesByCommandCenter.push(childNode);
            }
            await processNodeChildren(childNode, currentLevelPath);
            index++;
          }
        };
        await processNodeChildren(rootNode, "0");
      }
      for (let node of nodesToOrganize) {
        await this.organizeNode(node, node.nodeLevel, newMachineOrganigram);

      }
      this.sortChildrenRecursively(newMachineOrganigram)
      this.machineOrganigram = newMachineOrganigram.children;
      this.machineOrganigramLoading = false;
    },
    sortChildrenRecursively(node) {
      if (node.children && node.children.length > 0) {
        node.children.sort((a, b) => a.name.localeCompare(b.name));
        node.children.forEach(child => this.sortChildrenRecursively(child));
      }
    },
    controlScale(id, command) {
      let target = this.$refs[("scaleTree" + id)][0];
      switch (command) {
        case 'bigger':
          target.zoomIn()
          break
        case 'smaller':
          target.zoomOut()
          break
        case 'restore':
          target.restoreScale()
          break
      }
    },
    returnBack() {
      const path = this.$route.path;
      const newPath = path.replace('/commandCenter/', '/commandCenters/').replace(/\/[^/]+$/, '');
      this.$router.replace({
        path: newPath
      });
    },
    async onDisplayElements() {
      this.devicesByCommandCenter = [];
      this.machinesByCommandCenter = [];

      await this.displayMachineOrganigram()

      this.displayDevicesDataTable();

    },
    async onModifyIncidence(event) {
      console.log('MODIFY WHERE :');
      console.log(event.target.getAttribute('data-id'));
      this.targetIncidenceId = event.target.getAttribute('data-id');
      this.toggleEditHistoricIncidenceModal();
    },
    onAddComents(event) {
      this.targetIncidenceHistoryId = event.target.getAttribute('data-id');
      this.display.editCommentsModal = true;
    },
    onPrint(event) {
      this.targetIncidenceHistoryId = event.target.getAttribute('data-id');
      this.captureAndPrint('resumenPedido' + this.targetIncidenceHistoryId);
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetDevice);
        });
        $(".btnEditDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onEditDevice);
        });
        $(".btnLinkDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onLinkDevice);
        });
        $(".btnUnlinkDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onUnlinkDevice);
        });
        $(".btnDeleteDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteDevice);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
        $(".btntargetIncidence").get().forEach(btn => {
          btn.addEventListener('click', this.ontargetIncidence);
        });
        $(".btnDetailIncidence").get().forEach(btn => {
          btn.addEventListener('click', this.onDetaiIncidence)
        });
        $(".btnModifyIncidence").get().forEach(btn => {
          btn.addEventListener('click', this.onModifyIncidence);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
        $(".add-comments").get().forEach(btn => {
          btn.addEventListener('click', this.onAddComents);
        });
        $(".pdf-icon").get().forEach(btn => {
          btn.addEventListener('click', this.onPrint);
        });
        $(".btnEditMachine").get().forEach(btn => {
          btn.addEventListener('click', this.onEditMachine);
        });
        $(".btnLinkMachine").get().forEach(btn => {
          btn.addEventListener('click', this.onLinkMachine);
        });
        $(".btnUnlinkMachine").get().forEach(btn => {
          btn.addEventListener('click', this.onUnlinkMachine);
        });
        $(".btnDeleteMachine").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteMachine);
        });
      }, 300);
    },
    onEditMachine(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetMachineId = event.target.getAttribute('data-id');
      this.$router.replace({ params: { targetEntity: this.targetMachineId } })
      this.toggleEditMachineModal();
    },
    // LINK 
    onLinkMachine(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetMachineId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetMachineId } })
      this.linkAction = "link"
      this.toggleLinkMachineModal();
    },
    // UNLINK 
    onUnlinkMachine(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetMachineId = event.target.getAttribute('data-id')
      this.$router.replace({ params: { targetEntity: this.targetMachineId } })
      this.linkAction = "unlink"
      this.toggleLinkMachineModal();
    },
    // DELETE
    async onDeleteMachine(event) {
      if (!this.display.deleteMachineModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetMachineId);
        this.targetMachineId = event.target.getAttribute('data-id');
        this.$router.replace({ params: { targetEntity: this.targetMachineId } })
      }
      else {
        let response = await api.deleteMachine(this.targetMachineId);
        if (response["status-code"] === "200") {
          window.alert("La máquina ha sido eliminada satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar la máquina.");
        }
      }
      await this.toggleDeleteMachineModal();
    },
    async toggleEditMachineModal() {
      if (this.display.editMachineModal) {
        if (this.$route.params.currentDisplay === 'machines') {
          await this.getMachinesDataByCompany();
          this.displaymachinesConfigurationDataTable();
        }
        else {
          this.machinesByCommandCenter = [];
          await this.displayMachineOrganigram();
          this.displaymachinesConfigurationDataTable();
        }

      }
      this.display.editMachineModal = !this.display.editMachineModal;
    },
    async toggleLinkMachineModal() {
      if (this.display.linkMachineModal) {
        if (this.$route.params.currentDisplay === 'machines') {
          await this.getMachinesDataByCompany();
          this.displaymachinesConfigurationDataTable();
        }
        else {
          this.machinesByCommandCenter = [];
          await this.displayMachineOrganigram();
          this.displaymachinesConfigurationDataTable();
        }

      }
      this.display.linkMachineModal = !this.display.linkMachineModal;
    },
    async toggleDeleteMachineModal() {
      if (this.display.deleteMachineModal) {
        if (this.$route.params.currentDisplay === 'machines') {
          await this.getMachinesDataByCompany();
          this.displaymachinesConfigurationDataTable();
        }
        else {
          this.machinesByCommandCenter = [];
          await this.displayMachineOrganigram();
          this.displaymachinesConfigurationDataTable();
        }
        if (this.machineList[0] != undefined) this.targetMachineId = this.machineList[0].id;

      }
      this.display.deleteMachineModal = !this.display.deleteMachineModal;
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    displayDevicesDataTable() {
      $("#devicesDataTable").DataTable().clear()

      for (let i in this.devicesByCommandCenter) {
        $("#devicesDataTable").DataTable().rows.add([{
          deviceId: this.devicesByCommandCenter[i].id,
          deviceLabel: this.devicesByCommandCenter[i].label,
          deviceCommunicationDateTime: (this.devicesByCommandCenter[i].communicationDateTime != undefined && this.devicesByCommandCenter[i].communicationDateTime != "") ? new Date(this.devicesByCommandCenter[i].communicationDateTime) : "",
          deviceType: this.devicesByCommandCenter[i].type,
          deviceParent: {
            parentId: this.devicesByCommandCenter[i].parent,
            parentLabel: (() => {
              const machine = this.machinesByCommandCenter.find(machine => machine.id === this.devicesByCommandCenter[i].parent);
              if (machine) {
                return machine.label;
              }
              const device = this.devicesByCommandCenter.find(device => device.id === this.devicesByCommandCenter[i].parent);
              return device ? device.label : "none";
            })(),
            deviceId: this.devicesByCommandCenter[i].id
          },
          deviceVersion: this.devicesByCommandCenter[i].version,
          deviceCategory: this.devicesByCommandCenter[i].category,
        }]);
      }
      $("#devicesDataTable").DataTable().draw();
      this.setButtonFunctions();
      if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },
    displayIncidencesDataTable() {
      $("#IncidencesDataTable").DataTable().clear()
      for (let i in this.sortedIncidenceList) {
        console.log(this.sortedIncidenceList)
        $("#IncidencesDataTable").DataTable().rows.add([{
          IncidenceHistoricId: this.sortedIncidenceList[i].id,
          IncidenceHistoricStatus: this.sortedIncidenceList[i].status,
          IncidenceLevel: this.sortedIncidenceList[i].level,
          IncidenceHistoricMachine: this.machinesByCommandCenter.find(machine => machine.id === this.sortedIncidenceList[i].idDcl) ? this.machinesByCommandCenter.find(machine => machine.id === this.sortedIncidenceList[i].idDcl)?.label : this.devicesByCommandCenter.find(device => device.id === this.sortedIncidenceList[i].idDcl) ? this.devicesByCommandCenter.find(device => device.id === this.sortedIncidenceList[i].idDcl)?.label : '-',
          IncidenceLabel: this.sortedIncidenceList[i].incidenceLabel
        }]);
      }
      $("#IncidencesDataTable").DataTable().draw();
      this.setButtonFunctions();
      if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
    },

    async toggleNewDeviceModal() {
      if (this.display.newDeviceModal) {
        await this.onDisplayElements();
      }
      this.display.newDeviceModal = !this.display.newDeviceModal;
    },
    async toggleEditDeviceModal() {
      if (this.display.editDeviceModal) {
        await this.onDisplayElements();
      }
      this.display.editDeviceModal = !this.display.editDeviceModal;
    },
    async toggleLinkDeviceModal() {
      if (this.display.linkDeviceModal) {
        await this.onDisplayElements();
      }
      this.display.linkDeviceModal = !this.display.linkDeviceModal;
    },
    async toggleDeleteDeviceModal() {
      if (this.display.deleteDeviceModal) {
        await this.onDisplayElements();
      }
      this.display.deleteDeviceModal = !this.display.deleteDeviceModal;
    },
    async toggleEditHistoricIncidenceModal() {
      if (this.display.editHistoricModal) {
        await this.onDisplayElements();
      }
      this.display.editHistoricModal = !this.display.editHistoricModal;
    },
    async toggleEditCommentsModal() {
      if (this.display.editCommentsModal) {
        await this.onDisplayElements();
      }
      this.display.editCommentsModal = !this.display.editCommentsModal;
    },


    filterTablebyStatus() {
      if (this.selectedStatuses.length === 0) {
        this.sortedIncidenceList = this.incidenceList;
      } else {
        this.sortedIncidenceList = this.incidenceList.filter(item =>
          this.selectedStatuses.includes(item.status.toString())
        );
      }
      this.displayIncidencesDataTable();

    },
    isActive(status) {
      return this.selectedStatuses.includes(status);
    },
    onTargetTreeDevice(id) {
      this.targetDeviceId = id
      this.deviceData = this.devicesByCommandCenter.find(device => device.id === this.targetDeviceId)
      this.changeDeviceChartDisplay()
    },
    // TARGET 
    async onTargetDevice(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id')
      this.deviceData = this.devicesByCommandCenter.find(device => device.id === this.targetDeviceId)
    },
    // SET 
    onEditDevice(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id');

      this.toggleEditDeviceModal();
    },
    // LINK 
    onLinkDevice(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id')
      this.linkAction = "link"
      this.toggleLinkDeviceModal();
    },
    // UNLINK 
    onUnlinkDevice(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetDeviceId = event.target.getAttribute('data-id')
      this.linkAction = "unlink"
      this.toggleLinkDeviceModal();
    },
    // DELETE
    async onDeleteDevice(event) {
      if (!this.display.deleteDeviceModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetDeviceId);
        this.targetDeviceId = event.target.getAttribute('data-id');
      }
      else {
        let response = await api.deleteDevice(this.targetDeviceId);
        if (response["status-code"] === "200") {
          window.alert("El dispositivo ha sido eliminado satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el dispositivo.");
        }
      }
      await this.toggleDeleteDeviceModal();
    },

    async getEmplacementList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);


      this.emplacementLinkedId = '';
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
  },
  computed: {
    targetIncidence() {
      for (let i in this.sortedIncidenceList) {
        if (this.sortedIncidenceList[i].id === this.targetIncidenceId)
          return this.sortedIncidenceList[i];
      }
      return {};
    },
    fractionalLength() {
      return (localStorage.getItem("ClomosFractionalLength") != undefined) ? localStorage.getItem("ClomosFractionalLength") : 2;
    },




  },
  async created() {
    if (this.$route.path.includes('commandCenter')) {
      const path = this.$route.path;
      const commandCenterId = path.split('commandCenter/')[1];
      if (commandCenterId) {
        this.machineId = commandCenterId;

        await this.displayMachineOrganigram()

        if (this.devicesByCommandCenter.length > 0) {
          this.deviceData = this.devicesByCommandCenter[0]
          this.targetDeviceId = this.devicesByCommandCenter[0].id

        }
        await this.getEmplacementList();
        this.displayDevicesDataTable()
        this.displaymachinesConfigurationDataTable();
      }
    }
  },
  mounted() {
    $("#devicesDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.devicesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
        order: [[1, 'asc']]
      }
    );
    $("#machinesConfigurationDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 30,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.machinesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}

</script>

<style>
.btn-custom-primary {
  font-size: 12;
  border: 2px solid #3498db;
  border-left: 10px solid #3498db;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-secondary {
  border: 2px solid #2ecc71;
  border-left: 10px solid #2ecc71;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-success {
  border: 2px solid #e67e22;
  border-left: 10px solid #e67e22;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-danger {
  border: 2px solid #e74c3c;
  border-left: 10px solid #e74c3c;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.btn-custom-warning {
  border: 2px solid #f39c12;
  border-left: 10px solid #f39c12;
  color: black;
  background-color: #fff;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}


.btn-custom-primary:hover {
  font-size: 12;
  border-color: #2980b9;
  background-color: #eaf0f8;
}

.btn-custom-secondary:hover {
  border-color: #27ae60;

  background-color: #eaf6e7;
}

.btn-custom-success:hover {
  border-color: #d35400;
  background-color: #f8eae2;
}

.btn-custom-danger:hover {
  border-color: #c0392b;
  background-color: #f8d7da;
}

/* */
.btn-primary-active {
  border-color: #2980b9 !important;
  background-color: #eaf0f8 !important;
  box-shadow: inset 0 0 10px #2980b9;

  font-weight: bold;
  position: relative;
}

.btn-secondary-active {
  border-color: #27ae60 !important;
  background-color: #eaf6e7 !important;
  box-shadow: inset 0 0 10px #27ae60;

  font-weight: bold;
  position: relative;
}

.btn-success-active {
  border-color: #d35400 !important;
  background-color: #f8eae2 !important;
  box-shadow: inset 0 0 10px #d35400;

  font-weight: bold;
  position: relative;
}

.btn-danger-active {
  border-color: #c0392b !important;
  background-color: #f8d7da !important;
  box-shadow: inset 0 0 10px #c0392b;

  font-weight: bold;
  position: relative;
}

.btn-warning-active {
  border-color: #e67e22 !important;
  background-color: #fff3e0 !important;
  box-shadow: inset 0 0 10px #e67e22;

  font-weight: bold;
  position: relative;
}

.btn-primary-active::after,
.btn-secondary-active::after,
.btn-success-active::after,
.btn-danger-active::after,
.btn-warning-active::after {
  content: '✓';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);


}
</style>