<template>
  <div class="nav-top-menu bg-clomos gradient-background__noise"> 
    <!-- Logo -->
    <div class='top-menu-logo me-5'></div>

    <div class="m-auto ms-0 me-0 pe-3" style="scrollbar-width: thin;scrollbar-color: #0d48a4 #ECECEC; overflow-x: auto;overflow-y: hidden;">
      <AvailableMenuComponent
      :session="session"
      :dictionary="dictionary"
      :visibility="visibility"
      :availableModules="session.authorisation.availableModules"
      v-on:onAvailableMenuItemClicked="onAvailableMenuItemClicked"
      v-on:logOutRequest="logOutRequest">
      </AvailableMenuComponent>
    </div>
    
    <!-- Menu lateral -->
    <div class="top-menu-side-menu-toggle">
     <!--  <ion-icon v-if= "redirectModules.includes($route.params.currentModule)" name="help-outline" id="toggleSideMenuButton" class="top-menu-icon tx-white pe-2" style="font-size: 25px"
      @click="redirect()"></ion-icon> -->
      <ion-icon name="menu" id="toggleSideMenuButton" class="top-menu-icon tx-white" style="font-size: 25px"
      @click="$emit('toggleSideMenu')"></ion-icon>
    </div>

  </div>
</template>

<script>
import AvailableMenuComponent from '@/components/navigation/AvailableMenuComponent';

export default {
  name: "TopMenuComponent",
  components: { AvailableMenuComponent  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    }
  },
  watch: {
    currentCompany: function() {
      if ((this.userCompany.name === undefined && this.currentCompany.name != undefined)
      || (this.userCompany.name === undefined && this.currentCompany.name != undefined)
      || (this.userCompany.name != this.currentCompany.name)) {
        this.userCompany.name = this.currentCompany.name;
        this.userCompany.nifCif = this.currentCompany.nifCif;
      }
    }
  },
  emits: ["setCurrentCompany", "getCurrentCompanyData", "toggleSideMenu", "logOutRequest"],
  data() {
    return {
      userCompany: {},
      redirectModules: ['electricalMonitoring']
    }
  },
  methods: {
    // NAVIGATE 
    onAvailableMenuItemClicked() {
      if(this.visibility.sideMenu) this.$emit('toggleSideMenu');
    },
    // LOG OUT
    logOutRequest() {
      this.$emit('logOutRequest');
    },
    redirect (){
      try {  
        switch (true) {
          case this.$route.params.currentModule.includes("smartLockers"):
            return 1;
          case this.$route.params.currentModule.includes("devices"):
            return 2;

          case this.$route.params.currentModule.includes("fabric"):
            return 1;
          case this.$route.params.currentModule.includes("electricalMonitoring"):
            return window.open("/ayuda_aviles/MANUAL-USUARIO.html", "_blank");
          
          default:
            return null;
        }
      } catch (error) {
        console.error("Error parsing URL:", error);
        return null;
      }

    }
  }
}
</script>

<style>
.nav-top-menu {
  height: 4.5rem;
  width: 100%;
  color: #FFF;
  border-bottom: solid 0.5px rgba(255, 255, 255, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  font-weight:400;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.top-menu-icon:hover{
  cursor: pointer;
  opacity: 0.75;
  transition: all 0.3s;
}

.top-menu-logo {
  background-image: url("@/assets/img/logo.png");
  background-repeat:no-repeat;
  background-size:contain;
  background-position-y: center;
  cursor: pointer;
  width: 13rem;
  min-width: 17rem;
  height: 70%;
  padding: 0;
  margin: auto 1rem auto 1rem;
  transition: all 0.3s;
}

@media (max-width: 767px) {
  .top-menu-logo {
    background-image: url("@/assets/img/logo-sm.png");
    background-repeat:no-repeat;
    background-size:contain;
    background-position-y: center;
    cursor: pointer;
    width: 4.1rem;
    min-width: 4.1rem;
    height: 70%;
    padding: 0;
    margin: auto 1rem auto 1rem;
    transition: all 0.3s;
  }
}

.top-menu-side-menu-toggle{
  display: flex;
  justify-content: center;
  align-self: center;
  margin-left: auto;
  margin-right: 1.25rem;
  transition: all 0.3s;
}

.top-menu-selector{
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 200px;
  min-width: 200px;
  margin-right: 1.25rem;
  transition: all 0.3s;
}

.top-menu-warnings{
  display: flex;
  justify-content: center;
  float: left;
  align-self: center;
  margin-left: auto;
  margin-right: 1.25rem;
  transition: all 0.3s;
}

.top-menu-profile-button{
  display: flex;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  border: solid 1px white;
  border-radius: 3rem;
  transition: all 0.3s;
}
.f-modal-alert
{
  background-color: transparent;
  margin: 20px;
  padding: 0px;
    padding-top: 0px;
  width: 60px;
}

</style>