<template>
  <!-- MODAL New Machine -->
  <div id="newOrderModal" class="col-12 justify-content-center pe-6" style="padding: 15vh 25%">
    <div class="col-12 m-0 p-0 section-container rounded-5 shadow">

      <!-- HEAD New Company -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="cog" class="tx-18 me-2 align-bottom"></ion-icon>
            Nuevo informe
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleNewOrderModal')">
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <!-- CONTENT New Machine -->
      <div class="col-12 bg-caja row m-0 p-0 rounded-bottom-5 justify-content-center">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="row m-0 p-2 card-body justify-content-center">

            <div class="col-12 m-0 p-2 overflow-hidden">
              <form class="col-12 border rounded m-0 p-3 pt-1">
                <div class="row justify-content-center">

                  <!-- NOMBRE -->
                  <div class="form-group col-lg-6 col-md-6 col-6 p-1">
                    <label class="col-12 text-clomos tx-14 tx-bold">
                      {{ dictionary['Nombre'][session.user.language] }} *
                    </label>
                    <div class="col-md-12">
                      <input v-model="newOrderData.label" id="inputNewMachineLabel" type="text"
                        class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>
                  <div class="form-group col-lg-6 col-md-6 col-6 p-1">
                    <label class="col-12 text-clomos tx-14 tx-bold">
                      {{ dictionary['Descripción'][session.user.language] }} *
                    </label>
                    <div class="col-md-12">
                      <input v-model="newOrderData.description" id="inputNewMachineLabel" type="text"
                        class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>
                  <!-- TIPO -->
                  <div class="form-group col-lg-6 col-md-6 col-6 p-1">
                    <label class="col-12 text-clomos tx-14 tx-bold">
                      {{ dictionary['Dispositivo'][session.user.language] }}
                    </label>
                    <div class="col-md-12 row">
                      <input v-if="parentType === 'device'" type="text" v-model="searchQuery" placeholder="Buscar"
                        class="form-control mt-2 mb-2 text-dark col-6" />
                      <select v-if="parentType === 'device'"
                        class="col-6 m-0 p-0 form-control p-2 h-100 bg-white d-flex" style="color:black; line-height:"
                        v-model="targetEntityId" @change="onTargetEntity">
                        <option v-for="device in filteredDevices" :key="device" :value="device.id"
                          :selected="device.id === targetEntityId">
                          {{ device.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-lg-6 col-md-6 col-6 p-1">
                    <label class="col-12 text-clomos tx-14 tx-bold">
                      {{ dictionary['Variables'][session.user.language] }} *
                    </label>
                    <div class="col-md-12">
                      <select v-model="selectedVariables" multiple id="selectNewMachineType"
                        class="form-control form-control-line" style="color:black;">
                        <option class="text-dark" v-for="variable in variables" :key="variable.variableId"
                          :value="variable">{{ variable.label }}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row">

                  <div class="col-12 mt-4">
                    <!-- SUBMIT -->
                    <button type="button" @click="onAddNewDevice" id="btnSubmitCreateNewMachine"
                      class="button-ok gradient-tablas rounded-3">
                      <span class="button-text text-white">
                        <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                        <!-- {{  dictionary['Añadir'][session.user.language]}} --> Añadir
                      </span>
                    </button>
                  </div>

                </div>

                <div class="col-12 mt-4">
                  <table v-if="newOrderData.parametros && newOrderData.parametros.length > 0"
                    class="table table-striped">
                    <thead>
                      <tr>
                        <th>Dispositivo</th>
                        <th>Variables</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(parametro, index) in newOrderData.parametros" :key="index">
                        <td>
                          {{ availableDevices.find(device => device.id === parametro.id)?.label || 'No disponible' }}
                        </td>
                        <td>
                          {{ Array.isArray(parametro.variables)
                            ? parametro.variables.map(variable => variable.label || 'Sin etiqueta').join(", ")
                            : 'Sin variables'
                          }}
                        </td>
                        <td>
                          <button class="btn btn-danger btn-sm" @click="deleteEntry(parametro.id)">
                            Borrar
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p v-else></p>
                </div>

                <!-- Nuevo div para insertar emails -->
                <div class="col-12 mt-4">
                  <h5 class="text-clomos tx-14 tx-bold">correos electrónicos de notificación</h5>
                  <form @submit.prevent="addEmail">
                    <div class="input-group mb-3">
                      <input type="email" class="form-control" placeholder="Introduce un email" v-model="newEmail"
                        required>
                      <button class="button-ok gradient-tablas rounded-3" type="submit">Agregar</button>
                    </div>
                    <div v-if="newOrderData.emailList.length">
                      <h6>Correos añadidos:</h6>
                      <ul>
                        <li v-for="(email, index) in newOrderData.emailList" :key="index">
                          {{ email }}
                          <button class="button-ok btn-danger btn-sm" @click="removeEmail(index)">Eliminar</button>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>


                <div class="row">

                  <div class="col-12 mt-4">
                    <!-- SUBMIT -->
                    <button type="button" @click="onCreateNewMachine" id="btnSubmitCreateNewMachine"
                      class="button-ok gradient-tablas rounded-3">
                      <span class="button-text text-white">
                        <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                        <!-- {{ dictionary['Crear nueva máquina'][session.user.language] }} -->Crear nuevo informe
                      </span>
                    </button>
                  </div>

                </div>

              </form>
            </div>

          </div>
        </div>

      </div>

    </div>
  </div>

</template>

<script>

// Clomos
import api from '@/services/api';

export default {
  name: "tNewOrderModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },

  },
  watch: {
    filteredDevices(newVal) {
      if (newVal.length > 0) {
        this.targetEntityId = newVal[0].id;
      }
    },
    async targetEntityId() {
      await this.getDeviceData()
      this.getVariablesFromDevice()
    }
  },
  computed: {
    filteredDevices() {
      if (!Array.isArray(this.availableDevices) || this.availableDevices.length === 0) {
        return [];
      }
      return this.availableDevices.filter(device => {
        const label = device.label;
        return typeof label === 'string' && label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchQuery
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });

    },
  },
  data() {
    return {
      newOrderData: {
        label: '',
        description: '',
        resumen: [],
        listaDcls: [],
        parametros: [],
        emailList: []
      },
      newEmail: "",
      machineTypeList: [],
      emplacementList: [],
      route: '',
      parentType: 'device',
      targetEntityId: '',
      availableDevices: [],
      deviceData: "",
      searchQuery: "",
      variables: [],
      selectedVariables: []
    }
  },
  emits: ["toggleNewOrderModal"],
  methods: {
    addEmail() {
      if (this.newEmail && !this.newOrderData.emailList.includes(this.newEmail)) {
        this.newOrderData.emailList.push(this.newEmail);
        this.newEmail = "";
      }
    },
    removeEmail(index) {
      this.newOrderData.emailList.splice(index, 1);
    },
    deleteEntry(id) {
      this.newOrderData.parametros = this.newOrderData.parametros.filter(
        (parametro) => parametro.id !== id
      );
      this.newOrderData.listaDcls = this.newOrderData.listaDcls.filter(
        (dclId) => dclId !== id
      );
      if (this.newOrderData.listaDcls.length > 0) this.getVariablesFromDevice();
    },
    async onCreateNewMachine() {
      // Validar campos obligatorios
      if (!this.newOrderData.label.trim() || !this.newOrderData.description.trim()) {
        alert('Por favor, complete todos los campos obligatorios (Nombre y Descripción).');
        return;
      }
      const payload = {
        ...this.newOrderData,
        parametros: JSON.stringify(this.newOrderData.parametros), 
        resumen: JSON.stringify([
          ...this.newOrderData.resumen,
          { Nombre: this.newOrderData.label, Descripcion: this.newOrderData.description }
        ]),
      };

      try {
        let response = await api.postNewReport(payload);

        if (response["status-code"] === "201" || response["status-code"] === "200") {
          window.alert("El informe ha sido creado satisfactoriamente.");
          this.$emit('toggleNewOrderModal');


        } else {
          window.alert("Error al crear la máquina.");
        }
      } catch (error) {
        console.error("Error en la solicitud:", error);
        window.alert("Se produjo un error al enviar la solicitud.");
      }
    },
    onAddNewDevice() {
      this.variables = [];
      if (this.newOrderData.listaDcls.some(item => item === this.targetEntityId)) {
        window.alert("El dispositivo ya ha sido añadido");
      } else {
        this.newOrderData.listaDcls.push(
          this.targetEntityId
        );
        this.newOrderData.parametros.push({
          id: this.targetEntityId,
          variables: this.selectedVariables
        });
      }
    },
    getVariablesFromDevice() {
      this.variables = [];
      for (let key in this.deviceData.variables.analog) {
        this.variables.push(this.deviceData.variables.analog[key]);
      }
      for (let key in this.deviceData.variables.dynamic) {
        this.variables.push(this.deviceData.variables.dynamic[key]);
      }
      for (let key in this.deviceData.variables.digital) {
        this.variables.push(this.deviceData.variables.digital[key]);
      }

    },
    async getDeviceList(callback) {
      let request = {
        nifCif: this.$route.params.currentCompanyNifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getDeviceListByCompany(request);
      this.availableDevices = api.parseDevicesFromResponse(response);
      if (this.availableDevices[0] != undefined) {
        this.targetEntityId = this.availableDevices[0].id;
        this.targetEntity = this.availableDevices[0];
      }
      if (callback && typeof callback === "function") callback(this.availableDevices);
    },
    async getDeviceData(callback) {
      let response = await api.getDeviceData(this.targetEntityId);
      this.deviceData = api.parseDeviceFromResponse(response);
      if (callback && typeof callback === "function") callback(this.deviceData);
    },
  },
  async created() {
    await this.getDeviceList();
    await this.getDeviceData()
    this.getVariablesFromDevice()
  }
}
</script>

<style>
#newOrderModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>