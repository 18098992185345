<template>
  <!-- MODAL Edit Device -->
  <div id="editDeviceModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 shadow">
      <!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="hardware-chip" class="tx-18 me-2 align-bottom"></ion-icon>
            {{ dictionary['Editar dispositivo'][session.user.language] + ": " + editDeviceData.label }}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleEditDeviceModal')">
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <!-- CONTENT Edit Device -->
      <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
        <div id="editDeviceScroll" style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="col-12 p-2 pe-0 pb-0">

            <!-- CONTENT Edit Device -->
            <form class="p-0 overflow-hidden d-flex flex-wrap pe-2">

              <div class="col-12 m-0 p-2">
                <form class="col-12 border rounded m-0 p-1 overflow-hidden">
                  <div class="bg-menu text-white p-1 m-0 rounded">
                    {{ dictionary['Dispositivo'][session.user.language] }}
                  </div>
                  <div class="row col-lg-12 col-12 container-fluid p-0 pt-2 m-0">
                    <div class="col-lg-3 col-12 container-fluid p-2 m-0">
                      <!-- NOMBRE -->
                      <div class="col-lg-12 col-12 p-0 tx-left">
                        <label class="p-0 text-dark tx-bold tx-12">
                          {{ dictionary['Nombre'][session.user.language] }}
                        </label>
                        <input v-model="editDeviceData.label" id="inputEditDeviceLabel" type="text"
                          class="form-control form-control-line" style="color:black;">
                      </div>

                      <!-- TIPO -->
                      <div class="col-lg-12 col-12 p-0 tx-left">
                        <label class="p-0 pt-1 text-dark tx-bold tx-12">
                          {{ dictionary['Localización'][session.user.language] }}
                        </label>
                        <input v-model="editDeviceData.emplacement" id="inputEditDeviceEmplacement" type="text"
                          class="form-control form-control-line" style="color:black;">
                      </div>
                      <!-- SUBMIT -->
                      <div class="col-12 mt-3 mb-0 p-0">
                        <button type="button" @click="setEditDeviceData" id="btnSubmitDeviceData"
                          class="col-12 button-ok gradient-tablas rounded-3">
                          <span class="button-text text-white">
                            <ion-icon name="create-outline"
                              class="text-white p-0 m-auto ms-3 tx-24 hydrated"></ion-icon>
                            <span class="text-white m-auto">
                              {{ dictionary['Guardar cambios'][session.user.language] }}
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12 p-2">
                      <div class="col-12 m-0 p-0">
                        <div class="row bg-menu text-white m-0 p-3 pb-0 rounded-5 justify-content-around">
                          <!-- CONTENT Blueprint -->
                          <label class="p-0 pb-2 ps-1 text-light tx-bold tx-13">
                            {{ dictionary['Geoposición'][session.user.language] }}
                          </label>
                          <!--  <div v-if="editDeviceData.parent!=''"
                            class="blueprintCanvas w-100 m-0 p-0">
                              <img id="editDeviceBlueprint" class="w-100 m-0 p-0 rounded-3"
                              @click="onSetNewCoordinates"
                              :src="displayBlueprint()">
  
                              <div id="editDeviceBlueprintMarker" style="color: red;">
                                <ion-icon name="locate-outline" class="tx-20"></ion-icon>
                              </div>
                            </div> -->
                          <!-- CONTENT Map -->
                          <div id="deviceMapContent" class="m-0 p-3 rounded-3"
                            style="width: 100%; height: 370px; index: 0;">
                          </div>

                          <!-- LATITUD -->
                          <div class="col-5 m-2 p-0 d-flex">
                            <label class="p-0 pe-2 pt-2 tx-11">
                              {{ dictionary['Latitud'][session.user.language] }}
                            </label>
                            <input v-model="editDeviceData.geoJSON.geometry.coordinates[0]" id="inputEditDeviceLat"
                              type="number" step=0.00001 class="form-control form-control-line"
                              @change="onPositionChanged">
                          </div>

                          <!-- LONGITUD -->
                          <div class="col-5 m-2 p-0 d-flex">
                            <label class="p-0 pe-2 pt-2 tx-11">
                              {{ dictionary['Longitud'][session.user.language] }}
                            </label>
                            <input v-model="editDeviceData.geoJSON.geometry.coordinates[1]" id="inputEditDeviceLng"
                              type="number" step=0.00001 class="form-control form-control-line"
                              @change="onPositionChanged">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-12 m-0 p-2">
                      <div class="col-12 p-0 m-0 bg-menu rounded-5 text-white">

                        <div v-for="variable in editDeviceData.variables.analog" :key="variable.analogVariableId"
                          class="col-12 p-0">
                          <div v-if="variable.isConfiguration === 'true'" class="m-0 ps-2 pe-2">
                            <label class="col-12 tx-11 p-0 pt-2">
                              {{ variable.label }}
                              <span class="text-nowrap ms-auto"
                                v-if="variable.unit != undefined && variable.unit != ''"> {{ "(" + variable.unit + ")" }}
                              </span>
                            </label>
                            <input v-model="editDeviceData.variables.analog[variable.label].value"
                              :id="(variable.isConfiguration === 'false') ? ('editDevice' + variable.label) : 'inputEditDevice' + variable.label"
                              type="text" :disabled="variable.isConfiguration === 'false'"
                              :data-id="editDeviceData.variables.analog[variable.label].variableId"
                              :data-type="editDeviceData.variables.analog[variable.label].type"
                              class="inputEditDevice form-control form-control-line m-0 p-1"
                              style="font-size: 0.7rem !important;">
                          </div>
                        </div>

                        <div v-for="variable in editDeviceData.variables.digital" :key="variable.digitalVariableId"
                          class="col-12 p-0">
                          <div v-if="variable.isConfiguration === 'true'" class="m-0 p-0 w-100">
                            <label class="col-12 tx-11 p-0 pt-2">
                              {{ variable.label }}
                            </label>
                            <div :class="editDeviceData.variables.digital[variable.label].value"
                              :id="(variable.isConfiguration === 'false') ? ('editDevice' + variable.label) : 'inputEditDevice' + variable.label"
                              type="text" :disabled="variable.isConfiguration === 'false'"
                              :data-id="editDeviceData.variables.digital[variable.label].variableId"
                              :data-type="editDeviceData.variables.digital[variable.label].type"
                              :data-value="editDeviceData.variables.digital[variable.label].value"
                              class="inputEditDevice form-control form-control-line m-0 p-1 tx-10"
                              @click="(editDeviceData.variables.digital[variable.label].value === 'true') ? editDeviceData.variables.digital[variable.label].value = 'false' : editDeviceData.variables.digital[variable.label].value = 'true'">
                              {{
                                dictionary[editDeviceData.variables.digital[variable.label].value][session.user.language]
                              }}
                            </div>
                          </div>
                        </div>

                        <div v-for="variable in editDeviceData.variables.dynamic" :key="variable.dynamicVariableId"
                          class="col-12 p-0"
                          :style="((variable.isConfiguration === 'false') || (variable.isConfiguration === 'true' && variable.label === 'configuracion')) ? 'display:none' : 'display:block'">
                          <div class="m-0 ps-2 pe-2">
                            <label class="col-12 tx-11 p-0 pt-2">
                              {{ variable.label }}
                            </label>
                            <input v-model="editDeviceData.variables.dynamic[variable.label].value"
                              :id="(variable.isConfiguration === 'false') ? ('editDevice' + variable.label) : 'inputEditDevice' + variable.label"
                              type="text" :disabled="variable.isConfiguration === 'false'"
                              :data-id="editDeviceData.variables.dynamic[variable.label].variableId"
                              :data-type="editDeviceData.variables.dynamic[variable.label].type"
                              class="inputEditDevice form-control form-control-line m-0 p-1">
                          </div>
                        </div>
                        <div class="col-lg-12 col-12 p-3 pb-0">
                          <!-- SUBMIT SETTINGS-->
                          <button type="button" @click="setDeviceVariables" id="btnSubmitDeviceVariables"
                            class="button-ok gradient-tablas rounded-3 mb-3">
                            <span class="button-text text-white">
                              <ion-icon name="create-outline"
                                class="text-white p-0 m-auto ms-3 tx-24 hydrated"></ion-icon>
                              <span class="text-white m-auto">
                                {{ dictionary['Guardar variables'][session.user.language] }}
                              </span>
                            </span>
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </form>
<!--                 <div class="form-group border rounded col-12 mt-3 d-flex flex-wrap">
                  <div class="col-12">
                    <div class="m-0 p-0 w-100">
                      <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                        {{ dictionary['Configuración'][session.user.language] }}
                        <span class="ps-1 small">(JSON)</span>
                      </label>
                      <textarea v-model="deviceConfigurationForm.value" type="text" id="inputEditDeviceConfiguration"
                        class="form-control form-control-line" style="color:black; text-align: start;"></textarea>
                    </div>
                  </div>

                  <div class="form-group col-lg-6 col-12 p-0 pb-3">
                    <div class="col-12 mt-4">
                 
                      <button type="button" @click="setDeviceConfiguration" id="btnSubmitDeviceConfiguration"
                        class="button-ok gradient-tablas rounded-3">
                        <span class="button-text text-white">
                          <ion-icon name="create-outline" class="text-white p-0 m-auto ms-3 tx-24 hydrated"></ion-icon>
                          <span class="text-white m-auto">
                            {{ dictionary['Guardar configuración'][session.user.language]}}
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div> -->


              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
// Clomos
import api from '@/services/api';

export default {
  name: "EditDeviceModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    deviceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editDeviceData: {
        id: "",
        nifCif: "",
        dataConsumed: "",
        label: "",
        dclId: "",
        dclTypeId: "",
        dclClass: "",
        type: "",
        distributionGroup: "",
        parent: "",
        isBase: "",
        isPublic: 0,
        token: "",
        imgSrc: "",
        emplacement: "",
        fechaUltimaComunicacion: "",
        tieneAlarmaActivada: "",
        version: "",
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0, 0]
          }
        },
        variables: {
          analog: {},
          digital: {},
          dynamic: {},
        }
      },
      deviceConfigurationForm: {
        id: "",
        variableId: "",
        value: ""
      },
      deviceMap: undefined,
      deviceMarker: undefined
    }
  },
  computed: {
    bluePrintMarkerPosition() {
      let markerX = 0;
      let markerY = 0;
      let blueprintCanvas = document.getElementById('editDeviceBlueprint');
      if (blueprintCanvas != null) {
        let totalScrollLeft = document.getElementById('editDeviceScroll').scrollLeft;
        let totalScrollTop = document.getElementById('editDeviceScroll').scrollTop;

        let ratioX = blueprintCanvas.naturalWidth / blueprintCanvas.offsetWidth;
        let ratioY = blueprintCanvas.naturalHeight / blueprintCanvas.offsetHeight;

        let domX = this.editDeviceData.geoJSON.geometry.coordinates[0];
        let domY = this.editDeviceData.geoJSON.geometry.coordinates[1];

        markerX = Math.floor(domX / ratioX) + totalScrollLeft;
        markerY = Math.floor(domY / ratioY) + totalScrollTop;
      }
      return "color: red; position: absolute; top: " + markerY + "px; left: " + markerX + "px; margin-top: 2.2rem; margin-left: 0.4rem;"
    }
  },
  emits: ["toggleEditDeviceModal"],
  methods: {
    // DISPLAY
    displayDeviceMap() {
      if (this.deviceMap != undefined) {
        this.deviceMap.invalidateSize();
        this.deviceMap.remove();
      }
      var deviceMap = Leaflet.map("deviceMapContent");
      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang=' + this.session.user.language, { maxZoom: 19 }).addTo(deviceMap);
      deviceMap.on('click', this.onSetNewCoordinates)

      this.deviceMap = deviceMap;

      let deviceMapListForGroup = [];
      if ($(".leaflet-control-attribution").get()[0] != undefined) $(".leaflet-control-attribution").get()[0].remove();
      var deviceMarker = Leaflet.marker([this.editDeviceData.geoJSON.geometry.coordinates[0], this.editDeviceData.geoJSON.geometry.coordinates[1]]);
      deviceMarker.addTo(deviceMap);
      deviceMapListForGroup.push(deviceMarker);
      var deviceMapGroup = Leaflet.featureGroup(deviceMapListForGroup);
      this.deviceMap.fitBounds(deviceMapGroup.getBounds());

      this.deviceMarker = deviceMarker;
    },
    displayBlueprint() {
      setTimeout(() => {
        this.onPositionChanged();
      }, 300);
      let src = "";
      if (this.editDeviceData.parent != ""
        && this.editDeviceData.geoJSON != undefined
        && this.editDeviceData.geoJSON.properties != undefined
        && this.editDeviceData.geoJSON.properties.srcId != undefined)
        src = this.editDeviceData.geoJSON.properties.srcId;
      if (src != undefined && src != '') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero=' + src + '&identificadorusuario=' + this.session.user.id + '&Token=' + localStorage.getItem("ClomosAuthToken");
        }
        catch (e) {
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    onPositionChanged() {
      /* if (this.editDeviceData.parent!='')
        document.getElementById('editDeviceBlueprintMarker').style = this.bluePrintMarkerPosition;
      else { */
      this.deviceMap.removeLayer(this.deviceMarker);
      this.deviceMarker = Leaflet.marker([this.editDeviceData.geoJSON.geometry.coordinates[0], this.editDeviceData.geoJSON.geometry.coordinates[1]]).addTo(this.deviceMap);
      /* } */
      this.displayDeviceMap()
    },
    // SET
    async setEditDeviceData() {
      let response = await api.setDeviceData(this.editDeviceData);
      if (response["status-code"] === "200") {
        window.alert("El dispositivo ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el dispositivo.");
      }
      this.getEditDeviceData();
    },
    async setDeviceVariables() {
      let variablesData = {
        deviceId: this.editDeviceData.id
      };
      $(".inputEditDevice").get().forEach(input => {
        let variableLabel = input.id.replace("inputEditDevice", "");
        let ic = false;
        if (this.editDeviceData.variables.analog[variableLabel] != undefined
          && this.editDeviceData.variables.analog[variableLabel].isConfiguration === "true")
          ic = true;
        if (this.editDeviceData.variables.digital[variableLabel] != undefined
          && this.editDeviceData.variables.digital[variableLabel].isConfiguration === "true")
          ic = true;
        if (this.editDeviceData.variables.dynamic[variableLabel] != undefined
          && this.editDeviceData.variables.dynamic[variableLabel].isConfiguration === "true")
          ic = true;
        if (ic) {
          variablesData[variableLabel] = {
            value: (input.getAttribute("data-type") === "0")
              ? (input.getAttribute("data-value") === "true")
                ? 1
                : 0
              : input.value,
            id: input.getAttribute("data-id"),
            type: input.getAttribute("data-type")
          }
        }
      });
      let response = await api.setDeviceVariables(variablesData);
      if (response["status-code"] === "200") {
        window.alert("El dispositivo ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el dispositivo.");
      }
      this.getEditDeviceData();
    },
    onSetNewCoordinates(event) {
      if (this.editDeviceData.parent != "") {
        let target = event.target;
        let totalOffsetLeft = target.offsetLeft;
        let totalOffsetTop = target.offsetTop;
        let totalScrollLeft = target.scrollLeft;
        let totalScrollTop = target.scrollTop;
        while (target.offsetParent != undefined) {
          target = target.offsetParent;
          totalOffsetLeft += target.offsetLeft;
          totalOffsetTop += target.offsetTop;
          totalScrollLeft += target.scrollLeft;
          totalScrollTop += target.scrollTop;
        }
        let ratioX = event.target.naturalWidth / event.target.offsetWidth;
        let ratioY = event.target.naturalHeight / event.target.offsetHeight;

        let domX = event.x + totalScrollLeft - totalOffsetLeft;
        let domY = event.y + totalScrollTop - totalOffsetTop;

        this.editDeviceData.geoJSON.geometry.coordinates[0] = Math.floor(domX * ratioX);
        this.editDeviceData.geoJSON.geometry.coordinates[1] = Math.floor(domY * ratioY);

        this.onPositionChanged();
      }
      else {
        this.editDeviceData.geoJSON = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [Number(event.latlng.lat), Number(event.latlng.lng)]
          }
        }
        this.deviceMap.removeLayer(this.deviceMarker);
        this.deviceMarker = Leaflet.marker([this.editDeviceData.geoJSON.geometry.coordinates[0], this.editDeviceData.geoJSON.geometry.coordinates[1]]).addTo(this.deviceMap);
      }
    },
    async setDeviceConfiguration() {
      let response = await api.setDeviceConfiguration(this.deviceConfigurationForm);
      if (response["status-code"] === "200") {
        window.alert("El dispositivo ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el dispositivo.");
      }
      this.getEditDeviceData();
    },
    // GET 
    async getEditDeviceData(callback) {
      let response = await api.getDeviceData(this.deviceId);
      this.editDeviceData = api.parseDeviceFromResponse(response);
      this.deviceConfigurationForm.id = this.editDeviceData.id;
      if (this.editDeviceData.variables.dynamic['configuracion'] != undefined)
        this.deviceConfigurationForm = {
          id: this.editDeviceData.id,
          variableId: this.editDeviceData.variables.dynamic['configuracion'].variableId,
          value: this.editDeviceData.variables.dynamic['configuracion'].value
        }
      else
        this.deviceConfigurationForm = {
          id: this.editDeviceData.id,
          variableId: "",
          value: ""
        }
      if (callback && typeof callback === "function") callback(this.editDeviceData);
    }
  },
  async created() {
    await this.getEditDeviceData();
    /* if (this.editDeviceData.parent!='') setTimeout(()=>{this.onPositionChanged()}, 300)
    else */ setTimeout(() => { this.displayDeviceMap() }, 300);
  }
}
</script>

<style>
#editDeviceModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.inputEditDevice:disabled {
  color: dimGrey;
}
</style>