<template>
<!-- MODAL New Device -->
  <div id="newDeviceModal" class="col-12 justify-content-center pe-6"  style="padding: 15vh 25%">
    <div class="col-12 m-0 p-0 section-container rounded-5 shadow">

<!-- HEAD New Device -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="hardware-chip" class="tx-18 me-2 align-bottom"></ion-icon>
            {{dictionary['Nuevo dispositivo'][session.user.language]}}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleNewDeviceModal')">	
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT New Device -->
      <div class="col-12 bg-caja w-100 m-0 p-0 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="row m-0 p-2 card-body">

            <div class="col-lg-7 col-md-12 col-12 m-0 p-2">
              <form class="col-12 border rounded m-0 p-1 overflow-hidden">
                <div class="bg-menu text-white p-1 m-0 rounded"> 
                  {{dictionary['Dispositivo'][session.user.language]}}
                </div>
                <div class="container-fluid col-12 m-0 p-0 pt-2">
                
                  <div class="row">
<!-- NOMBRE -->
                    <div class="form-group col-6 p-1">
                      <label class="col-12 text-dark tx-bold tx-13">
                        {{dictionary['Nombre'][session.user.language]}}
                      </label>
                      <div class="col-md-12">
                        <input v-model="newDeviceData.label" id="inputNewDeviceLabel" type="text" 
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>
                    <div class="form-group col-6 p-1">
                      <label class="col-12 text-dark tx-bold tx-13">
                        {{dictionary['Identificador'][session.user.language]}}
                      </label>
                      <div class="col-md-12">
                        <input v-model="newDeviceData.id" id="inputNewDeviceLabel" type="text" 
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>

<!-- TIPO -->
                    <div class="form-group col-12 p-1">
                      <label class="col-12 text-dark tx-bold tx-13">
                        {{dictionary['Tipo'][session.user.language]}}
                      </label>
                      <div class="col-md-12">
                        <select v-model="newDeviceData.typeId" 
                        id="selectNewDeviceType"
                        class="form-control form-control-line" style="color:black;">
                          <option v-for="type in deviceTypeList" :key="type.id" :value="type.id">{{type.label}}</option>
                        </select>
                      </div>
                    </div>

                  </div>
              
                  <div class="row justify-content-around m-0 p-0 ps-1 pe-1">
<!-- LATITUD -->
                    <div class="form-group col-6 m-0 p-1 ps-2 pe-2">
                      <label class="col-12 m-0 p-0 text-dark tx-bold tx-13">
                        {{dictionary['Latitud'][session.user.language]}}
                      </label>
                      <div class="col-12 m-0 p-0">
                        <input v-model="newDeviceData.geoJSON.geometry.coordinates[0]" id="inputNewDeviceLat" type="number" step=0.00001
                        class="form-control form-control-line" style="color:black;"
                        @change="onPositionChanged">
                      </div>
                    </div>

<!-- LONGITUD -->
                    <div class="form-group col-6 m-0 p-1 ps-2 pe-2">
                      <label class="col-12 m-0 p-0 text-dark tx-bold tx-13">
                        {{dictionary['Longitud'][session.user.language]}}
                      </label>
                      <div class="col-12 m-0 p-0">
                        <input v-model="newDeviceData.geoJSON.geometry.coordinates[1]" id="inputNewDeviceLng" type="number" step=0.00001
                        class="form-control form-control-line" style="color:black;"
                        @change="onPositionChanged">
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-12 p-0 pt-3 pb-3">
                    <div class="col-12 m-0 p-0">
<!-- SUBMIT -->
                      <button type="button" @click="onCreateNewDevice"
                      id="btnSubmitCreateNewDevice" class="button-ok gradient-tablas rounded-3">
                        <span class="button-text text-white">
                          <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                          {{dictionary['Crear nuevo dispositivo'][session.user.language]}}
                        </span>
                      </button>
                    </div>
                  </div>

                </div>
              </form>
            </div>

            <div class="col-lg-5 col-md-12 col-12 m-0 p-2">
<!-- Device Map-->
              <div class="col-12 m-0 p-0">
                <div class="form-group col-12 border bg-menu rounded-5 col-12 m-0 p-0">
                  <div class="form-group m-0 p-3 pt-0">
                    <div class="bg-menu text-white p-1 m-0 rounded-5"> 
                      {{dictionary['Geoposición'][session.user.language]}}
                    </div>
<!-- CONTENT Map -->
                    <div id="deviceMapContent" class="m-0 p-3" style="width: 100%; height: 275px; index: 0; border-radius: 0.5rem;">
                    </div>
                  </div>
                </div>
              </div>
            </div>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
// Clomos
import api from '@/services/api';

export default {
  name: "NewDeviceModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newDeviceData: {
        nifCif: this.currentCompany.nifCif,
        label: "",
        typeId: "",
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0,0]
          }
        },
        id:''
      },
      deviceMap: undefined,
      deviceMarker: undefined,
      deviceTypeList: []
    }
  },
  emits: ["toggleNewDeviceModal"],
  methods: {
   // DISPLAY
    displayDeviceMap() {
      if (this.deviceMap != undefined) {
        this.deviceMap.invalidateSize();
        this.deviceMap.remove();
      }
      var deviceMap = Leaflet.map("deviceMapContent");
      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang='+this.session.user.language,{maxZoom: 19}).addTo(deviceMap);
      deviceMap.on('click', this.onSetNewCoordinates)
      
      this.deviceMap = deviceMap;
      
      let deviceMapListForGroup = []; 
      if ($(".leaflet-control-attribution").get()[0] != undefined) $(".leaflet-control-attribution").get()[0].remove();
      var deviceMarker = Leaflet.marker([this.newDeviceData.geoJSON.geometry.coordinates[0],this.newDeviceData.geoJSON.geometry.coordinates[1]]);
      deviceMarker.addTo(deviceMap);
      deviceMapListForGroup.push(deviceMarker);
      var deviceMapGroup = Leaflet.featureGroup(deviceMapListForGroup);
      this.deviceMap.fitBounds(deviceMapGroup.getBounds());

      this.deviceMarker = deviceMarker;
    },
    onPositionChanged() {
      this.deviceMap.removeLayer(this.deviceMarker);
      this.deviceMarker = Leaflet.marker([this.newDeviceData.geoJSON.geometry.coordinates[0],this.newDeviceData.geoJSON.geometry.coordinates[1]]).addTo(this.deviceMap);
      this.displayDeviceMap()
    },
    // SET
    async onCreateNewDevice() {
      let valid = true;
      if (this.newDeviceData.label === "") {
        $("#inputNewDeviceLabel").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newDeviceData.label === "") {
        $("#selectNewDeviceType").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputNewDeviceLabel").get()[0].classList.remove("missing")
          $("#selectNewDeviceType").get()[0].classList.remove("missing")
        }, 3000);
        return;
      }
      let response = await api.postNewDevice(this.newDeviceData);
      if (response["status-code"] === "201") {
        this.$emit('toggleNewDeviceModal');
        window.alert("El dispositivo ha sido creado satisfactoriamente.");
      }
      else {
        window.alert("Error al crear el dispositivo.");
      }
    },
    async getDeviceTypeList() {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getDeviceTypeList(request);
      for (let i in response.tiposDcl) [
        this.deviceTypeList[i] = {
          id: response.tiposDcl[i].idTipoDcl,
          label: response.tiposDcl[i].etiqueta,
        }
      ]
    },
    onSetNewCoordinates(event) {
      this.newDeviceData.geoJSON = {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [Number(event.latlng.lat), Number(event.latlng.lng)]
        }
      }
      this.deviceMap.removeLayer(this.deviceMarker);
      this.deviceMarker = Leaflet.marker([this.newDeviceData.geoJSON.geometry.coordinates[0],this.newDeviceData.geoJSON.geometry.coordinates[1]]).addTo(this.deviceMap);
    }
  },
  async created() {
    setTimeout(()=>{this.displayDeviceMap()}, 300);
    await this.getDeviceTypeList();
  }
}
</script>

<style>

#newDeviceModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>